import React from 'react'
import theme from 'theme'
import {
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { BalancedTank, Tank } from 'store/Site'
import { TelemetryMessage } from 'store/SiteDetails'
import {
  feetToFeetAndInches,
  getReadableProductionCode,
  getTankStrapFactor,
  inchToBbl,
  inchToFeetAndInches,
} from 'components/utils/converter'

interface Props {
  bTank: BalancedTank
  assetLatestTelemetry: TelemetryMessage
  handleBTankChange(
    tankId: string,
    property: string,
    value: any,
    isReciver: boolean,
    formValid: boolean
  ): void
  isAdmin: boolean
}

function formatTankLevelFeet(
  gaugedHeightInch: number | undefined,
  tank: BalancedTank | Tank
) {
  if (!gaugedHeightInch && gaugedHeightInch !== 0) {
    return undefined
  }
  const [tankLevelFt, tankLevelIn] = inchToFeetAndInches(gaugedHeightInch)
  const [heightFt, heightIn] = feetToFeetAndInches(tank.height)

  return `${tankLevelFt.toFixed()}' ${tankLevelIn.toFixed(
    1
  )}" / ${heightFt.toFixed()}' ${heightIn.toFixed(1)}"`
}

function formatTankLevelBarrels(
  gaugedHeightInch: number | undefined,
  tank: BalancedTank | Tank
) {
  if (!gaugedHeightInch && gaugedHeightInch !== 0) {
    return undefined
  }

  const tankLevelBbl = inchToBbl(gaugedHeightInch, tank.height, tank.capacity)

  return `${tankLevelBbl.toFixed(1)} / ${tank.capacity.toFixed()}`
}

let valid = true
const getValid = () => valid

export const BalancedTankAssetInfo = (props: Props) => {
  const payload = props.assetLatestTelemetry?.Payload ?? {}
  const tanksCount = 1
  const code = getReadableProductionCode(props.bTank.productionCode)

  const RenderReciverTank = () => {
    const tankLevelFt = props.bTank
      ? formatTankLevelFeet(
          payload?.telemetryValuePerTanks
            ? payload?.telemetryValuePerTanks[0].value
            : 0,
          props.bTank
        )
      : undefined
    const tankLevelBbl = props.bTank
      ? formatTankLevelBarrels(
          payload?.telemetryValuePerTanks
            ? payload?.telemetryValuePerTanks[0].value
            : 0,
          props.bTank
        )
      : undefined

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'grid',
            alignContent: 'flex-start',
            gridTemplateRows: '1fr 1fr',
            gridColumn: 2,
            gridRow: 1,
          }}
        >
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <Typography
              noWrap={true}
              variant="subtitle1"
              align="left"
              color="primary"
            >
              {`Tank ${tanksCount}: ID ${props.bTank.id}`}
            </Typography>
            <Typography noWrap={true} variant="h6" align="left">
              {tankLevelFt ?? 'N/A'}
            </Typography>
            <Typography noWrap={true} variant="caption" align="left">
              actual / tank height
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 2 }}
          >
            <Typography noWrap={true} variant="h6" align="left">
              {tankLevelBbl ? `${tankLevelBbl} bbl` : 'N/A'}{' '}
            </Typography>
            <Typography noWrap={true} variant="caption" align="left">
              actual / tank capacity
            </Typography>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
          }}
        >
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={props.bTank.isReciver}
                readOnly
                name="checkedA"
              />
            }
            label="Receiver Tank"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={!props.bTank.isInactive}
                readOnly
                name="checkedA"
              />
            }
            label="Active"
            labelPlacement="start"
          />
          <Typography noWrap={true} variant="body1" align="right">
            {`Strap factor: ${getTankStrapFactor(
              props.bTank.height,
              props.bTank.capacity
            ).toFixed(3)}`}
          </Typography>
        </div>
      </div>
    )
  }

  const getActiveTanks = () => {
    return (
      props.bTank.tanks.filter((tank) => tank.isInactive === false).length + 1
    )
  }

  return (
    <>
      <div
        style={{
          minWidth: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingRight: 5,
        }}
      >
        <div
          style={{
            display: 'inline-grid',
            alignContent: 'flex-start',
            marginBottom: theme.spacing(1.5),
          }}
        >
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <TextField
              required
              type="number"
              id="gravity"
              label="Gravity"
              value={props.bTank.gravity}
              onChange={(e) => {
                valid =
                  !e.target.validity.valueMissing &&
                  !e.target.validity.rangeUnderflow
                props.handleBTankChange(
                  props.bTank.id,
                  e.target.id,
                  e.target.value,
                  true,
                  getValid()
                )
              }}
              style={{
                width: 160,
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 2, gridRow: 1, justifyContent: 'end' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Chip
                label={code}
                variant="outlined"
                size="small"
                color="primary"
                style={{
                  marginRight: theme.spacing(1.5),
                }}
              />
              <Typography
                noWrap={true}
                variant="subtitle1"
                align="right"
                color="primary"
                display="inline"
              >
                {`${getActiveTanks()} ${
                  getActiveTanks() === 1 ? 'tank' : 'tanks'
                } active`}
              </Typography>
            </div>
          </Grid>
        </div>
        <RenderReciverTank />
        {props.bTank.tanks.map((tank, i) => {
          const tankLevelFt = tank
            ? formatTankLevelFeet(
                payload?.telemetryValuePerTanks
                  ? payload?.telemetryValuePerTanks[i + 1].value
                  : 0,
                tank
              )
            : undefined
          const tankLevelBbl = tank
            ? formatTankLevelBarrels(
                payload?.telemetryValuePerTanks
                  ? payload?.telemetryValuePerTanks[i + 1].value
                  : 0,
                tank
              )
            : undefined

          return (
            <div
              key={tank.id}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  alignContent: 'flex-start',
                  gridTemplateRows: '1fr 1fr',
                  gridColumn: 2,
                  gridRow: 1,
                }}
              >
                <Grid
                  container={true}
                  direction="column"
                  style={{ gridColumn: 1, gridRow: 1 }}
                >
                  <Typography
                    noWrap={true}
                    variant="subtitle1"
                    align="left"
                    color="primary"
                  >
                    {`Tank ${tanksCount + i + 1}: ID ${tank.id}`}
                  </Typography>
                  <Typography noWrap={true} variant="h6" align="left">
                    {tankLevelFt ?? 'N/A'}
                  </Typography>
                  <Typography noWrap={true} variant="caption" align="left">
                    actual / tank height
                  </Typography>
                </Grid>
                <Grid
                  container={true}
                  direction="column"
                  style={{ gridColumn: 1, gridRow: 2 }}
                >
                  <Typography noWrap={true} variant="h6" align="left">
                    {tankLevelBbl ? `${tankLevelBbl} bbl` : 'N/A'}{' '}
                  </Typography>
                  <Typography noWrap={true} variant="caption" align="left">
                    actual / tank capacity
                  </Typography>
                </Grid>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-start',
                }}
              >
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!props.isAdmin}
                      checked={!tank.isInactive}
                      onChange={(e) =>
                        props.handleBTankChange(
                          tank.id,
                          e.target.id,
                          !e.target.checked,
                          false,
                          getValid()
                        )
                      }
                      id="isInactive"
                      name="isInactive"
                    />
                  }
                  label={!tank.isInactive ? 'Active' : 'Inactive'}
                  labelPlacement="start"
                />
                <Typography noWrap={true} variant="body1" align="right">
                  {`Strap factor: ${getTankStrapFactor(
                    tank.height,
                    tank.capacity
                  ).toFixed(3)}`}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
