import { TelemetryType } from '../../api/apiservice'
import BaseSensor from './BaseSensor'

export default class CrankRevolutions extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.CrankRevolutions, 'Crank Revolutions')
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
