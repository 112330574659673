import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  createStyles,
  IconButton,
  LinearProgress,
  Slide,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
} from '@material-ui/core'
import {
  ChevronLeftTwoTone,
  ChevronRightTwoTone,
  ExpandMoreTwoTone,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { Cluster } from '@react-google-maps/marker-clusterer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import theme from 'theme'
import { getSiteClient } from '../api/client'
import {
  DrillDownCluster,
  DrillDownClusterType,
  OwnerType,
  SiteClient,
} from '../api/apiservice'
import { idRoute, sitesRoute } from '../App'
import { actionCreators, Selection } from '../store/AllSites'
import { AppState } from '../store/AppState'
import { ModelState } from '../store/ModelState'
import { mapSite, Site } from '../store/Site'
import { defaultTelemetryState } from '../store/SiteDetails'
import {
  MySitesContextData,
  withMySitesContext,
} from './contexts/MySitesContext'
import ListController from './sideMenu/ListController'
import { UiContextData, withUiContext } from './contexts/UiContext'
import { SiteContextData, withSiteContext } from './contexts/SiteContext'
import { Place } from './map/Map'
import { renderErrorAlert } from './misc/utils'
import SiteDetails, { SiteMatch } from './SiteDetails'
import AllSitesMap, {
  getCluster,
  WithDrillDownCluster,
} from './sites/AllSitesMap'
import MySites from './my-sites/MySites'
import SitesMap from './sites/SitesMap'
import { AllProps as SiteDetailsAllProps } from './site/MetricsDashboardTab'
import { MultitenantUserState } from 'store/reducers/multitenant'
import * as LDClient from 'launchdarkly-js-client-sdk'

export const SiteDetailsOneColumn = (
  props: Omit<SiteDetailsAllProps, 'oneColumn'>
) => (
  <div style={{ overflow: 'hidden', height: '100%' }}>
    <SiteDetails {...props} oneColumn={true} hideGaugeBreakpoint="sm" />
  </div>
)

const styles = (theme: Theme) =>
  createStyles({
    tile: {
      padding: theme.spacing(0.5),
      overflow: 'hidden',
      display: 'grid',
      alignItems: 'start',
    },
    siteCellHighlighted: {
      background: theme.palette.grey[100],
    },
    summaryRoot: {
      paddingRight: theme.spacing(1),
      '&$expanded': {
        minHeight: theme.spacing(6),
        margin: 0,
      },
    },
    summaryContent: {
      '&$expanded': {
        margin: 0,
      },
    },
    detailsRoot: {
      paddingLeft: theme.spacing(2),
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
    },
    accordionRoot: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
      '&:before': {
        backgroundColor: 'unset',
      },
      boxShadow: 'unset',
    },
    expanded: {},
  })

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly mapApiKey?: string
  readonly selection: Selection
  readonly selectedSite?: Site
  readonly selectedClusterType?: number
  readonly user: MultitenantUserState
  readonly userId?: string
  readonly launchDarklyClientsideId?: string
  readonly tenantName: string
  readonly clientName?: string
}

type PropsFromDispatch = typeof actionCreators

type AllSitesMatch = Partial<SiteMatch>

type AllProps = PropsFromState &
  PropsFromDispatch &
  WithStyles<typeof styles> &
  UiContextData &
  MySitesContextData &
  SiteContextData &
  RouteComponentProps<AllSitesMatch>

interface DrillDownCounties {
  [county: string]: DrillDownCluster
}

interface DrillDownState extends WithDrillDownCluster {
  readonly counties: DrillDownCounties
}

interface DrillDownStates {
  [state: string]: DrillDownCluster | DrillDownState
}

interface DrillDown extends WithDrillDownCluster {
  readonly states: DrillDownStates
}

interface DrillDownPumpers {
  [pumper: string]: DrillDownCluster
}

interface State extends ModelState, SiteContextData {
  readonly sites: Site[]
  readonly statuses?: { siteId: string; status: number }[]
  readonly selection: Selection
  readonly drillDown: DrillDown
  readonly drillDownOwners: DrillDownPumpers
  readonly drillDownCollapsed?: boolean
  readonly clusterTypeSelected: number
  readonly prevClusterTypeSelected?: number
  readonly tenantId: string
}

interface ClusterPlace extends Place {
  readonly level: Selection
}

class AllSites extends React.Component<AllProps, State> {
  flags: any
  showSuperIntendent: true | undefined
  constructor(props: AllProps) {
    super(props)
    this.state = {
      sites: [],
      drillDown: {
        states: {},
        cluster: new DrillDownCluster(),
      },
      drillDownOwners: {
        cluster: new DrillDownCluster(),
      },
      selection: {},
      telemetry: defaultTelemetryState,
      clusterTypeSelected:
        props.selectedClusterType ?? DrillDownClusterType.State,
      tenantId: props.tenantId.toString(),
    }
  }

  private readonly allPlaces = new Map<string, ClusterPlace>()

  tenantStorage = localStorage.getItem('selectedTenantId') ?? '0'

  private featureFlag() {
    const user: LDClient.LDUser = {
      key: this.props.tenantId.toString(),
    }

    const LaunchDarklyClientsideId = this.props.launchDarklyClientsideId!
    const options: LDClient.LDOptions = { bootstrap: 'localStorage' }
    const client = LDClient.initialize(LaunchDarklyClientsideId, user, options)

    client
      .waitForInitialization()
      .then(() => {
        this.flags = client.allFlags()

        if ('ShowSuperIntendent' in this.flags) {
          this.showSuperIntendent = this.flags.ShowSuperIntendent
        } else {
          this.showSuperIntendent = true
        }
      })
      .catch((err) => {
        console.error(
          '🚀 ~ file: AllSites.tsx ~ client.waitForInitialization ~ err',
          err
        )
      })
  }

  public componentDidMount() {
    // this.componentDidUpdate(this.props)
    this.flags = {}

    const { id } = this.props.match.params

    if (!id) {
      this.props.setPageTitle!('All Sites')
    }
    if (this.props.tenantId.toString() === '0') {
      const valor = localStorage.getItem('selectedTenantId')
      if (valor) {
        this.setState({ tenantId: valor })
      }
    }
    this.requestDrillDown(() => this.selectLevel(true, this.props.selection))
    this.featureFlag()
  }

  public componentWillUnmount() {
    this.props.changeSelection(this.state.selection)
    this.featureFlag()
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { id } = this.props.match.params
    const { selectedSite, accessToken, tenantId } = this.props
    this.featureFlag()
    if (
      (prevProps.accessToken !== accessToken && tenantId) ||
      (tenantId !== prevProps.tenantId && accessToken)
    ) {
      const tenantHasChanged = !!(
        prevProps.tenantId && tenantId !== prevProps.tenantId
      )
      this.requestDrillDown(
        () => this.selectLevel(true, this.props.selection, tenantHasChanged),
        tenantHasChanged
      )
    }

    if (!id) {
      if (prevProps.match.params.id) {
        this.props.setPageTitle!('All Sites')
      }
    } else if (
      selectedSite &&
      id === selectedSite?.id &&
      id !== this.state.selectedSiteId
    ) {
      this.setSelectedSiteId(false, id, () => {
        const { state, county } = selectedSite
        const { selection } = this.state
        if (state !== selection.state || county !== selection.county) {
          const newSelection: Selection = {
            state,
            county,
          }
          this.props.changeSelection(newSelection)
          this.selectLevel(true, newSelection)
        }
      })
    }
  }

  public render() {
    const { tile } = this.props.classes
    const { error, drillDownCollapsed: collapsed } = this.state
    const { path } = this.props.match
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <div style={{ overflow: 'hidden auto' }} className={tile}>
          {error && renderErrorAlert(error)}
          <Slide in={collapsed} direction="right" unmountOnExit={true}>
            {this.renderCollapsedTree()}
          </Slide>
          <Slide in={!collapsed} direction="right" unmountOnExit={true}>
            {this.renderTree()}
          </Slide>
        </div>
        <div className={tile}>
          <Switch>
            <Route path={path + idRoute} component={SiteDetailsOneColumn} />
            <Route
              path={path}
              render={() => this.renderMapAndSites()}
              exact={true}
            />
          </Switch>
        </div>
      </div>
    )
  }

  private renderMapAndSites() {
    const { sites, selection } = this.state

    if (selection.county === undefined) {
      return this.renderMap()
    }

    const {
      classes: { tile },
      showMap,
    } = this.props

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: showMap ? '3fr 7fr' : '1fr',
          overflow: 'auto',
          height: '100%',
        }}
      >
        {showMap && <div className={tile}>{this.renderMap()}</div>}
        <div className={tile}>
          <MySites
            key={sites.map((w) => w.id).join(',')}
            mySites={sites}
            siteRoute="sites"
            statuses={this.state.statuses}
            setSiteStatus={({
              siteId,
              status,
            }: {
              siteId: string
              status: number
            }) => this.setSiteStatus({ siteId, status })}
          />
        </div>
      </div>
    )
  }

  private readonly setSiteStatus = ({
    siteId,
    status,
  }: {
    siteId: string
    status: number
  }) => {
    const exist = this.state.statuses?.find(
      (s) => s.siteId === siteId && s.status === status
    )

    if (!exist) {
      const statuses = this.state?.statuses?.length
        ? [
            ...this.state.statuses.filter((s) => s.siteId !== siteId),
            { siteId, status },
          ]
        : [{ siteId, status }]

      if (statuses) {
        this.setState({ ...this.state, statuses: [...statuses] })
      }
    }
  }

  private readonly renderMap = () => {
    if (this.state.loading) {
      return <LinearProgress style={{ width: '100%' }} />
    }

    const { state, county } = this.state.selection

    if (state === undefined) {
      return this.renderStatesMap()
    }
    if (county === undefined) {
      return this.renderCountiesMap(state)
    }

    return (
      <SitesMap mySites={this.state.sites} statuses={this.state.statuses} />
    )
  }

  private readonly renderSimpleMap = (places: Place[]) =>
    places.length ? (
      <AllSitesMap places={places} onClusterClick={this.onClusterClick} />
    ) : undefined

  private readonly renderStatesMap = () => {
    const { states } = this.state.drillDown
    const places: Place[] = Object.keys(states).map((s) => {
      const state = states[s]
      const cluster = getCluster(state)
      const place: ClusterPlace = {
        id: s,
        pos: {
          lat: (cluster.latMin + cluster.latMax) / 2.0,
          lng: (cluster.lonMin + cluster.lonMax) / 2.0,
        },
        title: `${s} (${cluster.count})`,
        payload: cluster,
        level: { state: s },
      }

      this.allPlaces.set(s, place)

      return place
    })

    return this.renderSimpleMap(places)
  }

  private readonly renderCountiesMap = (stateName: string) => {
    const state = this.state.drillDown.states[stateName]

    if (state instanceof DrillDownCluster) {
      return undefined
    }

    const { counties } = state
    const places: Place[] = Object.keys(counties).map((c) => {
      const county = counties[c]
      const cluster = getCluster(county)
      const place: ClusterPlace = {
        id: c,
        pos: {
          lat: (cluster.latMin + cluster.latMax) / 2.0,
          lng: (cluster.lonMin + cluster.lonMax) / 2.0,
        },
        title: `${c} (${cluster.count})`,
        payload: cluster,
        level: { state: stateName, county: c },
      }

      this.allPlaces.set(`${stateName}-${c}`, place)

      return place
    })

    return this.renderSimpleMap(places)
  }

  private readonly renderTree = () => {
    const { drillDown, loading } = this.state

    this.allPlaces.clear()

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'start',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {loading && !drillDown.cluster.count ? (
            <LinearProgress />
          ) : (
            this.renderStates()
          )}
        </div>
        <Tooltip title="Hide drill down">
          <IconButton
            onClick={() =>
              this.setState({
                drillDownCollapsed: true,
              })
            }
          >
            <ChevronLeftTwoTone />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  private readonly renderCollapsedTree = () => {
    const {
      selection: { state, county },
      loading,
    } = this.state
    return (
      <div>
        <Tooltip title="Show drill down">
          <IconButton
            onClick={() =>
              this.setState({
                drillDownCollapsed: false,
              })
            }
          >
            <ChevronRightTwoTone />
          </IconButton>
        </Tooltip>
        <div>
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography
              style={{
                transform: 'rotate(90deg)',
                marginLeft: -theme.spacing(1.5),
                marginTop: theme.spacing(3),
              }}
            >
              {state}
              {county && ` - ${county}`}
            </Typography>
          )}
        </div>
      </div>
    )
  }

  private readonly onStateOrRegionSelection = (selection: number) => (_) => {
    if (selection !== this.state.clusterTypeSelected) {
      const siteApi = getSiteClient(
        this.props.accessToken!,
        this.props.tenantId.toString()
      )

      this.setState(
        (prevState) => {
          return {
            sites: [],
            drillDown: {
              states: {},
              cluster: new DrillDownCluster(),
            },
            selection: {},
            prevClusterTypeSelected: prevState.clusterTypeSelected,
            clusterTypeSelected: selection,
          }
        },
        async () => {
          const { state, county, pumper } = this.state.selection
          const { clusterTypeSelected } = this.state
          const { history } = this.props

          if (clusterTypeSelected === DrillDownClusterType.Owners) {
            try {
              if (this.showSuperIntendent) {
                await this.requestDrillDownCounts(
                  siteApi,
                  state,
                  county,
                  pumper
                )
              } else {
                await this.requestDrillDownCounts(siteApi, state, county)
              }

              this.props.selectClusterType(selection)
              history.push(sitesRoute)
              this.props.setPageTitle!('All Sites')
            } catch (error) {
              console.error(error)
            }
          } else {
            try {
              await this.requestDrillDownCounts(siteApi, state, county, pumper)
              this.props.selectClusterType(selection)
              history.push(sitesRoute)
              this.props.setPageTitle!('All Sites')
            } catch (error) {
              console.error(error)
            }
          }
        }
      )
    }
  }

  private renderStates() {
    const { classes } = this.props
    const { drillDown } = this.state
    const states = drillDown.states
    const renderedStates = Object.keys(states)
      .sort((a, b) => a.localeCompare(b))
      .map((state) => this.renderState(state, states[state]))

    return (
      <React.Fragment>
        <Accordion
          classes={{ root: classes.accordionRoot }}
          expanded={
            this.state.clusterTypeSelected === DrillDownClusterType.State
          }
          onChange={this.onStateOrRegionSelection(DrillDownClusterType.State)}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreTwoTone />}
          >
            <Typography>State</Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: this.props.classes.detailsRoot,
            }}
          >
            <div style={{ width: '100%' }}>{renderedStates}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{ root: classes.accordionRoot }}
          expanded={
            this.state.clusterTypeSelected === DrillDownClusterType.Region
          }
          onChange={this.onStateOrRegionSelection(DrillDownClusterType.Region)}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreTwoTone />}
          >
            <Typography>Region Operation</Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: this.props.classes.detailsRoot,
            }}
          >
            <div style={{ width: '100%' }}>{renderedStates}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{ root: classes.accordionRoot }}
          expanded={
            this.state.clusterTypeSelected === DrillDownClusterType.Owners
          }
          onChange={this.onStateOrRegionSelection(DrillDownClusterType.Owners)}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreTwoTone />}
          >
            <Typography>
              {this.showSuperIntendent ? 'Superintendent' : 'Foreman'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: this.props.classes.detailsRoot,
            }}
          >
            <div style={{ width: '100%' }}>{renderedStates}</div>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }

  private renderState(
    stateName: string,
    state: DrillDownCluster | DrillDownState
  ) {
    const selection: Selection = {
      state: stateName,
    }
    const expanded = stateName === this.state.selection.state
    const content: () => [JSX.Element[], number] = () => {
      const dds = state as DrillDownState
      return [
        [
          <React.Fragment key={stateName}>
            <Typography
              variant="caption"
              style={{
                marginLeft: theme.spacing(2),
              }}
            >
              {this.state.clusterTypeSelected === DrillDownClusterType.State
                ? 'County'
                : this.state.clusterTypeSelected === DrillDownClusterType.Owners
                ? this.showSuperIntendent
                  ? 'Foreman'
                  : 'Pumper/Route'
                : 'Field'}
            </Typography>
            {this.state.clusterTypeSelected !== DrillDownClusterType.Owners &&
            !this.showSuperIntendent
              ? this.renderStateContent(dds, selection)
              : this.renderSuperIntendentContent(dds, selection)}
          </React.Fragment>,
        ],
        0,
      ]
    }
    const name = stateName.trim()
      ? stateName
      : this.state.clusterTypeSelected === DrillDownClusterType.State
      ? 'W/o state'
      : this.state.clusterTypeSelected === DrillDownClusterType.Owners
      ? this.showSuperIntendent
        ? 'W/o superintendent'
        : 'W/o foreman'
      : 'W/o region'

    return this.renderDrillDownContent(
      name,
      state,
      expanded,
      content,
      selection,
      theme.palette.primary.main
    )
  }

  private isSameSelection(
    { state, county }: Selection,
    otherSelection: Selection
  ) {
    return state === otherSelection.state && county === otherSelection.county
  }

  private renderDrillDownContent<T extends WithDrillDownCluster>(
    name: string,
    drillDown: DrillDownCluster | T,
    expanded: boolean,
    content: () => [JSX.Element[], number],
    selection: Selection,
    titleColor?: string
  ) {
    if (
      this.state.clusterTypeSelected !== DrillDownClusterType.Owners ||
      !this.showSuperIntendent
    ) {
      const { classes } = this.props
      const count = getCluster(drillDown).count
      const { sites } = this.state
      const withContent =
        drillDown instanceof DrillDownCluster ? undefined : content()
      const renderedContent = withContent?.[0]
      const realCount = withContent?.[1]
      const countText =
        !realCount || count === realCount ? count : `${realCount} of ${count}`
      const style: React.CSSProperties = {
        color: titleColor,
        fontWeight: this.isSameSelection(selection, this.state.selection)
          ? 'bold'
          : undefined,
      }
      const sitesObject = Object.keys(sites)

      return (
        <Accordion
          key={name}
          classes={{ root: classes.accordionRoot }}
          expanded={expanded}
          onChange={this.onExpandedChange(selection)}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreTwoTone style={style} />}
          >
            <Typography style={style}>
              {name}&nbsp;({countText})
            </Typography>
          </AccordionSummary>
          {expanded && renderedContent && (
            <AccordionDetails
              classes={{
                root: this.props.classes.detailsRoot,
              }}
            >
              <div style={{ width: '100%' }}>
                {renderedContent || (
                  <LinearProgress style={{ width: '100%' }} />
                )}
                {selection.county !== undefined && sitesObject.length > 0 && (
                  <ListController
                    siteList={sites}
                    siteRoute="sites"
                    selection={this.props.selectedSite}
                  />
                )}
              </div>
            </AccordionDetails>
          )}
        </Accordion>
      )
    } else {
      const { classes } = this.props
      const count = getCluster(drillDown).count
      const { sites } = this.state
      const withContent =
        drillDown instanceof DrillDownCluster ? undefined : content()
      const renderedContent = withContent?.[0]
      const realCount = withContent?.[1]
      const countText =
        !realCount || count === realCount ? count : `${realCount} of ${count}`
      const style: React.CSSProperties = {
        color: titleColor,
        fontWeight: this.isSameSelection(selection, this.state.selection)
          ? 'bold'
          : undefined,
      }
      const entriesPumpers = Object.entries(this.state.drillDownOwners)

      return (
        <Accordion
          key={name}
          classes={{ root: classes.accordionRoot }}
          expanded={expanded}
          onChange={this.onExpandedChange(selection)}
        >
          <AccordionSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreTwoTone style={style} />}
          >
            <Typography style={style}>
              {name}&nbsp;({countText})
            </Typography>
          </AccordionSummary>
          {expanded && renderedContent && (
            <AccordionDetails
              classes={{
                root: this.props.classes.detailsRoot,
              }}
            >
              <div style={{ width: '100%' }}>
                {renderedContent || (
                  <LinearProgress style={{ width: '100%' }} />
                )}
                {selection.county !== undefined &&
                  this.state.clusterTypeSelected ===
                    DrillDownClusterType.Owners && (
                    <Accordion
                      key={name + '_pumper'}
                      classes={{ root: classes.accordionRoot }}
                      expanded={expanded}
                      onChange={this.onExpandedChange(selection)}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          marginLeft: theme.spacing(2),
                        }}
                      >
                        Pumper/Route
                      </Typography>
                      <>
                        {entriesPumpers.map((item) => {
                          const [name, data] = item

                          return (
                            <>
                              <AccordionSummary
                                classes={{
                                  root: classes.summaryRoot,
                                  content: classes.summaryContent,
                                  expanded: classes.expanded,
                                }}
                                expandIcon={<ExpandMoreTwoTone style={style} />}
                                onClick={() =>
                                  this.querySitesByCounty(selection, name)
                                }
                              >
                                <Typography style={style}>
                                  {name}&nbsp;({data.count})
                                </Typography>
                              </AccordionSummary>

                              {this.state.selection.pumper === name && (
                                <AccordionDetails
                                  classes={{
                                    root: this.props.classes.detailsRoot,
                                  }}
                                >
                                  <div style={{ width: '100%' }}>
                                    {this.state.selection.pumper === name &&
                                      this.state.clusterTypeSelected ===
                                        DrillDownClusterType.Owners && (
                                        <ListController
                                          siteList={sites}
                                          siteRoute="sites"
                                          selection={this.props.selectedSite}
                                        />
                                      )}
                                  </div>
                                </AccordionDetails>
                              )}
                            </>
                          )
                        })}
                      </>
                    </Accordion>
                  )}
              </div>
            </AccordionDetails>
          )}
        </Accordion>
      )
    }
  }

  private readonly onExpandedChange =
    (selection: Selection) => (_, expanded: boolean) => {
      const {
        match: { url },
        history,
      } = this.props

      if (expanded) {
        this.selectLevel(expanded, selection)

        if (url !== sitesRoute) {
          history.push(sitesRoute)
        }
      } else {
        if (url !== sitesRoute) {
          this.selectLevel(true, selection)
          history.push(sitesRoute)
        } else {
          this.selectLevel(expanded, selection)
        }
      }
    }

  private readonly querySitesByCounty = async (selection, name) => {
    if (selection.county !== undefined) {
      // QuerySitesByCounty
      const { state } = this.state.selection

      if (state === undefined) {
        return
      }

      const { accessToken, tenantId } = this.props
      if (!accessToken || !tenantId) {
        return
      }

      const api = getSiteClient(accessToken, tenantId.toString())

      await this.requestDrillDownFilterPumpers(api, name)

      this.setState({
        selection: {
          state: selection.state,
          county: selection.county,
          pumper: name,
        },
      })
    }
  }

  private readonly selectLevel = (
    selected: boolean,
    selection: Selection,
    tenantHasChanged = false
  ) => {
    const { state, county, pumper } = selected
      ? selection
      : this.drillUp(selection)
    const sites = county !== undefined ? this.state.sites : []

    this.setState(
      {
        sites: sites,
        selection: { state, county: undefined, pumper },
      },
      () => {
        this.requestDrillDown(() => {
          if (county !== undefined) {
            this.setState(
              {
                sites: sites,
                selection: { state, county, pumper },
              },
              () => this.requestDrillDown(undefined, tenantHasChanged)
            )
          }

          this.props.setMapSettings!(!!this.props.showMap, county === undefined)
        }, tenantHasChanged)
      }
    )
  }

  private drillUp(selection: Selection): Selection {
    const { state, county, pumper } = selection
    let levels: any
    if (this.showSuperIntendent) {
      levels = [state, county, pumper].filter((l) => l !== undefined)
    } else {
      levels = [state, county].filter((l) => l !== undefined)
    }

    levels.pop()
    const newLevel: Selection = levels.reduce((selected, l, i) => {
      switch (i) {
        case 0:
          return { state: l }

        case 1:
          return { ...selected, county: l }

        case 2:
          return { ...selected, supervisor: l }

        default:
          return selected
      }
    }, {})

    return newLevel
  }

  private renderStateContent(state: DrillDownState, selection: Selection) {
    const keys = Object.keys(state.counties)
    const counties = keys.map((county) =>
      this.renderCounty(county, state.counties[county], selection)
    )

    return counties
  }

  private renderSuperIntendentContent(
    state: DrillDownState,
    selection: Selection
  ) {
    const keys = Object.keys(state.counties)
    const counties = keys.map((county) =>
      this.renderForemans(county, state.counties[county], selection)
    )

    return counties
  }

  private readonly renderCounty = (
    countyName: string,
    county: DrillDownCluster,
    stateLevel: Selection
  ) => {
    const { selection } = this.state
    const expanded = selection.county === countyName
    const level: Selection = {
      ...stateLevel,
      county: countyName,
    }
    const content: () => [JSX.Element[], number] = () => [[], county.count]
    const t: WithDrillDownCluster = {
      cluster: county,
    }
    const name = countyName.trim()
      ? countyName
      : this.state.clusterTypeSelected === DrillDownClusterType.State
      ? 'W/o state'
      : this.state.clusterTypeSelected === DrillDownClusterType.Owners
      ? this.showSuperIntendent
        ? 'W/o superintendent'
        : 'W/o foreman'
      : 'W/o region'

    return this.renderDrillDownContent(name, t, expanded, content, level)
  }

  private readonly renderForemans = (
    countyName: string,
    county: DrillDownCluster,
    stateLevel: Selection
  ) => {
    const { selection } = this.state
    const expanded = selection.county === countyName
    const level: Selection = {
      ...stateLevel,
      county: countyName,
    }
    const content: () => [JSX.Element[], number] = () => [[], county.count]
    const t: WithDrillDownCluster = {
      cluster: county,
    }
    const name = countyName.trim()
      ? countyName
      : this.state.clusterTypeSelected === DrillDownClusterType.State
      ? 'W/o state'
      : this.state.clusterTypeSelected === DrillDownClusterType.Owners
      ? this.showSuperIntendent
        ? 'W/o superintendent'
        : 'W/o foreman'
      : 'W/o region'

    return this.renderDrillDownContent(name, t, expanded, content, level)
  }

  private readonly setSelectedSiteId = (
    toggleHighlight: boolean,
    selectedSiteId?: string,
    callback?: () => void
  ) =>
    this.setState(
      {
        selectedSiteId: toggleHighlight
          ? selectedSiteId === this.state.selectedSiteId
            ? undefined
            : selectedSiteId
          : selectedSiteId,
      },
      callback
    )

  private requestDrillDown(
    afterOkRequest?: () => void,
    tenantHasChanged = false
  ) {
    const { state, county, pumper } = this.state.selection
    const supervisor = state
    const foreman = county

    const { clusterTypeSelected } = this.state

    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const api = getSiteClient(accessToken, tenantId.toString())

    if (
      clusterTypeSelected === DrillDownClusterType.Owners &&
      this.showSuperIntendent
    ) {
      this.setState({ loading: true, sites: [] }, async () => {
        try {
          if (
            !tenantHasChanged &&
            supervisor !== undefined &&
            foreman !== undefined &&
            pumper !== undefined
          ) {
            await this.requestDrillDownFilter(api, supervisor, foreman)
          } else {
            // await this.requestDrillDownCountsOwners(
            await this.requestDrillDownCounts(
              api,
              supervisor,
              foreman,
              pumper,
              tenantHasChanged
            )
          }
          this.setState({ error: undefined }, () => {
            if (afterOkRequest) {
              afterOkRequest()
            }

            const { history } = this.props

            if (history.location.pathname !== sitesRoute) {
              history.push(sitesRoute)
            }
          })
        } catch (e) {
          this.setState({ error: e.toString() })
        } finally {
          this.setState({ loading: false })
        }
      })
    } else {
      this.setState({ loading: true, sites: [] }, async () => {
        try {
          if (this.showSuperIntendent) {
            if (
              !tenantHasChanged &&
              state !== undefined &&
              county !== undefined
            ) {
              await this.requestDrillDownFilter(api, state, county)
            } else {
              await this.requestDrillDownCounts(
                api,
                state,
                county,
                pumper,
                tenantHasChanged
              )
            }
          } else {
            if (
              !tenantHasChanged &&
              state !== undefined &&
              county !== undefined
            ) {
              await this.requestDrillDownFilter(api, state, county)
            } else {
              await this.requestDrillDownCounts(
                api,
                state,
                county,
                undefined,
                tenantHasChanged
              )
            }
          }

          this.setState({ error: undefined }, () => {
            if (afterOkRequest) {
              afterOkRequest()
            }

            const { history } = this.props

            if (history.location.pathname !== sitesRoute) {
              history.push(sitesRoute)
            }
          })
        } catch (e) {
          this.setState({ error: e.toString() })
        } finally {
          this.setState({ loading: false })
        }
      })
    }
  }

  private readonly requestDrillDownFilter = async (
    api: SiteClient,
    state: string,
    county: string
  ) => {
    const exactQuery = county === ''
    const sites = (
      await api.drillDown(
        exactQuery,
        this.state.clusterTypeSelected === DrillDownClusterType.State
          ? state
          : null,
        exactQuery
          ? undefined
          : this.state.clusterTypeSelected === DrillDownClusterType.State
          ? county
          : undefined,
        null,
        this.state.clusterTypeSelected === DrillDownClusterType.Region
          ? state
          : null,
        exactQuery
          ? undefined
          : this.state.clusterTypeSelected === DrillDownClusterType.Region
          ? county
          : undefined,
        exactQuery
          ? undefined
          : this.state.clusterTypeSelected === DrillDownClusterType.Owners
          ? state
          : undefined,
        OwnerType.Foreman,
        this.state.clusterTypeSelected,
        parseInt(this.state.tenantId)
      )
    ).result.map(mapSite)

    this.setState({ sites }, () => {
      if (this.state.sites.length === 1) {
        this.setSelectedSiteId(false, this.state.sites[0].id)
      }
    })
  }

  private readonly requestDrillDownFilterPumpers = async (
    api: SiteClient,
    state: string
  ) => {
    const exactQuery = false
    const sites = (
      await api.drillDown(
        exactQuery,
        null,
        undefined,
        null,
        null,
        undefined,
        this.state.clusterTypeSelected === DrillDownClusterType.Owners
          ? state
          : undefined,
        OwnerType.Pumper,
        this.state.clusterTypeSelected,
        parseInt(this.state.tenantId)
      )
    ).result.map(mapSite)

    this.setState({ sites }, () => {
      if (this.state.sites.length === 1) {
        this.setSelectedSiteId(false, this.state.sites[0].id)
      }
    })
  }

  private readonly requestDrillDownCounts = async (
    api: SiteClient,
    state?: string,

    county?: string,
    pumper?: string,
    tenantHasChanged = false
  ) => {
    if (state === undefined) {
      const states: DrillDownStates = (
        await api.drillDownClusters(
          null,
          null,
          null,
          null,
          null,
          this.showSuperIntendent
            ? OwnerType.SuperIntendent
            : OwnerType.Foreman,
          this.state.clusterTypeSelected,
          parseInt(this.state.tenantId)
        )
      ).result

      const statesCount = Object.keys(states)
        .map((s) => getCluster(states[s]).count)
        .reduce((count, c) => count + c, 0)
      const cluster = new DrillDownCluster()
      cluster.count = statesCount
      const drillDown: DrillDown = {
        states,
        cluster,
      }
      this.setState({ drillDown })
    } else if (county === undefined) {
      const prevState = this.state.drillDown.states[state]
      if (prevState instanceof DrillDownCluster) {
        const counties: DrillDownCounties = (
          await api.drillDownClusters(
            this.state.clusterTypeSelected === DrillDownClusterType.State
              ? state
              : null,
            null,
            this.state.clusterTypeSelected === DrillDownClusterType.Region
              ? state
              : null,
            null,
            state,
            this.showSuperIntendent ? OwnerType.Foreman : OwnerType.Pumper,
            this.state.clusterTypeSelected,
            parseInt(this.state.tenantId)
          )
        ).result
        const drillDown: DrillDown = {
          ...this.state.drillDown,
          states: {
            ...this.state.drillDown.states,
            [state]: {
              counties,
              cluster: prevState,
            },
          },
        }

        this.setState({ drillDown })
      }
    } else if (this.showSuperIntendent && pumper === undefined) {
      const counties: DrillDownPumpers = (
        await api.drillDownClusters(
          null,
          null,
          null,
          null,
          county,
          OwnerType.Pumper,
          this.state.clusterTypeSelected,
          parseInt(this.state.tenantId)
        )
      ).result
      this.setState({ drillDownOwners: counties })
    }
  }

  private readonly onClusterClick = (cluster: Cluster) => {
    const markers = cluster.getMarkers()
    const bounds = cluster.getBounds()

    if (markers.length === 1) {
      const place = (markers[0] as any)?.payload as ClusterPlace

      if (place) {
        this.selectLevel(true, place.level)
      }
    }

    if (markers.length > 1) {
      ;(cluster.getMap() as google.maps.Map).fitBounds(bounds)
    }
  }
}

const mapStateToProps = ({
  appConfig,
  allSites,
  multitenantUser,
}: AppState): PropsFromState => ({
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  accessToken: multitenantUser.accessToken,
  userId: multitenantUser.id,
  user: multitenantUser,
  selection: allSites.selection,
  selectedClusterType: allSites.selectedClusterType,
  clientName: appConfig.clientName,
  launchDarklyClientsideId: appConfig.launchDarklyClientsideId,
  tenantName: multitenantUser.selectedTenant?.name || '',
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actionCreators, dispatch)

// export default withRouter(
//   withStyles(styles)(
//     connect(
//       mapStateToProps,
//       mapDispatchToProps
//     )(withUiContext(withMySitesContext(withSiteContext(AllSites))))
//   )
// )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(styles)(
      withUiContext(withMySitesContext(withSiteContext(AllSites)))
    )
  )
)
