import axios from 'axios'
import { commandsApiBasePath } from './fetch'

export const getWellStatusRequest = (accessToken, tenantId, requestBody) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }
  return axios.post(`${commandsApiBasePath}/well/status`, requestBody, config)
}

export const turnOnWellRequest = (accessToken, tenantId, requestBody) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }
  return axios.post(`${commandsApiBasePath}/well/turnOn`, requestBody, config)
}

export const turnOffWellRequest = (accessToken, tenantId, requestBody) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }
  return axios.post(`${commandsApiBasePath}/well/turnOff`, requestBody, config)
}

export const turnOnPumpRequest = async (accessToken, tenantId, requestBody) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }

  try {
    const response = await axios.post(
      `${commandsApiBasePath}/Pump/turnOn`,
      requestBody,
      config
    )
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    }
  }
}

export const turnOffPumpRequest = async (
  accessToken,
  tenantId,
  requestBody
) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }

  try {
    const response = await axios.post(
      `${commandsApiBasePath}/Pump/turnOff`,
      requestBody,
      config
    )
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    }
  }
}

export const statusPumpRequest = async (accessToken, tenantId, requestBody) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }

  try {
    const response = await axios.post(
      `${commandsApiBasePath}/Pump/status`,
      requestBody,
      config
    )
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    }
  }
}

export const setFrequencyRequest = async (
  accessToken,
  tenantId,
  requestBody
) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, tenantId },
  }

  try {
    const response = await axios.post(
      `${commandsApiBasePath}/Pump/frequency`,
      requestBody,
      config
    )
    return { success: true, data: response.data }
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    }
  }
}
