import React, { useEffect, useState } from 'react'
import { Grid, Modal, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import {
  SiteApiModel,
  IntermittentAlertDto,
  IntermittentIgnitionDto,
  IntermittentSetPointDto,
  IntermittentSetpointRangeDto,
  CompressorDeviceApiModel,
  TimerSetpointDto,
} from 'api/apiservice'
import { getSiteClient, getCompressorClient } from 'api/client'
import CustomizedAccordions from './intermittent/collapse/collapse'
import BasicSelect from './intermittent/filters/siteCompresorFilters'
import { withMySitesContext } from './contexts/MySitesContext'
import { withUiContext } from './contexts/UiContext'
import { AppState } from 'store/AppState'
import { User } from 'oidc-client'
import TimeRangePicker from './misc/TimeRangePicker'
import { RelativeTimeRange, TimeRange, getTimeRange } from './misc/TimeRange'
import { duration } from 'moment'
import flat from 'array.prototype.flat'
import Snack from './misc/Snack'
import { saveAs } from 'file-saver'
import { hasSupervisorUserAccessLevel } from 'store/oidc/userManager'

interface PropsFromState {
  readonly user?: User
  readonly accessToken: string
  readonly tenantId: number
  readonly userId: string
  setPageTitle?: any
}

type AllProps = PropsFromState

const initialTimeRange = duration()
const initialIgnitionDto = IntermittentIgnitionDto.fromJS({})

function Intermittent2(props: AllProps) {
  const [sites, setSites] = useState<SiteApiModel[]>([])
  const [siteId, setSiteId] = useState<string>('')
  const [wellId, setWellId] = useState<string>('')
  const [timerSetPointWellId, setTimerSetPointWellId] = useState<string>('')
  const [timeRange, setTimeRange] = useState<TimeRange>(initialTimeRange)
  const [showTimeRangePicker, setShowTimeRangePicker] = React.useState(true)
  const [alertBySiteId, setAlertBySiteId] = useState<IntermittentAlertDto[]>([])
  const [compressors, setCompressors] = useState<
    SiteApiModel['compressorDevice'][] | undefined
  >()
  const [ignitionOrder, setIgnitionOrder] =
    useState<IntermittentIgnitionDto>(initialIgnitionDto)
  const [blockSiteFilter, setBlockSiteFilter] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [saveSuccess, setSaveSuccess] = useState<string | undefined>()
  const [savingCsv, setSavingCsv] = useState<boolean>(false)
  const [setPoints, setSetPoints] = useState<IntermittentSetPointDto>()
  const [timerSetPoint, setTimerSetPoint] = useState<TimerSetpointDto>()
  const [wells, setWells] = useState<SiteApiModel['wells'][] | undefined>()
  const [compressorDevice, setCompressorDevice] =
    useState<CompressorDeviceApiModel>()

  const apiSites = getSiteClient(
    props.accessToken,
    props.tenantId.toString(),
    props.userId
  )
  const apiCompressor = getCompressorClient(
    props.accessToken,
    props.tenantId.toString(),
    props.userId
  )
  const isValidRole = hasSupervisorUserAccessLevel(props.user!)

  async function getSites() {
    try {
      const sitesResponse = await apiSites.getAll(true)
      setSites(sitesResponse.result)
    } catch (e) {
      setError('An error while getting sites occurred')
    }
  }

  async function getSetPoints(siteId: string, wellId: string) {
    try {
      if (siteId && wellId) {
        const setPoints = await apiSites.getIntermittentSetPoint(siteId, wellId)
        setSetPoints(setPoints.result)
      }
    } catch (e) {
      setError('An error while getting set points occurred')
    }
  }

  async function getTimerSetPoint(siteId: string, wellId: string) {
    try {
      if (siteId && wellId) {
        const timerSetPoint = await apiSites.getTimerSetPoint(siteId, wellId)
        setTimerSetPoint(timerSetPoint.result)
      }
    } catch (e) {
      setError('An error while getting timer set point occurred')
    }
  }

  async function upsertIntermittentSetPoint(
    siteId: string,
    wellId: string,
    dto: IntermittentSetpointRangeDto[]
  ) {
    try {
      if (wellId && siteId) {
        const validValues = dto.every(
          (s) => Number(s.openWell) < Number(s.shutWell)
        )
        if (!validValues) {
          setError('For SetPoints On value should be greater than Off value')
        } else {
          await apiSites.upsertIntermittentSetPoint(siteId, wellId, dto)
          setSaveSuccess('Saved Information')
        }
      }
    } catch (e) {
      setError('An error while inserting/updating set points occurred')
    }
  }

  async function upsertTimerSetPoint(
    siteId: string,
    wellId: string,
    dto: TimerSetpointDto
  ) {
    try {
      if (wellId && siteId) {
        let valuesNotValid =
          Number(dto.timerTurnOnWell) <= Number(dto.timerTurnOffWell)

        if (valuesNotValid) {
          setError(
            'For Timer Set Points On value should be greater than Off value'
          )
          return
        }

        const today = new Date()
        valuesNotValid =
          Number(dto.timerTurnOffWell) <= Number(today) ||
          Number(dto.timerTurnOnWell) <= Number(today)

        if (valuesNotValid) {
          setError('For Timer Set Points values should be greater than today')
          return
        }

        await apiSites.upsertTimerSetPoint(siteId, wellId, dto)
        setSaveSuccess('Saved Information')
      }
    } catch (e) {
      setError('An error while inserting/updating timer set points occurred')
    }
  }

  function getCompressor(siteId: string) {
    try {
      setCompressors(
        sites.filter((e) => e.id === siteId).map((e) => e.compressorDevice)
      )
      setSiteId(siteId)
    } catch (e) {
      setError('An error while setting compressors in the selector occurred')
    }
  }

  function getWells(siteId: string) {
    try {
      setWells(sites.filter((e) => e.id === siteId).map((e) => e.wells))
    } catch (e) {
      setError('An error while getting sites occurred')
    }
  }

  function getCompressorDevice(siteId: string) {
    try {
      setCompressorDevice(
        sites.filter((e) => e.id === siteId)[0].compressorDevice
      )
    } catch (error) {
      setError('An error while getting compressor device occurred')
    }
  }

  function getCompressors() {
    try {
      setCompressors(sites.map((e) => e.compressorDevice))
    } catch (e) {
      setError('An error while getting compressors occurred')
    }
  }

  async function getAlertBySiteId(siteIdAlert: string) {
    try {
      const [from, to] = getTimeRange(timeRange)
      const intermittentSitesByIdResponse = await apiSites.getBySite(
        siteIdAlert,
        from,
        to
      )
      setAlertBySiteId(intermittentSitesByIdResponse.result)
    } catch (e) {
      setError('An error while getting Alerts occurred')
    }
  }

  const getIgnitionOrden = async (compressorId: string) => {
    try {
      const response = await apiCompressor.getIgnitionOrden(compressorId)
      setIgnitionOrder(response.result)
    } catch (e) {
      setError('An error while getting ignition order occurred')
    }
  }

  function ShowHideTimeRangePicker(panel, expanded = false) {
    setShowTimeRangePicker(panel === 'alertPanel')
    setBlockSiteFilter(panel === 'compressorPanel')
    if (panel === 'compressorPanel' && expanded) {
      getCompressors()
      setSites([])
    } else {
      setBlockSiteFilter(false)
      getSites()
      getCompressor(siteId)
    }
  }

  function WellSelectedForSetPoints(wellId) {
    setWellId(wellId)
    getSetPoints(siteId, wellId)
  }

  function WellSelectedForTimerSetPoint(wellId) {
    setTimerSetPointWellId(wellId)
    getTimerSetPoint(siteId, wellId)
  }

  function SaveSetPoints(dto: IntermittentSetpointRangeDto[]) {
    if (siteId && wellId) {
      upsertIntermittentSetPoint(siteId, wellId, dto)
    }
  }

  function SaveTimerSetPoints(dto: TimerSetpointDto) {
    if (siteId && timerSetPointWellId) {
      upsertTimerSetPoint(siteId, timerSetPointWellId, dto)
    }
  }

  const relativeTimeRanges: [RelativeTimeRange][] = flat([
    [12].map((d) => [duration(d, 'hour')]),
    [1, 3, 7, 14].map((d) => [duration(d, 'day')]),
    [1].map((d) => [duration(d, 'month')]),
  ])

  const onTimeRangeChanged = (timeRnge?: TimeRange) => {
    setTimeRange(timeRnge ?? initialTimeRange)
  }

  const onSaveAsCsv = async () => {
    try {
      setSavingCsv(true)
      const [from, to] = getTimeRange(timeRange)
      const response = await apiSites.exportCsvBySite(siteId, from, to)
      saveAs(response.result.data, response.result.fileName)
      setSavingCsv(false)
    } catch (err) {
      setError('Failed to download the csv file')
      setSavingCsv(false)
    }
  }

  const renderTimePicker = () => {
    return (
      <TimeRangePicker
        relativeTimeRanges={relativeTimeRanges}
        initialTimeRange={timeRange}
        onTimeRangeChanged={onTimeRangeChanged}
        onSaveAsCsv={onSaveAsCsv}
      />
    )
  }

  const saveIgnitionOrder = (
    id: string,
    ignitionDtoToPost: IntermittentIgnitionDto
  ) => {
    return apiCompressor.updateIgnitionOrden(id, ignitionDtoToPost)
  }

  useEffect(() => {
    if (props.accessToken && props.tenantId) {
      getSites()
      getCompressor(siteId)
      getWells(siteId)
    }
    props.setPageTitle('Intermittent')
  }, [props.accessToken, props.tenantId])

  useEffect(() => {
    if (siteId !== '') {
      getAlertBySiteId(siteId)
    }
  }, [timeRange])

  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Grid
          spacing={3}
          container={true}
          alignContent="flex-start"
          style={{ overflowY: 'auto' }}
        >
          <Grid item={true} xs={9}>
            <BasicSelect
              sites={sites}
              getCompressor={getCompressor}
              compressors={compressors}
              getAlertBySiteId={getAlertBySiteId}
              blockSiteFilter={blockSiteFilter}
              getIgnitionOrden={getIgnitionOrden}
              getWells={getWells}
              getSetPoints={getSetPoints}
              getTimerSetPoint={getTimerSetPoint}
              getCompressorDevice={getCompressorDevice}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <div style={{ display: showTimeRangePicker ? 'block' : 'none' }}>
              {renderTimePicker()}
            </div>
          </Grid>
          <Grid item={true} xs={12} alignContent="flex-start">
            <CustomizedAccordions
              isValidRole={isValidRole}
              ShowHideTimeRangePicker={ShowHideTimeRangePicker}
              WellSelectedForSetPoints={WellSelectedForSetPoints}
              WellSelectedForTimerSetPoint={WellSelectedForTimerSetPoint}
              SaveSetPoints={SaveSetPoints}
              SaveTimerSetPoints={SaveTimerSetPoints}
              alerts={alertBySiteId}
              setPoints={setPoints}
              timerSetPoint={timerSetPoint}
              ignitionOrder={ignitionOrder}
              saveIgnitionOrder={saveIgnitionOrder}
              timeRange={timeRange}
              wells={wells}
              siteId={siteId}
              compressorDevice={compressorDevice}
            />
          </Grid>
        </Grid>
        {savingCsv && (
          <Modal open={true}>
            <div
              style={{
                display: 'grid',
                height: '100%',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <CircularProgress size="10em" />
            </div>
          </Modal>
        )}
        {error && (
          <Snack
            message={error}
            severity="error"
            onClose={() => setError(undefined)}
          />
        )}
        {saveSuccess && (
          <Snack
            message={saveSuccess}
            severity="success"
            onClose={() => setSaveSuccess(undefined)}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({
  oidc,
  multitenantUser,
}: AppState): PropsFromState => {
  return {
    user: oidc.user,
    accessToken: multitenantUser.accessToken,
    tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    userId: multitenantUser.id,
  }
}

export default connect(mapStateToProps)(
  withUiContext(withMySitesContext(Intermittent2))
)
