import React from 'react'
import { withContext } from './Context'

export interface UiContextState {
  readonly title?: React.ReactNode
}

export interface UiContextData extends UiContextState {
  readonly setPageTitle?: (title?: React.ReactNode) => void
}

const uiContext = React.createContext<UiContextData>({})

export default uiContext

export const withUiContext = withContext(uiContext)
