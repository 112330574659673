import { MenuItem, TextField } from '@material-ui/core'
import * as React from 'react'

const boolOptions = [
  {
    value: 'true',
    label: 'Enabled',
  },
  {
    value: 'false',
    label: 'Disabled',
  },
]

export interface Props {
  readonly idPrefix?: string
  readonly label: string
  readonly value: string
  readonly onChange?: (event) => void
  readonly isEditable: boolean
}

export function BooleanSelectTextField(props: Props) {
  return (
    <TextField
      id={`${props.idPrefix}Select`}
      name={props.idPrefix}
      select
      label={props.label}
      style={{ minWidth: '200px' }}
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        readOnly: !props.isEditable,
      }}
    >
      {boolOptions.map((option) => (
        <MenuItem key={`${props.idPrefix}${option.value}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
