import React, { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { SiteWithTelemetry } from './MySites'
import { TelemetryMessage } from 'store/SiteDetails'
import { TelemetryType } from 'api/apiservice'
import { getReadableTelemetryType } from 'components/utils/converter'

interface Props {
  site: SiteWithTelemetry
}

export interface GeneralBatteryLevels {
  telType: string
  assetId: string
  batteryLevel: string
}

const BatteryNotification = ({ site }: Props) => {
  const [listedLevels, setListedLevels] = useState<GeneralBatteryLevels[]>([])

  const loadInfoBatteryLevelsByTelemetry = async (data: TelemetryMessage[]) => {
    const telTypes: GeneralBatteryLevels[] = []

    await data.map((item) => {
      const isWell =
        item.TelemetryType === 1 ||
        item.TelemetryType === 2 ||
        item.TelemetryType === 12 ||
        item.TelemetryType === 15

      let telType = isWell
        ? ` Well: ${TelemetryType[`${item.TelemetryType}`]}`
        : TelemetryType[`${item.TelemetryType}`]

      telType =
        getReadableTelemetryType(item.TelemetryType) ===
        'Gateway Metrics (Battery) '
          ? 'Gateway'
          : getReadableTelemetryType(item.TelemetryType)
      const assetId = item.AssetId ?? ''
      const batteryLevel = item.sensorBatteryLevel

      if (
        +batteryLevel < 20 &&
        item.TelemetryType !== TelemetryType.PumpControl
      ) {
        telTypes.push({ telType, assetId, batteryLevel })
      }
    })

    setListedLevels(telTypes)
  }

  useEffect(() => {
    loadInfoBatteryLevelsByTelemetry(site.latestTelemetry)
  }, [site])

  return (
    <div>
      {listedLevels.length > 0 &&
        listedLevels.map(({ telType, assetId, batteryLevel }, index) => (
          <div style={styleContainer} key={index}>
            <Typography style={{ fontWeight: 700, fontSize: '0.8em' }}>
              {telType}: {assetId}
              {assetId && ':'} [ {batteryLevel} %]
            </Typography>
          </div>
        ))}
    </div>
  )
}

const styleContainer: any = {
  wordBreak: 'break-word',
  backgroundColor: 'rgba(244, 67, 54, 0.3)',
  marginTop: '0.5rem',
  padding: '0.05rem',
  fontSize: '1.02rem',
  color: '#F44336',
  borderRadius: '0.7rem',
  textAlign: 'center',
}
export default BatteryNotification
