import * as React from 'react'
import { DraggableListItem } from './draggableListItem'
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { IntermittentOrderDto } from 'api/apiservice'
import { TextField, Typography } from '@material-ui/core'

export interface DraggableListProps {
  isValidRole: boolean
  items: IntermittentOrderDto[]
  onDragEnd: OnDragEndResponder
  setDelay: (delayInSconds, index) => void
}

export const DraggableList = React.memo(
  ({ items, onDragEnd, isValidRole, setDelay }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '1rem',
                  marginBottom: '.5rem',
                }}
              >
                <Typography color="primary">
                  <strong>Asset</strong>
                </Typography>
                <Typography color="primary">
                  <strong>Delay in Minutes</strong>
                </Typography>
              </div>
              {items.map((item, index) => {
                const delayVisibility: any = `${index ? 'visible' : 'hidden'}`
                return (
                  <div
                    style={{ display: 'flex' }}
                    key={`draggable-${item.wellId}`}
                  >
                    <DraggableListItem
                      isValidRole={isValidRole}
                      item={item}
                      index={index}
                      key={item.wellId}
                    />
                    <TextField
                      style={{
                        marginLeft: '1rem',
                        visibility: delayVisibility,
                      }}
                      value={item.delay && item.delay > 0 ? item.delay / 60 : 0}
                      type={'number'}
                      key={`delay-${item.wellId}`}
                      onChange={(e) => setDelay(Number(e.target.value), index)}
                    />
                  </div>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
)
