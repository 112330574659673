import React from 'react'
import GaugeSensor, {
  defaultGaugeWidth,
  Highlight,
  MajorTicksWithHighlights,
} from './GaugeSensor'
import { AppState } from '../../store/AppState'
import { Reading, SensorWithChartProps } from './SensorWithChart'
import { Grid, Typography } from '@material-ui/core'
import { RadialGauge } from 'react-canvas-gauges'
import { getStatusLensColor } from 'components/utils'
import { TelemetryStatus, StatusRange } from 'api/alertservice'
import { getTelemetryTypeUnit } from 'components/utils/converter'
import { TelemetryAverageResponse, TelemetryType } from 'api/apiservice'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import logo from '../../img/noun_flare_tower.png'
import pump from '../../img/pump.png'
import Button from '@material-ui/core/Button'
import { ConfirmDialog } from 'components/misc/ConfirmDialog'
import {
  statusPumpRequest,
  turnOffPumpRequest,
  turnOnPumpRequest,
} from 'api/commands'
import { getMultitenantUser } from 'api/multitenantAuth'
import { getProcessPeriodByTenant } from 'api/client'

interface CardInfo {
  label: string
  unit: string
  data: string | number
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default abstract class BaseSensor extends GaugeSensor {
  constructor(props, telemetryType, title, yAxisDomain) {
    super(props, telemetryType, title, yAxisDomain)
    this.state = {
      pumpState: 'Pending',
      isTableViewSelected: telemetryType === TelemetryType.PumpControl,
      gaugeReading: {
        value: props.initialLatestValue?.value,
        rawValue: props.initialLatestValue?.rawValue,
        formattedValue: '',
        formattedRawValue: '',
        timestamp: props.initialLatestValue?.CreationTimeUtc,
        unitOfMeasure: props.initialLatestValue?.unitMeasure,
      },
      modalConfirmation: false,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarSeverity: 'success',
      hiddenData: [],
      user: null,
      startHourBPD: '',
      endHourBPD: '',
    }
  }

  bpdAverage = 0
  bpdList: TelemetryAverageResponse[] = []

  async componentDidMount() {
    if (this.TelemetryType === TelemetryType.PumpControl) {
      const { asset, site } = this.props
      const { data } = await getMultitenantUser(this.props.accessToken)

      const filter = {
        email: data.email,
        siteId: site.id,
        assetId: asset.id,
        assetName: asset.name,
      }

      try {
        const response = await statusPumpRequest(
          this.props.accessToken,
          this.props.tenantId,
          filter
        )

        if (response && response.success) {
          this.setState({
            pumpState:
              response.data.status === 'OFF'
                ? 'Off'
                : response.data.status === 'ON'
                ? 'On'
                : 'Pending',
            user: data.email,
            gaugeReading: {
              value: 0,
              rawValue: 0,
              formattedValue: '',
              formattedRawValue: '',
              timestamp: new Date(),
              unitOfMeasure: 'N/A',
            },
          })
        } else {
          if (response && response.error && response.error?.errors) {
            const errorMessages = Object.entries(response.error.errors)
              .map(
                ([fieldName, fieldErrors]) =>
                  `${fieldName}: ${fieldErrors.join(', ')}`
              )
              .join(', ')

            this.setState({
              showSnackbar: true,
              snackbarMessage: `Errors with asset ${asset.name}: ${errorMessages}`,
              snackbarSeverity: 'error',
              user: data.email,
            })
          } else if (response.error?.Message) {
            const errorMessages = response.error?.Message
            this.setState({
              showSnackbar: true,
              snackbarMessage: `Error with asset ${asset.name}: ${errorMessages}`,
              snackbarSeverity: 'error',
              user: data.email,
            })
          } else {
            // Si no hay 'errors' ni 'message', muestra un mensaje genérico
            this.setState({
              showSnackbar: true,
              snackbarMessage: 'An error occurred',
              snackbarSeverity: 'error',
            })
          }
        }
        // this.setState({ pumpState: pumpStatus });
      } catch (error) {
        console.error('Error getting pump status:', error)
        this.setState({
          user: data.email,
        })
      }
    } else {
      if (this.TelemetryType === TelemetryType.LiquidFlow) {
        const result = await getProcessPeriodByTenant(
          this.props.accessToken,
          this.props.tenantId
        )

        this.setState({
          startHourBPD: result?.bpdHourFrom,
          endHourBPD: result?.bpdHourTo,
        })
      }

      if (!this.props.loading && this.props.initialLatestValue !== undefined) {
        this.setState({
          gaugeReading: {
            value: this.props.initialLatestValue?.Payload.value,
            rawValue: this.props.initialLatestValue?.Payload.rawValue,
            formattedValue: '',
            formattedRawValue: '',
            timestamp: this.props.initialLatestValue?.CreationTimeUtc,
            unitOfMeasure: this.props.initialLatestValue?.Payload.unitOfMeasure,
          },
        })
      }
    }
  }

  protected getUnitOfMeasure(): string {
    return getTelemetryTypeUnit(this.TelemetryType).toUpperCase()
  }

  protected abstract getDisplayReading(
    readingValue?: number
  ): number | undefined

  protected getMajorTicksWithUpdatedHighlights(
    minValue: number,
    maxValue: number,
    hasThresholds: boolean,
    highlights: Highlight[]
  ): MajorTicksWithHighlights {
    const minFixed = minValue > 0 ? 0 : Math.floor(minValue)

    const equalValues = minFixed === maxValue
    const majorTickRaw = (maxValue - minFixed) / 7
    let roundedMajorTick =
      this.TelemetryType === 16 || maxValue >= 10000 || equalValues
        ? Math.round(maxValue / 5)
        : majorTickRaw > 7
        ? Math.round(majorTickRaw / 7) * 7
        : 7
    roundedMajorTick =
      roundedMajorTick - minFixed > 2 ? roundedMajorTick : roundedMajorTick + 2
    const majorTicks = [
      ...this.getMajorTicks(
        Math.round(minFixed),
        maxValue > 0 ? Math.round(maxValue) : 7,
        roundedMajorTick > 0 ? Math.round(roundedMajorTick) : 2,
        0
      ),
    ]
    const lastMajorTick = parseInt(majorTicks[majorTicks.length - 1], 10)
    if (lastMajorTick < maxValue) {
      const newLastMajorTick = roundedMajorTick + lastMajorTick
      majorTicks.push(Math.round(newLastMajorTick).toString())
      // Adding red range for the reason we don't want to display grey
      if (hasThresholds) {
        highlights.push({
          from: Math.floor(maxValue),
          to: Math.round(newLastMajorTick),
          color: getStatusLensColor(TelemetryStatus.Red),
        })
      }
    }

    const firstMajorTick = parseInt(majorTicks[0], 10)

    if (firstMajorTick < minValue && hasThresholds) {
      // Adding red range for the reason we don't want to display grey
      highlights.push({
        from: firstMajorTick,
        to: Math.ceil(minValue),
        color: getStatusLensColor(TelemetryStatus.Red),
      })
    }

    if (this.TelemetryType === 16) {
      highlights.push({
        from: 0,
        to: parseInt(majorTicks[majorTicks.length - 1], 10),
        color: '#BDBDBD',
      })
    }

    return {
      majorTicks: majorTicks,
      highlights: highlights,
    }
  }

  protected getMinMaxValue(
    statusRanges: StatusRange[],
    readingValue?: number,
    isAveraging?: boolean
  ): [number, number, Highlight[]] {
    const ths = statusRanges ?? []
    const hasThresholds = !!ths.length

    const highlights = ths.map((t) => {
      const from = t.minThreshold
      const to = t.maxThreshold
      const color = getStatusLensColor(t.status)

      return { from, to, color }
    })

    if (hasThresholds) {
      // thresholds defined
      const [highlightsMinValue, highlightsMaxValue] =
        this.getMinMaxRangeValue(highlights)

      if (readingValue !== undefined) {
        // thresholds defined, reading defined
        let minValue = highlightsMinValue
        let maxValue = highlightsMaxValue

        if (highlightsMaxValue < readingValue) {
          highlights.push({
            from: highlightsMaxValue,
            to: readingValue,
            color: getStatusLensColor(TelemetryStatus.Red),
          })
          maxValue = readingValue
        }
        if (highlightsMinValue > readingValue) {
          highlights.push({
            from: readingValue,
            to: highlightsMinValue,
            color: getStatusLensColor(TelemetryStatus.Red),
          })
          minValue = readingValue
        }
        return [minValue, maxValue, highlights]
      } else {
        // thresholds defined, reading not defined
        return [highlightsMinValue, highlightsMaxValue, highlights]
      }
    } else {
      // thresholds not defined
      if (readingValue !== undefined) {
        // thresholds not defined, reading defined
        if (Math.sign(readingValue) > 0) {
          // positive reading
          return [0, readingValue, highlights]
        } else if (Math.sign(readingValue) < 0) {
          // negative reading
          return [readingValue, Math.abs(readingValue), highlights]
        } else {
          // zero (+0 or -0)
          return [readingValue, 100, highlights]
        }
      } else {
        // thresholds not defined, reading not defined
        return [0, 100, highlights]
      }
    }
  }

  protected getBPDRange(bpd: TelemetryAverageResponse[]) {
    this.bpdList = bpd
    this.bpdAverage = this.getIsAveraging()
      ? this.getBPDRangeAverage(bpd)
      : this.getLastBPDAverage(bpd)
  }

  protected renderGauge = (reading: Reading) => {
    const bpdAvg = this.bpdAverage
    const average = this.getIsAveraging() // Identify if the data is coming from Datefilter, if true is average
    // if average is true and getHaveThreshold is true, Calculate the average
    const readingValue = average
      ? this.getAveragingValue(this.props.historicalData)
      : this.getDisplayReading(reading.value) // this is the value will show in the gauge
    const { majorTicks, highlights } =
      this.TelemetryType === 16
        ? this.getBPDGaugeParams(this.bpdList)
        : this.getGaugeParams(
            this.memoizesThresholds(this.props.thresholds),
            readingValue,
            average
          )
    const calculateMinorTicks = (value) => {
      if (value >= 1000) {
        return value / 50
      }
      return 5
    }

    const majorTicksPrevent = ['0', '25', '50', '75', '100']

    if (this.TelemetryType === TelemetryType.GasFlow) {
      return this.renderCardTable(
        this.getGasFlowValues(this.props.historicalData)
      )
    }

    if (this.TelemetryType === 24) {
      return this.renderPumpControl(
        this.getPumpControlValues(this.props.historicalData),
        reading
      )
    }

    return (
      <React.Fragment>
        <div style={{ marginBottom: -140 }}>
          <RadialGauge
            width={defaultGaugeWidth}
            height={250}
            value={this.TelemetryType === 16 ? bpdAvg : readingValue}
            minValue={majorTicks[0] ?? '0'}
            maxValue={majorTicks[majorTicks.length - 1] ?? '100'}
            majorTicks={majorTicks ?? majorTicksPrevent} // this is the values show in the gauge from 0 to maxvalue this is an Array
            minorTicks={
              calculateMinorTicks(majorTicks[majorTicks.length - 1]) ?? 5
            }
            strokeTicks={false}
            startAngle={90}
            ticksAngle={180}
            ticksWidth={0.5}
            valueBox={false}
            highlights={
              highlights ?? [{ from: 0, to: 10000, color: '#BDBDBD' }]
            } // this is the red zone of the gauge, it's mean 120 to 160 danger zone
            colorPlate="transparent"
            borders={false}
            needleType="line"
            colorNeedle="#1d1d24"
            colorNeedleEnd="#6b6b87"
            needleWidth={reading.value !== undefined ? 2 : 0}
            needleCircleOuter={false}
            needleCircleInner={false}
            animation={true}
            animationDuration={1000}
            animationRule="bounce"
            exactTicks={true}
          />
        </div>

        {typeof readingValue === 'number' && this.renderGaugeMeasures(reading)}
      </React.Fragment>
    )
  }

  protected convertTimestampToTime = (timestamp: Date) => {
    const offsetActual = timestamp.getTimezoneOffset()
    const offsetCT = 360
    const adjustTimezone = (offsetActual - offsetCT) * 60 * 1000
    const newDate = new Date(timestamp.getTime() - adjustTimezone)
    return newDate
  }

  protected renderGaugeMeasures(reading: Reading) {
    const bpdAvg = this.bpdAverage
    const average = this.getIsAveraging()
    const readingValue = average
      ? this.getAveragingValue(this.props.historicalData)
      : this.getDisplayReading(reading.value)

    if (this.TelemetryType === 16) {
      const bpdHourF = new Date()
      const bpdHourT = new Date()

      const bpdHourFrom = this.state.startHourBPD ?? '00:00'
      const bpdHourTo = this.state.endHourBPD ?? '23:59'
      const [bpdFromOnlyHour, discardFrom] = bpdHourFrom.split(':')
      const [bpdToOnlyHour, discardTo] = bpdHourTo.split(':')

      bpdHourF.setHours(Number(bpdFromOnlyHour))
      bpdHourT.setHours(Number(bpdToOnlyHour))

      // bpdHourF = this.convertTimestampToTime(bpdHourF)
      // bpdHourT = this.convertTimestampToTime(bpdHourT)
      const fromDateBDP = bpdHourF.toLocaleTimeString(undefined, {
        hour: 'numeric',
        hour12: true,
      })
      const toDateBDP = bpdHourT.toLocaleTimeString(undefined, {
        hour: 'numeric',
        hour12: true,
      })
      return (
        <React.Fragment>
          <br />
          {this.TelemetryType === 16 && (
            <>
              <Typography variant="h5" align="center">
                {`${bpdAvg.toFixed(1)} BPD*`}
              </Typography>
              <Typography
                noWrap={false}
                align="center"
                style={{ fontSize: 10 }}
              >
                *Previous day at {fromDateBDP} to current day {toDateBDP}
              </Typography>
            </>
          )}
          <Typography variant="h5" align="center">
            {readingValue.toFixed(1)} {this.getUnitOfMeasure()}
          </Typography>
        </React.Fragment>
      )
    }

    if (this.TelemetryType === 1 || this.TelemetryType === 2) {
      return (
        <React.Fragment>
          <br />
          <Typography variant="h5" align="center">
            {readingValue.toFixed(1)} {reading.unitOfMeasure}
          </Typography>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Typography variant="h5" align="center">
            {readingValue.toFixed(1)} {this.getUnitOfMeasure()}
          </Typography>
        </React.Fragment>
      )
    }
  }

  protected renderFlareStatus = (reading: Reading) => {
    const isInvalidValue =
      reading.value === undefined || typeof reading.value !== 'number'
    const statusText = isInvalidValue
      ? 'N/A'
      : reading.value === 1
      ? 'ON'
      : 'OFF'
    const statusColor =
      reading.value === 1
        ? getStatusLensColor(TelemetryStatus.Green)
        : getStatusLensColor(TelemetryStatus.Red)
    return (
      <React.Fragment>
        <div
          style={{
            width: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={logo}
            alt="flare tower"
            style={{ width: '50%', height: '50%' }}
          />
          <Typography>
            Status:{' '}
            <span
              style={
                isInvalidValue ? {} : { color: statusColor, fontWeight: 'bold' }
              }
            >
              {statusText}
            </span>
          </Typography>
        </div>
      </React.Fragment>
    )
  }

  public handleToogle = () => {
    const { pumpState } = this.state

    this.setState({ modalConfirmation: true })
  }

  public turnOnPumpWithDelay = async (accessToken, tenantId, requestBody) => {
    try {
      const response = await turnOnPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )

      if (response && response.success) {
        this.setState({
          showSnackbar: true,
          snackbarMessage: 'Successfully sent the command to turn ON the pump',
          snackbarSeverity: 'success',
        })

        await new Promise((resolve) => setTimeout(resolve, 1000))

        await this.checkPumpStatusOFFtoON(accessToken, tenantId, requestBody)
      } else {
        console.error('Error sending command:', response.error)

        if (response && response.error && response.error?.errors) {
          const errorMessages = Object.entries(response.error.errors)
            .map(
              ([fieldName, fieldErrors]) =>
                `${fieldName}: ${fieldErrors.join(', ')}`
            )
            .join(', ')

          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: ${errorMessages}`,
            snackbarSeverity: 'error',
          })
        } else if (response.error?.Message) {
          const errorMessageObj = JSON.parse(response.error.Message)
          const errorMessages = JSON.parse(errorMessageObj.Message).message
          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: ${errorMessages}`,
            snackbarSeverity: 'error',
          })
        } else {
          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: An error occurred`,
            snackbarSeverity: 'error',
          })
        }
      }
    } catch (error) {
      console.error('Error:', error)

      this.setState({
        showSnackbar: true,
        snackbarMessage: `Error asset ${requestBody.name}: An error occurred`,
        snackbarSeverity: 'error',
      })
    }
  }

  public turnOffPumpWithDelay = async (accessToken, tenantId, requestBody) => {
    try {
      const response = await turnOffPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )

      if (response && response.success) {
        this.setState({
          showSnackbar: true,
          snackbarMessage: 'Successfully sent the command to turn OFF the pump',
          snackbarSeverity: 'success',
        })

        await this.checkPumpStatusONtoOFF(accessToken, tenantId, requestBody)
      } else {
        console.error('Error sending command:', response)

        if (response.error && response.error?.errors) {
          // Convierte el objeto 'errors' a un mensaje de texto plano
          const errorMessages = Object.entries(response.error.errors)
            .map(
              ([fieldName, fieldErrors]) =>
                `${fieldName}: ${fieldErrors.join(', ')}`
            )
            .join(', ')

          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: ${errorMessages}`,
            snackbarSeverity: 'error',
          })
        } else if (response.error?.Message) {
          const errorMessageObj = JSON.parse(response.error.Message)
          const errorMessages = JSON.parse(errorMessageObj.Message).message
          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: ${errorMessages}`,
            snackbarSeverity: 'error',
          })
        } else {
          this.setState({
            showSnackbar: true,
            snackbarMessage: `Error asset ${requestBody.name}: An error occurred`,
            snackbarSeverity: 'error',
          })
        }
      }
    } catch (error) {
      console.error('Error:', error.message)

      this.setState({
        showSnackbar: true,
        snackbarMessage: `Error asset ${requestBody.name}: An error occurred`,
        snackbarSeverity: 'error',
      })
    }
  }

  public checkPumpStatusONtoOFF = async (
    accessToken,
    tenantId,
    requestBody
  ) => {
    let status = ''

    while (status !== 'OFF') {
      const { data } = await statusPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      status = data.status
      if (status !== 'OFF') {
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
    }
    this.setState({
      pumpState: 'Off',
      gaugeReading: {
        value: 0,
        rawValue: 0,
        formattedValue: '',
        formattedRawValue: '',
        timestamp: new Date(),
        unitOfMeasure: 'N/A',
      },
    })
  }

  public checkPumpStatusOFFtoON = async (
    accessToken,
    tenantId,
    requestBody
  ) => {
    let status = ''

    while (status !== 'ON') {
      const { data } = await statusPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      status = data.status
      if (status !== 'ON') {
        await new Promise((resolve) => setTimeout(resolve, 8000))
      }
    }
    this.setState({
      pumpState: 'On',
      gaugeReading: {
        value: 0,
        rawValue: 0,
        formattedValue: '',
        formattedRawValue: '',
        timestamp: new Date(),
        unitOfMeasure: 'N/A',
      },
    })
  }

  public checkPumpStatus = async (accessToken, tenantId, requestBody) => {
    const data = await statusPumpRequest(accessToken, tenantId, requestBody)
    return data
  }

  public handleChangePumpControl = async () => {
    const { pumpState, user } = this.state

    const { asset, site } = this.props

    const requestBody = {
      email: user,
      siteId: site.id,
      assetId: asset.id,
      assetName: asset.name,
    }

    if (pumpState === 'Off') {
      this.setState({
        pumpState: 'Pending',
        gaugeReading: {
          value: 0,
          rawValue: 0,
          formattedValue: '',
          formattedRawValue: '',
          timestamp: new Date(),
          unitOfMeasure: 'N/A',
        },
      })
      await this.turnOnPumpWithDelay(
        this.props.accessToken,
        this.props.tenantId,
        requestBody
      )
    } else if (pumpState === 'On') {
      this.setState({
        pumpState: 'Pending',
        gaugeReading: {
          value: 0,
          rawValue: 0,
          formattedValue: '',
          formattedRawValue: '',
          timestamp: new Date(),
          unitOfMeasure: 'N/A',
        },
      })
      await this.turnOffPumpWithDelay(
        this.props.accessToken,
        this.props.tenantId,
        requestBody
      )
    }
  }

  private getGasFlowValues<T>(currentData: T): CardInfo[] {
    const attributeIndex = Object.keys(currentData as {})[0]

    const lastFetchedData = currentData[attributeIndex]

    const dataLength = currentData[attributeIndex].length

    const { value } = lastFetchedData[dataLength - 1] || {}

    const {
      AccumulatedVolume,
      BatteryVolts,
      DiffPressure,
      StaticPressure,
      Temperature,
      TodayVolumeFlowed,
      VolumeFlowRate,
      YesterdayVolumeFlowed,
    } = value || {}

    return [
      {
        label: 'Accumulated Volume',
        unit: 'MCF',
        data: (+AccumulatedVolume)?.toFixed(2),
      },
      {
        label: 'Battery Volts',
        unit: 'V',
        data: (+BatteryVolts).toFixed(2),
      },
      {
        label: 'Diff Pressure',
        unit: 'PSIG',
        data: (+DiffPressure).toFixed(2),
      },
      {
        label: 'Static Pressure',
        unit: 'PSIG',
        data: (+StaticPressure).toFixed(2),
      },
      {
        label: 'Temperature',
        unit: 'F°',
        data: (+Temperature).toFixed(2),
      },
      {
        label: "Today's Volume",
        unit: 'MCF',
        data: (+TodayVolumeFlowed).toFixed(2),
      },
      {
        label: 'Current Flow Rate',
        unit: 'MCF',
        data: (+VolumeFlowRate).toFixed(2),
      },
      {
        label: "Yesterday's Volume",
        unit: 'MCF',
        data: (+YesterdayVolumeFlowed).toFixed(2),
      },
    ]
  }

  private getPumpControlValues<T>(currentData: T): CardInfo[] {
    if (!currentData) {
      console.error('currentData es null o undefined')
      return []
    }

    const keys = Object.keys(currentData)
    if (keys.length === 0) {
      console.error('currentData no tiene atributos')
      return []
    }

    const attributeIndex = keys[0]
    const lastFetchedData = currentData[attributeIndex]

    if (!lastFetchedData || typeof lastFetchedData.length !== 'number') {
      console.error(
        'lastFetchedData es null, undefined o no tiene una propiedad length válida'
      )
      return []
    }

    const dataLength = lastFetchedData.length

    const { metaData } = lastFetchedData[dataLength - 1] || {}
    const output = this.transformData(metaData)

    return output
  }

  protected transformData(input) {
    if (input === undefined) return []

    return Object.entries(input).map(([key, value]) => {
      if (value === 'ON' || value === 'OFF') {
        return {
          label: key,
          unit: '',
          data: value,
        }
      }

      const matches = value.match(/([\d.]+)|([a-zA-Z]+)/g)
      if (matches.length === 2) {
        const [numericPart, unitPart] = matches
        const numericValue = parseFloat(numericPart)
        const unit = unitPart.trim()

        return {
          label: key,
          unit: unit,
          data:
            unit.toLowerCase() === 'sec'
              ? numericValue.toFixed(0)
              : numericValue.toFixed(2),
        }
      }

      return {
        label: key,
        unit: '',
        data: value,
      }
    })
  }

  protected renderCardTable(currentData: CardInfo[]) {
    return (
      <div
        style={{ width: 'auto', alignSelf: 'flex-start', padding: '0.6rem' }}
      >
        {currentData.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '280px',
            }}
          >
            <Typography
              key={index}
              style={{ fontSize: '0.7rem', fontWeight: 100 }}
            >
              {' '}
              {item.label} ({item.unit}):
            </Typography>
            <Typography
              key={index}
              style={{ fontSize: '0.7rem', textAlign: 'right' }}
            >
              {' '}
              {isNaN(+item.data) ? '-' : item.data}
            </Typography>
          </div>
        ))}
      </div>
    )
  }

  protected renderPumpControl = (currentData: CardInfo[], reading: Reading) => {
    const isInvalidValue =
      reading.value === undefined || typeof reading.value !== 'number'
    const statusText = isInvalidValue
      ? 'N/A'
      : reading.value === 1
      ? 'ON'
      : 'OFF'
    const statusColor =
      reading.value === 1
        ? getStatusLensColor(TelemetryStatus.Green)
        : getStatusLensColor(TelemetryStatus.Red)

    let buttonText
    switch (this.state.pumpState) {
      case 'On':
        buttonText = 'On'
        break
      case 'Pending':
        buttonText = 'Pending'
        break
      case 'Off':
        buttonText = 'Off'
        break
      default:
        break
    }

    return (
      <React.Fragment>
        <div
          style={{
            width: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: 'auto',
              alignSelf: 'flex-start',
              padding: '0.6rem',
            }}
          >
            {currentData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '280px',
                }}
              >
                <Typography
                  key={index}
                  style={{ fontSize: '0.7rem', fontWeight: 100 }}
                >
                  {' '}
                  {item.unit === ''
                    ? item.label
                    : `${item.label} (${item.unit})`}
                  :
                </Typography>
                <Typography
                  key={index}
                  style={{ fontSize: '0.7rem', textAlign: 'right' }}
                >
                  {' '}
                  {item.data}
                </Typography>
              </div>
            ))}
          </div>
          <Typography style={{ textAlign: 'center' }}>
            Current status:{' '}
            <span
              style={
                isInvalidValue ? {} : { color: statusColor, fontWeight: 'bold' }
              }
            >
              <div>
                {/* Botón con estilo dinámico según el estado actual */}
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  disabled={this.state.pumpState === 'Pending'}
                  onClick={this.handleToogle}
                >
                  {buttonText}
                </Button>
              </div>
            </span>
          </Typography>
        </div>
        {this.state.modalConfirmation && (
          <ConfirmDialog
            title={
              this.state.pumpState === 'On' ? 'Turn OFF Pump' : 'Turn ON Pump'
            }
            onAction={async (ok) => {
              if (ok) {
                await this.handleChangePumpControl()
              }
              this.setState({ modalConfirmation: false })
            }}
          >
            Are you sure you want to{' '}
            {this.state.pumpState === 'On' ? 'turn OFF Pump?' : 'turn ON Pump?'}
          </ConfirmDialog>
        )}
        {this.state.showSnackbar && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ zIndex: 2147483647, top: 100 }}
            onClose={() => {
              this.setState({
                showSnackbar: false,
                snackbarMessage: '',
                snackbarSeverity: 'success',
              })
            }}
          >
            <Alert severity={this.state.snackbarSeverity}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.multitenantUser, // state.oidc.user,
  accessToken: state.multitenantUser.accessToken, // user?.access_token,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id ?? 0,
  email: state.multitenantUser.email,
})
