import * as React from 'react'
import { connect } from 'react-redux'
import {
  withMySitesContext,
  MySitesContextData,
} from '../contexts/MySitesContext'
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

interface State {
  readonly showMap: boolean
}

type AllProps = MySitesContextData & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    mapContainer: {
      marginBottom: '30px',
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(0, 4),
      marginTop: '20px',
    },
  })

class QuickSettings extends React.Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = { showMap: !!this.props.showMap }
  }

  render() {
    return this.props.hideMapToggle ? null : this.renderMapToggle()
  }

  private readonly onEnableMapChanged = (showMap: boolean) => {
    this.setState({ showMap })
    this.props.setMapSettings!(showMap, this.props.hideMapToggle)
  }

  private renderMapToggle() {
    return (
      <FormControlLabel
        className={this.props.classes.mapContainer}
        control={
          <Switch
            checked={this.state.showMap}
            onChange={(_, checked) => this.onEnableMapChanged(checked)}
            color="primary"
            name="checkedA"
          />
        }
        label="Show map"
      />
    )
  }
}

export default connect()(withMySitesContext(withStyles(styles)(QuickSettings)))
