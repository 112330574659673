import { getPlungerClient } from 'api/client'
import {
  PlungerArrivalTimesResponse,
  PlungerDescriptionResponse,
  PlungerJob,
  PlungerJobInfo,
  PlungerLatestStateApiModel,
  PlungerOperatingMode,
  SwaggerException,
  SwaggerResponse,
  UpdatePlungerOperatingModeRequest,
} from 'api/apiservice'
import { ModelState } from './ModelState'
import { parseError } from './utils'

export interface PlungerState extends ModelState {
  readonly plungerArrivalTimes?: PlungerArrivalTimesResponse
  readonly plungerSettings?: PlungerDescriptionResponse
  readonly plungerJobs?: PlungerJobInfo[]
  readonly infoMessage?: string
}

export const updateAction = async (
  getState: () => PlungerState,
  setState: (State: PlungerState) => void,
  apiCall: () => Promise<SwaggerResponse<PlungerJob>>,
  settingsName: keyof Pick<
    PlungerDescriptionResponse,
    | 'autoAdjustModeSettings'
    | 'commonWellSettings'
    | 'pressureModeSettings'
    | 'timeModeSettings'
  >,
  settingsValue: any
) => {
  requestUpdate(getState, setState)
  try {
    await receiveUpdate(
      getState,
      setState,
      apiCall,
      settingsName,
      settingsValue
    )
  } catch (error) {
    receiveUpdatingError(getState, setState, error)
  }
}

export const requestArrivalTimes = async (
  accessToken: string,
  tenantId: string,
  plungerId: string,
  getState: () => PlungerState,
  setState: (State: PlungerState) => void
) => {
  const state = getState()
  const newState = {
    ...state,
    error: undefined,
    loading: true,
  }

  setState(newState)

  try {
    const api = getPlungerClient(accessToken, tenantId)
    const { result } = await api.getArrivalTimes(plungerId)

    const state = getState()
    const newState = {
      ...state,
      plungerArrivalTimes: result,
      loading: false,
      error: undefined,
    }

    setState(newState)
  } catch (error) {
    console.error(error)
    const state = getState()
    const newState = {
      ...state,
      plungerArrivalTimes: {} as PlungerArrivalTimesResponse,
      error: parseError(error),
      loading: false,
    }

    setState(newState)
  }
}

export const requestPlungerSettings = async (
  accessToken: string,
  tenantId: string,
  plungerId: string,
  getState: () => PlungerState,
  setState: (State: PlungerState) => void
) => {
  requestUpdate(getState, setState)

  try {
    const api = getPlungerClient(accessToken, tenantId)
    const { result } = await api.getSettings(plungerId)

    const newState = {
      ...getState(),
      plungerSettings: result,
      loading: false,
      error: undefined,
    }

    setState(newState)
  } catch (error) {
    console.error(error)
    const newState = {
      ...getState(),
      plungerSettings: {} as PlungerDescriptionResponse,
      error: parseError(error),
      loading: false,
    }

    setState(newState)
  }
}

export const requestPlungerJobs = async (
  accessToken: string,
  tenantId: string,
  plungerId: string,
  getState: () => PlungerState,
  setState: (State: PlungerState) => void
) => {
  const state = getState()
  const newState = {
    ...state,
    error: undefined,
    loading: true,
  }

  setState(newState)

  try {
    const api = getPlungerClient(accessToken, tenantId)
    const { result } = await api.getJobList(plungerId)

    const state = getState()
    const newState = {
      ...state,
      plungerJobs: result,
      loading: false,
      error: undefined,
    }

    setState(newState)
  } catch (error) {
    console.error(error)
    const state = getState()
    const newState = {
      ...state,
      plungerJobs: [] as PlungerJobInfo[],
      error: parseError(error),
      loading: false,
    }

    setState(newState)
  }
}

export const updateOperatingMode = async (
  accessToken: string,
  tenantId: string,
  plungerId: string,
  mode: PlungerOperatingMode,
  getState: () => PlungerState,
  setState: (State: PlungerState) => void
) => {
  requestUpdate(getState, setState)

  try {
    const api = getPlungerClient(accessToken, tenantId)
    const modeRequest = UpdatePlungerOperatingModeRequest.fromJS({
      Operating_Mode: mode,
    })

    const { result } = await api.updateOperatingMode(plungerId, modeRequest)

    setState({
      plungerSettings: {
        ...getState().plungerSettings,
        latestState: {
          ...getState().plungerSettings?.latestState,
          operatingMode: mode,
        } as PlungerLatestStateApiModel,
      } as PlungerDescriptionResponse,
      infoMessage: getInfoMessage(result.jobId),
      loading: false,
      error: undefined,
    })
  } catch (error) {
    receiveUpdatingError(getState, setState, error)
  }
}

const requestUpdate = (
  getState: () => PlungerState,
  setState: (State: PlungerState) => void
) => {
  const state = getState()
  const newState = {
    ...state,
    error: undefined,
    loading: true,
  }
  setState(newState)
}

const receiveUpdate = async (
  getState: () => PlungerState,
  setState: (State: PlungerState) => void,
  apiCall: () => Promise<SwaggerResponse<PlungerJob>>,
  settingsName: keyof Pick<
    PlungerDescriptionResponse,
    | 'autoAdjustModeSettings'
    | 'commonWellSettings'
    | 'timeModeSettings'
    | 'pressureModeSettings'
  >,
  settingsValue: any
) => {
  const { result } = await apiCall()
  const state = getState()
  const newState = {
    ...state,
    plungerSettings: {
      ...state.plungerSettings,
      [settingsName]: settingsValue,
    },
    infoMessage: getInfoMessage(result.jobId),
    loading: false,
    error: undefined,
  } as PlungerState

  setState(newState)
}

const receiveUpdatingError = (
  getState: () => PlungerState,
  setState: (State: PlungerState) => void,
  error: any
) => {
  let errorMessage
  if (error instanceof SwaggerException && error.status === 500) {
    errorMessage = error.response
  } else {
    errorMessage = error
  }

  console.error(errorMessage)
  const state = getState()
  const newState = {
    ...state,
    error: parseError(error),
    loading: false,
  }

  setState(newState)
}

const getInfoMessage = (jobId?: string) => {
  return `Setting update is scheduled and will be applied when device is online. JobId: ${jobId}`
}
