import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import { ListItemProps } from '@material-ui/core/ListItem'
import { ListItemTextProps } from '@material-ui/core/ListItemText'

export interface ListItemLinkProps {
  readonly icon: React.ReactElement
}

type AllProps = ListItemLinkProps &
  LinkProps &
  ListItemProps &
  ListItemTextProps

export default class ListItemLink extends React.Component<AllProps> {
  private readonly renderLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (itemProps, ref) => (
      <Link {...itemProps} innerRef={ref} to={this.props.to} />
    )
  )

  public render() {
    const { icon, primary, secondary, to, replace, selected } = this.props
    return (
      <ListItem
        button={true}
        component={this.renderLink}
        to={to}
        replace={replace}
        selected={selected}
        divider={true}
      >
        {icon && (
          <Tooltip title={<React.Fragment>{primary}</React.Fragment>}>
            <ListItemIcon>{icon}</ListItemIcon>
          </Tooltip>
        )}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    )
  }
}
