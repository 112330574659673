import { PlungerOperatingMode } from 'api/apiservice'

export function getPlungerOperatingModeFriendlyName(
  mode: PlungerOperatingMode
) {
  switch (mode) {
    case PlungerOperatingMode.TimeMode:
      return 'Time Mode'

    case PlungerOperatingMode.AutoAdjustMode:
      return 'Auto Adjust Mode'

    case PlungerOperatingMode.PressureMode:
      return 'Pressure Mode'

    default:
      return '?'
  }
}
