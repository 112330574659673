import * as React from 'react'
import {
  withStyles,
  Typography,
  createStyles,
  TableCellProps,
  TableCell,
  PropTypes,
  TypographyProps,
  Chip,
  ChipProps,
  alpha,
} from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { TelemetryStatus } from '../../api/alertservice'
import { getStatusLensColor } from 'components/utils'
import theme from 'theme'

const greyTextColor = '#999999'

export const GreyTextTypography = withStyles({
  root: { color: greyTextColor },
})(Typography)

export const DoubleRowTableColumnHeader = (props: {
  readonly title?: React.ReactNode
  readonly caption?: React.ReactNode
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <DoubleRowBasic
      title={props.title}
      titleVariant="subtitle2"
      TitleComponent={GreyTextTypography}
      caption={props.caption}
      CaptionComponent={GreyTextTypography}
      align="center"
    />
  </div>
)

export const StyledTableCell: React.ComponentType<TableCellProps> = withStyles(
  (theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down('md')]: {
          '&:last-child': {
            borderRight: 0,
          },
        },
      },
      head: {
        lineHeight: '1rem',
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('md')]: {
          maxWidth: '125px',
        },
      },
      body: {
        [theme.breakpoints.down('md')]: {
          maxWidth: '125px',
        },
      },
      sizeSmall: {
        [theme.breakpoints.down('md')]: {
          paddingLeft: 0,
          paddingRight: 0,
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    })
)(TableCell)

export const StyledFirstTableCell: React.ComponentType<TableCellProps> =
  withStyles((theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down('md')]: {
          '&:last-child': {
            borderRight: 0,
          },
        },
        '&:first-child': {
          position: 'sticky',
          backgroundColor: theme.palette.background.paper,
          left: 0,
          zIndex: 1,
        },
      },
      head: {
        '&:first-child': {
          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
          },
        },
        lineHeight: '1rem',
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('md')]: {
          maxWidth: '125px',
        },
      },
      body: {
        '&:first-child': {
          [theme.breakpoints.down('md')]: {
            minWidth: '270px',
            maxWidth: '300px',
            paddingLeft: theme.spacing(1),
          },
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '125px',
        },
      },
      sizeSmall: {
        [theme.breakpoints.down('md')]: {
          paddingLeft: 0,
          paddingRight: 0,
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    })
  )(TableCell)

interface DoubleRowTableCellProps {
  readonly first?: React.ReactNode
  readonly second?: React.ReactNode
  readonly align?: TableCellProps['align']
  readonly style?: React.CSSProperties
}

export interface DoubleRowProps {
  readonly title?: React.ReactNode
  readonly caption?: React.ReactNode
  readonly style?: React.CSSProperties
}

export const DoubleRow = ({ title, caption, style }: DoubleRowProps) => (
  <div
    style={{
      ...style,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <DoubleRowBasic
      title={title}
      titleVariant="body2"
      TitleComponent={Typography}
      caption={caption}
      CaptionComponent={GreyTextTypography}
      noWrap={true}
      captionStyle={{ textOverflow: 'ellipsis' }}
    />
  </div>
)

export const DoubleRowTableCell = React.forwardRef(
  (
    {
      first: title,
      second: caption,
      align,
      style,
      ...parentProps
    }: DoubleRowTableCellProps,
    ref
  ) => (
    <StyledTableCell
      ref={ref}
      scope="row"
      style={style}
      align={align}
      {...parentProps}
    >
      <DoubleRow title={title} caption={caption} />
    </StyledTableCell>
  )
)

interface DoubleRowBasicProps {
  readonly title?: React.ReactNode
  readonly titleVariant?: Variant
  readonly titleExtension?: React.ReactNode
  readonly TitleComponent: React.ComponentType<TypographyProps>
  readonly titleStyle?: React.CSSProperties
  readonly caption?: React.ReactNode
  readonly CaptionComponent: React.ComponentType<TypographyProps>
  readonly noWrap?: boolean
  readonly captionStyle?: React.CSSProperties
  readonly align?: PropTypes.Alignment
}

export const DoubleRowBasic = ({
  title,
  titleVariant,
  titleExtension,
  TitleComponent,
  titleStyle,
  caption,
  CaptionComponent,
  noWrap,
  captionStyle,
  align,
}: DoubleRowBasicProps) => {
  return (
    <>
      {title && (
        <TitleComponent
          variant={titleVariant}
          noWrap={noWrap}
          align={align}
          style={titleStyle}
        >
          {title}
          {titleExtension}
        </TitleComponent>
      )}
      {caption && (
        <CaptionComponent
          variant="caption"
          noWrap={noWrap}
          align={align}
          style={captionStyle}
        >
          {caption}
        </CaptionComponent>
      )}
    </>
  )
}

interface DoubleRowBasicWithEmptyTextProps extends DoubleRowBasicProps {
  readonly emptyText?: string
}

export const DoubleRowBasicWithEmptyText = (
  props: DoubleRowBasicWithEmptyTextProps
) => {
  const { title, emptyText } = props

  if (title === undefined) {
    return <> {emptyText ?? 'N/A'} </>
  }

  return <DoubleRowBasic {...(props as DoubleRowBasicProps)} />
}

type StyledChipProps = ChipProps & {
  colorPrimary?: string
  backgroundColorPrimary?: string
}

const StyledChip = ({
  colorPrimary,
  backgroundColorPrimary,
  ...props
}: StyledChipProps) => {
  const ChipWithStyle = withStyles({
    colorPrimary: {
      color: colorPrimary,
      backgroundColor: backgroundColorPrimary,
      fontWeight: 'bold',
      margin: theme.spacing(0.5, 0.5, 0, 0),
    },
    root: {
      overflow: 'hidden',
    },
  })(Chip)

  return <ChipWithStyle size="small" color="primary" {...props} />
}

type StatusChipProps = ChipProps & {
  status?: TelemetryStatus
}

const getStatusColor = (
  status?: TelemetryStatus
): [string | undefined, string | undefined] => {
  switch (status) {
    case TelemetryStatus.Green:
      return [getStatusLensColor(TelemetryStatus.Green), '#FFF']

    case TelemetryStatus.Red:
      const redStatusColor = getStatusLensColor(TelemetryStatus.Red)
      return [redStatusColor, alpha(redStatusColor, 0.3)]

    case TelemetryStatus.Yellow:
      const yellowStatusColor = getStatusLensColor(TelemetryStatus.Yellow)
      return ['dimgray', alpha(yellowStatusColor, 0.3)]

    default:
      return [undefined, undefined]
  }
}

export const StatusChip = ({ status, ...props }: StatusChipProps) => {
  const [color, backgroundColor] = getStatusColor(status)

  return (
    <StyledChip
      colorPrimary={color}
      backgroundColorPrimary={backgroundColor}
      {...props}
    />
  )
}
