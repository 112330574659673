import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { AppState } from '../store/AppState'
import { User } from 'oidc-client'

import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  CssBaseline,
} from '@material-ui/core'
import { MsalContext } from '@azure/msal-react'
import ReactBreakpoints from 'react-breakpoints'
import Navigation from './Navigation'
import MySitesContext, { MySitesContextData } from './contexts/MySitesContext'
import UiContext, { UiContextData } from './contexts/UiContext'
import SiteContext, { SiteContextData } from './contexts/SiteContext'
import { defaultTelemetryState, TelemetryState } from '../store/SiteDetails'
import { Site } from 'store/Site'
import { actionCreators as multitenantActionCreators } from 'store/actions/multitenant'
import theme from 'theme'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      display: 'grid',
      height: `calc(100vh - ${theme.spacing(2)}px)`,
      width: '100%',
      overflow: 'hidden',
      paddingTop: theme.mixins.toolbar.minHeight,
      margin: theme.spacing(2, 1, 1, 1),
    },
  })

interface Props {
  readonly accessToken?: string
  readonly tenantId: number
  readonly user?: User
}

type AllProps = Props & React.PropsWithChildren<any> & WithStyles<typeof styles>

interface State {
  readonly title?: string
  readonly uiContext: UiContextData
  readonly siteContext: SiteContextData
  readonly mySitesContext: MySitesContextData
}

class Layout extends React.Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      siteContext: {
        setSelectedSiteId: this.setSelectedSiteId,
        selectSite: this.selectSite,
        setTelemetry: this.setTelemetry,
        setLastSeenTime: this.setLastSeenTime,
        telemetry: defaultTelemetryState,
      },
      uiContext: {
        setPageTitle: this.setPageTitle,
      },
      mySitesContext: {
        showMap: true,
        setSelectedGroupId: this.setSelectedGroupId,
        setMapSettings: this.setMapSettings,
      },
    }

    setInterval(() => {
      this.getMultitenantUser()
    }, 1800 * 1000)
  }

  static contextType = MsalContext // required to get context properties from AD MSAL
  componentDidMount() {
    this.getMultitenantUser()
  }

  private getMultitenantUser() {
    this.props.getMultitenantUser(this.context.instance, this.context.accounts)
  }

  public render() {
    const { classes, children } = this.props
    const { uiContext, siteContext, mySitesContext } = this.state
    const breakpoints = theme.breakpoints.values
    const selectedSite =
      this.state.siteContext.selectedSite !== undefined
        ? this.state.siteContext.selectedSite
        : ''
    if (selectedSite !== '') {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <UiContext.Provider value={uiContext}>
            <SiteContext.Provider value={siteContext}>
              <MySitesContext.Provider value={mySitesContext}>
                <ReactBreakpoints breakpoints={breakpoints}>
                  <Navigation selectedSite={selectedSite} />
                  <main className={classes.content}>{children}</main>
                </ReactBreakpoints>
              </MySitesContext.Provider>
            </SiteContext.Provider>
          </UiContext.Provider>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <UiContext.Provider value={uiContext}>
            <SiteContext.Provider value={siteContext}>
              <MySitesContext.Provider value={mySitesContext}>
                <ReactBreakpoints breakpoints={breakpoints}>
                  <Navigation />
                  <main className={classes.content}>{children}</main>
                </ReactBreakpoints>
              </MySitesContext.Provider>
            </SiteContext.Provider>
          </UiContext.Provider>
        </div>
      )
    }
  }

  private readonly setSelectedGroupId = (selectedGroupId?: string) =>
    this.setState({
      mySitesContext: {
        ...this.state.mySitesContext,
        selectedGroupId,
      },
    })

  private readonly setMapSettings = (
    showMap: boolean,
    hideMapToggle?: boolean
  ) =>
    this.setState({
      mySitesContext: {
        ...this.state.mySitesContext,
        showMap,
        hideMapToggle,
      },
    })

  private readonly setSelectedSiteId = (
    toggleHighlight: boolean,
    selectedSiteId?: string
  ) =>
    this.setState({
      siteContext: {
        ...this.state.siteContext,
        selectedSiteId: toggleHighlight
          ? selectedSiteId === this.state.siteContext.selectedSiteId
            ? undefined
            : selectedSiteId
          : selectedSiteId,
      },
    })

  private readonly selectSite = (selectedSite?: Site) =>
    this.setState({
      siteContext: {
        ...this.state.siteContext,
        selectedSite: selectedSite,
      },
    })

  private readonly setPageTitle = (title?: React.ReactNode) =>
    this.setState({
      uiContext: {
        ...this.state.uiContext,
        title,
      },
    })

  private readonly setTelemetry = (telemetry: TelemetryState) =>
    this.setState({
      siteContext: {
        ...this.state.siteContext,
        telemetry,
      },
    })

  private readonly setLastSeenTime = (lastSeenTime?: Date) =>
    this.setState({
      siteContext: {
        ...this.state.siteContext,
        lastSeenTime,
      },
    })
}

const mapStateToProps = (state: AppState): Props => {
  return {
    accessToken: state.multitenantUser.accessToken,
    tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    user: state.oidc.user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(multitenantActionCreators, dispatch),
})

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Layout)
)
