import React from 'react'
import { connect } from 'react-redux'

import { createStyles, Theme, Paper } from '@material-ui/core'
import { TrendingUpTwoTone as StatusIcon } from '@material-ui/icons'
import { WithStyles, withStyles } from '@material-ui/styles'

import { withUiContext, UiContextData } from './contexts/UiContext'
import { ConfigType, getConfigTitle } from '../store/Configuration'
import { Tabs, TabProps } from './misc/Tabs'
import GlobalThresholdConfiguration from './config/thresholds/GlobalThresholdConfiguration'
import { AppState } from '../store/AppState'

const styles = (_: Theme) => createStyles({})

export interface Props {
  readonly accessToken?: string
  readonly tenantId: number
}

type AllProps = Props & WithStyles<typeof styles> & UiContextData

export class Configuration extends React.Component<AllProps> {
  public componentDidMount() {
    this.props.setPageTitle!('Global Thresholds')
  }

  public render() {
    const tabs = this.renderTabs()

    return (
      <Paper
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          overflow: 'hidden',
        }}
      >
        {this.props.accessToken && this.props.tenantId && (
          <Tabs<ConfigType>
            initialTab={ConfigType.Thresholds}
            tabs={tabs}
            getTabPanel={(tab) => this.renderTabContent(tab)}
          />
        )}
      </Paper>
    )
  }

  private renderTabs() {
    return Object.values(ConfigType)
      .filter((s) => {
        return typeof s === 'number'
      })
      .map((s) => {
        return this.getTabProps(s as ConfigType)
      })
  }

  private readonly getTabProps = (tab: ConfigType): TabProps<ConfigType> => {
    return {
      value: tab,
      icon: this.getTabIcon(tab),
      label: getConfigTitle(tab),
    }
  }

  private getTabIcon(tab: ConfigType) {
    switch (tab) {
      case ConfigType.Thresholds:
        return <StatusIcon />

      default:
        return undefined
    }
  }

  private renderTabContent(tab: ConfigType) {
    switch (tab) {
      case ConfigType.Thresholds:
        return this.renderThresholds()

      default:
        console.warn(`Settings type '${ConfigType[tab]}' is not supported.`)
        return undefined
    }
  }

  private renderThresholds() {
    return (
      <GlobalThresholdConfiguration
        tenantId={this.props.tenantId}
        accessToken={this.props.accessToken}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  accessToken: state.multitenantUser.accessToken,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

export default withStyles(styles)(
  withUiContext(connect(mapStateToProps)(Configuration))
)
