import React from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import { ProductionCode, TelemetryType } from 'api/apiservice'
import { GasFlowValues } from './AssetInfo'

const fieldStyle = {
  width: 160,
}

export const isShowingAssetInfoExtraFields = (
  asset: any,
  telemetryType: TelemetryType
) => {
  switch (telemetryType) {
    case TelemetryType.TankLevel:
    case TelemetryType.BalancedTank:
    case TelemetryType.LiquidFlow:
    case TelemetryType.GasFlow:
      return true
    default:
      return false
  }
}

export const getLiquidFlowFields = (
  asset: any,
  handleInputChange,
  validRole: boolean
) => {
  return (
    <Grid item>
      <TextValidator
        disabled={!validRole}
        name="kfactor"
        style={fieldStyle}
        id="kfactor"
        label="K-Factor"
        type="number"
        value={asset.kfactor}
        onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
        validators={['required', 'minNumber:0']}
        errorMessages={['this field is required', 'Value must be positive']}
      />
    </Grid>
  )
}

export const getTankFields = (
  asset: any,
  handleInputChange,
  validRole: boolean
) => {
  return (
    <>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="height"
          id="height"
          label="Tank height (ft)"
          type="number"
          value={asset.height}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="capacity"
          id="capacity"
          label="Tank capacity (bbl)"
          type="number"
          value={asset.capacity}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="gravity"
          id="gravity"
          label="Gravity"
          type="number"
          value={asset.gravity}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid>
      <Grid item>
        <FormControl style={fieldStyle}>
          <InputLabel id="productionCode-label">Liquid type</InputLabel>
          <Select
            disabled={!validRole}
            labelId="productionCode-label"
            id="productionCode"
            value={asset.productionCode}
            onChange={(e) =>
              handleInputChange(e.target.value, 'productionCode')
            }
          >
            <MenuItem value={ProductionCode.Oil}>Oil</MenuItem>
            <MenuItem value={ProductionCode.Water}>Water</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}

export const getFlowFields = (
  asset: any,
  handleInputChange,
  validRole: boolean
) => {
  return (
    <>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="tubeSize"
          id="tubeSize"
          label="Tube Size"
          type="number"
          value={asset.tubeSize}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid>
      {/* <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="orificeSize"
          id="orificeSize"
          label="Orifice Size"
          type="number"
          value={asset.orificeSize}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="gravity"
          id="gravity"
          label="Gravity"
          type="number"
          value={asset.gravity}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required', 'minNumber:0']}
          errorMessages={['this field is required', 'Value must be positive']}
        />
      </Grid> */}
    </>
  )
}

export const getGasFlowFields = (
  asset: any,
  handleInputChange,
  validRole: boolean,
  latestGasFlowValues: GasFlowValues
) => {
  // const { PipeDiameter, OrificeDiameter } = latestGasFlowValues || {}
  return (
    <>
      <Grid item>
        <TextValidator
          disabled={!validRole}
          style={fieldStyle}
          name="totalFlowType"
          id="totalFlowType"
          label="Total Flow Type"
          type="text"
          value={asset.totalFlowType}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required']}
          errorMessages={['this field is required']}
        />
      </Grid>
      {/* <Grid item>
        <TextValidator
          disabled={true}
          style={fieldStyle}
          name="pipeDiameter"
          id="pipeDiameter"
          label="Pipe Diameter"
          type="text"
          value={isNaN(+PipeDiameter) ? '-' : (+PipeDiameter).toFixed(2)}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required']}
          errorMessages={['this field is required']}
        />
      </Grid>
      <Grid item>
        <TextValidator
          disabled={true}
          style={fieldStyle}
          name="orificeDiameter"
          id="orificeDiameter"
          label="Orifice Diameter"
          type="text"
          value={isNaN(+OrificeDiameter) ? '-' : (+OrificeDiameter).toFixed(2)}
          onChange={(e: any) => handleInputChange(e.target.value, e.target.id)}
          validators={['required']}
          errorMessages={['this field is required']}
        />
      </Grid> */}
    </>
  )
}
