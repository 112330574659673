import * as React from 'react'
import { connect } from 'react-redux'
import { duration } from 'moment'

import { LinearProgress, Grid, Typography } from '@material-ui/core'
import { AppState } from '../../../store/AppState'
import { HistoricalDataEntry, getTelemetryHistoricalData } from 'api/client'
import { ModelState } from 'store/ModelState'
import { Device as DeviceData } from 'store/Device'
import { renderErrorAlert } from '../../misc/utils'
import { relativeTimeRanges } from 'components/site/MetricsDashboardTab'
import { TimeRange, getTimeRange } from 'components/misc/TimeRange'
import SensorChart from 'components/sensors/SensorChart'
import { GatewayResponse, TelemetryType } from 'api/apiservice'
import TimeRangePicker from 'components/misc/TimeRangePicker'

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly sensor?: DeviceData
  readonly gateway?: GatewayResponse
  readonly siteId: string
  readonly telemetryType: string
}

interface State extends ModelState {
  readonly loadingBatteryLevel?: boolean
  readonly batteryLevel: HistoricalDataEntry[]
  readonly timeRange: TimeRange
}

type AllProps = PropsFromState

const defaultTimeRange = duration(1, 'day')

class SensorBatteryLevelChart extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      batteryLevel: [],
      timeRange: defaultTimeRange,
    }
  }

  public componentDidMount() {
    this.load()
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { sensor, siteId, telemetryType } = this.props

    if (
      prevProps.sensor !== sensor ||
      prevProps.siteId !== siteId ||
      prevProps.telemetryType !== telemetryType
    ) {
      this.load()
    }
  }

  public render() {
    const { loading, error } = this.state

    if (loading) {
      return <LinearProgress />
    }

    if (error) {
      renderErrorAlert(error)
    }

    if (this.props.sensor === undefined && this.props.gateway === undefined) {
      return null
    }

    return (
      <React.Fragment>
        <Grid item={true} xs={6}>
          {this.renderTitle()}
        </Grid>
        <Grid item={true} xs={6}>
          {this.renderTimePicker()}
        </Grid>
        <Grid item={true} xs={12}>
          {this.renderChart()}
        </Grid>
      </React.Fragment>
    )
  }

  private renderTitle() {
    return (
      <div
        style={{
          display: 'flex',
          padding: '5px 15px',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" noWrap={true}>
          Battery Level
        </Typography>
      </div>
    )
  }

  private renderChart() {
    const { batteryLevel, timeRange } = this.state

    return (
      <SensorChart
        data={batteryLevel}
        timeRange={timeRange}
        statusRanges={[]}
        fullWidth={true}
        formatValue={(value) => `${value}%`}
      />
    )
  }

  private readonly renderTimePicker = () => {
    const { timeRange } = this.state

    return (
      <TimeRangePicker
        relativeTimeRanges={relativeTimeRanges}
        onTimeRangeChanged={(timeRange) =>
          this.setState(
            {
              timeRange: timeRange ?? defaultTimeRange,
            },
            this.load
          )
        }
        initialTimeRange={timeRange}
      />
    )
  }

  private load() {
    const { accessToken, tenantId, siteId, sensor, gateway } = this.props

    if (!accessToken || !tenantId || !siteId) {
      return
    }

    if (sensor === undefined && gateway === undefined) {
      return
    }

    try {
      this.setState({ loading: true })
      this.loadBatteryLevel()
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }

  private readonly loadBatteryLevel = async () => {
    const { accessToken, siteId, sensor, tenantId, gateway } = this.props

    const { telemetryType }: { telemetryType: TelemetryType } = this.props
    const { timeRange } = this.state
    const [from, to] = getTimeRange(timeRange)

    const result = await getTelemetryHistoricalData(
      accessToken!,
      tenantId,
      siteId,
      telemetryType,
      null,
      sensor?.id! ?? gateway?.id,
      from,
      to
    )

    if (result instanceof Error) {
      this.setState({ error: result.message })
      return
    }

    const batteryLevel = result.map(
      (m) =>
        ({
          value: m.Payload.value ?? m.Payload.primaryBatteryLevel,
          timestamp: m.CreationTimeUtc.valueOf(),
        } as HistoricalDataEntry)
    )
    this.setState({ batteryLevel })
  }
}

const mapPropsFromState = ({ oidc, multitenantUser }: AppState) => ({
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

export default connect(mapPropsFromState)(SensorBatteryLevelChart)
