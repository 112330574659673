import React from 'react'

import { ConfirmDialog } from '../misc/ConfirmDialog'
import { TextField, InputAdornment } from '@material-ui/core'
import { ChatOutlined } from '@material-ui/icons'

export default function AcknowledgeDialog(props: {
  prompt: string
  onAction: (ok: boolean, ...params) => void
}) {
  const [note, setNote] = React.useState('')
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setNote(value?.slice(0, 1024))
  }
  const onAction = (ok: boolean) => props.onAction(ok, note)

  return (
    <ConfirmDialog title="Confirmation" onAction={onAction}>
      {props.prompt}
      <br />
      <br />
      <TextField
        label="Note (maximum 1024 characters)"
        value={note}
        onChange={onChange}
        fullWidth={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ChatOutlined color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </ConfirmDialog>
  )
}
