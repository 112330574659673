import * as React from 'react'
import { TelemetryMessage } from '../../store/SiteDetails'
import { getTelemetryHistoricalData } from '../../api/client'
import { TelemetryType } from '../../api/apiservice'
import { TimeRange, getTimeRange } from '../misc/TimeRange'
import { TrendingDown, TrendingUp } from '@material-ui/icons'
import { getStatusLensColor } from '../utils'
import { IconButton, SvgIconProps } from '@material-ui/core'
import { TelemetryStatus } from 'api/alertservice'

export interface HistoricalData<T> {
  readonly data: T[]
  readonly from: Date
}

export async function getTelemetryHistory(
  accessToken: string,
  siteId: string,
  telemetryType: TelemetryType,
  timeRange: TimeRange,
  assetId: string | null,
  sensorId: string | null,
  tenantId: number
): Promise<HistoricalData<TelemetryMessage> | Error> {
  const [from, to] = getTimeRange(timeRange)

  try {
    const result = await getTelemetryHistoricalData(
      accessToken,
      tenantId,
      siteId,
      telemetryType,
      sensorId,
      assetId,
      from,
      to
    )

    if (result instanceof Error) {
      return result
    }

    return {
      from: from || new Date(0),
      data: result,
    }
  } catch (e) {
    console.error(e)
    return e
  }
}

export function renderStatus(
  status: TelemetryStatus,
  prevStatus: TelemetryStatus
) {
  const Icon = status > prevStatus ? TrendingUp : TrendingDown
  const leftColor = getStatusLensColor(prevStatus)
  const rightColor = getStatusLensColor(status)
  const id = `gradient${prevStatus}${status}`

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <IconButton disabled={true} style={{ backgroundColor: leftColor }} />
      <Icon
        component={(svgProps: SvgIconProps) => (
          <svg {...svgProps}>
            <defs>
              <linearGradient id={id}>
                <stop offset="0%" stopColor={leftColor} />
                <stop offset="100%" stopColor={rightColor} />
              </linearGradient>
            </defs>
            {React.cloneElement(
              (
                svgProps.children as React.ReactNodeArray
              )[0] as React.ReactElement,
              { fill: `url(#${id})` }
            )}
          </svg>
        )}
      />
      <IconButton disabled={true} style={{ backgroundColor: rightColor }} />
    </div>
  )
}
