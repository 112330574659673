import * as React from 'react'

import {
  Typography,
  Tab as MuiTab,
  createStyles,
  TabProps as MuiTabProps,
  WithStyles,
  withStyles,
  Theme,
  Tabs as MuiTabs,
} from '@material-ui/core'
import CssStyle from './CssStyle'

interface TabPanelProps {
  readonly children?: React.ReactNode
}

const TabPanel = ({ children, ...other }: TabPanelProps) => (
  <Typography
    component="div"
    role="tabpanel"
    style={{ display: 'grid', overflow: 'auto' }}
    {...other}
  >
    {children}
  </Typography>
)

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      flexDirection: 'row',
    },
    labelIcon: {
      minHeight: 'unset',
      '& .MuiTab-wrapper > *:first-child': {
        marginRight: theme.spacing(0.5),
        marginBottom: 0,
      },
    },
  })

function tab({
  classes: { wrapper, labelIcon },
  ...props
}: MuiTabProps & WithStyles<typeof styles>) {
  return <MuiTab classes={{ wrapper, labelIcon }} {...props} />
}

export const Tab = withStyles(styles)(tab)

export interface TabProps<T> extends CssStyle {
  readonly value: T
  readonly icon?: string | React.ReactElement
  readonly label?: React.ReactNode
  readonly getTabPanel?: (tab: T) => React.ReactNode
}

export interface TabsProps<T> extends CssStyle {
  readonly initialTab: T
  readonly tabs: TabProps<T>[]
  readonly getTabPanel?: (tab: T) => React.ReactNode
  readonly onTabChanged?: (tab: T) => void
  readonly tabsBarContent?: (tab: T) => React.ReactNode
}

export function Tabs<T>({
  initialTab,
  tabs,
  getTabPanel,
  onTabChanged,
  tabsBarContent: tabBarContent,
  style,
}: TabsProps<T>) {
  const [currentTab, setCurrentTab] = React.useState(initialTab)
  const containerStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
  }
  const mergedStyle = style ? { ...containerStyle, ...style } : containerStyle
  const getTab =
    tabs?.find((t) => t.value === currentTab)?.getTabPanel ?? getTabPanel

  React.useEffect(() => setCurrentTab(initialTab), [initialTab])

  return (
    <div style={mergedStyle}>
      <div style={{ display: 'flex' }}>
        <MuiTabs
          value={currentTab}
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, selectedTab: T) => {
            setCurrentTab(selectedTab)

            if (onTabChanged) {
              onTabChanged(selectedTab)
            }
          }}
          style={{ flexGrow: 1 }}
        >
          {tabs.map((t) => (
            <Tab
              key={`tab-${t.value}`}
              value={t.value}
              icon={t.icon}
              label={t.label}
              style={t.style}
            />
          ))}
        </MuiTabs>
        {tabBarContent && (
          <div style={{ flexGrow: 0 }}>{tabBarContent(currentTab)}</div>
        )}
      </div>
      <TabPanel>{getTab && getTab(currentTab)}</TabPanel>
    </div>
  )
}
