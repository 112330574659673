import { StatusRange } from 'api/alertservice'
import { HistoricalDataEntry } from 'api/client'
import { celsiusToFahrenheit } from 'components/utils/converter'
import { TelemetryType } from '../../api/apiservice'
import BaseSensor from './BaseSensor'

export default class HeaterTemperature extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.HeaterTemp, 'Heater Temperature')

    this.loadReferences = this.loadReferences.bind(this)
  }

  protected getDisplayReading(readingValue: number): number | undefined {
    return readingValue !== undefined
      ? celsiusToFahrenheit(readingValue)
      : undefined
  }

  protected processData(data: HistoricalDataEntry): HistoricalDataEntry {
    const newData: HistoricalDataEntry = {
      ...data,
      value: data.value && celsiusToFahrenheit(data.value),
    }

    return newData
  }

  protected processThresholds(statusRanges: StatusRange[]): StatusRange[] {
    const newThresholds = statusRanges.map((item) =>
      StatusRange.fromJS({
        status: item.status,
        minThreshold: celsiusToFahrenheit(item.minThreshold),
        maxThreshold: celsiusToFahrenheit(item.maxThreshold),
      })
    )

    return newThresholds
  }
}
