import * as React from 'react'
import moment from 'moment'

import EditIcon from '@material-ui/icons/Edit'
import {
  createStyles,
  Grid,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  PlungerLatestStateApiModel,
  PlungerOperatingMode,
} from 'api/apiservice'
import { getPlungerOperatingModeFriendlyName } from './formatter'
import ChangeOperatingModeDialog from './ChangeOperatingModeDialog'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflowX: 'hidden',
      overflowY: 'auto',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    value: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      width: 'auto',
    },
  })

interface Props {
  readonly latestState?: PlungerLatestStateApiModel
  updateOperatingMode(mode: PlungerOperatingMode): void
}

interface State {
  readonly isEditModeDialogShown: boolean
}

type AllProps = Props & WithStyles<typeof styles>

class CurrentState extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      isEditModeDialogShown: false,
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Paper className={this.props.classes.paper} elevation={6} square={true}>
          <Typography variant="h6">Current State</Typography>
          {this.renderLatestStateGrid()}
        </Paper>
        {this.state.isEditModeDialogShown && (
          <ChangeOperatingModeDialog
            operatingMode={this.props.latestState?.operatingMode}
            onCloseDialog={() =>
              this.setState({
                isEditModeDialogShown: false,
              })
            }
            onSave={this.props.updateOperatingMode}
          />
        )}
      </React.Fragment>
    )
  }

  private readonly renderLatestStateGrid = () => {
    const value = this.props.latestState

    if (value === undefined) {
      return (
        <Typography color="error">No Latest State Has Been Found</Typography>
      )
    }

    return (
      <Grid
        container={true}
        direction="column"
        justify="flex-start"
        alignItems="baseline"
        style={{ flexFlow: 'wrap' }}
      >
        {Object.keys(value)
          .filter((i) => value[i] !== null)
          .map((i) =>
            this.renderOption(i as keyof PlungerLatestStateApiModel, value[i])
          )}
      </Grid>
    )
  }

  private readonly renderOption = (key, rawValue) => {
    const display = this.getDisplayLabelAndValue(key, rawValue)
    if (display === undefined) {
      return null
    }

    const [label, value] = display
    return this.renderGridRow(key, label, value)
  }

  private readonly renderGridRow = (key, label, value) => {
    return (
      <Grid
        item={true}
        container={true}
        alignItems="baseline"
        key={key}
        className={this.props.classes.value}
        lg={12}
        md={6}
        sm={6}
        xs={6}
      >
        <Typography variant="body1">{label}:</Typography>
        <Typography variant="body2">
          <b>&nbsp;{value}</b>
        </Typography>
        {this.isOperatingModeRow(key) && (
          <Tooltip title="Edit Operating Mode">
            <IconButton
              onClick={() =>
                this.setState({
                  isEditModeDialogShown: true,
                })
              }
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    )
  }

  private readonly isOperatingModeRow = (
    key: keyof PlungerLatestStateApiModel
  ) => {
    return key === 'operatingMode'
  }

  private getDisplayLabelAndValue(
    key: keyof PlungerLatestStateApiModel,
    rawValue
  ): [string, string] | undefined {
    switch (key) {
      case 'time':
        return ['Time', moment(rawValue).format('llll')]
      case 'operatingMode':
        return ['Operating Mode', getPlungerOperatingModeFriendlyName(rawValue)]
      case 'batteryVoltage':
        return ['Battery Voltage', rawValue]
      case 'firmwareVersion':
        return ['Firmware Version', rawValue]
      case 'casingPressure':
        return ['Casing Pressure', rawValue]
      case 'linePressure':
        return ['Line Pressure', rawValue]
      case 'tubingPressure':
        return ['Tubing Pressure', rawValue]
      default:
        return undefined
    }
  }
}

export default withStyles(styles)(CurrentState)
