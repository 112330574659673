import { useDispatch } from 'react-redux'
import { TextField } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { useStyles } from './styles'
import {
  getDecodedToken,
  checkExpirationToken,
  extractParamManuallyFromSearchLocation,
} from '../../../utils/token'
import CancelButton from '../buttons/CancelButton'
import { texts } from '../../../utils/constantsTexts'
import GenericDialog from '../../common/GenericDialog'
import AlertComponent from '../../common/GenericAlert'
import { setHandleDialog } from '../../../store/actions/common'
import ExpiredActionButton from '../buttons/ExpiredActionButton'
import NotExpiredActionButton from '../buttons/NotExpiredActionButton'

const EmailAcknowledgeLayout = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const [note, setNote] = useState(null)
  const [encodedToken, setEncodedToken] = useState(null)
  const [decodedToken, setDecodedToken] = useState(null)
  const [isExpiredToken, setIsExpiredToken] = useState(true)

  useEffect(() => {
    handleToken()
  }, [])

  useEffect(() => {
    validateExpirationToken()
  }, [encodedToken])

  const openDialog = () => {
    dispatch(setHandleDialog(true))
  }

  const validateExpirationToken = () => {
    if (encodedToken) {
      const isExpired = checkExpirationToken(encodedToken)
      setIsExpiredToken(isExpired)

      openDialog()
    }
  }

  const handleToken = () => {
    const encodeToken = extractParamManuallyFromSearchLocation(search, 'token')
    setEncodedToken(encodeToken)
    if (encodeToken) {
      const decodedToken = getDecodedToken(encodeToken)
      setDecodedToken(decodedToken)
    }
  }

  const handleNote = (event) => {
    const noteText = event.target.value
    setNote(noteText)
  }

  return (
    <>
      <AlertComponent />
      {isExpiredToken ? (
        <GenericDialog
          cancelButton={<CancelButton />}
          actionButton={<ExpiredActionButton />}
          dialogTitle={texts.emailAcknowledgeExpiredTokenTitle}
          dialogContentText={texts.emailAcknowledgeExpiredTokenText}
        />
      ) : (
        <GenericDialog
          cancelButton={<CancelButton />}
          actionButton={
            <NotExpiredActionButton
              note={note}
              token={encodedToken}
              email={decodedToken?.UserEmail}
            />
          }
          dialogTitle={texts.emailAcknowledgeNotExpiredTokenTitle}
          dialogContentText={texts.emailAcknowledgeNotExpiredTokenText}
        >
          <TextField
            id="textFieldEmailAcknowledge"
            className={classes.textfieldNote}
            label="Note (maximum 1024 characters)"
            onChange={handleNote}
          />
        </GenericDialog>
      )}
    </>
  )
}

export default EmailAcknowledgeLayout
