import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import memoize from 'memoize-one'

import { UserSettings, actionCreators } from '../../store/UserSettings'
import { AppState } from '../../store/AppState'

import {
  GoogleMap,
  MarkerClusterer,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import {
  MarkerExtended,
  ClusterIconInfo,
  ClusterIconStyle,
  Cluster,
} from '@react-google-maps/marker-clusterer'
import { Clusterer } from '@react-google-maps/marker-clusterer/dist/Clusterer'
import { ButtonBase, Tooltip, Zoom, LinearProgress } from '@material-ui/core'
import ZoomOutMapOutlined from '@material-ui/icons/ZoomOutMapOutlined'
import { WithStyles, createStyles, withStyles } from '@material-ui/styles'
import ErrorBoundary from 'components/utils/ErrorBoundary'
import { TelemetryStatus } from 'api/alertservice'

const styles = () =>
  createStyles({
    bestFitButton: {
      position: 'absolute',
      top: '55px',
      right: '10px',
      height: '40px',
      width: '40px',
      backgroundColor: 'white',
      color: '#6d6262',
      borderRadius: '2px',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    },
  })

export interface Place {
  readonly id: string
  readonly pos: google.maps.LatLngLiteral
  readonly title?: string
  readonly payload?: any
  readonly status?: TelemetryStatus
}

interface State {
  readonly map?: google.maps.Map
  readonly selectedPlaceId?: string
  readonly clickedLocation?: google.maps.LatLngLiteral
  readonly isInfoOpen: boolean
  readonly zoomedClusterTitle?: string
  readonly mapLoadingError?: Error
}

export interface MarkerPlace {
  readonly marker: google.maps.Marker
  readonly place: Place
  readonly clusterer?: Clusterer
}

export interface Props {
  readonly places: Place[]
  readonly selectedPlaceId?: string
  readonly infoWindow?: (
    marker: google.maps.Marker,
    place: Place
  ) => React.ReactNode
  readonly clusterStyles?: ClusterIconStyle[]
  readonly clusterCalculator?: (
    markers: MarkerExtended[],
    num: number
  ) => ClusterIconInfo
  readonly getMarkerIcon?: (place: Place) => google.maps.Icon
  readonly onMarkerClick?: (
    isInfoOpen: boolean,
    selectedPlaceId?: string
  ) => void
  readonly disableClustering?: boolean
  readonly minimumClusterSize?: number
  readonly onClusterClick?: (cluster: Cluster) => void
  readonly maxZoom?: number
  readonly getPlacePositions?: (place: Place) => google.maps.LatLngLiteral[]
  readonly mapLoadingError?: Error
}

interface PropsFromState {
  readonly userSettings: UserSettings
  readonly mapApiKey?: string
}

type PropsFromDispatch = typeof actionCreators

type AllProps = Props &
  PropsFromState &
  PropsFromDispatch &
  WithStyles<typeof styles>

class GMap extends React.Component<AllProps, State> {
  private readonly markers = new Map<string, MarkerPlace>()

  private readonly memoizedPlaces = memoize((places: Place[]) =>
    places.filter((place: Place) => {
      const pos = place.pos

      if (pos) {
        const { lat, lng } = pos

        return (
          lat >= -90 &&
          lat <= 90 &&
          lng >= -180 &&
          lng <= 180 &&
          lat !== 0 &&
          lng !== 0
        )
      }

      return false
    })
  )

  constructor(props) {
    super(props)
    this.state = {
      isInfoOpen: false,
      selectedPlaceId: this.props.selectedPlaceId,
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { selectedPlaceId, places } = this.props

    if (prevProps.selectedPlaceId !== selectedPlaceId && selectedPlaceId) {
      const markerPlace = this.markers.get(selectedPlaceId)

      if (markerPlace) {
        const { clusterer, marker } = markerPlace

        if (
          clusterer &&
          marker &&
          !clusterer
            .getClusters()
            .some(
              (c) =>
                c.isMarkerAlreadyAdded(marker) &&
                c.getSize() !== 1 &&
                c.minClusterSize > 1
            )
        ) {
          this.panToPlaceMarker(selectedPlaceId)
        } else {
          this.zoomToPlaceMarker(selectedPlaceId)
        }
      } else {
        this.zoomToPlaceMarker(selectedPlaceId)
      }

      this.setState({ isInfoOpen: false })
    }

    if (prevProps.places.length !== places.length) {
      this.fitBounds()
    }
  }

  public render() {
    return (
      <ErrorBoundary error={this.state.mapLoadingError?.message}>
        {this.renderMap()}
      </ErrorBoundary>
    )
  }

  public renderMap() {
    const {
      classes,
      minimumClusterSize,
      onClusterClick,
      maxZoom,
      userSettings: { mapSettings },
    } = this.props

    return (
      <GoogleMap
        onLoad={this.onMapLoaded}
        onClick={(e) =>
          this.setState({
            clickedLocation: e.latLng.toJSON(),
          })
        }
        mapContainerStyle={{
          height: '100%',
          position: 'relative',
        }}
        options={{
          maxZoom,
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeId: mapSettings.type,
          rotateControl: false,
          tilt: 0,
        }}
        onMapTypeIdChanged={this.onMapTypeIdChanged}
      >
        {this.props.disableClustering ? (
          <React.Fragment>{this.getMarkers()}</React.Fragment>
        ) : (
          <MarkerClusterer
            averageCenter={true}
            calculator={this.props.clusterCalculator}
            styles={this.props.clusterStyles}
            maxZoom={maxZoom}
            minimumClusterSize={minimumClusterSize}
            onClick={onClusterClick ?? this.onClusterClick}
            zoomOnClick={false}
            onClusteringEnd={this.onClusteringEnd}
          >
            {(clusterer) => this.getMarkers(clusterer)}
          </MarkerClusterer>
        )}
        {this.state.isInfoOpen && this.renderInfo()}
        <Tooltip
          title="Best fit"
          placement="bottom-end"
          TransitionComponent={Zoom}
        >
          <ButtonBase
            className={classes.bestFitButton}
            onClick={() => this.fitBounds()}
          >
            <ZoomOutMapOutlined />
          </ButtonBase>
        </Tooltip>
      </GoogleMap>
    )
  }

  private readonly onClusteringEnd = (clusterer: Clusterer) => {
    const { zoomedClusterTitle } = this.state

    if (!zoomedClusterTitle) {
      return
    }

    setTimeout(() => {
      const map = clusterer.activeMap as google.maps.Map
      const clusterTitles = clusterer.clusters.map(
        (c) => c.clusterIcon.sums?.title
      )
      const similarCluster = clusterTitles.find(
        (title) => title === zoomedClusterTitle
      )

      if (similarCluster) {
        let zoomMap
        if (!isNaN(map.getZoom())) {
          zoomMap = map.getZoom()
        } else {
          zoomMap = 0
        }

        const zoom = parseInt(zoomMap) + 1

        map.setZoom(zoom)
      } else {
        this.setState({
          zoomedClusterTitle: undefined,
        })
      }
    }, 250)
  }

  protected getPlaces = () => this.memoizedPlaces(this.props.places)

  private readonly getMarkers = (clusterer?: Clusterer) => {
    const places = this.getPlaces()
    return places.map((place: Place) => (
      <Marker
        key={place.id}
        position={place.pos}
        clusterer={clusterer}
        title={place.title}
        onClick={(_) => this.onMarkerClick(place)}
        clickable={true}
        onLoad={(marker) => this.onMarkerLoaded(marker, place, clusterer)}
        icon={this.props.getMarkerIcon && this.props.getMarkerIcon(place)}
      />
    ))
  }

  private getPlacePos(place: Place) {
    return [place.pos]
  }

  private fitBounds() {
    if (!this.state.map) {
      return
    }

    const getPlacePos = this.props.getPlacePositions ?? this.getPlacePos
    const bounds = this.getPlaces()
      .flatMap(getPlacePos)
      .reduce((bounds, pos) => {
        bounds.extend(pos)
        return bounds
      }, new google.maps.LatLngBounds())

    this.state.map.fitBounds(bounds)
  }

  private readonly onMapLoaded = (map: google.maps.Map) => {
    this.setState({ map }, () => this.fitBounds())
  }

  private zoomToPlaceMarker(placeId: string) {
    const { map } = this.state

    if (!map) {
      return
    }

    const marker = this.markers.get(placeId)
    if (!marker) {
      return
    }

    const bounds = new google.maps.LatLngBounds(marker.place.pos)

    map.fitBounds(bounds)
  }

  private panToPlaceMarker(placeId: string) {
    const { map } = this.state

    if (!map) {
      return
    }

    const marker = this.markers.get(placeId)
    if (!marker) {
      return
    }

    map.panTo(marker.place.pos)
  }

  private readonly onMarkerLoaded = (
    marker: google.maps.Marker,
    place: Place,
    clusterer?: Clusterer
  ) => {
    ;(marker as any).payload = place
    this.markers.set(place.id, {
      marker,
      place,
      clusterer,
    })
  }

  private readonly onMarkerClick = (place: Place) => {
    const selectedPlaceId = place.id
    const isOpen = !this.state.isInfoOpen

    this.setState({ selectedPlaceId, isInfoOpen: isOpen }, () => {
      if (this.props.onMarkerClick) {
        this.props.onMarkerClick(isOpen, selectedPlaceId)
      }
    })
  }

  private renderInfo() {
    const { selectedPlaceId } = this.state

    if (!selectedPlaceId || !this.props.infoWindow) {
      return null
    }

    const entry = this.markers.get(selectedPlaceId)
    if (!entry) {
      return null
    }

    return (
      <InfoWindow
        anchor={entry.marker}
        onCloseClick={() => this.setState({ isInfoOpen: false })}
      >
        {this.props.infoWindow(entry.marker, entry.place)}
      </InfoWindow>
    )
  }

  private readonly onMapTypeIdChanged = () => {
    const { map } = this.state
    const { userSettings } = this.props

    if (map && userSettings.mapSettings.type !== map.getMapTypeId()) {
      this.props.setUserSettings({
        ...userSettings,
        mapSettings: { type: map.getMapTypeId() },
      })
    }
  }

  private readonly onClusterClick = (cluster: Cluster) => {
    const bounds = cluster.getBounds()
    const map = cluster.getMap() as google.maps.Map

    this.setState({
      zoomedClusterTitle: cluster.clusterIcon.sums!.title,
    })

    map.fitBounds(bounds)
  }
}

function MapFunc(props: Omit<AllProps, 'mapLoadingError'>) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: props.mapApiKey!,
  })

  return isLoaded ? (
    <GMap {...props} mapLoadingError={loadError} />
  ) : (
    <LinearProgress />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(actionCreators, dispatch)

const mapStateToProps = ({
  userSettings,
  appConfig,
}: AppState): PropsFromState => ({
  userSettings,
  mapApiKey: appConfig.mapApiKey,
})

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MapFunc)
)
