import React, { useState, useEffect } from 'react'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import WarningAmberIcon from '@material-ui/icons/Warning'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import MomentUtils from '@date-io/moment'
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import { WellApiModel, TimerSetpointDto } from '../../../api/apiservice'
import moment from 'moment'

interface Props {
  isValidRole: boolean
  siteId: string
  wells: (WellApiModel | undefined)[] | undefined
  WellSelectedForTimerSetPoint: any
  SaveTimerSetPoints: any
  timerSetPoint: TimerSetpointDto | undefined
}

type AllProps = Props

export function TimerSetpoints(props: AllProps) {
  const [valueWellFilter, setValueWellFilter] = useState<string>('')
  const [turnOnTimeFromPicker, setTurnOnTimeChange] =
    useState<MaterialUiPickersDate | null>(null)
  const [turnOffTimeFromPicker, setTurnOffTimeChange] =
    useState<MaterialUiPickersDate | null>(null)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (
      !props.timerSetPoint?.timerTurnOnWell ||
      !props.timerSetPoint?.timerTurnOffWell
    ) {
      return
    }
    setTurnOnTimeChange(moment(props.timerSetPoint?.timerTurnOnWell))
    setTurnOffTimeChange(moment(props.timerSetPoint?.timerTurnOffWell))
  }, [props.timerSetPoint])

  useEffect(() => {
    setDafaultForSetPoints()
  }, [props.siteId])

  const handleChangeWellFilter = (event) => {
    setValueWellFilter(event.target.value)
    setDafaultForSetPoints()
    props.WellSelectedForTimerSetPoint(event.target.value)
  }

  const setDafaultForSetPoints = () => {
    setTurnOnTimeChange(null)
    setTurnOffTimeChange(null)
  }

  const handleTurnOnWellTimeChange = (
    turnOnWellTime: MaterialUiPickersDate | null
  ) => {
    validateSetPointsTime(turnOnWellTime, turnOffTimeFromPicker)
    setTurnOnTimeChange(turnOnWellTime)
  }

  const handleTurnOffWellTimeChange = (
    turnOffWellTime: MaterialUiPickersDate | null
  ) => {
    validateSetPointsTime(turnOnTimeFromPicker, turnOffWellTime)
    setTurnOffTimeChange(turnOffWellTime)
  }

  const validateSetPointsTime = (
    turnOnWellTime: MaterialUiPickersDate | null,
    turnOffWellTime: MaterialUiPickersDate | null
  ) => {
    if (
      turnOffWellTime &&
      turnOnWellTime &&
      turnOffWellTime >= turnOnWellTime
    ) {
      setError(true)
    } else if (error !== false) {
      setError(false)
    }
  }

  const handleSave = () => {
    if (turnOnTimeFromPicker && turnOffTimeFromPicker) {
      props.SaveTimerSetPoints(
        TimerSetpointDto.fromJS({
          timerTurnOnWell: turnOnTimeFromPicker,
          timerTurnOffWell: turnOffTimeFromPicker,
        })
      )
    }
  }

  const inputSection = {
    height: '100%',
    paddingLeft: 20,
  }

  const helperText = error ? 'On value should be greater than Off value' : ''

  return props.siteId ? (
    <Grid container>
      <Grid item xs={12}>
        <FormControl
          variant="standard"
          style={{ marginBottom: 30, minWidth: 150 }}
        >
          <InputLabel>Select Well</InputLabel>
          <Select
            style={{
              borderRadius: 2,
              padding: 2,
              minWidth: 300,
            }}
            value={valueWellFilter}
            onChange={handleChangeWellFilter}
          >
            {props.wells?.map((well) => {
              return (
                <MenuItem key={well?.id} value={well?.id}>
                  {well?.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} style={{ marginTop: 40 }}>
        <Typography color="primary" variant="h6">
          Timer Ranges
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <div style={inputSection}>
          <Typography variant="h6">Turn OFF Well</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              readOnly={!props.isValidRole}
              value={turnOffTimeFromPicker}
              onChange={(date) => date && handleTurnOffWellTimeChange(date)}
              error={error}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div style={inputSection}>
          <Typography variant="h6">Turn ON Well</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              readOnly={!props.isValidRole}
              value={turnOnTimeFromPicker}
              onChange={(date) => date && handleTurnOnWellTimeChange(date)}
              error={error}
              helperText={helperText}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <WarningAmberIcon />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Setpoints will be ignored during selected time ranges!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {props.isValidRole && (
        <Grid item xs={12} style={{ marginTop: 35 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave()}
                disabled={!turnOnTimeFromPicker || !turnOffTimeFromPicker}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography align="center">
      Please select a site to display the timer set points
    </Typography>
  )
}

export default TimerSetpoints
