import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Avatar from '@material-ui/core/Avatar'
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  MenuList,
  Paper,
  Popover,
  ListItemIcon,
  ListItemText,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  Divider,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { ExitToApp as SignOutIcon } from '@material-ui/icons'
import { adminRoute } from '../../App'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import { AppState } from '../../store/AppState'
import { actionCreators } from '../../store/actions/multitenant'
import { MultitenantUserState } from '../../store/reducers/multitenant'
import { useMsal } from '@azure/msal-react'
import { isAdmin } from '../../store/oidc/userManager'
import { useHistory } from 'react-router'

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginLeft: theme.spacing(2),
    },
  })
type AllProps = MultitenantUserState &
  React.PropsWithChildren<any> &
  WithStyles<typeof styles>

function UserMenu(props: AllProps) {
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState<boolean | undefined>()
  const [anchorRef] = React.useState(React.createRef<HTMLDivElement>())
  const msalContext = useMsal()
  const onClick = () => setIsOpen(true)
  const onClose = () => setIsOpen(undefined)
  const onSignOutClick = (e) => {
    e.preventDefault()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () =>
      await msalContext.instance.logoutRedirect({
        postLogoutRedirectUri: `${window.location.origin}`,
      }))()
    localStorage.removeItem('selectedTenantId')
  }
  const classes = props.classes
  const userName = props.fullName || '?'

  return (
    <div ref={anchorRef} onClick={onClick}>
      <Tooltip title={userName}>
        <Avatar className={classes.avatar} alt={userName} />
      </Tooltip>
      {anchorRef.current && (
        <Popover
          anchorEl={anchorRef.current}
          open={!!isOpen}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ClickAwayListener onClickAway={onClose}>
            <Paper>
              <MenuList>
                {props.tenants?.map((tenant) => (
                  <MenuItem
                    selected={tenant.selected}
                    key={tenant.name}
                    onClick={() => {
                      props.setActiveTenant(tenant.id)

                      if (history.location.pathname.search('/site/') !== -1) {
                        history.push('/')
                      }
                    }}
                  >
                    <ListItemText>{`${tenant.name}`}</ListItemText>
                    {isAdmin(tenant?.roles) && (
                      <ListItemSecondaryAction
                        style={{
                          right: '5px',
                        }}
                      >
                        <Tooltip title="Admin manager">
                          <IconButton href={adminRoute}>
                            <VerticalSplitIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem onClick={onSignOutClick}>
                  <ListItemText>Sign out</ListItemText>
                  <ListItemIcon
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <SignOutIcon />
                  </ListItemIcon>
                </MenuItem>
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popover>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  ...state.multitenantUser,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserMenu))
