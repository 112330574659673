import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route, Switch, withRouter } from 'react-router-dom'

import { idRoute, deviceRoute, telemetryTypeRoute } from 'App'
import SiteDetails from 'components/SiteDetails'
import DeviceDetails from 'components/details/device/DeviceDetails'

const router = (props: RouteComponentProps) => (
  <Switch>
    <Route
      path={props.match.url + idRoute + deviceRoute + telemetryTypeRoute}
      component={DeviceDetails}
      exact={true}
    />
    <Route path={props.match.url + idRoute} component={SiteDetails} />
  </Switch>
)

export default withRouter(router)
