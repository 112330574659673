import React, { useState, useEffect, useRef, PropsWithChildren } from 'react'
import { useInfiniteQuery } from 'react-query'
import MaterialTable, {
  Column,
  MTableBody,
  MTablePagination,
} from 'material-table'
import { icons, OneCellRow } from '../utils/materialTable'
import { Typography } from '@material-ui/core'

import {
  SwaggerException,
  SwaggerResponse,
  PagedResultOfPumpLogsWebResponse,
  PumpLogsWebResponse,
} from '../../api/apiservice'

import { getPumpClient } from '../../api/client'
import { nameOf } from '../utils'
import Snack from '../misc/Snack'
import moment from 'moment'

const nameof = (property: keyof PumpLogsWebResponse) =>
  nameOf<PumpLogsWebResponse>(property)

const pageSize = 5

interface Props {
  readonly accessToken: string
  readonly userId: string
  readonly tenantId: number
  readonly pumpDeviceId: string
  readonly hasFrequency: boolean
}

const schemaWithoutLookups = (
  arrayData: Column<PumpLogsWebResponse>[]
): Column<PumpLogsWebResponse>[] => {
  return arrayData?.map((item): any => {
    return item
  })
}

export function PumpLogs(props: Props) {
  const queryKey = ['lastLogs', props.pumpDeviceId]
  const api = getPumpClient(props.accessToken, props.tenantId.toString())
  const [error, setError] = useState<string | undefined>()
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [initialColumns, setInitialColumns] = useState(true)
  const getLastLogs = useInfiniteQuery<
    SwaggerResponse<PagedResultOfPumpLogsWebResponse>,
    SwaggerException
  >(
    queryKey,
    ({ pageParam = 0 }) =>
      api.getLastLogs(props.pumpDeviceId, pageParam, pageSize),
    {
      enabled: false,
      keepPreviousData: true,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retryOnMount: false,
      onSuccess: ({ pages }) => {
        setTotalCount(pages[pages.length - 1].result.count)
        setPageCount(
          pages.length ? pages[pages.length - 1].result.pageCount : 0
        )
      },
      onError: (err) => {
        console.log(err)
        setError(`Failed to load pump logs: ${err.result}`)
      },

      getNextPageParam: (_, allPages) =>
        allPages.length < pageCount ? allPages.length : undefined,
    }
  )

  const [showedPumpLogs, setShowedPumpLogs] = useState<PumpLogsWebResponse[]>(
    []
  )

  const table = useRef<MaterialTable<PumpLogsWebResponse>>()
  const pumpLogColumns: Column<PumpLogsWebResponse>[] = [
    {
      title: 'Log Type',
      field: nameof('logType'),
      editable: 'never',
    },
    {
      title: 'Source',
      field: nameof('source'),
      editable: 'never',
    },
    {
      title: 'Email',
      field: nameof('email'),
      editable: 'never',
    },
    {
      title: 'Old Value',
      field: nameof('oldStatus'),
      editable: 'never',
    },
    {
      title: 'New Value',
      field: nameof('newStatus'),
      editable: 'never',
    },
    {
      title: 'Time',
      field: nameof('creationTimeUtc'),
      editable: 'never',
      render: (rowData) => moment(rowData.creationTimeUtc).format('llll'),
    },
  ]

  const loading =
    getLastLogs.isLoading ||
    getLastLogs.isFetching ||
    getLastLogs.isFetchingNextPage ||
    getLastLogs.isFetchingPreviousPage
  const setPumpLogs = (result) => {
    setShowedPumpLogs(
      result.data?.pages[result.data?.pages.length - 1].result.result
    )
  }

  useEffect(() => {
    getLastLogs.refetch().then(setPumpLogs)
  }, [props.pumpDeviceId, props.tenantId])

  const OneCellPumpLogRow = (
    props: PropsWithChildren<{ readonly style?: React.CSSProperties }>
  ) => (
    <OneCellRow colSpan={pumpLogColumns.length + 1} style={props.style}>
      {props.children}
    </OneCellRow>
  )

  return (
    <div style={{ overflowY: 'hidden' }}>
      <MaterialTable<PumpLogsWebResponse>
        tableRef={table}
        isLoading={loading}
        icons={icons}
        options={{
          paging: true,
          pageSize: pageSize,
          pageSizeOptions: [],
          showFirstLastPageButtons: true,
          search: false,
          overflowY: 'hidden',
          showTitle: false,
          toolbar: false,
        }}
        columns={
          initialColumns ? schemaWithoutLookups(pumpLogColumns) : pumpLogColumns
        }
        data={showedPumpLogs}
        style={{
          overflow: 'auto',
          height: '100%',
        }}
        components={{
          Pagination: (paginationProps) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <div style={{ width: 'fit-content' }}>
                  <MTablePagination
                    {...paginationProps}
                    count={totalCount}
                    onChangePage={(_, page) => {
                      getLastLogs
                        .fetchNextPage({ pageParam: page })
                        .then(setPumpLogs)
                      setCurrentPage(page)
                    }}
                    page={currentPage}
                  />
                </div>
              </div>
            )
          },
          Body: (bodyProps) =>
            bodyProps.renderData?.length || bodyProps?.showAddRow ? (
              <MTableBody {...bodyProps} />
            ) : (
              <tbody>
                <OneCellPumpLogRow>
                  <Typography>No data</Typography>
                </OneCellPumpLogRow>
              </tbody>
            ),
        }}
      />
      {error && (
        <Snack
          message={error}
          severity="error"
          onClose={() => setError(undefined)}
        />
      )}
    </div>
  )
}
