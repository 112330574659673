import { Reducer, AnyAction, Dispatch } from 'redux'

import { AppState } from '../AppState'
import { HANDLE_UTIL_ALERT, HANDLE_GENERIC_DIALOG } from '../types/types'

export interface CommonState {
  readonly handleAlert?: any
  readonly handleDialog?: any
}

export const initialState: CommonState = {
  handleAlert: {
    message: '',
    status: false,
    severity: 'success',
  },
  handleDialog: false,
}

export const actionCreators = {
  setHandleAlert: (handleAlert) => (dispatch: Dispatch, _: () => AppState) => {
    dispatch({
      type: HANDLE_UTIL_ALERT,
      payload: { handleAlert },
    })
  },

  setHandleDialog:
    (handleDialog) => (dispatch: Dispatch, _: () => AppState) => {
      dispatch({
        type: HANDLE_GENERIC_DIALOG,
        payload: { handleDialog },
      })
    },
}

export const reducer: Reducer<CommonState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case HANDLE_UTIL_ALERT:
      return Object.assign({}, state, {
        handleAlert: action.payload.handleAlert,
      })

    case HANDLE_GENERIC_DIALOG:
      return Object.assign({}, state, {
        handleDialog: action.payload.handleDialog,
      })

    default:
      return state
  }
}
