import { TelemetryType } from '../../api/apiservice'
import BaseSensor from './BaseSensor'

export default class KnockoutPressure extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.KnockoutPressure, 'Knockout Pressure')
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
