import * as React from 'react'
import moment from 'moment'
import theme from 'theme'

import { GatewayMetricsPayload } from '../../api/client'

import { Grid, Typography, Paper, WithStyles } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import { celsiusToFahrenheit, millibarsToPsi } from 'components/utils/converter'
import CssStyle from 'components/misc/CssStyle'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Edit } from '@material-ui/icons'

const styles = (theme: Theme) =>
  createStyles({
    value: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      width: 'auto',
    },
  })

interface Props extends CssStyle {
  readonly lastSeenTime?: Date
  readonly startDate?: string
  readonly metrics?: any
  handleStartDateChange(startDatetoUpdate: string): void
  readonly isSystemAdmin: boolean
}

type AllProps = Props & WithStyles<typeof styles>

class GatewayMetrics extends React.Component<AllProps> {
  public render() {
    return (
      <div
        style={{
          // ...this.props.style,
          display: 'grid',
          overflow: 'hidden',
        }}
      >
        {this.renderContent()}
      </div>
    )
  }

  private renderContent() {
    return (
      <Paper
        elevation={6}
        square={true}
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        <Typography variant="h6">Gateway Metrics</Typography>
        {this.renderMetrics()}
      </Paper>
    )
  }

  private readonly renderMetrics = () => {
    return (
      <Grid
        container={true}
        direction="column"
        justify="flex-start"
        alignItems="baseline"
        style={{ flexFlow: 'wrap' }}
      >
        {this.renderLastSeen()}
        {this.renderGatewayId()}
        {this.renderGatewayMetrics()}
        {/* {this.renderStartDate()} */}
      </Grid>
    )
  }

  private renderGatewayMetrics() {
    const { metrics } = this.props
    const value: GatewayMetricsPayload = metrics.Payload

    if (value !== undefined) {
      return Object.keys(value)
        .filter((i) => value[i] !== null)
        .map((i) =>
          this.renderMetric(i as keyof GatewayMetricsPayload, value[i])
        )
    }

    return null
  }

  private renderMetric(key: keyof GatewayMetricsPayload, value: any) {
    const metric = this.getMetric(key, value)

    if (metric) {
      const [label, formattedValue] = metric

      if (label.trim().toLocaleLowerCase() === 'primary battery') {
        const formattedVal = formattedValue.replace('mV', '%')

        return this.renderValue(label, formattedVal ?? 'N/A', key)
      } else {
        return this.renderValue(label, formattedValue ?? 'N/A', key)
      }
    }

    return null
  }

  private getMetric(
    key: keyof GatewayMetricsPayload,
    value: any
  ): [string, string] | undefined {
    switch (key) {
      case 'gpsLatitude':
        return ['Latitude', value.toFixed(4)]
      case 'gpsLongitude':
        return ['Longitude', value.toFixed(4)]
      case 'pressure':
        return [
          'Pressure',
          `${value} mBar (${millibarsToPsi(value).toFixed(2)} psi)`,
        ]
      case 'primaryBatteryLevel':
        return ['Primary battery', `${value} mV`]
      case 'capacitorLevel':
        return ['Capacitor battery', `${value} mV`]
      case 'commType':
        return ['Communication', value]
      case 'signalStrength':
        const payload: GatewayMetricsPayload = this.props.metrics?.Payload
        if (payload.commType === 'Satellite') {
          return undefined
        }
        return ['Signal strength', value]
      case 'temperature':
        return [
          'Temperature',
          `${celsiusToFahrenheit(value).toFixed(1)} °F / ${value.toFixed(
            1
          )} °C`,
        ]
      default:
        return undefined
    }
  }

  private renderLastSeen() {
    const { lastSeenTime } = this.props
    const lastSeen = lastSeenTime
      ? moment(lastSeenTime).format('llll')
      : 'never'

    return this.renderValue('Last seen', lastSeen)
  }

  private renderGatewayId() {
    const { metrics } = this.props

    return this.renderValue('Gateway ID', metrics?.AssetId)
  }

  private renderStartDate() {
    const { startDate } = this.props
    const _startDate = startDate ? new Date(startDate) : null
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          direction="row"
          justify="flex-start"
          alignItems="baseline"
          container={true}
          className={this.props.classes.value}
          // lg={6}
          // md={12}
          style={{ flexFlow: 'wrap' }}
        >
          <Typography variant="body1" gutterBottom={true}>
            {'Start date:'}
          </Typography>
          <KeyboardDatePicker
            style={{
              width: '60%',
              marginLeft: 5,
              marginTop: 0,
              marginBottom: 0,
            }}
            margin="normal"
            id="date-picker-dialog"
            format="MM/DD/YY"
            value={_startDate}
            onChange={(e) =>
              this.props.handleStartDateChange(e?.toISOString()!)
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
              disabled: !this.props.isSystemAdmin,
            }}
            InputProps={{ readOnly: true, disableUnderline: true }}
            keyboardIcon={<Edit />}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }

  private renderValue(label?: string, value?: string, key?: React.Key) {
    return (
      <Grid
        item={true}
        container={true}
        alignItems="baseline"
        key={key}
        className={this.props.classes.value}
        lg={6}
        md={12}
      >
        <Typography variant="body1" gutterBottom={true}>
          {label}:
        </Typography>
        <Typography variant="body2" gutterBottom={true}>
          <b>&nbsp;{value}</b>
        </Typography>
      </Grid>
    )
  }
}

export default withStyles(styles)(GatewayMetrics)
