import React from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  generatePath,
  RouteComponentProps,
  withRouter,
  Route,
  Router,
} from 'react-router-dom'
import clsx from 'clsx'
import {
  sitesRoute,
  homeRoute,
  IntermittentPanelRoute,
  siteRoute,
  configurationRoute,
  alertsRoute,
  idRoute,
  userSettingsRoute,
  pumpAlertsRoute,
} from '../App'

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Divider,
  List,
  WithStyles,
  createStyles,
  Theme,
  withStyles,
  Tooltip,
  CircularProgress,
  Badge,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  AssignmentTwoTone as AllSites,
  AssignmentIndTwoTone as MySitesIcon,
  SettingsApplicationsTwoTone as ConfigIcon,
  NotificationImportantTwoTone as MyAlertsIcon,
  WarningTwoTone as MyPumpAlertsIcon,
  Notifications,
  NotificationsNone,
  AccountCircle as UserSettingsIcon,
  Info,
} from '@material-ui/icons'
import IntermittentIcon from './intermittent/intermittentIcon'

import { AppState } from '../store/AppState'
import { Site } from '../store/Site'
import { withUiContext, UiContextData } from './contexts/UiContext'

import ListItemLink from './misc/ListItemLink'
import UserMenu from './appbar/UserMenu'
import { AlertsState } from '../store/Alert'
import MySitesSideMenu from './my-sites/MySitesSideMenu'
import { actionCreators } from 'store/UserSettings'
import QuickSettings from './misc/QuickSettings'
import { Media } from 'react-breakpoints'
import theme from 'theme'
import { SiteSearch } from './sites/SiteSearch'
import * as LDClient from 'launchdarkly-js-client-sdk'
import { MultitenantUserState } from 'store/reducers/multitenant'
import { MsalContext } from '@azure/msal-react'

import SiteInfoDialog from './site/SiteInfoDialog'
import Snack from './misc/Snack'

const FlexTypography = withStyles({
  root: {
    color: theme.palette.primary.light,
    textOverflow: 'ellipsis',
  },
})(Typography)

export function renderTitlePart(title: React.ReactNode, variant? = false) {
  if (variant) {
    return { title }
  } else {
    return (
      <FlexTypography variant="h6" noWrap={true}>
        {title}
      </FlexTypography>
    )
  }
}

const drawerWidth = 240

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      minHeight: '64px !IMPORTANT',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.primary.main,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.primary.light,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: 'hidden',
      height: '100vh',
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    },
    search: {
      position: 'relative',
      color: theme.palette.secondary.main,
    },
    alertsProgressRoot: {
      color: theme.palette.background.default,
    },
    menuContainer: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      overflow: 'hidden',
      height: '100%',
    },
    sideMenuContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '&:empty': {
        display: 'none',
      },
      overflow: 'auto',
    },
  })

interface State {
  readonly isOpen: boolean
  readonly showSiteInfo?: boolean
  readonly assetInfoUpdateMessage?: Color
}

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly tenantName: string
  readonly buildVersion?: string
  readonly user?: MultitenantUserState
  readonly alerts: AlertsState
  readonly isDrawerClosed?: boolean
  readonly clientName?: string
  readonly launchDarklyClientsideId?: string
  readonly selectedSite?: Site
}

type PropsFromDispatch = typeof actionCreators

type AllProps = PropsFromState &
  PropsFromDispatch &
  WithStyles<typeof styles> &
  RouteComponentProps &
  UiContextData

class Navigation extends React.Component<AllProps, State> {
  flags: any
  showSuperIntendent: true | undefined
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      showSiteInfo: false,
      assetInfoUpdateMessage: undefined,
    }
  }

  static contextType = MsalContext

  public render() {
    return (
      <header>
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            const smallScreen = breakpoints[currentBreakpoint] <= breakpoints.sm

            return (
              <React.Fragment>
                {this.renderAppBar(smallScreen)}
                {this.renderDrawer(smallScreen)}
                {this.state.assetInfoUpdateMessage && (
                  <Snack
                    autoHideDuration={2000}
                    message={
                      this.state.assetInfoUpdateMessage === 'success'
                        ? 'Site Updated. Page will be reloaded'
                        : 'Something went wrong updating the site'
                    }
                    severity={this.state.assetInfoUpdateMessage}
                    onClose={() => {
                      this.setState({
                        assetInfoUpdateMessage: undefined,
                      })
                      window.location.href = '/sites'
                    }}
                  />
                )}
              </React.Fragment>
            )
          }}
        </Media>
        {this.state.showSiteInfo && this.renderSiteInfoDialog()}
      </header>
    )
  }

  private renderAppBar(smallScreen: boolean) {
    const { classes, isDrawerClosed } = this.props
    const isOpen = smallScreen ? this.state.isOpen : !isDrawerClosed

    return (
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, isOpen && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {this.renderDrawerButton(smallScreen)}
          {this.renderTitle()}
          {this.renderSearch()}
          {this.renderAlerts()}
          {this.renderUserMenu()}
        </Toolbar>
      </AppBar>
    )
  }

  private renderDrawerButton(smallScreen: boolean) {
    const { classes, buildVersion, isDrawerClosed } = this.props
    const buildVer = buildVersion || 'local'
    const isOpen = smallScreen ? this.state.isOpen : !isDrawerClosed

    return (
      <IconButton
        edge="start"
        color="secondary"
        onClick={() => this.setOpen(true, smallScreen)}
        className={clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
      >
        <Tooltip title={buildVer}>
          <MenuIcon />
        </Tooltip>
      </IconButton>
    )
  }

  private renderTitle() {
    const { title, selectedSite } = this.props

    if (selectedSite !== undefined && title !== 'All Sites') {
      return (
        <div style={{ flexGrow: 1, minWidth: 0 }}>
          <FlexTypography variant="h6" noWrap={true}>
            {title}

            <Tooltip title="Show site information">
              <IconButton
                onClick={() => this.setState({ showSiteInfo: true })}
                size="small"
              >
                <Info />
              </IconButton>
            </Tooltip>
          </FlexTypography>
        </div>
      )
    } else {
      return (
        <div style={{ flexGrow: 1, minWidth: 0 }}>
          <FlexTypography variant="h6" noWrap={true}>
            {title}
          </FlexTypography>
        </div>
      )
    }
  }

  public componentDidMount() {
    this.flags = {}
    this.featureFlag()
  }

  public componentDidUpdate(prevProps: PropsFromState) {
    this.featureFlag()
  }

  private featureFlag() {
    const user: LDClient.LDUser = {
      key: this.props.tenantId.toString(),
    }

    const LaunchDarklyClientsideId = this.props.launchDarklyClientsideId!
    const options: LDClient.LDOptions = { bootstrap: 'localStorage' }
    const client = LDClient.initialize(LaunchDarklyClientsideId, user, options)
    client
      .waitForInitialization()
      .then(() => {
        this.flags = client.allFlags()
        if ('ShowSuperIntendent' in this.flags) {
          this.showSuperIntendent = this.flags.ShowSuperIntendent
        } else {
          this.showSuperIntendent = true
        }
      })
      .catch((err) => {
        console.error(
          '🚀 ~ file: Navigation.tsx ~ line 288 ~ client.waitForInitialization ~ err',
          err
        )
      })
  }

  private renderDrawer(smallScreen: boolean) {
    const { classes, buildVersion, isDrawerClosed } = this.props
    const buildVer = buildVersion || 'local'
    const isOpen = smallScreen ? this.state.isOpen : !isDrawerClosed

    return (
      <Drawer
        variant={smallScreen ? 'temporary' : 'permanent'}
        classes={{
          paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
        }}
        open={isOpen}
        onClick={() => smallScreen && this.hideDrawer()}
        onKeyDown={() => smallScreen && this.hideDrawer()}
      >
        <div>
          <div className={classes.toolbarIcon}>
            <Tooltip title={buildVer}>
              <IconButton
                onClick={() => this.setOpen(false, smallScreen)}
                color="primary"
              >
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <div className={classes.menuContainer}>
          <List>
            {this.listItemLink(homeRoute, <MySitesIcon />, 'My Sites')}
            {this.flags?.intermittentWebControl ? (
              this.listItemLink(
                IntermittentPanelRoute,
                <IntermittentIcon />,
                'Intermittent'
              )
            ) : (
              <></>
            )}
            {this.listItemLink(alertsRoute, <MyAlertsIcon />, 'My Alerts')}
            {this.flags?.ShowPump ? (
              this.listItemLink(
                pumpAlertsRoute,
                <MyPumpAlertsIcon />,
                'My Pump Alerts'
              )
            ) : (
              <></>
            )}
            {this.listItemLink(sitesRoute, <AllSites />, 'All Sites')}
            {this.listItemLink(
              configurationRoute,
              <ConfigIcon />,
              'Global Thresholds'
            )}
            {this.listItemLink(
              userSettingsRoute,
              <UserSettingsIcon />,
              'User Settings'
            )}
          </List>
          {isOpen && this.renderDrawerMisc()}
        </div>
      </Drawer>
    )
  }

  private readonly hideDrawer = () => {
    this.setState({ isOpen: false })
  }

  private renderDrawerMisc() {
    return (
      <div className={this.props.classes.sideMenuContainer}>
        <Router history={this.props.history}>
          <Route
            path={[homeRoute, sitesRoute, siteRoute]}
            component={QuickSettings}
            exact={true}
          />
          <Route path={homeRoute} component={MySitesSideMenu} exact={true} />
        </Router>
      </div>
    )
  }

  private renderSearch() {
    const {
      classes: { search },
      accessToken,
      tenantId,
    } = this.props

    return (
      <SiteSearch
        className={search}
        onOptionSelected={this.onOptionSelected}
        accessToken={accessToken!}
        tenantId={tenantId.toString()}
      />
    )
  }

  private renderAlerts() {
    const {
      alerts: { alertsCount, loading },
      classes,
    } = this.props
    const Icon = alertsCount ? Notifications : NotificationsNone
    const maxAlerts = 999
    const alerts = (
      <IconButton
        onClick={() => this.props.history.push(generatePath(alertsRoute))}
      >
        <Badge
          badgeContent={
            loading ? (
              <CircularProgress
                size="1em"
                classes={{
                  root: classes.alertsProgressRoot,
                }}
              />
            ) : (
              alertsCount
            )
          }
          showZero={false}
          max={maxAlerts}
          color="error"
        >
          <Icon fontSize="large" htmlColor={theme.palette.primary.light} />
        </Badge>
      </IconButton>
    )

    return alertsCount > maxAlerts ? (
      <Tooltip title={alertsCount}>{alerts}</Tooltip>
    ) : (
      alerts
    )
  }

  private renderUserMenu() {
    const { user } = this.props

    return <UserMenu>{user}</UserMenu>
  }

  private readonly listItemLink = (route, icon, label) => (
    <ListItemLink
      button={true}
      to={route}
      icon={icon}
      primary={label}
      selected={this.props.location.pathname === route}
    />
  )

  private readonly setOpen = (isOpen: boolean, smallScreen: boolean) =>
    this.setState({ isOpen }, () => {
      if (!smallScreen) {
        this.props.setUserSettings({
          uiSettings: { isDrawerClosed: !isOpen },
        })
      }
    })

  private readonly renderSiteInfoDialog = () => {
    return (
      <SiteInfoDialog
        selectedSite={this.props.selectedSite}
        closeSiteInfoDialog={this.closeSiteInfoDialog}
        showSiteUpdateNotification={this.showSiteUpdateNotification}
        showSuperIntendent={this.showSuperIntendent}
      />
    )
  }

  private readonly showSiteUpdateNotification = (updateResult?: Color) =>
    this.setState({
      assetInfoUpdateMessage: updateResult,
    })

  private readonly closeSiteInfoDialog = () =>
    this.setState({
      showSiteInfo: false,
    })

  private readonly onOptionSelected = (site: Site | null) => {
    if (site) {
      this.props.history.push(
        generatePath(siteRoute + idRoute, {
          id: site.id,
        })
      )
    }
  }
}

const mapStateToProps = ({
  appConfig,
  oidc: { user },
  alerts,
  userSettings,
  multitenantUser,
}: AppState): PropsFromState => ({
  alerts,
  user: multitenantUser,
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.selectedTenant?.id || 0,
  tenantName: multitenantUser.selectedTenant?.name || '',
  buildVersion: appConfig.buildVersion,
  isDrawerClosed: userSettings.uiSettings.isDrawerClosed,
  clientName: appConfig.clientName,
  launchDarklyClientsideId: appConfig.launchDarklyClientsideId,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withUiContext(Navigation))
  )
)
