import React from 'react'
import { connect } from 'react-redux'

import { createStyles, Theme, LinearProgress } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/styles'

import { AppState } from '../store/AppState'
import { Site, SitesState, defaultSitesState, mapSite } from '../store/Site'
import SitesMap from './sites/SitesMap'
import MySites from './my-sites/MySites'
import SiteContext, { SiteContextData } from './contexts/SiteContext'
import { withUiContext, UiContextData } from './contexts/UiContext'
import { getSiteClient } from '../api/client'
import { User } from 'oidc-client'
import { Group } from 'store/Group'
import {
  withMySitesContext,
  MySitesContextData,
} from './contexts/MySitesContext'
import { defaultTelemetryState } from '../store/SiteDetails'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    tile: {
      padding: theme.spacing(0.5),
      overflow: 'hidden',
      display: 'grid',
    },
    noStretchTile: {
      display: 'flex',
      padding: theme.spacing(0.5),
    },
  })

interface PropsFromState {
  readonly accessToken?: string
  readonly userId?: string
  readonly tenantId: number
  readonly user?: User
  readonly groups: Group[]
}

type AllProps = PropsFromState &
  WithStyles<typeof styles> &
  UiContextData &
  SiteContextData &
  MySitesContextData

interface State extends SiteContextData {
  readonly sites: SitesState
  readonly statuses?: { siteId: string; status: number }[]
  readonly mySites: Site[]
}

export class Home extends React.Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      sites: defaultSitesState,
      mySites: [],
      telemetry: defaultTelemetryState,
      setSelectedSiteId: this.setSelectedSiteId,
    }
  }

  public componentDidMount() {
    this.props.setPageTitle!('My Sites')

    this.props.setMapSettings!(this.props.showMap!, false)

    if (this.props.accessToken) this.requestSites()
  }

  public componentDidUpdate(prevProps: AllProps) {
    if (
      (prevProps.accessToken !== this.props.accessToken &&
        this.props.tenantId) ||
      (prevProps.tenantId !== this.props.tenantId && this.props.accessToken)
    ) {
      this.requestSites()
    }

    if (
      prevProps.selectedGroupId !== this.props.selectedGroupId ||
      prevProps.tenantId !== this.props.tenantId
    ) {
      this.setMySites()
    }
  }

  private readonly setSiteStatus = ({
    siteId,
    status,
  }: {
    siteId: string
    status: number
  }) => {
    const exist = this.state.statuses?.find(
      (s) => s.siteId === siteId && s.status === status
    )

    if (!exist) {
      const statuses = this.state?.statuses?.length
        ? [
            ...this.state.statuses.filter((s) => s.siteId !== siteId),
            { siteId, status },
          ]
        : [{ siteId, status }]

      if (statuses) {
        this.setState({ ...this.state, statuses: [...statuses] })
      }
    }
  }

  private setMySites() {
    const { selectedGroupId, groups } = this.props
    const {
      sites: { sites },
    } = this.state
    let mySites = sites

    if (selectedGroupId) {
      const siteIdsByGroups = groups.find(
        (e) => e.id === selectedGroupId
      )?.siteIds
      mySites = sites.filter((e) => siteIdsByGroups?.includes(e.id))
    }

    this.setState({ mySites })
  }

  public render() {
    return (
      <div className={this.props.classes.root}>
        {this.renderMySitesAndMap()}
      </div>
    )
  }

  private renderMySitesAndMap() {
    const { classes } = this.props
    const { tile } = classes
    const {
      sites: { loading },
      mySites,
    } = this.state

    if (loading) {
      return <LinearProgress style={{ width: '100%' }} />
    }

    return (
      <SiteContext.Provider value={this.state}>
        {this.props.showMap && (
          <div style={{ height: '25%' }} className={tile}>
            <SitesMap
              mySites={mySites}
              paperProps={{ elevation: 0 }}
              statuses={this.state.statuses || []}
            />
          </div>
        )}
        <div
          style={this.props.showMap ? { height: '75%' } : { height: '100%' }}
          className={tile}
        >
          <MySites
            mySites={mySites}
            siteRoute="site"
            statuses={this.state.statuses}
            setSiteStatus={({
              siteId,
              status,
            }: {
              siteId: string
              status: number
            }) => this.setSiteStatus({ siteId, status })}
          />
        </div>
      </SiteContext.Provider>
    )
  }

  private readonly setSelectedSiteId = (
    toggleHighlight: boolean,
    selectedSiteId?: string
  ) =>
    this.setState({
      selectedSiteId: toggleHighlight
        ? selectedSiteId === this.state.selectedSiteId
          ? undefined
          : selectedSiteId
        : selectedSiteId,
    })

  private readonly requestSites = async () => {
    try {
      const sitesResponse = await getSiteClient(
        this.props.accessToken!,
        this.props.tenantId.toString()
      ).getByUserId(this.props.userId, this.props.tenantId)
      const sites: Site[] = sitesResponse.result.map(mapSite)

      this.setState(
        {
          sites: { sites: sites, loading: false },
        },
        () => this.setMySites()
      )
    } catch (e) {
      this.setState({
        sites: {
          ...this.state.sites,
          error: e.message,
          loading: false,
        },
      })
    }
  }
}

const mapStateToProps = ({
  groups,
  oidc,
  multitenantUser,
}: AppState): PropsFromState => {
  return {
    accessToken: multitenantUser.accessToken,
    tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    userId: multitenantUser.id,
    user: oidc.user,
    groups: groups.groups,
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(withUiContext(withMySitesContext(Home)))
)
