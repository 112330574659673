import * as React from 'react'
import theme from 'theme'
import moment from 'moment'

import { Typography, Grid, Chip, Tooltip } from '@material-ui/core'
import { LinearGauge } from 'react-canvas-gauges'

import GaugeSensor, {
  Highlight,
  defaultGaugeWidth,
  MajorTicksWithHighlights,
} from './GaugeSensor'
import { Reading } from './SensorWithChart'
import { TelemetryType } from '../../api/apiservice'
import { HistoricalDataEntry } from 'api/client'
import {
  feetToBbl,
  feetToFeetAndInches,
  inchToBbl,
  inchToFeet,
  inchToFeetAndInches,
  getReadableProductionCode,
} from '../utils/converter'
import { getStatusLensColor } from 'components/utils'
import { Tank } from 'store/Site'
import { TelemetryStatus, StatusRange } from 'api/alertservice'

export default class TankLevel extends GaugeSensor {
  protected renderFlareStatus(reading: Reading): React.ReactNode {
    throw new Error('Method not implemented.')
  }

  // protected renderPumpControl(reading: Reading): React.ReactNode {
  //   throw new Error('Method not implemented.')
  // }

  constructor(props) {
    super(props, TelemetryType.TankLevel, 'Tank Level')
  }

  protected renderContent = (reading: Reading) => {
    const time = reading.timestamp ? moment(reading.timestamp) : undefined

    return (
      <div
        style={{
          width: defaultGaugeWidth,
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            gridColumn: 1,
            gridRow: '1 / span 2',
            marginTop: -theme.spacing(4),
            marginBottom: -theme.spacing(6),
            marginRight: -theme.spacing(4),
          }}
        >
          {this.renderLinearGauge(reading)}
        </div>
        {this.renderGauge(reading)}
        <div style={{ gridColumn: 2, gridRow: 2 }}>
          {this.renderUpdateTime(time)}
        </div>
      </div>
    )
  }

  protected renderGauge = (reading: Reading) => {
    const { value } = reading
    const {
      site: { tanks },
    } = this.props

    const { tankBalance } = this.state
    const tank = tanks.find((t) => t.id === this.props.asset.id)
    const tankLevelFt = tank ? this.formatTankLevelFeet(value, tank) : undefined
    const tankLevelBbl = tank
      ? this.formatTankLevelBarrels(value, tank)
      : undefined

    let changeDailyBBl =
      tankBalance && tank
        ? inchToBbl(
            tankBalance.balance ?? 0,
            tank.height,
            tank.capacity
          ).toFixed(1)
        : undefined

    const lastDates = tankBalance
      ? moment(tankBalance.previousTelemetryDateTime).format(
          'MM/DD/YYYY HH:mm:ss'
        ) +
        ' to ' +
        moment(tankBalance.latestTelemetryDateTime).format(
          'MM/DD/YYYY HH:mm:ss'
        )
      : 'N/A'

    if (
      (tankBalance?.balance && tankBalance.balance < 0) ||
      tankBalance?.balance === null
    ) {
      changeDailyBBl = '0'
    }

    if (changeDailyBBl) {
      return (
        <div
          style={{
            display: 'grid',
            alignContent: 'center',
            gridTemplateRows: '1fr 1fr',
            gridColumn: 2,
            gridRow: 1,
          }}
        >
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <Typography noWrap={true} variant="h5" align="center">
              {tankLevelFt ?? 'N/A'}
            </Typography>
            <Typography noWrap={true} variant="caption" align="center">
              actual / tank height
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 2 }}
          >
            <Typography noWrap={true} variant="h5" align="center">
              {tankLevelBbl ? `${tankLevelBbl} bbl` : 'N/A'}{' '}
            </Typography>
            <Typography noWrap={true} variant="caption" align="center">
              actual / tank capacity
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 3 }}
            justifyContent={'space-between'}
          >
            <Typography noWrap={true} variant="caption" align="center">
              &nbsp;
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 4 }}
            justifyContent={'space-between'}
          >
            <Typography noWrap={true} variant="caption" align="center">
              &nbsp;
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 5 }}
            justifyContent={'space-between'}
          >
            <Tooltip title={lastDates} placement="top-end">
              <>
                <Typography noWrap={true} align="center">
                  24 Hr Change: {changeDailyBBl} bbls*
                </Typography>
                <Typography
                  noWrap={false}
                  align="center"
                  style={{ fontSize: 10 }}
                >
                  * Volume is calculated from last update and prior 24 hours.
                </Typography>
              </>
            </Tooltip>
          </Grid>
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'grid',
            alignContent: 'center',
            gridTemplateRows: '1fr 1fr',
            gridColumn: 2,
            gridRow: 1,
          }}
        >
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <Typography noWrap={true} variant="h5" align="center">
              {tankLevelFt ?? 'N/A'}
            </Typography>
            <Typography noWrap={true} variant="caption" align="center">
              actual / tank height
            </Typography>
          </Grid>
          <Grid
            container={true}
            direction="column"
            style={{ gridColumn: 1, gridRow: 2 }}
          >
            <Typography noWrap={true} variant="h5" align="center">
              {tankLevelBbl ? `${tankLevelBbl} bbl` : 'N/A'}{' '}
            </Typography>
            <Typography noWrap={true} variant="caption" align="center">
              actual / tank capacity
            </Typography>
          </Grid>
        </div>
      )
    }
  }

  private formatTankLevelFeet(
    gaugedHeightInch: number | undefined,
    tank: Tank
  ) {
    if (!gaugedHeightInch && gaugedHeightInch !== 0) {
      return undefined
    }

    const [tankLevelFt, tankLevelIn] = inchToFeetAndInches(gaugedHeightInch)
    const [heightFt, heightIn] = feetToFeetAndInches(tank.height)

    return `${tankLevelFt.toFixed()}' ${tankLevelIn.toFixed(
      1
    )}" / ${heightFt.toFixed()}' ${heightIn.toFixed(1)}"`
  }

  private formatTankLevelBarrels(
    gaugedHeightInch: number | undefined,
    tank: Tank
  ) {
    if (!gaugedHeightInch && gaugedHeightInch !== 0) {
      return undefined
    }

    const tankLevelBbl = inchToBbl(gaugedHeightInch, tank.height, tank.capacity)

    return `${tankLevelBbl.toFixed(1)} / ${tank.capacity.toFixed()}`
  }

  protected processData(data: HistoricalDataEntry): HistoricalDataEntry {
    const newData: HistoricalDataEntry = {
      ...data,
      value: data.value && inchToFeet(data.value),
    }

    return newData
  }

  protected formatValue = (value?: any, timeStamp?: Date): React.ReactNode => {
    if (typeof value !== 'number') {
      return 'N/A'
    }

    const [ft, ins] = feetToFeetAndInches(value)
    const {
      site,
      asset: { id: assetId },
    } = this.props

    if (!site) {
      return super.formatValue(value)
    }

    const tank = site.tanks.find((t) => t.id === assetId)
    const volume = tank
      ? feetToBbl(value, tank.height, tank.capacity).toFixed(1)
      : undefined

    return (
      <React.Fragment>
        {this.renderValue('Height', `${ft}' ${ins.toFixed(1)}"`)}
        {this.renderValue('Volume', volume ? `${volume} bbl` : 'N/A')}
      </React.Fragment>
    )
  }

  protected renderLinearGauge = (reading: Reading) => {
    const heightFeet = reading.value && inchToFeet(reading.value)
    const { majorTicks: rawMajorTicks, highlights } = this.getGaugeParams(
      this.memoizesThresholds(this.props.thresholds),
      heightFeet
    )
    let majorTicks = rawMajorTicks.map(Number.parseFloat)
    let minValue = 0
    let maxValue = 10

    if (majorTicks.length) {
      const majorTicksCount = majorTicks.length - 1

      minValue = majorTicks[0]
      maxValue = majorTicks[majorTicksCount]
    } else {
      majorTicks = new Array(maxValue + 1).map((_, i) => i)
    }

    // remove ranges that are not visible from highlights
    const updHighlights: Highlight[] = []
    highlights.forEach(function (elem) {
      const from = Math.max(elem.from, minValue)
      const to = Math.min(elem.to, maxValue)
      const color = elem.color

      if (from !== to) {
        updHighlights.push({ from, to, color })
      }
    })

    return (
      <LinearGauge
        width={100}
        height={theme.spacing(26)}
        value={heightFeet}
        minValue={minValue}
        maxValue={maxValue}
        majorTicks={majorTicks}
        minorTicks={2}
        highlights={updHighlights}
        highlightsWidth={15}
        strokeTicks={true}
        exactTicks={true}
        colorPlate="transparent"
        tickSide="left"
        numberSide="left"
        needleSide="left"
        needleShadow={false}
        needleStart={250}
        needleEnd={100}
        colorNeedle={theme.palette.primary.main}
        valueBox={false}
        borders={false}
        barBeginCircle={false}
        barWidth={0}
        ticksWidth={15}
        ticksWidthMinor={15}
        colorBar="transparent"
        colorBarProgress={theme.palette.primary.light}
        fontNumbersSize={30}
      />
    )
  }

  protected getMajorTicksWithUpdatedHighlights(
    minValue: number,
    maxValue: number,
    hasThresholds: boolean,
    highlights: Highlight[]
  ): MajorTicksWithHighlights {
    // define range [minRangeValue, maxRangeValue] for highlights
    const [minRangeValue, maxRangeValue] = this.getMinMaxRangeValue(highlights)

    const majorTickRaw = (maxValue - minValue) / 10
    const roundedMajorTick =
      majorTickRaw > 10 ? Math.round(majorTickRaw / 10) * 10 : 10
    const minValueRounded = Math.floor(minValue / 10) * 10
    const majorTicks = [
      ...this.getMajorTicks(minValueRounded, maxValue, roundedMajorTick, 0),
    ]

    const lastMajorTick = parseInt(majorTicks[majorTicks.length - 1], 10)
    if (lastMajorTick < maxValue) {
      const newLastMajorTick = Math.ceil(maxValue)

      // if two last ticks are very close to each other
      if (newLastMajorTick - lastMajorTick <= 3) {
        const lastIndex = majorTicks.length - 1
        majorTicks.splice(lastIndex, 1)
      }

      majorTicks.push(newLastMajorTick.toString())
      // Adding red range for the reason we don't want to display grey
      if (hasThresholds && maxRangeValue < newLastMajorTick) {
        highlights.push({
          from: Math.floor(maxRangeValue),
          to: newLastMajorTick,
          color: getStatusLensColor(TelemetryStatus.Red),
        })
      }
    }

    // process extreme ranges and mark them as red
    highlights.push(
      ...this.processExtremeRanges(
        majorTicks,
        minRangeValue,
        maxRangeValue,
        hasThresholds
      )
    )

    return {
      majorTicks: majorTicks,
      highlights: highlights,
    }
  }

  protected getMinMaxValue(
    statusRanges: StatusRange[],
    readingValue?: number
  ): [number, number, Highlight[]] {
    const {
      site: { tanks },
      asset: { id: assetId },
    } = this.props
    const tankLevelFt = tanks.find((t) => t.id === assetId)!.height
    let minValue = 0
    let maxValue = tankLevelFt

    const ths = statusRanges
    const hasThresholds = !!ths.length

    const highlights = ths.map((t) => {
      const from = t.minThreshold
      const to = t.maxThreshold
      const color = getStatusLensColor(t.status)

      return { from, to, color }
    })

    const [minRangeValue, maxRangeValue] = this.getMinMaxRangeValue(highlights)

    // reading value doesn't belong to [minValue, maxValue] range
    if (readingValue !== undefined) {
      if (maxValue !== undefined && maxValue < readingValue) {
        if (hasThresholds && maxRangeValue < readingValue) {
          highlights.push({
            from: maxRangeValue,
            to: readingValue,
            color: getStatusLensColor(TelemetryStatus.Red),
          })
        }
        maxValue = readingValue
      }
      if (minValue !== undefined && minValue > readingValue) {
        if (hasThresholds && minRangeValue > readingValue) {
          highlights.push({
            from: readingValue,
            to: minRangeValue,
            color: getStatusLensColor(TelemetryStatus.Red),
          })
        }
        minValue = readingValue
      }
    }

    return [minValue, maxValue, highlights]
  }

  protected processThresholds(statusRanges: StatusRange[]): StatusRange[] {
    const newThresholds = statusRanges.map((item) =>
      StatusRange.fromJS({
        status: item.status,
        minThreshold: inchToFeet(item.minThreshold),
        maxThreshold: inchToFeet(item.maxThreshold),
      })
    )

    return newThresholds
  }

  protected getTitleExtension(): React.ReactNode | undefined {
    const {
      site: { tanks },
    } = this.props
    const tank = tanks?.length
      ? tanks.find((t) => t.id === this.props.asset.id)
      : undefined
    const prodCode = tank?.productionCode
    const code = getReadableProductionCode(prodCode)

    return code ? (
      <Chip
        label={code}
        variant="outlined"
        size="small"
        style={{
          marginLeft: theme.spacing(0.5),
          alignSelf: 'center',
        }}
      />
    ) : undefined
  }

  private processExtremeRanges(
    majorTicks: string[],
    minRangeValue: number,
    maxRangeValue: number,
    hasThresholds: boolean
  ): Highlight[] {
    const highlights: Highlight[] = []
    const firstMajorTick = parseInt(majorTicks[0], 10)
    const lastMajorTick = parseInt(majorTicks[majorTicks.length - 1], 10)

    if (firstMajorTick < minRangeValue && hasThresholds) {
      // Adding red range for the reason we don't want to display grey
      highlights.push({
        from: firstMajorTick,
        to: Math.ceil(minRangeValue),
        color: getStatusLensColor(TelemetryStatus.Red),
      })
    }

    if (lastMajorTick > maxRangeValue && hasThresholds) {
      // Adding red range for the reason we don't want to display grey
      highlights.push({
        from: maxRangeValue,
        to: lastMajorTick,
        color: getStatusLensColor(TelemetryStatus.Red),
      })
    }

    return highlights
  }
}
