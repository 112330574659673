import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import theme from 'theme'
import {
  createStyles,
  Paper,
  Theme,
  WithStyles,
  withStyles,
  ButtonGroup,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core'

import { SiteMatch } from 'components/SiteDetails'
import { AppState } from 'store/AppState'
import { ModelState } from 'store/ModelState'
import {
  PlungerDescriptionResponse,
  PlungerOperatingMode,
  PlungerTimeModeSettingsApiModel,
  PlungerPressureModeSettingsApiModel,
  PlungerAutoAdjustModeSettingsApiModel,
} from '../../../api/apiservice'
import { BooleanSelectTextField } from 'components/misc/BooleanSelectTextField'
import SettingsTileWrapper from './SettingsTileWrapper'
import { getPlungerOperatingModeFriendlyName } from './formatter'

const styles = (theme: Theme) =>
  createStyles({
    gridRow: {
      marginLeft: theme.spacing(1),
      '& .MuiInput-root': {
        marginTop: theme.spacing(2.5),
      },
      '& .MuiTextField-root': {
        display: 'block',
      },
      '& .MuiFormLabel-root': {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top-left',
      },
    },
  })

interface Props {
  readonly plungerSettings?: PlungerDescriptionResponse
  readonly updateAutoAdjustModeSettings: (
    newSettings: PlungerAutoAdjustModeSettingsApiModel
  ) => void
  readonly updatePressureModeSettings: (
    newSettings: PlungerPressureModeSettingsApiModel
  ) => void
  readonly updateTimeModeSettings: (
    newSettings: PlungerTimeModeSettingsApiModel
  ) => void
}

interface PropsFromState {
  readonly accessToken?: string
}

interface State extends ModelState {
  readonly plungerMode?: PlungerOperatingMode
  readonly selectedMode?: PlungerOperatingMode
  readonly autoAdjustModeSettings?: PlungerAutoAdjustModeSettingsApiModel
  readonly pressureModeSettings?: PlungerPressureModeSettingsApiModel
  readonly timeModeSettings?: PlungerTimeModeSettingsApiModel
  readonly errorFieldIds?: string[]
}

type AllProps = PropsFromState &
  Props &
  RouteComponentProps<SiteMatch> &
  WithStyles<typeof styles>

class ModeSettings extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = this.getStateFromProps(this.props)
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.plungerSettings?.latestState?.operatingMode !==
        this.props.plungerSettings?.latestState?.operatingMode ||
      prevProps.plungerSettings?.autoAdjustModeSettings !==
        this.props.plungerSettings?.autoAdjustModeSettings ||
      prevProps.plungerSettings?.timeModeSettings !==
        this.props.plungerSettings?.timeModeSettings ||
      prevProps.plungerSettings?.pressureModeSettings !==
        this.props.plungerSettings?.pressureModeSettings
    ) {
      this.setState(this.getStateFromProps(this.props))
    }
  }

  private readonly getStateFromProps = (props: Props) => {
    const latestStatePlungerMode =
      props.plungerSettings?.latestState?.operatingMode

    return {
      plungerMode: latestStatePlungerMode,
      selectedMode: latestStatePlungerMode,
      autoAdjustModeSettings: props.plungerSettings?.autoAdjustModeSettings,
      timeModeSettings: props.plungerSettings?.timeModeSettings,
      pressureModeSettings: props.plungerSettings?.pressureModeSettings,
      errorFieldIds: [],
    }
  }

  public render() {
    return (
      <Paper
        elevation={8}
        square={true}
        style={{
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        {this.renderModeSettings()}
      </Paper>
    )
  }

  private readonly renderModeSettings = () => {
    if (
      !this.props.plungerSettings ||
      Object.keys(this.props.plungerSettings).length === 0
    ) {
      return (
        <Typography color="error">No Mode Settings Has Been Found</Typography>
      )
    }

    return (
      <SettingsTileWrapper
        tileTitle="Mode Settings"
        isSubmitVisible={this.state.selectedMode === this.state.plungerMode}
        onClick={this.handleSubmit}
      >
        <ButtonGroup color="primary">
          {Object.values(PlungerOperatingMode)
            .filter((k) => typeof k === 'string')
            .map((k) => (
              <Button
                key={PlungerOperatingMode[k]}
                variant={
                  PlungerOperatingMode[k] === this.state.selectedMode
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() =>
                  this.setState({
                    selectedMode: PlungerOperatingMode[k],
                  })
                }
                fullWidth={true}
              >
                {getPlungerOperatingModeFriendlyName(PlungerOperatingMode[k])}
              </Button>
            ))}
        </ButtonGroup>
        {this.displayModeGrid(this.state.selectedMode!)}
      </SettingsTileWrapper>
    )
  }

  private readonly displayModeGrid = (mode: PlungerOperatingMode) => {
    switch (mode) {
      case PlungerOperatingMode.AutoAdjustMode:
        return this.getAutoAdjustModeGrid()

      case PlungerOperatingMode.PressureMode:
        return this.getPressureModeGrid()

      case PlungerOperatingMode.TimeMode:
        return this.getTimeModeGrid()

      default:
        return null
    }
  }

  private readonly getAutoAdjustModeGrid = () => {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid
          container={true}
          item
          xs={12}
          spacing={1}
          className={classes.gridRow}
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Grid item xs={4}>
            <TextField
              id="arrivalTimeMin"
              label="Arrival Time Min (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('arrivalTimeMin')}
              helperText={this.getErrorTextIfFieldIsEmpty('arrivalTimeMin')}
              value={this.state.autoAdjustModeSettings?.arrivalTimeMin}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="arrivalTimeMax"
              label="Arrival Time Max (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('arrivalTimeMax')}
              helperText={this.getErrorTextIfFieldIsEmpty('arrivalTimeMax')}
              value={this.state.autoAdjustModeSettings?.arrivalTimeMax}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="autoAdjLowerLimitAfterflow"
              label="Auto Adj Lower Limit After flow (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjLowerLimitAfterflow'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjLowerLimitAfterflow'
              )}
              value={
                this.state.autoAdjustModeSettings?.autoAdjLowerLimitAfterflow
              }
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="autoAdjLowerLimitShutIn"
              label="Auto Adj Lower Limit ShutIn (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjLowerLimitShutIn'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjLowerLimitShutIn'
              )}
              value={this.state.autoAdjustModeSettings?.autoAdjLowerLimitShutIn}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="autoAdjIncrementAfterFlow"
              label="Auto Adj Increment After Flow (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjIncrementAfterFlow'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjIncrementAfterFlow'
              )}
              value={
                this.state.autoAdjustModeSettings?.autoAdjIncrementAfterFlow
              }
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="autoAdjIncrementShutIn"
              label="Auto Adj Increment ShutIn (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjIncrementShutIn'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjIncrementShutIn'
              )}
              value={this.state.autoAdjustModeSettings?.autoAdjIncrementShutIn}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="autoAdjUpperLimitAfterflow"
              label="Auto Adj Upper Limit After flow (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjUpperLimitAfterflow'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjUpperLimitAfterflow'
              )}
              value={
                this.state.autoAdjustModeSettings?.autoAdjUpperLimitAfterflow
              }
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="autoAdjUpperLimitShutIn"
              label="Auto Adj Upper Limit ShutIn (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'autoAdjUpperLimitShutIn'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'autoAdjUpperLimitShutIn'
              )}
              value={this.state.autoAdjustModeSettings?.autoAdjUpperLimitShutIn}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="autoAdjPlungerType"
              label="Auto Adj Plunger Type"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('autoAdjPlungerType')}
              helperText={this.getErrorTextIfFieldIsEmpty('autoAdjPlungerType')}
              value={this.state.autoAdjustModeSettings?.autoAdjPlungerType}
              onChange={this.handleAutoAdjustModeSettingsInput}
              InputProps={{
                readOnly: this.autoAdjustModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  private readonly getPressureModeGrid = () => {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid
          container={true}
          item
          xs={12}
          spacing={1}
          className={classes.gridRow}
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="casingUpturnFunction"
              label="Casing Upturn Function"
              value={String(
                this.state.pressureModeSettings?.casingUpturnFunction
              )}
              isEditable={!this.pressureModeIsNotCurrentMode()}
              onChange={this.handlePressureModeSettingsInput}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="casingHoldFunction"
              label="Casing Hold Function"
              value={String(
                this.state.pressureModeSettings?.casingHoldFunction
              )}
              isEditable={!this.pressureModeIsNotCurrentMode()}
              onChange={this.handlePressureModeSettingsInput}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="casingHoldAfterflowThreshold"
              label="Casing Hold After flow Threshold"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'casingHoldAfterflowThreshold'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'casingHoldAfterflowThreshold'
              )}
              value={
                this.state.pressureModeSettings?.casingHoldAfterflowThreshold
              }
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="casingHoldShutInThreshold"
              label="Casing Hold Shut In Threshold"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'casingHoldShutInThreshold'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'casingHoldShutInThreshold'
              )}
              value={this.state.pressureModeSettings?.casingHoldShutInThreshold}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="casingUpturnValue"
              label="Casing Upturn Value"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('casingUpturnValue')}
              helperText={this.getErrorTextIfFieldIsEmpty('casingUpturnValue')}
              value={this.state.pressureModeSettings?.casingUpturnValue}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="loadFactorMinShutIn"
              label="Load Factor Min Shut In"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'loadFactorMinShutIn'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'loadFactorMinShutIn'
              )}
              value={this.state.pressureModeSettings?.loadFactorMinShutIn}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="loadFactor"
              label="Load Factor"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('loadFactor')}
              helperText={this.getErrorTextIfFieldIsEmpty('loadFactor')}
              value={this.state?.pressureModeSettings?.loadFactor}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="loadFactorFunction"
              label="Load Factor Function"
              value={String(
                this.state?.pressureModeSettings?.loadFactorFunction
              )}
              isEditable={!this.pressureModeIsNotCurrentMode()}
              onChange={this.handlePressureModeSettingsInput}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="pressureModeThreshold"
              label="Pressure Mode Threshold"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList(
                'pressureModeThreshold'
              )}
              helperText={this.getErrorTextIfFieldIsEmpty(
                'pressureModeThreshold'
              )}
              value={this.state?.pressureModeSettings?.pressureModeThreshold}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="whichPressurePort"
              label="Which Pressure Port"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('whichPressurePort')}
              helperText={this.getErrorTextIfFieldIsEmpty('whichPressurePort')}
              value={String(
                this.state?.pressureModeSettings?.whichPressurePort
              )}
              onChange={this.handlePressureModeSettingsInput}
              InputProps={{
                readOnly: this.pressureModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  private readonly getTimeModeGrid = () => {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid
          container={true}
          item
          xs={12}
          spacing={1}
          className={classes.gridRow}
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Grid item xs={4}>
            <TextField
              id="openTime"
              label="Open Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('openTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('openTime')}
              value={this.state.timeModeSettings?.openTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="afterflowTime"
              label="After flow Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('afterflowTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('afterflowTime')}
              value={this.state.timeModeSettings?.afterflowTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="shutinTime"
              label="Shutin Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('shutinTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('shutinTime')}
              value={this.state.timeModeSettings?.shutinTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="mandShutinTime"
              label="Mand Shutin Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('mandShutinTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('mandShutinTime')}
              value={this.state.timeModeSettings?.mandShutinTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="afterflowMinTime"
              label="After flow Min Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('afterflowMinTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('afterflowMinTime')}
              value={this.state.timeModeSettings?.afterflowMinTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="afterflowMaxTime"
              label="After flow Max Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('afterflowMaxTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('afterflowMaxTime')}
              value={this.state.timeModeSettings?.afterflowMaxTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <TextField
              id="flowrateDelayTime"
              label="Flow Rate Delay Time (Mins)"
              type="number"
              error={this.idHasBeenAddedToErrorFieldsList('flowrateDelayTime')}
              helperText={this.getErrorTextIfFieldIsEmpty('flowrateDelayTime')}
              value={this.state.timeModeSettings?.flowrateDelayTime}
              onChange={this.handleTimeModeSettingsInput}
              InputProps={{
                readOnly: this.timeModeIsNotCurrentMode(),
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  private readonly displayErrorIfTextFieldIsEmpty = (
    field: HTMLInputElement
  ) => {
    if (field.value === '') {
      this.addIdToErrorFieldsList(field.id)
    } else {
      this.removeIdFromErrorFieldsList(field.id)
    }
  }

  private readonly addIdToErrorFieldsList = (textFieldId: string) => {
    if (!this.idHasBeenAddedToErrorFieldsList(textFieldId)) {
      this.state.errorFieldIds?.push(textFieldId)
    }
  }

  private readonly removeIdFromErrorFieldsList = (textFieldId: string) =>
    this.setState({
      errorFieldIds: this.state.errorFieldIds?.filter((f) => f !== textFieldId),
    })

  private readonly idHasBeenAddedToErrorFieldsList = (textFieldId: string) =>
    this.state.errorFieldIds?.some((f) => f === textFieldId)

  private readonly getErrorTextIfFieldIsEmpty = (textFieldId: string) =>
    this.idHasBeenAddedToErrorFieldsList(textFieldId)
      ? 'Field cannot be empty.'
      : ''

  private readonly handleAutoAdjustModeSettingsInput = (event) => {
    const name = this.getFieldName(event)

    this.displayErrorIfTextFieldIsEmpty(event.target)

    const newSettings = {
      ...this.state.autoAdjustModeSettings,
      [name]: event.target.value,
    }

    this.setState({
      autoAdjustModeSettings:
        PlungerAutoAdjustModeSettingsApiModel.fromJS(newSettings),
    })
  }

  private readonly handlePressureModeSettingsInput = (event) => {
    const name = this.getFieldName(event)

    this.displayErrorIfTextFieldIsEmpty(event.target)

    const newSettings = {
      ...this.state.pressureModeSettings,
      [name]: event.target.value,
    }

    this.setState({
      pressureModeSettings:
        PlungerPressureModeSettingsApiModel.fromJS(newSettings),
    })
  }

  private readonly handleTimeModeSettingsInput = (event) => {
    const name = this.getFieldName(event)

    this.displayErrorIfTextFieldIsEmpty(event.target)

    const newSettings = {
      ...this.state.timeModeSettings,
      [name]: event.target.value,
    }

    this.setState({
      timeModeSettings: PlungerTimeModeSettingsApiModel.fromJS(newSettings),
    })
  }

  private readonly getFieldName = (event) => {
    if (event.target.name === '') {
      return event.target.id
    }
    return event.target.name
  }

  private readonly handleSubmit = (event) => {
    event.preventDefault()

    switch (this.state.selectedMode) {
      case PlungerOperatingMode.AutoAdjustMode:
        if (!this.state.autoAdjustModeSettings) {
          break
        }
        this.props.updateAutoAdjustModeSettings(
          this.state.autoAdjustModeSettings
        )
        break

      case PlungerOperatingMode.PressureMode:
        if (!this.state.pressureModeSettings) {
          break
        }
        this.props.updatePressureModeSettings(this.state.pressureModeSettings)
        break

      case PlungerOperatingMode.TimeMode:
        if (!this.state.timeModeSettings) {
          break
        }
        this.props.updateTimeModeSettings(this.state.timeModeSettings)
        break

      default:
        break
    }
  }

  private readonly autoAdjustModeIsNotCurrentMode = () =>
    this.state.plungerMode !== PlungerOperatingMode.AutoAdjustMode

  private readonly pressureModeIsNotCurrentMode = () =>
    this.state.plungerMode !== PlungerOperatingMode.PressureMode

  private readonly timeModeIsNotCurrentMode = () =>
    this.state.plungerMode !== PlungerOperatingMode.TimeMode
}

const mapPropsFromState = ({ oidc }: AppState) => ({
  accessToken: oidc.user?.access_token,
})

export default withRouter(
  connect(mapPropsFromState)(withStyles(styles)(ModeSettings))
)
