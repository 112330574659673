import { Dispatch, Reducer, AnyAction } from 'redux'
import { AppState } from './AppState'

export enum MapTypeId {
  /** This map type displays a transparent layer of major streets on satellite images. */
  HYBRID = 'hybrid',
  /** This map type displays a normal street map. */
  ROADMAP = 'roadmap',
  /** This map type displays satellite images. */
  SATELLITE = 'satellite',
  /** This map type displays maps with physical features such as terrain and vegetation. */
  TERRAIN = 'terrain',
}

export interface MapSettings {
  readonly type: MapTypeId
}

export interface UiSettings {
  readonly isDrawerClosed?: boolean
}

export interface UserSettings {
  readonly mapSettings: MapSettings
  readonly uiSettings: UiSettings
}

export const defaultUserSettings: UserSettings = {
  mapSettings: {
    type: MapTypeId.SATELLITE,
  },
  uiSettings: { isDrawerClosed: true },
}

const userSettingsTypeHeader = 'USER_SETTINGS_'
const setUserSettingsType = `${userSettingsTypeHeader}SET`
const loadUserSettingsType = `${userSettingsTypeHeader}LOAD`
const userSettingsKey = `${userSettingsTypeHeader}KEY`

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export const actionCreators = {
  setUserSettings:
    (settings: RecursivePartial<UserSettings>) =>
    (dispatch: Dispatch, _: () => AppState) =>
      dispatch({
        settings,
        type: setUserSettingsType,
      }),
  loadUserSettings: () => (dispatch: Dispatch, _: () => AppState) =>
    dispatch({ type: loadUserSettingsType }),
}

function loadUserSettings(state: UserSettings): UserSettings {
  const json = localStorage.getItem(userSettingsKey)

  if (!json) {
    return state
  }

  const loadedSettings = JSON.parse(json)

  return {
    ...defaultUserSettings,
    ...loadedSettings,
  }
}

export const reducer: Reducer<UserSettings> = (
  state = defaultUserSettings,
  action: AnyAction
) => {
  switch (action.type) {
    case setUserSettingsType:
      const { settings } = action

      if (settings) {
        const userSettings: UserSettings = {
          ...loadUserSettings(state),
          ...settings,
        }
        localStorage.setItem(userSettingsKey, JSON.stringify(userSettings))

        return userSettings
      }

      return state

    case loadUserSettingsType:
      return loadUserSettings(state)
  }

  return state
}
