import moment, { duration } from 'moment'

export type RelativeTimeRange = moment.Duration

export interface AbsoluteTimeRange {
  readonly from: moment.Moment | null
  readonly to: moment.Moment | null
}

export type TimeRange = RelativeTimeRange | AbsoluteTimeRange

export function isAbsoluteTimeRange(tr: TimeRange): tr is AbsoluteTimeRange {
  const atp = tr as AbsoluteTimeRange
  return atp.from !== undefined
}

export function getTimeRange(
  timeRange?: TimeRange
): [Date | undefined, Date | undefined] {
  if (!timeRange) {
    return [undefined, undefined]
  }

  const range: [Date | undefined, Date | undefined] = isAbsoluteTimeRange(
    timeRange
  )
    ? [timeRange.from?.toDate(), timeRange.to?.toDate()]
    : timeRange.asMilliseconds() !== 0
    ? [moment.utc().subtract(timeRange).toDate(), undefined]
    : [undefined, undefined]

  return range
}

type TimeRangePredicate = (Date?) => boolean

export function getTimeRangePredicate(
  timeRange?: TimeRange
): TimeRangePredicate {
  const [from, to] = getTimeRange(timeRange)

  if (from && to) {
    const fromVal = from.valueOf()
    const toVal = to.valueOf()

    return fromVal !== toVal
      ? (date) => date?.valueOf() >= fromVal && date?.valueOf() <= toVal
      : (_) => true
  }

  if (from && !to) {
    const fromVal = from.valueOf()
    return (date) => date?.valueOf() >= fromVal
  }

  if (!from && to) {
    const toVal = to.valueOf()
    return (date) => date?.valueOf() <= toVal
  }

  return (_) => true
}

export function getTimeRangeDuration(timeRange: TimeRange) {
  const [from, to] = getTimeRange(timeRange)

  return duration({ from, to })
}
