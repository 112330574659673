import React from 'react'
import MaskedInput from 'react-text-mask'

interface FeetInchesInputMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

class FeetInchesInputMask extends React.Component<FeetInchesInputMaskProps> {
  render() {
    const { inputRef, ...other } = this.props

    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={this.feetAndInchesHeightMask}
        showMask
      />
    )
  }

  private readonly feetAndInchesHeightMask = (userInput) => {
    const [feetHeight, inchesHeight] = userInput.split("'")

    const feetLength = this.getNumLength(feetHeight?.trim())
    const inchesLength = this.getNumLength(inchesHeight?.trim())

    const feetMask = feetLength >= 2 ? [/[1-9]/, /[0-9]/] : [/[0-9]/]
    const inchesMask = inchesLength >= 2 ? [/[1]/, /[0-1]/] : [/[0-9]/]

    return [...feetMask, "'", ' ', ...inchesMask, "''"]
  }

  private readonly getNumLength = (numStr: string) => {
    const num = parseInt(numStr, 10) || 0
    return num.toString().length
  }
}

export default FeetInchesInputMask
