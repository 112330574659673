import * as React from 'react'
import Map, { Props, Place } from '../map/Map'
import {
  ClusterIconStyle,
  MarkerExtended,
  ClusterIconInfo,
} from '@react-google-maps/marker-clusterer'
import { DrillDownCluster } from '../../api/apiservice'
import { getStatusLensColor } from '../utils'
import { Paper } from '@material-ui/core'
import theme from '../../theme'
import { TelemetryStatus } from 'api/alertservice'

export interface WithDrillDownCluster {
  readonly cluster: DrillDownCluster
}

export function getCluster<T extends WithDrillDownCluster>(
  obj: DrillDownCluster | T
): DrillDownCluster {
  return obj instanceof DrillDownCluster ? obj : obj.cluster
}

const clusterSizes = [60, 72, 84, 96, 108]
const color = getStatusLensColor(TelemetryStatus.Undefined)
const clusterStyles: ClusterIconStyle[] = clusterSizes.map((width) => {
  const height = width
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="${color}" opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8z"/><path fill="${color}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/><circle stroke="black" fill="none" stroke-width="1%" cx="12" cy="12" r="10"/></svg>`
  const url = `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`
  const textSize = 8 + (width / 12 - 3) * 4

  return { width, height, url, textSize }
})
const clusterCalculator =
  (sitesCount: number) =>
  (markers: MarkerExtended[], _: number): ClusterIconInfo => {
    if (sitesCount === 0) {
      return { index: 0, text: '', title: '' }
    }

    const places = markers
      .map((m) => (m as any).payload as Place)
      .filter((p) => p)
    const clusteredSitesCount = places
      .map((p) => getCluster(p.payload))
      .filter((d) => d)
      .reduce((count, d) => count + d.count, 0)
    const index = Math.round(
      ((clusterSizes.length - 1) * clusteredSitesCount) / sitesCount
    )
    const text = clusteredSitesCount.toString()
    const title = places
      .map((p) => p.title)
      .filter((t) => t)
      .join('\n')

    return { index, text, title }
  }

export type AllProps = Omit<
  Props,
  | 'clusterCalculator'
  | 'clusterStyles'
  | 'minimumClusterSize'
  | 'maxZoom'
  | 'getPlacePositions'
>

function allSitesMap(props: AllProps): React.ReactElement {
  function getPlacePos(place: Place): google.maps.LatLngLiteral[] {
    const { latMin, lonMin, latMax, lonMax } = place.payload as DrillDownCluster

    return [
      { lat: latMin, lng: lonMin },
      { lat: latMax, lng: lonMax },
    ]
  }

  const sitesCount = props.places
    .map((p) => (p.payload as DrillDownCluster)?.count || 0)
    .reduce((count, c) => count + c, 0)

  return (
    <Paper
      style={{
        padding: theme.spacing(1),
        height: '100%',
      }}
    >
      <Map
        clusterCalculator={clusterCalculator(sitesCount)}
        clusterStyles={clusterStyles}
        minimumClusterSize={1}
        getPlacePositions={getPlacePos}
        {...(props as React.ComponentProps<typeof Map>)}
      />
    </Paper>
  )
}

export default allSitesMap
