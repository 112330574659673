import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'

export interface Props {
  readonly title?: string
  readonly onAction: (ok: boolean, ...params) => void
  readonly disableOk?: boolean
  readonly titleOkButton?: string
}

export function ConfirmDialog(props: React.PropsWithChildren<Props>) {
  const [open, setOpen] = React.useState(true)

  function onAction(ok) {
    setOpen(false)
    props.onAction(ok)
  }

  return (
    <Dialog disableEscapeKeyDown={true} open={open}>
      <DialogTitle disableTypography={true}>
        <Typography color="primary" variant="h5">
          {props.title || 'Confirm'}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true}>{props.children}</DialogContent>
      <DialogActions>
        <Button
          autoFocus={true}
          onClick={() => onAction(false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onAction(true)}
          color="primary"
          disabled={props.disableOk}
        >
          {props.titleOkButton || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
