import * as React from 'react'
import {
  StatisticsClient,
  TelemetryAverageResponse,
  TelemetryType,
} from '../../api/apiservice'
import moment from 'moment'
import { multitenantApiBasePath, getFetch } from 'api/fetch'
import BaseSensor from './BaseSensor'
import { Reference } from './SensorChart'
import { getTimeRange, TimeRange } from 'components/misc/TimeRange'

export default class LiquidFlow extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.LiquidFlow, 'Liquid Flow')
    this.loadReferences = this.loadReferences.bind(this)
  }

  protected async loadReferences(refTimeRange?: TimeRange) {
    const {
      asset,
      accessToken,
      tenantId,
      timeRange,
      site: { id },
    } = this.props

    try {
      const selectedRange = refTimeRange ?? getTimeRange(timeRange)

      const selectedEndDate = selectedRange[1]
      const selectedStartDate = selectedRange[0]
      const toDateTimeUtc = moment(selectedEndDate).utc().toDate()
      let fromDateTimeUtc = moment(selectedStartDate).utc().toDate()

      const hoursDifference = moment(toDateTimeUtc).diff(
        moment(fromDateTimeUtc),
        'hours'
      )

      if (hoursDifference < 24) {
        fromDateTimeUtc = moment(toDateTimeUtc).subtract(24, 'hours').toDate()
      }

      const {
        result: { averages },
      } = await new StatisticsClient(
        multitenantApiBasePath,
        getFetch(accessToken, tenantId.toString())
      ).getAverageByTelemetry(
        TelemetryType.LiquidFlow,
        id,
        asset.id,
        fromDateTimeUtc,
        toDateTimeUtc
      )

      this.getBPDRange(averages ?? [])
    } catch (error) {
      console.warn(
        `Failed to load averages for ${TelemetryType[this.TelemetryType]}`,
        error
      )
    }
  }

  private readonly mapRef = ({
    value,
    fromUtc,
    toUtc,
  }: TelemetryAverageResponse): Reference | null => {
    const [fromDate, toDate] = getTimeRange(this.props.timeRange)
    const [from, to] = [
      fromDate?.valueOf() ?? 0,
      (toDate ?? Date.now()).valueOf(),
    ]
    const refFromLocal = moment.utc(fromUtc).local()
    let refFrom = refFromLocal.add(1, 'day').valueOf()
    const refToLocal = moment.utc(toUtc).local()
    let refTo = refToLocal.add(1, 'day').valueOf()

    if (refTo <= from || refFrom >= to) {
      return null
    }
    if (refFrom < from) {
      refFrom = from
    }
    if (refTo > to) {
      refTo = to
    }

    const ref: Reference = {
      value,
      from: new Date(refFrom),
      to: new Date(refTo),
    }

    return ref
  }

  protected formatValue = (value?: any, timeStamp?: Date): React.ReactNode => {
    if (typeof value !== 'number') {
      return 'N/A'
    }
    return (
      <React.Fragment>
        Flow Rate:
        {this.renderValue('BPH', value.toFixed(1))}
        {this.renderValue('BPD', (value * 24).toFixed(1))}
      </React.Fragment>
    )
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
