import {
  UserManagerSettings,
  UserManager,
  WebStorageStateStore,
} from 'oidc-client'
import { createUserManager } from 'redux-oidc'

const authorityBasePath =
  process.env.NODE_ENV === 'production'
    ? `${window.location.origin}/auth`
    : 'https://wm-dev.westeurope.cloudapp.azure.com/auth'

const userManagerConfig: UserManagerSettings = {
  client_id: 'webPortal-spa',
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'code',
  scope:
    'openid profile roles api-service api-streaming api-simulator api-identity api-ingestor api-alerts api-identity.users api-commands',
  authority: authorityBasePath,
  silent_redirect_uri: `${window.location.origin}/silent.html`,
  silentRequestTimeout: 60000,
  accessTokenExpiringNotificationTime: 30,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  acr_values: 'idp:aad',
  post_logout_redirect_uri: `${window.location.origin}`,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
}

export const userManager: UserManager = createUserManager(userManagerConfig)

export function isSystemAdmin(roles: any | null): boolean {
  return userHasRole(roles, 'SystemAdmin')
}

export function isAdmin(roles: any | null): boolean {
  return userHasRole(roles, 'Admin') || isSystemAdmin(roles)
}

export function hasSupervisorUserAccessLevel(roles: any | null): boolean {
  return userHasRole(roles, 'Supervisor') || isAdmin(roles)
}

export function hasBasicRole(roles: any | null): boolean {
  return userHasRole(roles, 'Basic')
}

function userHasRole(roles: any | null, role: string): boolean {
  try {
    return !!roles?.some(
      (item) => item.roleName.toLowerCase() === role.toLowerCase()
    )
  } catch (error) {
    console.error(error)
  }

  return false
}
