const userAppSettingsKey = 'USER_APP_SETTINGS_KEY'
const userUiPreferencesKey = 'USER_UI_PREFERENCES_KEY'

export const getUserSettings = () => {
  const json = localStorage.getItem(userAppSettingsKey)
  if (!json) {
    return []
  }
  return JSON.parse(json)
}

export const getUserUiPreferences = () => {
  const json = localStorage.getItem(userUiPreferencesKey)
  if (!json) {
    return []
  }
  return JSON.parse(json)
}

export enum UserSettingType {
  AllowSendSms = 0,
  AllowSendEmail = 1,
  AllowCall = 2,
  CallStartTime = 3,
  CallEndTime = 4,
  SmsStartTime = 5,
  SmsEndTime = 6,
  CallAttempts = 7,
  AllowSendEmailSensors = 8,
  AllowHighPriorityAlerts = 9,
}

export enum SitesTableOrderByType {
  None = -1,
  Site = 0,
  LastReport = 1,
  Summary = 2,
  Status = 3,
}

export enum TableOrderDirectionType {
  Asc = 'asc',
  Desc = 'desc',
}

export enum TimeRangeType {
  lastInHour = 'last_in_a_hour',
  lastInSixHours = 'last_in_6_hours',
  lastInTwelveHours = 'last_in_12_hours',
  lastInDay = 'last_in_a_day',
  lastInThreeDays = 'last_in_3_days',
  lastInSevenDays = 'last_in_7_days',
  lastInFourteenDays = 'last_in_14_days',
  lastInMonth = 'last_in_a_month',
}

export const setUserSettings = (userSettings) =>
  localStorage.setItem(userAppSettingsKey, JSON.stringify(userSettings))
export const setUserUiPreferences = (userPreferences) =>
  localStorage.setItem(userUiPreferencesKey, JSON.stringify(userPreferences))

const timeZoneSettingSearch = (userSetting) => userSetting.key === 'TimeZone'
const sitesTableOrderByColumnSearch = (userPreferences) =>
  userPreferences.key === sitesTableOrderByColumnIndex
const sitesTableOrderDirectionSearch = (userPreferences) =>
  userPreferences.key === sitesTableOrderDirection
const metricsDashboardTabSearch = (userPreferences) =>
  userPreferences.key === metricsDashboardTab

const sitesTableOrderByColumnIndex = 'SitesTableOrderByColumnIndex'
const sitesTableOrderDirection = 'SitesTableOrderDirection'
const metricsDashboardTab = 'MetricsDashboardTab'

const setUserSetting = (alertObj: any, userSettingType: UserSettingType) => {
  const userSettings = getUserSettings()
  setUserSettings(userSettings)
  return userSettings
}

export const setSitesTableSortingOrderBy = (orderBy: SitesTableOrderByType) => {
  const userUiPreferences = getUserUiPreferences()
  const index = userUiPreferences.findIndex(sitesTableOrderByColumnSearch)
  const sitesTableOrderByObj = {
    key: sitesTableOrderByColumnIndex,
    value: orderBy,
  }
  index >= 0
    ? (userUiPreferences[index] = sitesTableOrderByObj)
    : userUiPreferences.push(sitesTableOrderByObj)
  setUserUiPreferences(userUiPreferences)
  return userUiPreferences
}

export const setSitesTableSortingOrderDirection = (
  orderDirection: TableOrderDirectionType
) => {
  const userUiPreferences = getUserUiPreferences()
  const index = userUiPreferences.findIndex(sitesTableOrderDirectionSearch)
  const sitesTableOrderDirectionObj = {
    key: sitesTableOrderDirection,
    value: orderDirection,
  }
  index >= 0
    ? (userUiPreferences[index] = sitesTableOrderDirectionObj)
    : userUiPreferences.push(sitesTableOrderDirectionObj)
  setUserUiPreferences(userUiPreferences)
  return userUiPreferences
}

export const setFilteringTimeRange = (duration: TimeRangeType) => {
  const userUiPreferences = getUserUiPreferences()
  const index = userUiPreferences.findIndex(metricsDashboardTabSearch)
  const timeRangeObj = {
    key: metricsDashboardTab,
    value: duration,
  }
  if (index >= 0) {
    userUiPreferences[index] = timeRangeObj
    setUserUiPreferences(userUiPreferences)
    return
  }
  userUiPreferences.push(timeRangeObj)
  setUserUiPreferences(userUiPreferences)
}

export const getUserTimeZoneObj = () => {
  const timeZoneSettingsObj = getUserSettings().find(timeZoneSettingSearch)
  return timeZoneSettingsObj ?? null
}

export const getSitesTableOrderByColumnObj = () => {
  const sitesTableSortingSearchObj = getUserUiPreferences().find(
    sitesTableOrderByColumnSearch
  )
  return sitesTableSortingSearchObj ?? null
}

export const getSitesTableOrderDirectionObj = () => {
  const sitesTableSortingSearchObj = getUserUiPreferences().find(
    sitesTableOrderDirectionSearch
  )
  return sitesTableSortingSearchObj ?? null
}

export const getFilteringTimeRangeObj = () => {
  const searchObj = getUserUiPreferences().find(metricsDashboardTabSearch)
  return searchObj ?? null
}

export const setUserAllowSendSms = (allowSendSmsObj) =>
  setUserSetting(allowSendSmsObj, UserSettingType.AllowSendSms)

export const setUserAllowSendEmail = (allowSendEmailObj) =>
  setUserSetting(allowSendEmailObj, UserSettingType.AllowSendEmail)

export const setUserAllowSendEmailSensors = (allowSendEmailSensorsObj) =>
  setUserSetting(
    allowSendEmailSensorsObj,
    UserSettingType.AllowSendEmailSensors
  )

export const setUserAllowCall = (allowCallObj) =>
  setUserSetting(allowCallObj, UserSettingType.AllowCall)

export const setUserCallStartTime = (startTimeObj) =>
  setUserSetting(startTimeObj, UserSettingType.CallStartTime)

export const setUserCallEndTime = (endTimeObj) =>
  setUserSetting(endTimeObj, UserSettingType.CallEndTime)

export const setUserSmsStartTime = (startTimeObj) =>
  setUserSetting(startTimeObj, UserSettingType.SmsStartTime)

export const setUserSmsEndTime = (endTimeObj) =>
  setUserSetting(endTimeObj, UserSettingType.SmsEndTime)

export const setUserCallAttempts = (callAttemptsObj) =>
  setUserSetting(callAttemptsObj, UserSettingType.CallAttempts)

export const setUserTimeZone = (timeZoneObj) => {
  const userSettings = getUserSettings()
  const timeZoneIndex = userSettings.findIndex(timeZoneSettingSearch)
  timeZoneIndex >= 0
    ? (userSettings[timeZoneIndex] = timeZoneObj)
    : userSettings.push(timeZoneObj)
  setUserSettings(userSettings)
  return userSettings
}

export const setUserAllowHighPriorityAlerts = (allowCallObj) =>
  setUserSetting(allowCallObj, UserSettingType.AllowHighPriorityAlerts)
