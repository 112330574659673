import { TelemetryType } from '../../api/apiservice'
import BaseSensor from './BaseSensor'

export default class StrokesPerMinute extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.StrokesPerMinute, 'Strokes Per Minute')
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
