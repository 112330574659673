import { Breadcrumbs, Link } from '@material-ui/core'
import { siteRoute, idRoute } from 'App'
import { renderTitlePart } from 'components/Navigation'
import * as React from 'react'
import { Link as RouterLink, generatePath } from 'react-router-dom'

export interface Props {
  readonly siteId: string
  readonly siteName?: string
  readonly secondLabel?: string
}

export function BreadcrumbsWithSiteLink(props: Props) {
  const { siteId, siteName, secondLabel } = props
  return (
    <Breadcrumbs maxItems={3}>
      <Link
        component={RouterLink}
        key={siteId}
        to={generatePath(siteRoute + idRoute, {
          id: siteId,
        })}
        underline="hover"
        style={{ color: '#FFFFFF' }}
      >
        {renderTitlePart(siteName)}
      </Link>
      {secondLabel && renderTitlePart(secondLabel)}
    </Breadcrumbs>
  )
}
