import { ModelState } from './ModelState'
import { Reducer, Dispatch, AnyAction } from 'redux'
import { AppState } from './AppState'
import { parseError } from './utils'
import { getUserClient } from '../api/client'
import { UserSettingsResponseDto } from '../api/identityprovider'
import {
  setUserAllowSendSms,
  setUserAllowSendEmail,
  setUserAllowCall,
  setUserSettings,
  setUserTimeZone,
  setUserCallStartTime,
  setUserCallEndTime,
  setUserSmsStartTime,
  setUserSmsEndTime,
  setUserCallAttempts,
  setUserAllowHighPriorityAlerts,
} from '../utils/localStorage'

const requestUserAppSettings = 'REQUEST_USER_APP_SETTINGS'
const receiveUserAppSettingsType = 'RECEIVE_USER_APP_SETTINGS'
const receiveUserAppSettingsErrorType = 'RECEIVE_USER_APP_SETTINGS_ERROR'
const setUserAppSettingType = 'SET_USER_APP_SETTING'
const createUserAppSettingErrorType = 'CREATE_USER_APP_SETTING_ERROR'
const updateUserAppSettingErrorType = 'UPDATE_USER_APP_SETTING_ERROR'

export interface UserAppSettings extends ModelState {
  readonly userAppSettings?: UserSettingsResponseDto[]
}

export const defaultState: UserAppSettings = {}

export const actionCreators = {
  loadUserAppSettings:
    (accessToken: string, userId: string, tenantId: string) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ type: requestUserAppSettings })
      try {
        const userClientApi = getUserClient(
          accessToken,
          tenantId?.toString(),
          userId
        )
        const { result } = await userClientApi.getUsersSettings(userId)

        if (result instanceof Error) {
          console.error(result)
          dispatch({
            error: result,
            type: receiveUserAppSettingsErrorType,
          })
          return
        }
        setUserSettings(result)
        dispatch({
          appSettings: result,
          type: receiveUserAppSettingsType,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: receiveUserAppSettingsErrorType,
        })
      }
    },

  createUserAppSetting:
    (
      accessToken: string,
      userId: string,
      userSetting: UserSettingsResponseDto,
      tenantId: string
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      try {
        const arrayUserSettings: UserSettingsResponseDto[] = [userSetting]
        const userClientApi = getUserClient(accessToken, tenantId, userId)
        await userClientApi.createUserSettings(userId, arrayUserSettings)
        const appUserSettings = setUserTimeZone(userSetting)
        dispatch({ appSettings: appUserSettings, type: setUserAppSettingType })
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: createUserAppSettingErrorType,
        })
      }
    },

  updateUserAppSetting:
    (
      accessToken: string,
      userId: string,
      userSetting: UserSettingsResponseDto,
      tenantId: string
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      try {
        const arrayUserSettings: UserSettingsResponseDto[] = [userSetting]
        const userClientApi = getUserClient(accessToken, tenantId, userId)
        await userClientApi.createUserSettings(userId, arrayUserSettings)
        const appUserSettings = setUserTimeZone(userSetting)
        dispatch({ appSettings: appUserSettings, type: setUserAppSettingType })
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: updateUserAppSettingErrorType,
        })
      }
    },
}

export const reducer: Reducer<UserAppSettings> = (
  state = defaultState,
  action: AnyAction
) => {
  const userAppSettings: UserSettingsResponseDto[] = action.appSettings
  switch (action.type) {
    case requestUserAppSettings:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case receiveUserAppSettingsType:
    case setUserAppSettingType:
    case setUserAllowSendSms:
    case setUserAllowSendEmail:
    case setUserAllowCall:
    case setUserCallStartTime:
    case setUserCallEndTime:
    case setUserSmsStartTime:
    case setUserSmsEndTime:
    case setUserAllowHighPriorityAlerts:
    case setUserCallAttempts:
      return {
        ...state,
        userAppSettings,
        loading: false,
        error: undefined,
      }

    case receiveUserAppSettingsErrorType:
    case createUserAppSettingErrorType:
    case updateUserAppSettingErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    default:
      return state
  }
}
