import * as React from 'react'
import {
  Typography,
  Button,
  Grid,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    gridRow: {
      marginLeft: theme.spacing(1),
    },
  })

interface Props {
  readonly tileTitle: string
  readonly isSubmitVisible: boolean
  readonly onClick: (event) => void
}

type AllProps = Props & React.PropsWithChildren<any> & WithStyles<typeof styles>

class SettingsTileWrapper extends React.Component<AllProps> {
  public render() {
    return (
      <React.Fragment>
        {this.getHeaderRow()}
        {this.props.children}
        {this.props.isSubmitVisible && (
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 8,
              }}
            >
              {this.getButtonsFooter()}
            </div>
          </Grid>
        )}
      </React.Fragment>
    )
  }

  private readonly getHeaderRow = () => {
    return (
      <Grid
        container
        item
        xs={12}
        spacing={1}
        className={this.props.classes.gridRow}
      >
        <Grid item xs={10}>
          <Typography variant="h6">{this.props.tileTitle}</Typography>
        </Grid>
      </Grid>
    )
  }

  private readonly getButtonsFooter = () => {
    return (
      <Button onClick={this.props.onClick} variant="contained">
        Submit
      </Button>
    )
  }
}

export default withStyles(styles)(SettingsTileWrapper)
