import { Reducer, AnyAction } from 'redux'

import {
  HANDLE_SITE_NAME_FILTER_CONTROL_PANEL,
  HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL,
  HANDLE_DATE_RANGE_CONTROL_PANEL,
  HANDLE_PRODUCTS_CONTROL_PANEL,
  HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL,
  HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL,
  HANDLE_ASSETS_LIST,
  HANDLE_SITES_LIST,
  HANDLE_SEARCH_ASSETS_BY_SITES_FLAG,
  HANDLE_SEARCH_SITES_BY_ASSETS_FLAG,
  HANDLE_EXPORT_QUERY,
  HANDLE_ACTIVATE_FILTER_FLAG,
  HANDLE_DEACTIVATE_FILTER_FLAG,
} from '../types/types'

export interface ControlPanelState {
  readonly selectedSitesFilter?: any
  readonly selectedAssetsFilter?: any
  readonly dateRange?: any
  readonly products?: any
  readonly siteName?: any
  readonly assetName?: any
  readonly assetsList?: any
  readonly sitesList?: any
  readonly assetsBySitesFlag?: any
  readonly sitesByAssetsFlag?: any
  readonly exportQuery?: any
  readonly activateFilterFlag?: any
}

export const initialState: ControlPanelState = {
  selectedSitesFilter: [],
  selectedAssetsFilter: [],
  assetsList: [],
  sitesList: [],
  dateRange: [],
  products: [],
  siteName: '',
  assetName: '',
  assetsBySitesFlag: false,
  sitesByAssetsFlag: false,
  exportQuery: '',
  activateFilterFlag: 0,
}

export const reducer: Reducer<ControlPanelState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        selectedSitesFilter: action.payload.selectedSites,
      })

    case HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        selectedAssetsFilter: action.payload.selectedAssets,
      })

    case HANDLE_SITE_NAME_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        siteName: action.payload.siteName,
      })

    case HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        assetName: action.payload.assetName,
      })

    case HANDLE_DATE_RANGE_CONTROL_PANEL:
      return Object.assign({}, state, {
        dateRange: action.payload.data,
      })

    case HANDLE_PRODUCTS_CONTROL_PANEL:
      return Object.assign({}, state, {
        products: action.payload.data,
      })

    case HANDLE_ASSETS_LIST:
      return Object.assign({}, state, {
        assetsList: action.payload.assetsList,
      })

    case HANDLE_SITES_LIST:
      return Object.assign({}, state, {
        sitesList: action.payload.sitesList,
      })

    case HANDLE_SEARCH_ASSETS_BY_SITES_FLAG:
      return Object.assign({}, state, {
        assetsBySitesFlag: action.payload.assetsBySitesFlag,
      })

    case HANDLE_SEARCH_SITES_BY_ASSETS_FLAG:
      return Object.assign({}, state, {
        sitesByAssetsFlag: action.payload.sitesByAssetsFlag,
      })

    case HANDLE_EXPORT_QUERY:
      return Object.assign({}, state, {
        exportQuery: action.payload.exportQuery,
      })

    case HANDLE_ACTIVATE_FILTER_FLAG:
      return Object.assign({}, state, {
        activateFilterFlag: Number(state.activateFilterFlag) + 1,
      })

    case HANDLE_DEACTIVATE_FILTER_FLAG:
      return Object.assign({}, state, {
        activateFilterFlag: 0,
      })

    default:
      return state
  }
}
