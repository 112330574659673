import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { LinearProgress, Grid, Button } from '@material-ui/core'

import { AppState } from '../../../store/AppState'
import { getSensorClient, getGatewaysClient } from 'api/client'
import { ModelState } from 'store/ModelState'
import { Device as DeviceData } from 'store/Device'
import { UiContextData } from 'components/contexts/UiContext'
import { withUiContext } from '../../contexts/UiContext'
import { SiteContextData, withSiteContext } from '../../contexts/SiteContext'
import { renderErrorAlert } from '../../misc/utils'
import { SiteMatch } from 'components/SiteDetails'
import { getSiteClient } from '../../../api/client'
import SensorBatteryLevelChart from './SensorBatteryLevelChart'
import { BreadcrumbsWithSiteLink } from 'components/misc/BreadcrumbsWithSiteLink'
import { GatewayResponse } from '../../../api/apiservice'

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
}

interface State extends ModelState {
  readonly sensor?: DeviceData
  readonly gateway?: GatewayResponse
}

export interface DeviceMatch extends SiteMatch {
  readonly deviceId: string
  readonly telemetryType: string
}

type AllProps = PropsFromState &
  RouteComponentProps<DeviceMatch> &
  UiContextData &
  SiteContextData

class DeviceDetails extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = { loading: false }
  }

  public componentDidMount() {
    this.load()
  }

  public componentDidUpdate({
    match: {
      params: { id, deviceId, telemetryType },
    },
  }: AllProps) {
    const { params } = this.props.match

    if (
      id !== params.id ||
      deviceId !== params.deviceId ||
      telemetryType !== params.telemetryType
    ) {
      this.load()
    }
  }

  public render() {
    const { loading, error } = this.state
    const {
      match: {
        params: { id, telemetryType },
      },
    } = this.props

    if (loading) {
      return <LinearProgress />
    }

    if (error) {
      renderErrorAlert(error)
    }

    return (
      <Grid container={true} alignContent="flex-start">
        <Grid item={true} xs={12}>
          {this.renderBackBtn()}
        </Grid>
        <SensorBatteryLevelChart
          sensor={this.state.sensor}
          siteId={id}
          telemetryType={telemetryType}
          gateway={this.state.gateway}
        />
      </Grid>
    )
  }

  private readonly renderBackBtn = () => {
    return (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={() => this.props.history.goBack()}
      >
        Back
      </Button>
    )
  }

  private async getGateways(accessToken, tenantId, siteId) {
    const apiGateways = getGatewaysClient(accessToken, tenantId.toString())
    const { result } = await apiGateways.getGateways(siteId)
    const gateways = result.map((t) => ({ ...t.toJSON() } as GatewayResponse))

    return gateways
  }

  private async load() {
    const {
      accessToken,
      tenantId,
      match: {
        params: { id, deviceId, telemetryType },
      },
    } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const gateways = await this.getGateways(
      accessToken,
      tenantId.toString(),
      id
    )

    this.setState({ gateway: gateways.filter((e) => e.id?.match(deviceId))[0] })

    try {
      this.setState({ loading: true })

      const api = getSensorClient(accessToken, tenantId.toString())
      const { result: sensors } = await api.getSensors(id, [deviceId])
      const siteApi = getSiteClient(accessToken, tenantId.toString())
      const { result: site } = await siteApi.get(id, tenantId)
      const title = (
        <BreadcrumbsWithSiteLink
          siteId={id}
          siteName={site.name}
          secondLabel={deviceId}
        />
      )

      this.props.setPageTitle!(title)

      this.setState({
        sensor: sensors[0],
        error: undefined,
      })
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }
}

const mapPropsFromState = ({ oidc, multitenantUser }: AppState) => ({
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

export default connect(mapPropsFromState)(
  withUiContext(withSiteContext(DeviceDetails))
)
