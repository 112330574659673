import React from 'react'
import { connect } from 'react-redux'
import flat from 'array.prototype.flat'

import { Paper, Theme, PaperProps } from '@material-ui/core'
import { WithStyles, createStyles, withStyles } from '@material-ui/styles'
import {
  ClusterIconStyle,
  MarkerExtended,
  ClusterIconInfo,
} from '@react-google-maps/marker-clusterer'

import { withSiteContext, SiteContextData } from '../contexts/SiteContext'
import { Site } from '../../store/Site'
import { getStatusLensColor } from '../utils'
import Map, { Place } from '../map/Map'
import SiteInfoWindow from './SiteInfoWindow'
import { TelemetryStatus } from 'api/alertservice'

const clusterSizes = [60, 72, 84, 96, 108, 120]

const clusterStyles: ClusterIconStyle[] = flat(
  Object.values(TelemetryStatus)
    .filter((s) => typeof s === 'number')
    .map((status) => {
      const s = status as TelemetryStatus
      const color = getStatusLensColor(s)

      return clusterSizes.map((width) => {
        const height = width
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="${color}" opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8z"/><path fill="${color}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/><circle stroke="black" fill="none" stroke-width="1%" cx="12" cy="12" r="10"/></svg>`
        const url = `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`
        const textSize = 11 + (width / 12 - 3) * 4

        return { width, height, url, textSize }
      })
    })
)

const svgHighlightFilter = (color) =>
  `<filter id="glow" x="-5000%" y="-5000%" width="10000%" height="10000%">
    <feFlood result="flood" flood-color="${color}" flood-opacity="1"/>
    <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"/>
    <feMorphology in="mask" result="dilated" operator="dilate" radius="4"/>
    <feGaussianBlur in="dilated" result="blurred" stdDeviation="4"/>
    <feComponentTransfer in="SourceGraphic" result="noAlphaSource">
      <feFuncA type="discrete" tableValues="0 1 1 1"/>
    </feComponentTransfer>
    <feComposite in="blurred" result="blurred_mask" in2="noAlphaSource" operator="out"/>
    <feMerge>
      <!--feMergeNode in="noAlphaSource"/-->
      <feMergeNode in="blurred_mask"/>
      <feMergeNode in="SourceGraphic"/>
    </feMerge>
  </filter>`

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
  })

export interface Props {
  readonly mySites: Site[]
  readonly statuses?: { siteId: string; status: number }[]
  readonly disableClustering?: boolean
}

type AllProps = Props &
  WithStyles<typeof styles> &
  SiteContextData & {
    readonly paperProps?: Partial<PaperProps>
  }

interface State {
  readonly isInfoOpen: boolean
}

class SitesMap extends React.Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = { isInfoOpen: false }
  }

  public render() {
    return (
      <Paper {...this.props.paperProps} className={this.props.classes.root}>
        {this.renderMap()}
      </Paper>
    )
  }

  private renderMap() {
    const places: Place[] = this.props.mySites.map((d) => {
      const status =
        this.props.statuses?.find((x) => x.siteId === d.id)?.status ?? 0

      return {
        id: d.id,
        pos: {
          lat: d.latitude,
          lng: d.longitude,
        },
        title: `${d.name} @ ${d.latitude}, ${d.longitude}`,
        payload: d,
        status: !status ? TelemetryStatus.Green : status,
      }
    })

    return (
      <Map
        places={places}
        selectedPlaceId={this.props.selectedSiteId}
        infoWindow={this.getInfoWindow}
        getMarkerIcon={this.getMarkerIcon}
        clusterStyles={clusterStyles}
        clusterCalculator={this.clusterCalculator}
        onMarkerClick={this.onMarkerClick}
        disableClustering={this.props.disableClustering}
      />
    )
  }

  private readonly getInfoWindow = (_, { payload }: Place) => {
    const selectedSite = payload as Site

    return <SiteInfoWindow site={selectedSite} />
  }

  private readonly getMarkerIcon = (place: Place): google.maps.Icon => {
    const color = getStatusLensColor(place.status)
    const highlighted = place.id === this.props.selectedSiteId
    const width = highlighted ? 72 : 36
    const height = width
    const defs = highlighted ? `<defs>${svgHighlightFilter(color)}</defs>` : ''
    const filter = highlighted ? 'filter="url(#glow)"' : ''
    const viewBox = highlighted ? '-12 -12 48 48' : '0 0 24 24'
    const highlight = highlighted
      ? '<circle stroke="gray" cx="12" cy="9" r="2.5"/><path stroke="gray" fill="none" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"/>'
      : ''
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}" style="fill: ${color}">
        ${defs}
        <g ${filter}>
          <path fill="none" d="M0 0h24v24H0V0z"/>
          <path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
          <circle cx="12" cy="9" r="2.5"/>
        </g>
        ${highlight}
      </svg>`

    return {
      url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`,
      scaledSize: new google.maps.Size(width, height),
      anchor: highlighted ? new google.maps.Point(36, 56) : undefined,
    }
  }

  private readonly clusterCalculator = (
    markers: MarkerExtended[],
    _: number
  ): ClusterIconInfo => {
    const places = markers
      .map((m) => (m as any).payload as Place)
      .filter((p) => p)
    const status = TelemetryStatus.Undefined
    const partition = TelemetryStatus[TelemetryStatus[status]]
    const partitionOffset = partition * clusterSizes.length
    const clusterSizeIndex =
      Math.ceil(
        (clusterSizes.length * markers.length) / this.props.mySites.length
      ) - 1
    const index = partitionOffset + clusterSizeIndex + 1

    return {
      index,
      text: markers.length.toLocaleString(),
      title: places
        .map((p) => p.payload as Site)
        .filter((d) => d)
        .map((d) => d.name || d.id)
        .join('\n'),
    }
  }

  private readonly onMarkerClick = (_: boolean, selectedPlaceId?: string) => {
    this.props.setSelectedSiteId!(false, selectedPlaceId)
  }
}

export default connect()(withStyles(styles)(withSiteContext(SitesMap)))
