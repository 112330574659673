import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { DragIndicator } from '@material-ui/icons'
import { IntermittentOrderDto } from 'api/apiservice'

const useStyles = makeStyles({
  draggingListItem: {
    background: '#ebebeb',
    border: '1px solid #ebebeb',
  },
  notDraggingListItem: {
    border: '1px solid #ebebeb',
    marginBottom: 5,
  },
})

export interface DraggableListItemProps {
  isValidRole: boolean
  item: IntermittentOrderDto
  index: number
}

export const DraggableListItem = ({
  item,
  index,
  isValidRole,
}: DraggableListItemProps) => {
  const classes = useStyles()
  return (
    <Draggable
      draggableId={item.wellId}
      index={index}
      isDragDisabled={!isValidRole}
    >
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            snapshot.isDragging
              ? classes.draggingListItem
              : classes.notDraggingListItem
          }
        >
          <DragIndicator />
          <ListItemText
            key={`${item.siteName}-${item.wellName}`}
            primary={`${item.siteName}-${item.wellName}`}
          />
        </ListItem>
      )}
    </Draggable>
  )
}
