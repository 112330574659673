/* eslint-disable */
import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Typography,
} from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'

import { Site } from 'store/Site'
import { TitleAndSubtitle } from 'components/misc/TitleAndSubtitle'
import { ModelState } from 'store/ModelState'
import { connect } from 'react-redux'
import { AppState } from 'store/AppState'
import { getSiteClient } from 'api/client'
import { Color } from '@material-ui/lab'

import {
  hasSupervisorUserAccessLevel,
  isAdmin,
  isSystemAdmin,
} from 'store/oidc/userManager'
import { MultitenantUserState } from '../../../store/reducers/multitenant'
import { SiteOwnerApiModel, SwaggerException } from 'api/apiservice'
import { SaveTwoTone } from '@material-ui/icons'

const styles = () =>
  createStyles({
    dialogPaperWidthSm: {
      maxWidth: 'unset',
      minWidth: '80vh',
    },
    assetForm: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  })

interface ComponentState<T> extends ModelState {
  readonly isEdited: boolean
  readonly isFirstTime: boolean
  readonly siteUpdating: boolean
  readonly superintendent: string | undefined
  readonly foreman: string | undefined
  readonly pumper: string | undefined
}

interface Props {
  // loadSite(): void
  selectedSite?: Site
  closeSiteInfoDialog(): void
  showSiteUpdateNotification(updateResult?: Color): void
  showSuperIntendent: boolean | undefined
}
interface PropsFromState {
  readonly user: MultitenantUserState
  readonly accessToken?: string
  readonly tenantId: number
}

type AllProps = Props & WithStyles<typeof styles> & PropsFromState

class SiteInfoDialog<TSubState = {}> extends React.Component<
  AllProps,
  ComponentState<TSubState>
> {
  constructor(props: AllProps) {
    super(props)
    this.state = {
      // formAsset: this.props.asset,
      isEdited: false,
      isFirstTime: false,
      siteUpdating: false,
      superintendent: undefined,
      foreman: undefined,
      pumper: undefined,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  isSystemAdmin = isSystemAdmin(this.props.user.selectedTenant?.roles)
  isAdmin = isAdmin(this.props.user.selectedTenant?.roles)
  validRole = hasSupervisorUserAccessLevel(
    this.props.user.selectedTenant?.roles
  )

  superintendent: string | undefined
  foreman: string | undefined
  pumper: string | undefined

  site = this.props.selectedSite!
  siteAPI = getSiteClient(
    this.props.accessToken!,
    this.props.tenantId.toString()
  )

  formRef = React.createRef<ValidatorForm>()
  private handleInputChange(value, id) {
    switch (id) {
      case 'Superintendent':
        this.setState({
          superintendent: value,
          isEdited: true,
        })
        break
      case 'Foreman':
        this.setState({
          foreman: value,
          isEdited: true,
        })
        break
      case 'Pumper/Route':
        this.setState({
          pumper: value,
          isEdited: true,
        })
        break
    }
  }

  private createData(name: string, value: string) {
    return { name, value }
  }

  private updateSite() {
    try {
      this.setState({
        siteUpdating: true,
      })

      let siteOwnerApiModel: SiteOwnerApiModel = {
        siteId: this.site.id,
        superintendent: this.state.superintendent?.trim(),
        foreman: this.state.foreman?.trim(),
        pumper: this.state.pumper?.trim(),
      }

      if (!this.props.showSuperIntendent) {
        siteOwnerApiModel.superintendent = ''
      }

      if (this.state.isFirstTime) {
        // Insert
        this.siteAPI
          .addSiteOwner(siteOwnerApiModel, this.props.tenantId)
          .then((result) => {
            this.props.showSiteUpdateNotification('success')
          })
      } else {
        // Edit
        this.siteAPI
          .updateSiteOwner(siteOwnerApiModel, this.props.tenantId)
          .then((result) => {
            this.props.showSiteUpdateNotification('success')
          })
      }
    } catch (error) {
      this.setState({
        siteUpdating: false,
      })
      this.props.showSiteUpdateNotification('error')
      console.error(error)
    }
  }

  componentDidMount(): void {
    try {
      this.siteAPI.getSiteOwners(this.site.id).then((owners) => {
        if (
          owners.result.superintendent === null ||
          (owners.result.superintendent?.trim() === '' &&
            owners.result.foreman === null) ||
          (owners.result.foreman?.trim() === '' &&
            owners.result.pumper === null) ||
          owners.result.pumper?.trim() === ''
        ) {
          this.setState({
            isFirstTime: true,
          })
        } else {
          this.setState({
            superintendent: owners.result.superintendent,
            foreman: owners.result.foreman,
            pumper: owners.result.pumper,
            isFirstTime: false,
          })
        }
      })
    } catch (error) {
      if (error instanceof SwaggerException) {
        this.setState({
          superintendent: undefined,
          foreman: undefined,
          pumper: undefined,
          isFirstTime: true,
        })
      } else {
        console.error(error)
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.beginUpdate()
  }

  handleConfirmation() {
    this.setState({
      siteUpdating: true,
    })
    this.beginUpdate()
  }

  private beginUpdate() {
    this.updateSite()
  }

  private isFormValid() {
    return this.formRef.current?.isFormValid(false)
  }

  private renderFields() {
    const rowsLocation = [
      this.createData(
        'State',
        this.site.state === undefined || this.site.state === ''
          ? 'W/o state'
          : this.site.state
      ),
      this.createData(
        'County',
        this.site.county === undefined || this.site.county === ''
          ? 'W/o county'
          : this.site.county
      ),
      this.createData(
        'Region',
        this.site.region === undefined || this.site.region === ''
          ? 'W/o region'
          : this.site.region
      ),
      this.createData(
        'Field',
        this.site.field === undefined || this.site.field === ''
          ? 'W/o field'
          : this.site.field
      ),
    ]

    return (
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rowsLocation.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {this.renderFieldsAssignation()}
        </Grid>
      </Grid>
    )
  }

  private renderFieldsAssignation() {
    const section = {
      height: '100%',
      paddingTop: 15,
    }
    let rowsAssignation: { name: string; value: string }[] = []

    if (this.props.showSuperIntendent) {
      rowsAssignation = [
        this.createData('Superintendent', this.state.superintendent ?? ''),
        this.createData('Foreman', this.state.foreman ?? ''),
        this.createData('Pumper/Route', this.state.pumper ?? ''),
      ]
    } else {
      rowsAssignation = [
        this.createData('Foreman', this.state.foreman ?? ''),
        this.createData('Pumper/Route', this.state.pumper ?? ''),
      ]
    }

    if (this.isAdmin || this.isSystemAdmin) {
      return (
        <ValidatorForm
          ref={this.formRef}
          onSubmit={this.handleSubmit}
          instantValidate={true}
        >
          {rowsAssignation.map((row) => (
            // eslint-disable-next-line react/jsx-key
            <div style={section} key={row.name}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h6">
                    {row.name}
                  </Typography>
                  <TextField
                    required
                    inputProps={{ maxLength: 20 }}
                    fullWidth={true}
                    value={row.value}
                    onChange={(e) =>
                      this.handleInputChange(e.target.value, row.name)
                    }
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </ValidatorForm>
      )
    } else {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {rowsAssignation.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  {row.value}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
  }

  public render() {
    return (
      <>
        <Dialog
          disableEscapeKeyDown={true}
          open={true}
          classes={{
            paperWidthSm: this.props.classes.dialogPaperWidthSm,
          }}
        >
          <DialogContent dividers={true}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12,
                }}
              >
                <TitleAndSubtitle
                  title={`${this.site.name}`}
                  subtitle={`ID: ${this.site.id}`}
                />
              </div>
              {this.renderFields()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.siteUpdating}
              onClick={this.props.closeSiteInfoDialog}
              color="primary"
            >
              Close
            </Button>
            {this.validRole && (
              <Button
                disabled={
                  this.props.showSuperIntendent
                    ? !this.state.isEdited ||
                      this.state.siteUpdating ||
                      this.state.superintendent === undefined ||
                      this.state.foreman === undefined ||
                      this.state.pumper === undefined ||
                      this.state.superintendent?.trim() === '' ||
                      this.state.foreman?.trim() === '' ||
                      this.state.pumper?.trim() === ''
                    : !this.state.isEdited ||
                      this.state.siteUpdating ||
                      this.state.foreman === undefined ||
                      this.state.pumper === undefined ||
                      this.state.foreman?.trim() === '' ||
                      this.state.pumper?.trim() === ''
                }
                style={{ width: '120px' }}
                variant="contained"
                color="primary"
                startIcon={<SaveTwoTone />}
                form="siteInfoForm"
                onClick={this.handleSubmit}
              >
                {this.state.siteUpdating ? 'Saving' : 'Save'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapPropsFromState = ({
  oidc: { user },
  multitenantUser,
}: AppState): PropsFromState => ({
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  user: multitenantUser,
})

export default connect(mapPropsFromState)(withStyles(styles)(SiteInfoDialog))
