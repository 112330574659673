import * as React from 'react'
import { styled } from '@material-ui/core/styles'
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { NotificationImportantTwoTone as MyAlertsIcon } from '@material-ui/icons'
import BuildSharpIcon from '@material-ui/icons/BuildTwoTone'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Typography from '@material-ui/core/Typography'
import { CompressorOrder } from '../compressorOrder'
import { AlertsList } from '../alertLists/alertLists'
import {
  IntermittentAlertDto,
  IntermittentIgnitionDto,
  SiteApiModel,
  IntermittentSetPointDto,
  CompressorDeviceApiModel,
  TimerSetpointDto,
} from 'api/apiservice'
import { TimeRange } from 'components/misc/TimeRange'
import { WellFilter } from '../wellFilter/wellFilter'
import TimerSetpoints from '../timerSetpoints/timerSetpoints'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: 25,
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon color="primary" />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4),
  alignContent: 'flex-start',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  display: 'inherit',
}))

interface Props {
  isValidRole: boolean
  ShowHideTimeRangePicker: any
  alerts: IntermittentAlertDto[]
  ignitionOrder: IntermittentIgnitionDto
  saveIgnitionOrder: any
  readonly timeRange: TimeRange
  wells: SiteApiModel['wells'][] | undefined
  siteId: string
  setPoints: IntermittentSetPointDto | undefined
  timerSetPoint: TimerSetpointDto | undefined
  WellSelectedForSetPoints: any
  WellSelectedForTimerSetPoint: any
  SaveSetPoints: any
  SaveTimerSetPoints: any
  compressorDevice: CompressorDeviceApiModel | undefined
}

type AllProps = Props

export default function CustomizedAccordions(props: AllProps) {
  const [expanded, setExpanded] = React.useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    props.ShowHideTimeRangePicker(panel, newExpanded)
  }

  const wellsFlattened = props.wells?.flatMap((w) => w)

  return (
    <div>
      <Accordion
        expanded={expanded === 'alertPanel'}
        onChange={handleChange('alertPanel')}
      >
        <AccordionSummary
          aria-controls="alertPanel-content"
          id="alertPaneld-header"
        >
          <MyAlertsIcon color="primary" />
          <Typography style={{ fontWeight: 'bold' }} color="primary">
            {' '}
            ALERTS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AlertsList timeRange={props.timeRange} alerts={props.alerts} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'setPointsPanel'}
        onChange={handleChange('setPointsPanel')}
      >
        <AccordionSummary
          aria-controls="setPointsPaneld-content"
          id="setPointsPaneld-header"
        >
          <BuildSharpIcon color="primary" />
          <Typography style={{ fontWeight: 'bold' }} color="primary">
            {' '}
            SETPOINTS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WellFilter
            isValidRole={props.isValidRole}
            siteId={props.siteId}
            wells={wellsFlattened}
            setPoints={props.setPoints}
            WellSelectedForSetPoints={props.WellSelectedForSetPoints}
            SaveSetPoints={props.SaveSetPoints}
            compressorDevice={props.compressorDevice}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'compressorPanel'}
        onChange={handleChange('compressorPanel')}
      >
        <AccordionSummary
          aria-controls="compressorPaneld-content"
          id="compressorPaneld-header"
        >
          <PowerSettingsNewIcon color="primary" />
          <Typography style={{ fontWeight: 'bold' }} color="primary">
            {' '}
            COMPRESSOR
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CompressorOrder
            isValidRole={props.isValidRole}
            ignitionOrder={props.ignitionOrder}
            saveIgnitionOrder={props.saveIgnitionOrder}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'timerSetPointsPanel'}
        onChange={handleChange('timerSetPointsPanel')}
      >
        <AccordionSummary
          aria-controls="timerSetPointsPanel-content"
          id="timerSetPointsPanel-header"
        >
          <AccessTimeIcon color="primary" />
          <Typography style={{ fontWeight: 'bold' }} color="primary">
            {' '}
            TIMER SETPOINTS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TimerSetpoints
            isValidRole={props.isValidRole}
            siteId={props.siteId}
            wells={wellsFlattened}
            SaveTimerSetPoints={props.SaveTimerSetPoints}
            WellSelectedForTimerSetPoint={props.WellSelectedForTimerSetPoint}
            timerSetPoint={props.timerSetPoint}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
