import React from 'react'
import moment from 'moment'
import { UserState } from 'redux-oidc'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AxisDomain,
  ReferenceArea,
  Line,
  LineChart,
  Legend,
  LabelList,
  Dot,
  TooltipProps,
  LegendProps,
  LegendPayload,
} from 'recharts'
import { Props as DotProps } from 'recharts/src/shape/Dot'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Tooltip as MuiTooltip,
  Typography,
} from '@material-ui/core'
import { getStatusLensColor } from '../utils'
import { HistoricalDataEntry, updateTelemetryDatapoint } from '../../api/client'
import {
  TimeRange,
  getTimeRange,
  getTimeRangeDuration,
} from '../misc/TimeRange'
import theme from '../../theme'
import { blue } from '@material-ui/core/colors'
import { StatusRange } from 'api/alertservice'
import { AppState } from '../../store/AppState'
import { MultitenantUserState } from 'store/reducers/multitenant'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import InvalidDatapointDialog from '../details/InvalidData/InvalidDatapoint'
import { TelemetryType } from '../../api/apiservice'

import {
  hasSupervisorUserAccessLevel,
  isAdmin,
  isSystemAdmin,
} from 'store/oidc/userManager'

const referenceColor = blue.A700

const styles = (_: Theme) =>
  createStyles({
    container: {
      height: 235,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '40vw',
      },
      display: 'grid',
      alignItems: 'start',
    },
    responsiveContainer: {
      gridColumn: 1,
      gridRow: 1,
    },
  })

const chartRefreshRateMs = moment.duration(1, 'second').asMilliseconds()

export interface Reference {
  readonly value: number
  readonly from: Date
  readonly to: Date
}

enum LegendState {
  Show = 1,
  BringToFront = 2,
  Hide = 3,
}

const maxReferences = 3

export interface Props {
  readonly data: HistoricalDataEntry[]
  readonly formatValue?: (value?: any, addValue?: string) => React.ReactNode
  readonly formatRawValue?: (value?: any) => React.ReactNode
  readonly yAxisDomain?: [AxisDomain, AxisDomain]
  readonly timeRange: TimeRange
  readonly statusRanges: StatusRange[]
  readonly fullWidth?: boolean
  readonly style?: React.CSSProperties
  readonly references?: Reference[]
  readonly referenceName?: string
  readonly updateReferences?: (timeRange: TimeRange) => void
  readonly user?: MultitenantUserState
  readonly updateState: (dataModified, hiddenData) => string[]
  hideData: string[]
}

interface PropsFromStateUser {
  readonly accessToken?: string
  readonly tenantId: number
  readonly email: string
}

type PropsFromState = Pick<UserState, 'user'>

type AllProps = Props &
  WithStyles<typeof styles> &
  PropsFromState &
  PropsFromStateUser

interface State {
  readonly xAxisDomain: [number, number]
  readonly legendState: LegendState
  readonly showTooltipCursor?: boolean
  readonly isUserSystemAdmin: boolean
  readonly currData?: HistoricalDataEntry
  readonly showModal: boolean
  hoverIsActive: boolean
  gasFlowLegendValues: GasFlowGraphValues
  hiddenData: string[]
  lastClickTime: number
  lastDatapointClicked: any
}

interface ResultPlusdataKey extends LegendPayload {
  dataKey?: any
}

interface GasFlowGraphValues {
  volumeFlowRate: string
  todayVolumeFlowed: string
  yesterdayVolumeFlowed: string
  staticPressure: string
}

let hoverLock = true
setInterval(() => {
  hoverLock = true
}, 250)

class SensorChart extends React.Component<AllProps, State> {
  private timer?: NodeJS.Timeout

  constructor(props) {
    super(props)

    this.state = {
      xAxisDomain: this.xAxisDomain,
      legendState: LegendState.Show,
      isUserSystemAdmin: false,
      hoverIsActive: false,
      gasFlowLegendValues: {
        volumeFlowRate: '',
        todayVolumeFlowed: '',
        yesterdayVolumeFlowed: '',
        staticPressure: '',
      },
      showModal: false,
      currData: undefined,
      hiddenData: this.props.hideData,
      lastClickTime: 0,
      lastDatapointClicked: undefined,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  isSystemAdmin = isSystemAdmin(this.props.user.selectedTenant?.roles)
  isAdmin = isAdmin(this.props.user.selectedTenant?.roles)
  validRole = hasSupervisorUserAccessLevel(
    this.props.user.selectedTenant?.roles
  )

  public openModal = (currentData) => {
    if (currentData !== undefined) {
      this.setState({
        currData: currentData,
        showModal: true,
      })
    }
  }

  public closeModal = (id: string) => (ok: boolean) => {
    if (ok) {
      const currData = this.state.currData
      this.setValidDatapoint(
        currData?.id,
        currData?.telemetryType,
        currData?.invalidData,
        currData
      )
    } else {
      this.setState({
        showModal: false,
        currData: undefined,
      })
    }
  }

  async setValidDatapoint(
    id: any,
    telemetryType: TelemetryType | undefined,
    invalidData: boolean | undefined,
    currentData: HistoricalDataEntry | undefined
  ): Promise<void> {
    const { accessToken, tenantId, email } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const result = await updateTelemetryDatapoint(
      accessToken,
      tenantId,
      telemetryType,
      invalidData,
      email,
      id
    ) // .then((result) => {
    let currState: string[] = []
    if (result) {
      const state = currentData?.invalidData!
      const itemFound = this.state.hiddenData.findIndex((t) => t === id)
      if (itemFound !== undefined && itemFound !== -1) {
        currState = this.props.updateState(itemFound, state)
      } else {
        currState = this.props.updateState(id, true)
      }
    }
    this.setState({
      showModal: false,
      currData: undefined,
      hiddenData: currState,
    })
    // })
  }

  public componentDidMount() {
    const isUserSystemAdmin = this.props.user?.selectedTenant?.roles
      ? isSystemAdmin(this.props.user?.selectedTenant?.roles)
      : false
    this.timer = setInterval(
      () =>
        this.setState({
          ...this.state,
          isUserSystemAdmin,
          xAxisDomain: this.xAxisDomain,
        }),
      chartRefreshRateMs
    )
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  public handleClick = (props) => {
    const currentTime = new Date().getTime()
    const lastClickTime = this.state.lastClickTime
    const diff = currentTime - lastClickTime

    const datapoint = props?.activePayload?.length
      ? props.activePayload[0]?.payload
      : undefined
    if (diff < 300 && datapoint === this.state.lastDatapointClicked) {
      this.openModal(datapoint)
    }
    this.setState({
      lastClickTime: currentTime,
      lastDatapointClicked: datapoint,
    })
  }

  public render() {
    const { classes, fullWidth, style, references } = this.props
    const divStyle: React.CSSProperties | undefined = fullWidth
      ? { ...style, width: '100%' }
      : style
    const referencesPresent = references && references.length > 0

    return (
      <div className={classes.container} style={divStyle}>
        <ResponsiveContainer className={classes.responsiveContainer}>
          {this.renderChart()}
        </ResponsiveContainer>
        {referencesPresent && this.renderLegend()}
        {this.state.showModal && (
          <InvalidDatapointDialog
            currData={this.state.currData}
            onClose={this.closeModal(this.state.currData?.id)}
          />
        )}
      </div>
    )
  }

  public getHiddenData(historicalData): string[] {
    const invalidIds = historicalData
      .filter((x) => x.invalidData)
      .map((x) => x.id)
    return invalidIds
  }

  private hideHistoricalData(
    allHistoricalData,
    hiddenData
  ): HistoricalDataEntry[] {
    if (allHistoricalData !== undefined) {
      allHistoricalData.forEach((element) => {
        if (hiddenData !== undefined) {
          if (hiddenData.length !== 0) {
            if (hiddenData.some((x) => x === element.id)) {
              element.invalidData = true
            } else {
              element.invalidData = false
            }
          }
        }
      })
    }
    return allHistoricalData
  }

  private renderChart() {
    const { yAxisDomain, references, statusRanges: thresholds } = this.props
    const { xAxisDomain, legendState } = this.state
    const [from, to] = xAxisDomain
    const duration = to - from
    const tickCount = moment.duration(duration).asHours() < 12 ? 5 : 4
    const tickIncrement = duration / (tickCount - 1)
    const ticks = [...Array(tickCount).keys()].map(
      (i) => from + i * tickIncrement
    )

    let historicalDataGasFlow: HistoricalDataEntry[] = []

    historicalDataGasFlow = this.hideHistoricalData(
      this.props.data,
      this.state.hiddenData
    )

    const dataflow = historicalDataGasFlow.filter((d) => !d.invalidData)

    const [yMinValue, yMaxValue, isBarreled] =
      this.getMaxValueForBarrelPerDay(references)

    if (this.isGasFlow(dataflow)) {
      const lineComponents = [
        {
          name: 'Volume Flow Rate',
          dataKey: 'value',
          color: '#f27a54',
        },
        {
          name: 'Today Volume Flowed',
          dataKey: 'todayVolumeFlowed',
          color: '#414042',
        },
        {
          name: 'Yesterday Volume Flowed',
          dataKey: 'yesterdayVolumeFlowed',
          color: '#5499c7',
        },
        {
          name: 'Static Pressure',
          dataKey: 'staticPressure',
          color: '#7d3c98',
        },
      ]

      return (
        <LineChart
          {...this.state}
          data={historicalDataGasFlow}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseLeave={() => {
            this.setState({ hoverIsActive: false })
          }}
          onMouseMove={(props) => {
            const payload = props?.activePayload?.length
              ? props.activePayload[0]?.payload
              : undefined

            const [min, max] = this.xAxisDomain

            const showTooltipCursor =
              payload?.timestamp >= min && payload?.timestamp <= max

            this.setState({ showTooltipCursor })
          }}
        >
          {this.renderThresholds(thresholds)}

          {lineComponents.map((item, key) => {
            if (item.dataKey === 'value') {
              return (
                <Line
                  key={key}
                  name="Volume Flow Rate"
                  data={historicalDataGasFlow}
                  type="monotone"
                  dataKey="value"
                  stroke={theme.palette.primary.main}
                  strokeOpacity={
                    legendState === LegendState.BringToFront ? 0.3 : undefined
                  }
                  strokeWidth={2}
                  isAnimationActive={dataflow?.length > 1}
                  isUpdateAnimationActive={true}
                  activeDot={(props: DotProps) => {
                    const [min, max] = this.xAxisDomain
                    const timestamp = props?.payload?.timestamp

                    return timestamp >= min && timestamp <= max ? (
                      <Dot {...props} />
                    ) : (
                      false
                    )
                  }}
                />
              )
            }
            return null
          })}
          {lineComponents.map((item, key) => {
            if (item.dataKey !== 'value') {
              return (
                <Line
                  key={key}
                  type="monotone"
                  name={item.name}
                  dataKey={item.dataKey}
                  stroke={item.color}
                />
              )
            }
            return null
          })}

          <Tooltip
            content={memoizeOne(this.TooltipGasFlowComponent.bind(this))}
            cursor={this.state.showTooltipCursor}
          />
          {this.renderSimpleLegend()}

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            key={`${from.toString()} ${to.toString()}`}
            dataKey="timestamp"
            type={'number'}
            tickFormatter={this.toTime}
            domain={xAxisDomain}
            allowDataOverflow={!false}
            ticks={ticks}
          />
          <YAxis
            key={`${from.toString()}y ${to.toString()}y`}
            domain={!isBarreled ? yAxisDomain : [yMinValue, yMaxValue]}
            allowDecimals={false}
            allowDataOverflow={false}
          />

          {this.renderReferences(references)}
        </LineChart>
      )
    }

    if (this.isAdmin) {
      return (
        <LineChart
          {...this.state}
          data={dataflow}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={this.handleClick}
        >
          {this.renderThresholds(thresholds)}
          {this.renderReferences(references)}
          <Line
            data={dataflow}
            type="monotone"
            dataKey="value"
            stroke={theme.palette.primary.main}
            strokeOpacity={
              legendState === LegendState.BringToFront ? 0.3 : undefined
            }
            strokeWidth={2}
            isAnimationActive={false}
            isUpdateAnimationActive={true}
            activeDot={(props: DotProps) => {
              const [min, max] = this.xAxisDomain
              const timestamp = props?.payload?.timestamp

              return timestamp >= min && timestamp <= max ? (
                <Dot {...props} />
              ) : (
                false
              )
            }}
          />
          <Tooltip
            content={this.renderTooltipContent}
            cursor={this.state.showTooltipCursor}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            key={`${from.toString()} ${to.toString()}`}
            dataKey="timestamp"
            type="number"
            tickFormatter={this.toTime}
            domain={xAxisDomain}
            allowDataOverflow={!false}
            ticks={ticks}
          />
          <YAxis
            key={`${from.toString()}y ${to.toString()}y`}
            domain={!isBarreled ? yAxisDomain : [yMinValue, yMaxValue]}
            allowDecimals={false}
            allowDataOverflow={false}
          />
          {this.renderReferences(references)}
        </LineChart>
      )
    } else {
      return (
        <LineChart
          {...this.state}
          data={dataflow}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {this.renderThresholds(thresholds)}
          {this.renderReferences(references)}
          <Line
            data={dataflow}
            type="monotone"
            dataKey="value"
            stroke={theme.palette.primary.main}
            strokeOpacity={
              legendState === LegendState.BringToFront ? 0.3 : undefined
            }
            strokeWidth={2}
            isAnimationActive={false}
            isUpdateAnimationActive={true}
            activeDot={(props: DotProps) => {
              const [min, max] = this.xAxisDomain
              const timestamp = props?.payload?.timestamp

              return timestamp >= min && timestamp <= max ? (
                <Dot {...props} />
              ) : (
                false
              )
            }}
          />
          <Tooltip
            content={this.renderTooltipContent}
            cursor={this.state.showTooltipCursor}
          />
          {this.renderSimpleLegend()}

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            key={`${from.toString()} ${to.toString()}`}
            dataKey="timestamp"
            type="number"
            tickFormatter={this.toTime}
            domain={xAxisDomain}
            allowDataOverflow={!false}
            ticks={ticks}
          />
          <YAxis
            key={`${from.toString()}y ${to.toString()}y`}
            domain={!isBarreled ? yAxisDomain : [yMinValue, yMaxValue]}
            allowDecimals={false}
            allowDataOverflow={false}
          />
          {this.renderReferences(references)}
        </LineChart>
      )
    }
  }

  private TooltipGasFlowComponent({ active, payload }: TooltipProps) {
    let time = ''
    if (!payload || !payload.length) {
      return null
    }

    const item = payload.find((p) => p.dataKey === 'value')

    const legenedValues: GasFlowGraphValues = {
      volumeFlowRate: '',
      todayVolumeFlowed: '',
      yesterdayVolumeFlowed: '',
      staticPressure: '',
    }

    if (active) {
      const {
        timestamp,
        value,
        todayVolumeFlowed,
        yesterdayVolumeFlowed,
        staticPressure,
      } = item.payload || {}
      const formattedTimestamp = this.toTimeWithSeconds(timestamp, false)
      time = formattedTimestamp
      if (hoverLock) {
        hoverLock = false
        legenedValues.volumeFlowRate = value
        legenedValues.todayVolumeFlowed = todayVolumeFlowed
        legenedValues.yesterdayVolumeFlowed = yesterdayVolumeFlowed
        legenedValues.staticPressure = staticPressure
        if (!this.state.hoverIsActive) {
          this.setState({ hoverIsActive: true })
        }
        if (this.state.gasFlowLegendValues !== legenedValues) {
          this.setState({ gasFlowLegendValues: legenedValues })
        }
      }
    }

    const formattedTimestamp = this.toTimeWithSeconds(time, false)
    const color = theme.palette.primary.main

    return (
      <div
        style={{
          color,
          backgroundColor: 'white',
          border: `1px solid ${color}`,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <i>{formattedTimestamp}</i>
      </div>
    )
  }

  private getMaxValueForBarrelPerDay(inReferences): [number, number, boolean] {
    let yMax = 0
    let isTrue = false
    if (
      inReferences !== undefined &&
      this.props.referenceName === 'Barrels per day'
    ) {
      isTrue = true
      yMax = Math.max(
        ...inReferences.map(function (o) {
          return Math.round(parseFloat(o.value) + parseFloat(o.value) * 0.1)
        })
      )
    }
    return [0, yMax, isTrue]
  }

  private legendCustomComponent(props: LegendProps) {
    const { payload } = props

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {payload?.map((item: ResultPlusdataKey, index) => {
          let result = '0'

          const {
            volumeFlowRate,
            todayVolumeFlowed,
            yesterdayVolumeFlowed,
            staticPressure,
          } = this.state.gasFlowLegendValues || {}

          switch (item.dataKey) {
            case 'value':
              result = this.state.hoverIsActive ? volumeFlowRate : '0'
              break
            case 'todayVolumeFlowed':
              result = this.state.hoverIsActive ? todayVolumeFlowed : '0'
              break
            case 'yesterdayVolumeFlowed':
              result = this.state.hoverIsActive ? yesterdayVolumeFlowed : '0'
              break
            case 'staticPressure':
              result = this.state.hoverIsActive ? staticPressure : '0'
              break
          }

          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Typography style={{ fontSize: '0.8rem', margin: '0 2px' }}>
                  <FiberManualRecordIcon
                    style={{
                      color: item.color,
                      fontSize: '0.8rem',
                      margin: '0 2px',
                    }}
                  />
                  {item.value}
                </Typography>
                <Typography
                  style={{
                    fontSize: '0.8rem',
                    color: item.color,
                    textAlign: 'center',
                    fontWeight: 700,
                  }}
                >
                  {isNaN(+result) ? ' - ' : (+result).toFixed(2)}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private renderSimpleLegend() {
    const data = this.hideHistoricalData(this.props.data, this.state.hiddenData)

    if (this.isGasFlow(data)) {
      const [info] = data || []
      return (
        <Legend
          verticalAlign="bottom"
          height={36}
          content={(props) => this.legendCustomComponent(props, info)}
        />
      )
    }
    return null
  }

  private isGasFlow(data: HistoricalDataEntry[]) {
    if (!data || !data.length) {
      return null
    }
    const item = 'yesterdayVolumeFlowed' in data[0]
    return item
  }

  private readonly renderReferences = (references?: Reference[]) => {
    if (!references?.length) {
      return null
    }

    const refs = references
      .map((r, i) => this.renderReference(r, i))
      .slice(0, maxReferences)

    if (!refs.length) {
      return null
    }

    return refs
  }

  private renderLegend() {
    const { referenceName } = this.props

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: theme.spacing(7),
          gridColumn: 1,
          gridRow: 1,
          zIndex: 1,
          cursor: 'pointer',
        }}
      >
        <MuiTooltip title="Show/bring to front/hide" placement="top">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              opacity:
                this.state.legendState === LegendState.Hide ? 0.5 : undefined,
              cursor: 'hand',
              userSelect: 'none',
            }}
            onClick={this.onLegendClick}
          >
            <svg viewBox="0 0 15 4" width="15px">
              <line
                x1={0}
                y1={0}
                x2={15}
                y2={0}
                strokeWidth={4}
                stroke={referenceColor}
              />
            </svg>
            &nbsp;
            <Typography
              variant="body2"
              style={{
                color: referenceColor,
                fontWeight:
                  this.state.legendState === LegendState.BringToFront
                    ? 'bold'
                    : undefined,
              }}
            >
              {referenceName}
            </Typography>
          </div>
        </MuiTooltip>
      </div>
    )
  }

  private readonly onLegendClick = () => {
    const states = [
      LegendState.Show,
      LegendState.BringToFront,
      LegendState.Hide,
    ]
    const index = states.findIndex((s) => this.state.legendState === s) + 1
    const legendState = states[index % states.length]

    this.setState({ legendState })
  }

  private readonly renderReference = (
    { value, from, to }: Reference,
    refIndex: number
  ) => {
    const { references } = this.props
    const [xFrom, xTo] = this.state.xAxisDomain
    const data = [
      new Date(Math.max(from.valueOf(), xFrom)),
      refIndex === 0 ? new Date(xTo) : to,
    ].map((timestamp, i) => ({
      timestamp,
      value,
    }))

    return (
      <Line
        id="ref"
        key={`ref${refIndex}`}
        isAnimationActive={false}
        dataKey="value"
        type="monotone"
        data={data}
        strokeWidth={2}
        strokeDasharray="15 5"
        stroke={referenceColor}
        activeDot={false}
        dot={false}
        hide={this.state.legendState === LegendState.Hide}
      >
        <LabelList
          dataKey="value"
          position="top"
          content={(props) => {
            const { value, index, x, y } = props as any
            const num1 = references?.length
            const currValue = +value.toFixed(1).toString()
            const prevValue =
              refIndex === 0
                ? undefined
                : +references![refIndex - 1].value.toFixed(1).toString()
            const [dy, baseLine] =
              refIndex === 0 && value === 0
                ? [-5, 'auto']
                : refIndex === 0 && value < 0
                ? [-5, 'auto']
                : refIndex === num1
                ? [5, 'hanging']
                : [5, 'hanging']

            return index === 1 && currValue !== prevValue ? (
              <g>
                <text
                  x={x}
                  y={y}
                  dy={dy}
                  textAnchor="end"
                  dominantBaseline={baseLine}
                >
                  {value.toFixed(1)}
                </text>
              </g>
            ) : null
          }}
        />
      </Line>
    )
  }

  private renderThresholds(statusRanges: StatusRange[]) {
    return statusRanges.map((item) => (
      <ReferenceArea
        key={`${item.minThreshold}-${item.maxThreshold}`}
        y1={item.minThreshold}
        y2={item.maxThreshold}
        fill={getStatusLensColor(item.status)}
        fillOpacity={0.3}
        ifOverflow="visible"
      />
    ))
  }

  private get xAxisDomain() {
    const [from, to] = getTimeRange(this.props.timeRange)
    const domain: [number, number] = [
      (from || new Date(0)).valueOf(),
      to ? to.valueOf() : moment().valueOf(),
    ]

    return domain
  }

  private readonly toTime = (timestamp) => {
    const time = moment(timestamp)
    return time.format(`MMM D, LT`)
  }

  private readonly toTimeWithSeconds = (timestamp) => {
    const time = moment(timestamp)
    return time.format(`MMM D, LTS`)
  }

  private getFormattedRawValue(rawValue: any) {
    if (!this.state.isUserSystemAdmin) return undefined

    return this.props.formatRawValue
      ? this.props.formatRawValue(rawValue)
      : rawValue
  }

  private readonly renderTooltipContent = (props: TooltipProps) => {
    const { payload } = props

    if (!payload || !payload.length) {
      return null
    }

    const item = payload.find((p) => p.dataKey === 'value')

    if (!item) {
      return null
    }
    const { timestamp }: HistoricalDataEntry = item.payload

    const value = item.payload.value
    const [min, max] = this.xAxisDomain
    let unitOfMeasure: string = item.payload.unitOfMeasure

    if (timestamp < min || timestamp > max) {
      return null
    }

    if (typeof unitOfMeasure === 'undefined') {
      unitOfMeasure = ''
    }

    let formattedValue = ''
    let formattedRawValue = ''

    if (item.payload.telemetryType === TelemetryType.PumpControl) {
      formattedValue = value === 1 ? 'On' : 'Off'
      formattedRawValue = ''
    } else {
      formattedValue = this.props.formatValue
        ? this.props.formatValue(value, unitOfMeasure)
        : value

      formattedRawValue = this.getFormattedRawValue(item.payload.rawValue)
    }

    const formattedTimestamp = this.toTimeWithSeconds(timestamp, true)
    const color = theme.palette.primary.main

    return (
      <div
        style={{
          color,
          backgroundColor: item.fill,
          border: `1px solid ${color}`,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {formattedValue}
        {formattedRawValue}
        <i>{formattedTimestamp}</i>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.multitenantUser, // state.oidc.user,
  accessToken: state.multitenantUser.accessToken, // user?.access_token,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id ?? 0,
  email: state.multitenantUser.email,
})

export default connect(mapStateToProps)(withStyles(styles)(SensorChart))
