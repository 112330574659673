import React, { ReactElement } from 'react'
import {
  Chip,
  Table,
  TableBody,
  Paper,
  WithStyles,
  TableContainer,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { createStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      alignItems: 'start',
      margin: theme.spacing(2, 2, 0, 0),
      padding: theme.spacing(1),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(-0.5, 2, 1.5, 1),
    },
  })

interface Props extends WithStyles<typeof styles> {
  readonly title: string
  readonly tag?: React.ReactNode
  readonly wellState?: string
}

const AssetCard = ({
  title,
  tag,
  children,
  classes,
  wellState,
}: React.PropsWithChildren<Props>): ReactElement => {
  return (
    <Paper
      className={classes.mainContainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.title}>
        {title}&nbsp;
        {
          <Chip
            label={tag}
            size="small"
            style={{
              visibility: tag ? 'visible' : 'hidden',
            }}
          />
        }
        {
          <Chip
            label={wellState}
            size="small"
            style={{
              visibility:
                wellState && wellState !== 'notavailable'
                  ? 'visible'
                  : 'hidden',
              backgroundColor: wellState === 'On' ? '#32CD32' : '',
              fontWeight: 'bold',
            }}
          />
        }
      </div>
      <TableContainer
        component={Paper}
        square={true}
        variant="outlined"
        style={{ borderBottom: 0 }}
      >
        <Table size="small">
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default withStyles(styles)(AssetCard)
