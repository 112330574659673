import React, { useState, useEffect } from 'react'
import { Button, Snackbar } from '@material-ui/core'
import {
  turnOnPumpRequest,
  turnOffPumpRequest,
  statusPumpRequest,
} from 'api/commands' // Importa tus funciones de API
import { ConfirmDialog } from '../misc/ConfirmDialog'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const PumpControlButton = ({ accessToken, tenantId, data, onStateChange }) => {
  const [pumpState, setPumpState] = useState('Pending')
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [_, setEnabledControls] = useState(false)

  const requestBody = {
    email: data.email,
    siteId: data.siteId,
    assetId: data.assetId,
    assetName: data.assetName,
  }

  useEffect(() => {
    const checkPumpStatusOnMount = async () => {
      try {
        const response = await checkPumpStatus(accessToken, tenantId)
        if (response && response.success) {
          const pumpState =
            response.data.status === 'OFF'
              ? 'Off'
              : response.data.status === 'ON'
              ? 'On'
              : 'Pending'
          setPumpState(pumpState)
          setEnabledControls(true)
          onStateChange({
            pumpState,
            enableControls: pumpState !== 'Pending',
            metaData: response.data.responseMetaData,
          })
        } else {
          setEnabledControls(false)
          onStateChange({ pumpState, enableControls: false, metaData: {} })
          if (response && response.error && response.error?.errors) {
            const errorMessages = Object.entries(response.error.errors)
              .map(
                ([fieldName, fieldErrors]) =>
                  `${fieldName}: ${fieldErrors.join(', ')}`
              )
              .join(', ')

            setShowSnackbar(true)
            setSnackbarMessage(
              `Errors with asset ${data.assetName}: ${errorMessages}`
            )
            setSnackbarSeverity('error')
          } else if (response.error?.Message) {
            const errorMessages = response.error?.Message
            setShowSnackbar(true)
            setSnackbarMessage(
              `Error with asset ${data.assetName}: ${errorMessages}`
            )
            setSnackbarSeverity('error')
          } else {
            setShowSnackbar(true)
            setSnackbarMessage(
              `Error with asset ${data.assetName}: ${response.error}`
            )
            setSnackbarSeverity('error')
          }
        }
      } catch (error) {
        console.error('Error getting pump status:', error)
      }
    }

    checkPumpStatusOnMount()
  }, [])

  const turnOnPumpWithDelay = async (accessToken, tenantId) => {
    try {
      const response = await turnOnPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      if (response && response.success) {
        setShowSnackbar(true)
        setSnackbarMessage('Successfully sent the command to turn ON the pump')
        setSnackbarSeverity('success')

        await checkPumpStatusOFFtoON(accessToken, tenantId)
      } else {
        handleError(response, requestBody.assetName)
      }
    } catch (error) {
      console.error('Error:', error)
      handleError(null, requestBody.assetName)
    }
  }

  const turnOffPumpWithDelay = async (accessToken, tenantId) => {
    try {
      const response = await turnOffPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      if (response && response.success) {
        setShowSnackbar(true)
        setSnackbarMessage('Successfully sent the command to turn OFF the pump')
        setSnackbarSeverity('success')

        await checkPumpStatusONtoOFF(accessToken, tenantId)
      } else {
        handleError(response, requestBody.assetName)
      }
    } catch (error) {
      console.error('Error:', error)
      handleError(null, requestBody.assetName)
    }
  }

  const handleError = (response, assetName) => {
    if (response && response.error && response.error.errors) {
      const errorMessages = Object.entries(response.error.errors)
        .map(
          ([fieldName, fieldErrors]) =>
            `${fieldName}: ${fieldErrors.join(', ')}`
        )
        .join(', ')
      setSnackbarMessage(`Error asset ${assetName}: ${errorMessages}`)
    } else if (response && response.error?.Message) {
      const errorMessageObj = JSON.parse(response.error.Message)
      const errorMessages = JSON.parse(errorMessageObj.Message).message
      setSnackbarMessage(`Error asset ${assetName}: ${errorMessages}`)
    } else {
      setSnackbarMessage(`Error asset ${assetName}: An error occurred`)
    }
    setSnackbarSeverity('error')
    setShowSnackbar(true)
  }

  const checkPumpStatusOFFtoON = async (accessToken, tenantId) => {
    let status = ''
    while (status !== 'ON') {
      const { data } = await statusPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      status = data.status
      if (status !== 'ON') {
        setEnabledControls(false)
        onStateChange({
          pumpState: 'Pending',
          enableControls: false,
          metaData: data.responseMetaData,
        })
        await new Promise((resolve) => setTimeout(resolve, 8000))
      }
    }
    setPumpState('On')
    setEnabledControls(true)
    onStateChange({
      pumpState: 'On',
      enableControls: true,
      metaData: data.responseMetaData,
    })
  }

  const checkPumpStatusONtoOFF = async (accessToken, tenantId) => {
    let status = ''
    while (status !== 'OFF') {
      const { data } = await statusPumpRequest(
        accessToken,
        tenantId,
        requestBody
      )
      status = data.status
      if (status !== 'OFF') {
        setEnabledControls(false)
        onStateChange({
          pumpState: 'Pending',
          enableControls: false,
          metaData: data.responseMetaData,
        })
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
    }
    setEnabledControls(true)
    setPumpState('Off')
    onStateChange({
      pumpState: 'Off',
      enableControls: true,
      metaData: data.responseMetaData,
    })
  }

  const handleChangePumpControl = async () => {
    setPumpState('Pending')
    if (pumpState === 'Off') {
      await turnOnPumpWithDelay(accessToken, tenantId)
    } else if (pumpState === 'On') {
      await turnOffPumpWithDelay(accessToken, tenantId)
    }
  }

  const handleToggle = () => {
    setShowModalConfirmation(true)
  }

  const checkPumpStatus = async (accessToken, tenantId) => {
    const data = await statusPumpRequest(accessToken, tenantId, requestBody)
    return data
  }

  let buttonText
  switch (pumpState) {
    case 'On':
      buttonText = 'On'
      break
    case 'Pending':
      buttonText = 'Pending'
      break
    case 'Off':
      buttonText = 'Off'
      break
    default:
      break
  }

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={pumpState === 'Pending'}
          onClick={handleToggle}
        >
          {buttonText}
        </Button>
      </div>
      {showModalConfirmation && (
        <ConfirmDialog
          title={pumpState === 'On' ? 'Turn OFF Pump' : 'Turn ON Pump'}
          onAction={async (ok) => {
            if (ok) {
              await handleChangePumpControl()
            }
            setShowModalConfirmation(false)
          }}
        >
          Are you sure you want to{' '}
          {pumpState === 'On' ? 'turn OFF Pump?' : 'turn ON Pump?'}
        </ConfirmDialog>
      )}
      {showSnackbar && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ zIndex: 2147483647, top: 100 }}
          onClose={() => {
            setShowSnackbar(false)
            setSnackbarMessage('')
            setSnackbarSeverity('sucess')
          }}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
      )}
    </>
  )
}

PumpControlButton.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired,
    assetName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    responseMetaData: PropTypes.array.isRequired,
  }).isRequired,
  onStateChange: PropTypes.func.isRequired,
}

export default PumpControlButton
