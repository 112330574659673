import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Theme, createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { AlertsState, defaultPageSize } from '../../store/Alert'
import { renderErrorAlert } from '../misc/utils'
import { getAlertsClient } from '../../api/client'
import { parseError, delay } from '../../store/utils'
import { AppState } from '../../store/AppState'
import { StreamingSubscription } from '../../store/utils/streaming'
import { ModelState } from '../../store/ModelState'
import {
  SwaggerException,
  Alert,
  AlertAcknowledgement,
} from '../../api/alertservice'
import {
  TimeRange,
  getTimeRange,
  getTimeRangePredicate,
} from '../misc/TimeRange'
import AlertsView, { Columns } from './AlertsView'

function getOrderByProperty(
  col?: Columns
): keyof Alert | keyof AlertAcknowledgement {
  switch (col) {
    case Columns.AcknowledgedBy:
      return 'userId'

    case Columns.Metric:
      return 'telemetryType'

    case Columns.StatusChange:
      return 'telemetryStatus'

    case Columns.Time:
      return 'telemetryTimestamp'

    case Columns.Site:
      return 'siteId'

    default:
      return getOrderByProperty(Columns.Time)
  }
}

const alertsComparer = (
  orderByColumn: Columns,
  directionAsc: boolean
): ((a: Alert, b: Alert) => number) => {
  const propName = getOrderByProperty(orderByColumn)
  const diff = (a: Alert, b: Alert): number => {
    const data: [any, any] =
      orderByColumn === Columns.StatusChange
        ? [a.telemetryStatus, b.telemetryStatus]
        : [a, b]
    const [aa, bb] = data.map((v: any) =>
      v !== undefined ? v[propName] : undefined
    )

    switch (orderByColumn) {
      case Columns.Metric:
      case Columns.StatusChange:
      case Columns.Time:
        return (aa ?? 0).valueOf() - (bb ?? 0).valueOf()
      case Columns.AcknowledgedBy:
      case Columns.Site:
        return (aa ?? '').localeCompare(bb ?? '')
      default:
        return 0
    }
  }

  return directionAsc ? diff : (a, b) => -diff(a, b)
}

const styles = (_: Theme) =>
  createStyles({
    container: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
  })

interface PropsFromState {
  readonly accessToken?: string
  readonly userId?: string
  readonly tenantId: number
  readonly alerts: AlertsState
}

interface Props {
  readonly siteId?: string
  readonly isAcknowledged?: boolean
  readonly timeRange?: TimeRange
}

type AllProps = Props & PropsFromState & WithStyles<typeof styles>

interface State extends ModelState {
  readonly alerts: Alert[][]
  readonly alertsSubscription?: StreamingSubscription<Alert>
  readonly savingCsv?: boolean
  readonly alertsCount: number
  readonly orderByColumn: Columns
  readonly directionAsc: boolean
}

const defaultState: State = {
  alerts: [],
  alertsCount: 1,
  orderByColumn: Columns.Time,
  directionAsc: false,
}

class FilteredAlerts extends Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = defaultState
  }

  public componentWillUnmount() {
    const { alertsSubscription } = this.state

    if (alertsSubscription) {
      alertsSubscription.unsubscribe(this.onStreamedAlert)
    }
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { siteId, timeRange, accessToken, tenantId } = prevProps

    if (
      (accessToken !== this.props.accessToken && tenantId) ||
      (tenantId && this.props.tenantId !== tenantId) ||
      siteId !== this.props.siteId ||
      timeRange !== this.props.timeRange
    ) {
      this.setState(defaultState, () => {
        this.loadAlerts(0)
      })
    }

    const {
      alerts: { subscription },
    } = this.props
    const { alertsSubscription } = this.state

    if (!alertsSubscription && subscription) {
      this.setState({ alertsSubscription: subscription }, () =>
        subscription?.subscribe(this.onStreamedAlert)
      )
    }
  }

  public render() {
    const { classes, siteId, isAcknowledged } = this.props
    const { error, alerts, alertsCount, orderByColumn, directionAsc } =
      this.state
    const count = alerts
      .map((p) => p?.length ?? 0)
      .reduce((sum, length) => sum + length, 0)

    return (
      <div className={classes.container}>
        {error &&
          renderErrorAlert(error, 'Failed to load acknowledged alerts.')}
        <AlertsView
          alerts={alerts}
          siteId={siteId}
          isAcknowledged={isAcknowledged}
          onAlert={this.onStreamedAlert}
          onSort={this.onSort}
          defaultOrder={orderByColumn}
          defaultDirectionAsc={directionAsc}
          isRowLoaded={this.isRowLoaded}
          loadPage={(startIndex) =>
            Promise.resolve(this.loadAlerts(startIndex))
          }
          alertsCount={Math.max(alertsCount, count)}
        />
      </div>
    )
  }

  private readonly onStreamedAlert = (alert: Alert) => {
    const { isAcknowledged, timeRange, siteId } = this.props

    if (!siteId || alert.siteId === siteId) {
      const correctType = !isAcknowledged || alert.acknowledgement?.userId
      const inTimeRange = getTimeRangePredicate(timeRange)

      if (correctType && inTimeRange(alert.telemetryTimestamp)) {
        this.updateAlerts(alert)
      }
    }
  }

  private updateAlerts(alert: Alert) {
    const { alerts } = this.state

    for (let p = 0; p < alerts.length; p = p + 1) {
      if (this.isInPage(alert, p)) {
        const page = alerts[p]
        const position = page.findIndex((a) => a.id === alert.id)

        if (position >= 0) {
          const newAlerts = [...alerts]
          const newPage = [...page]

          newPage[position] = alert
          newAlerts[p] = newPage

          this.setState({
            alerts: newAlerts,
            alertsCount: newAlerts.length,
          })

          return
        }
      }
    }

    for (let p = 0; p < alerts.length; p = p + 1) {
      if (this.isInPage(alert, p)) {
        const newAlerts = [...alerts]
        const page = alerts[p]
        const newPage = page
          .concat(alert)
          .sort(
            alertsComparer(this.state.orderByColumn, this.state.directionAsc)
          )

        newAlerts[p] = newPage

        this.setState({
          alerts: newAlerts,
          alertsCount: newAlerts.length,
        })

        return
      }
    }
  }

  private readonly isInPage = (alert: Alert, page: number): boolean => {
    const alerts = this.state.alerts[page]

    if (!alerts?.length) {
      return false
    }

    const start = page > 0 ? alerts[0] : undefined
    const end =
      page >= alerts.length - 1
        ? alerts.length > 1
          ? alerts[alerts.length - 1]
          : start
        : undefined

    return this.isInRange(alert, start, end)
  }

  private isInRange(alert: Alert, start?: Alert, end?: Alert) {
    const { orderByColumn, directionAsc } = this.state
    const propName = getOrderByProperty(orderByColumn)
    const data: [any, any, any] = Columns.StatusChange
      ? [alert.telemetryStatus, start?.telemetryStatus, end?.telemetryStatus]
      : [alert, start, end]
    const [a, s, e] = data.map((v: any) =>
      v !== undefined ? v[propName] : undefined
    )
    switch (orderByColumn) {
      case Columns.Metric:
      case Columns.StatusChange:
      case Columns.Time:
        return directionAsc
          ? (a ?? 0).valueOf() >= (s ?? 0).valueOf() &&
              (a ?? 0).valueOf() <= (e ?? 0).valueOf()
          : (a ?? 0).valueOf() >= (e ?? 0).valueOf() &&
              (a ?? 0).valueOf() <= (s ?? 0).valueOf()

      case Columns.AcknowledgedBy:
      case Columns.Site:
        return directionAsc
          ? (a ?? '').localeCompare(s ?? '') >= 0 &&
              (a ?? '').localeCompare(e ?? '') <= 0
          : (a ?? '').localeCompare(e ?? '') >= 0 &&
              (a ?? '').localeCompare(s ?? '') <= 0

      default:
        return false
    }
  }

  private readonly onSort = (sortBy: string, directionAsc: boolean) => {
    const orderByColumn = Columns[Columns[parseInt(sortBy, 10)]]

    this.setState(
      {
        ...defaultState,
        orderByColumn,
        directionAsc,
      },
      () => this.loadAlerts(0)
    )
  }

  private loadAlerts(startIndex: number, throttleDelay = 3000) {
    const { accessToken, userId, tenantId } = this.props

    if (!accessToken || !userId || !tenantId) {
      return
    }

    this.setState({ loading: true }, async () => {
      try {
        const pageToLoad = Math.floor(startIndex / defaultPageSize)

        if (this.state.alerts[pageToLoad]) {
          this.setState({
            loading: false,
            error: undefined,
          })
        } else {
          const api = getAlertsClient(accessToken, tenantId.toString())
          const { siteId, isAcknowledged } = this.props
          const [from, to] = getTimeRange(this.props.timeRange)
          const { orderByColumn, directionAsc } = this.state
          const promise = siteId
            ? api.getBySite(
                siteId,
                isAcknowledged,
                from,
                to,
                pageToLoad,
                defaultPageSize,
                getOrderByProperty(orderByColumn),
                directionAsc
              )
            : api.getByUser(
                userId,
                isAcknowledged,
                from,
                to,
                pageToLoad,
                defaultPageSize,
                getOrderByProperty(orderByColumn),
                directionAsc
              )

          const { result } = await promise
          const page = result.result ?? []
          const alertsCount = result.count
          const alerts =
            this.state.alertsCount !== alertsCount ? [] : [...this.state.alerts]
          alerts[pageToLoad] = page

          this.setState({
            alertsCount,
            alerts,
            loading: false,
            error: undefined,
          })
        }
      } catch (error) {
        if (
          SwaggerException.isSwaggerException(error) &&
          error.status === 429
        ) {
          // too many requests
          console.warn(error)
          this.setState({ loading: false }, async () => {
            await delay(throttleDelay)
            this.loadAlerts(startIndex, 1.5 * throttleDelay)
          })
        } else {
          const alertsCount =
            this.state.alertsCount > 0 ? this.state.alertsCount - 1 : 0
          this.setState({
            alertsCount,
            loading: false,
            error: parseError(error),
          })
        }
      }
    })
  }

  private readonly isRowLoaded = (index: number) => {
    const page = Math.floor(index / defaultPageSize)

    return !!this.state.alerts[page]
  }
}

const mapStateToProps = ({
  oidc: { user },
  alerts,
  multitenantUser,
}: AppState): PropsFromState => {
  return {
    accessToken: multitenantUser.accessToken,
    userId: multitenantUser.id,
    tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    alerts: alerts,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(FilteredAlerts))
