import React from 'react'

import { feetToFeetAndInches } from 'components/utils/converter'

import { ConfirmDialog } from '../../misc/ConfirmDialog'
import { HistoricalDataEntry } from '../../../api/client'
import moment from 'moment'
import { TelemetryType } from '../../../api/apiservice'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

// eslint-disable-next-line react/prop-types
export default function InvalidDatapointDialog(props: {
  currData: HistoricalDataEntry | undefined
  onClose: (ok: boolean, ...params) => void
}) {
  const onClose = (ok: boolean) => props.onClose(ok, props.currData)
  const action = props.currData?.invalidData ? 'unhide' : 'hide'

  if (props.currData?.telemetryType === TelemetryType.TankLevel) {
    const [tankLevelFt, tankLevelIn] = feetToFeetAndInches(
      props.currData?.value
    )
    const value = `${tankLevelFt.toFixed()}' ${tankLevelIn.toFixed(1)}"`

    return (
      <ConfirmDialog title="Confirmation" onAction={onClose}>
        Do you want to {action} this value - <b>({value})</b>?
        <br />
        Date{' '}
        <b>{moment(props.currData?.timestamp).format('MM/DD/YYYY h:mm:ss')}</b>
        <br />
      </ConfirmDialog>
    )
  }

  return (
    <ConfirmDialog title="Confirmation" onAction={onClose}>
      Do you want to {action} this value -{' '}
      <b>({props.currData?.value.toFixed(2)})</b>?
      <br />
      Date{' '}
      <b>{moment(props.currData?.timestamp).format('MM/DD/YYYY h:mm:ss')}</b>
      <br />
    </ConfirmDialog>
  )
}
