import React, { useState } from 'react'
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import { SiteApiModel } from 'api/apiservice'
interface Props {
  sites: SiteApiModel[]
  getCompressor: any
  compressors: SiteApiModel['compressorDevice'][] | undefined
  getAlertBySiteId: any
  blockSiteFilter: boolean
  getIgnitionOrden: any
  getWells: any
  getSetPoints: any
  getTimerSetPoint: any
  getCompressorDevice: any
}

type AllProps = Props

export default function BasicSelect(props: AllProps) {
  const [valueSite, setValueSite] = useState('')
  const [valueCompressor, setValueCompressor] = useState('')
  const handleChangeSite = (event) => {
    setValueSite(event.target.value)
    props.getCompressor(event.target.value)
    props.getAlertBySiteId(event.target.value)
    props.getWells(event.target.value)
    props.getSetPoints(event.target.value)
    props.getTimerSetPoint(event.target.value)
    props.getCompressorDevice(event.target.value)
  }

  const handleChangeCompressor = (event) => {
    setValueCompressor(event.target.value)
    props.getIgnitionOrden(event.target.value)
  }
  const handleFocus = (event) => {
    event.target.value = ''
  }

  return (
    <>
      <Box sx={{ minWidth: 220 }}>
        <FormControl variant="standard" style={{ margin: 10, minWidth: 150 }}>
          <InputLabel id="siteSelect-label">Sites</InputLabel>
          <Select
            style={{
              borderRadius: 2,
              padding: 2,
              minWidth: 300,
            }}
            onFocus={handleFocus}
            value={valueSite}
            onChange={handleChangeSite}
            disabled={!!props.blockSiteFilter}
          >
            {props.sites?.map((site) => {
              return (
                <MenuItem key={site?.id} value={site?.id}>
                  {site?.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          style={{
            margin: 10,
            minWidth: 150,
            display: props.blockSiteFilter ? 'none' : 'inline-block',
          }}
        >
          <InputLabel id="compressorSelect-label">Compressor</InputLabel>
          <>
            <Select
              style={{
                borderRadius: 2,
                padding: 2,
                minWidth: 300,
                // borderRadius: 0.75
              }}
              onFocus={handleFocus}
              value={
                props.compressors?.map((compressor) => compressor?.id) ?? ''
              }
            >
              {props.compressors?.map((compressor) => {
                return (
                  <MenuItem key={compressor?.id} value={compressor?.id}>
                    {compressor?.name}
                  </MenuItem>
                )
              })}
            </Select>
          </>
        </FormControl>
        <FormControl
          variant="standard"
          style={{
            margin: 10,
            minWidth: 150,
            display: props.blockSiteFilter ? 'inline-block' : 'none',
          }}
        >
          <InputLabel id="compressorSelect-label">Compressor</InputLabel>
          <>
            <Select
              style={{
                borderRadius: 2,
                padding: 2,
                minWidth: 300,
              }}
              onFocus={handleFocus}
              onChange={handleChangeCompressor}
              value={valueCompressor}
            >
              {props.compressors?.map((compressor) => {
                return (
                  <MenuItem key={compressor?.id} value={compressor?.id}>
                    {compressor?.name}
                  </MenuItem>
                )
              })}
            </Select>
          </>
        </FormControl>
      </Box>
    </>
  )
}
