import { Reducer, AnyAction } from 'redux'

import {
  HANDLE_SITE_NAME_FILTER_CONTROL_PANEL,
  HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL,
  HANDLE_DATE_RANGE_CONTROL_PANEL,
  HANDLE_PRODUCTS_CONTROL_PANEL,
  HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL,
  HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL,
  HANDLE_ASSETS_LIST,
  HANDLE_SITES_LIST,
  HANDLE_SEARCH_ASSETS_BY_SITES_FLAG,
  HANDLE_SEARCH_SITES_BY_ASSETS_FLAG,
  HANDLE_EXPORT_QUERY,
  HANDLE_ACTIVATE_FILTER_FLAG,
  HANDLE_DEACTIVATE_FILTER_FLAG,
  HANDLE_SET_MULTITENANT_USER,
  HANDLE_SET_ACTIVE_TENANT,
} from '../types/types'

export interface ControlPanelState {
  readonly selectedSitesFilter?: any
  readonly selectedAssetsFilter?: any
  readonly dateRange?: any
  readonly products?: any
  readonly siteName?: any
  readonly assetName?: any
  readonly assetsList?: any
  readonly sitesList?: any
  readonly assetsBySitesFlag?: any
  readonly sitesByAssetsFlag?: any
  readonly exportQuery?: any
  readonly activateFilterFlag?: any
}

export interface MultitenantUserState {
  readonly accessToken: string
  readonly id: string
  readonly email: string
  readonly fullName: string
  readonly selectedTenant: {
    id: number
    name: string
    selected: boolean
    roles: [{ roleName: string }] | null
  } | null
  readonly tenants:
    | [
        {
          id: number
          name: string
          selected: boolean
          roles: [{ roleName: string }] | null
        }
      ]
    | null
}

export const multitenantUserInitialState: MultitenantUserState = {
  accessToken: '',
  id: '',
  email: '',
  fullName: '',
  tenants: null,
  selectedTenant: null,
}

export const controlPanelInitialState: ControlPanelState = {
  selectedSitesFilter: [],
  selectedAssetsFilter: [],
  assetsList: [],
  sitesList: [],
  dateRange: [],
  products: [],
  siteName: '',
  assetName: '',
  assetsBySitesFlag: false,
  sitesByAssetsFlag: false,
  exportQuery: '',
  activateFilterFlag: 0,
}

export const multitenantUserReducer: Reducer<MultitenantUserState> = (
  state = multitenantUserInitialState,
  action: AnyAction
) => {
  let tenants:
    | [
        {
          id: number
          name: string
          selected: boolean
          roles: [{ roleName: string }] | null
        }
      ]
    | null
  switch (action.type) {
    case HANDLE_SET_MULTITENANT_USER:
      tenants = action.payload.tenants
      let selectedTenantId = 0
      const tenant = localStorage.getItem('selectedTenantId')
      if (tenant) {
        selectedTenantId = isNaN(parseInt(tenant)) ? 0 : parseInt(tenant)
      }
      return Object.assign({}, state, {
        accessToken: action.payload.accessToken,
        id: action.payload.id,
        email: action.payload.email,
        fullName: action.payload.fullName,
        tenants: tenants?.map((t, i) => {
          if (selectedTenantId === 0 && i === 0) t.selected = true
          else if (t.id === selectedTenantId) t.selected = true
          else t.selected = false
          return t
        }),
        selectedTenant: tenants?.find((e) => e.selected),
      })
    case HANDLE_SET_ACTIVE_TENANT:
      const tenantId: number = action.payload.tenantId
      localStorage.setItem('selectedTenantId', tenantId.toString())
      tenants = state.tenants
      return Object.assign({}, state, {
        ...state,
        tenants: tenants?.map((t) => {
          if (t.id === tenantId) t.selected = true
          else t.selected = false
          return t
        }),
        selectedTenant: tenants?.find((e) => e.selected),
      })
    default:
      return state
  }
}

export const controlPanelReducer: Reducer<ControlPanelState> = (
  state = controlPanelInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        selectedSitesFilter: action.payload.selectedSites,
      })

    case HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        selectedAssetsFilter: action.payload.selectedAssets,
      })

    case HANDLE_SITE_NAME_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        siteName: action.payload.siteName,
      })

    case HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL:
      return Object.assign({}, state, {
        assetName: action.payload.assetName,
      })

    case HANDLE_DATE_RANGE_CONTROL_PANEL:
      return Object.assign({}, state, {
        dateRange: action.payload.data,
      })

    case HANDLE_PRODUCTS_CONTROL_PANEL:
      return Object.assign({}, state, {
        products: action.payload.data,
      })

    case HANDLE_ASSETS_LIST:
      return Object.assign({}, state, {
        assetsList: action.payload.assetsList,
      })

    case HANDLE_SITES_LIST:
      return Object.assign({}, state, {
        sitesList: action.payload.sitesList,
      })

    case HANDLE_SEARCH_ASSETS_BY_SITES_FLAG:
      return Object.assign({}, state, {
        assetsBySitesFlag: action.payload.assetsBySitesFlag,
      })

    case HANDLE_SEARCH_SITES_BY_ASSETS_FLAG:
      return Object.assign({}, state, {
        sitesByAssetsFlag: action.payload.sitesByAssetsFlag,
      })

    case HANDLE_EXPORT_QUERY:
      return Object.assign({}, state, {
        exportQuery: action.payload.exportQuery,
      })

    case HANDLE_ACTIVATE_FILTER_FLAG:
      return Object.assign({}, state, {
        activateFilterFlag: Number(state.activateFilterFlag) + 1,
      })

    case HANDLE_DEACTIVATE_FILTER_FLAG:
      return Object.assign({}, state, {
        activateFilterFlag: 0,
      })

    default:
      return state
  }
}
