import {
  DeviceHubTwoTone,
  FitnessCenterTwoTone,
  TimelineTwoTone,
  BallotTwoTone,
} from '@material-ui/icons'
import React from 'react'
import { generatePath, matchPath, Route, Switch } from 'react-router-dom'
import theme from 'theme'
import {
  devicesRoute,
  idRoute,
  plungerLiftRoute,
  pumpControllersRoute,
  siteRoute,
  sitesRoute,
} from '../App'
import { ModelState } from '../store/ModelState'
import DevicesTab from './site/DevicesTab'
import MetricsDashboardTab, { AllProps } from './site/MetricsDashboardTab'
import { Tabs } from './misc/Tabs'
import PlungerLiftsTab from './site/PlungerLiftsTab'
import { TelemetryStatus } from 'api/alertservice'
import PumpControllerTab from './site/PumpControllerTab'

enum TabsType {
  Dashboard = 1,
  Devices = 2,
  PlungerLifts = 3,
  Pumps = 4,
}

export interface SiteMatch {
  readonly id: string
}

export interface State extends ModelState {
  readonly isPlungerTabVisible: boolean
  readonly siteStatus?: TelemetryStatus
  readonly isPumpControlTabVisible: boolean
}

export default class SiteDetails extends React.Component<AllProps, State> {
  private readonly tabBarContent: React.RefObject<HTMLDivElement>

  constructor(props: AllProps) {
    super(props)
    this.tabBarContent = React.createRef<HTMLDivElement>()
    this.state = {
      isPlungerTabVisible: false,
      isPumpControlTabVisible: false,
    }

    this.setIsPlungerTabVisible = this.setIsPlungerTabVisible.bind(this)
    this.setIsPumpControlTabVisible = this.setIsPumpControlTabVisible.bind(this)
  }

  public render() {
    const { path } = this.props.match
    const { search } = this.props.location

    if (this.state.siteStatus === undefined)
      this.setState({
        ...this.state,
        siteStatus: Number(search?.split('=')[1] ?? '0'),
      })

    if (this.state.siteStatus === undefined)
      this.setState({
        ...this.state,
        siteStatus: Number(search?.split('=')[1] ?? '0'),
      })

    return (
      <Switch>
        <Route
          path={`${path}${devicesRoute}`}
          exact={true}
          render={() =>
            this.renderTabs(TabsType.Devices, () => (
              <DevicesTab
                setIsPlungerTabVisible={this.setIsPlungerTabVisible}
                setIsPumpControlTabVisible={this.setIsPumpControlTabVisible}
              />
            ))
          }
        />
        <Route
          path={`${path}${plungerLiftRoute}`}
          exact={true}
          render={() =>
            this.renderTabs(TabsType.PlungerLifts, () => <PlungerLiftsTab />)
          }
        />
        <Route
          path={`${path}${pumpControllersRoute}`}
          exact={true}
          render={() =>
            this.renderTabs(TabsType.Pumps, () => (
              <PumpControllerTab
                setIsPlungerTabVisible={this.setIsPlungerTabVisible}
                setIsPumpControlTabVisible={this.setIsPumpControlTabVisible}
              />
            ))
          }
        />
        <Route
          path={path}
          exact={true}
          render={() => {
            return this.renderTabs(TabsType.Dashboard, () => (
              <MetricsDashboardTab
                {...this.props}
                tabBarContainer={this.tabBarContent}
                setIsPlungerTabVisible={this.setIsPlungerTabVisible}
                setIsPumpControlTabVisible={this.setIsPumpControlTabVisible}
                status={
                  this.state.siteStatus === undefined
                    ? Number(search?.split('=')[1] ?? '0')
                    : this.state.siteStatus
                }
              />
            ))
          }}
        />
      </Switch>
    )
  }

  private renderTabs(
    currentTab: TabsType,
    getTabPanel?: () => React.ReactNode
  ) {
    const tabs = [
      {
        getTabPanel,
        value: TabsType.Dashboard,
        icon: <TimelineTwoTone />,
        label: 'Dashboard',
      },
      {
        getTabPanel,
        value: TabsType.Devices,
        icon: <DeviceHubTwoTone />,
        label: 'Devices',
      },
    ]
    if (
      this.state.isPlungerTabVisible ||
      currentTab === TabsType.PlungerLifts
    ) {
      tabs.push({
        getTabPanel,
        value: TabsType.PlungerLifts,
        icon: <FitnessCenterTwoTone />,
        label: 'Plunger Lift',
      })
    }
    if (this.state.isPumpControlTabVisible || currentTab === TabsType.Pumps) {
      tabs.push({
        getTabPanel,
        value: TabsType.Pumps,
        icon: <BallotTwoTone />,
        label: 'Pump Controllers',
      })
    }

    return (
      <Tabs<TabsType>
        initialTab={currentTab}
        tabsBarContent={() => this.renderTabsBar()}
        onTabChanged={this.onTabChanged}
        style={{ height: '100%' }}
        tabs={tabs}
      />
    )
  }

  private readonly onTabChanged = (currentTab: TabsType) => {
    const path = this.props.history.location.pathname
    const match = matchPath<SiteMatch>(path, {
      path: [siteRoute + idRoute, sitesRoute + idRoute],
    })

    if (match) {
      const tabPath = this.getTabPath(
        currentTab,
        match.path,
        match.params.id,
        this.state.siteStatus ?? 0
      )

      if (tabPath && tabPath !== path) {
        this.props.history.push(tabPath)
      }
    }
  }

  private getTabPath(
    currentTab: TabsType,
    path: string,
    id: string,
    status: number
  ) {
    switch (currentTab) {
      case TabsType.Dashboard:
        return `${generatePath(path, { id })}?status=${status}`

      case TabsType.Devices:
        return generatePath(path + devicesRoute, {
          id,
        })

      case TabsType.PlungerLifts:
        return generatePath(path + plungerLiftRoute, { id })

      case TabsType.Pumps:
        return generatePath(path + pumpControllersRoute, { id })
    }
  }

  private renderTabsBar(): React.ReactNode {
    const hide =
      matchPath(this.props.history.location.pathname, {
        path: [
          siteRoute + idRoute + devicesRoute,
          sitesRoute + idRoute + devicesRoute,
        ],
        exact: true,
        strict: true,
      }) !== null

    return hide ? null : (
      <div
        ref={this.tabBarContent}
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: theme.spacing(3.25),
        }}
      />
    )
  }

  private setIsPlungerTabVisible(value: boolean) {
    this.setState({ isPlungerTabVisible: value })
  }

  private setIsPumpControlTabVisible(value: boolean) {
    this.setState({ isPumpControlTabVisible: value })
  }
}
