/* Control Panel */
export const HANDLE_SITE_NAME_FILTER_CONTROL_PANEL =
  'HANDLE_SITE_NAME_FILTER_CONTROL_PANEL'

export const HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL =
  'HANDLE_ASSET_NAME_FILTER_CONTROL_PANEL'

export const HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL =
  'HANDLE_SELECTED_SITES_FILTER_CONTROL_PANEL'

export const HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL =
  'HANDLE_SELECTED_ASSETS_FILTER_CONTROL_PANEL'

export const HANDLE_DATE_RANGE_CONTROL_PANEL = 'HANDLE_DATE_RANGE_CONTROL_PANEL'

export const HANDLE_PRODUCTS_CONTROL_PANEL = 'HANDLE_PRODUCTS_CONTROL_PANEL'

export const HANDLE_ASSETS_LIST = 'HANDLE_ASSETS_LIST'

export const HANDLE_SITES_LIST = 'HANDLE_SITES_LIST'

export const HANDLE_SEARCH_ASSETS_BY_SITES_FLAG =
  'HANDLE_SEARCH_ASSETS_BY_SITES_FLAG'

export const HANDLE_SEARCH_SITES_BY_ASSETS_FLAG =
  'HANDLE_SEARCH_SITES_BY_ASSETS_FLAG'

export const HANDLE_EXPORT_QUERY = 'HANDLE_EXPORT_QUERY'

export const HANDLE_ACTIVATE_FILTER_FLAG = 'HANDLE_ACTIVATE_FILTER_FLAG'

export const HANDLE_DEACTIVATE_FILTER_FLAG = 'HANDLE_DEACTIVATE_FILTER_FLAG'


export const HANDLE_UTIL_ALERT = 'HANDLE_UTIL_ALERT'

/* Generic Dialog */
export const HANDLE_GENERIC_DIALOG = 'HANDLE_GENERIC_DIALOG'

export const HANDLE_SET_MULTITENANT_USER = 'HANDLE_SET_MULTITENANT_USER'
export const HANDLE_SET_ACTIVE_TENANT = 'HANDLE_SET_ACTIVE_TENANT'
