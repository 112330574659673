/* eslint-disable @typescript-eslint/no-empty-interface */
import { Dispatch, AnyAction, Reducer } from 'redux'
import { AppState } from './AppState'
import { parseError } from './utils'
import {
  SiteApiModel,
  ProductionCode,
  PlungerLiftType,
} from '../api/apiservice'
import { ModelState } from './ModelState'
import { getSiteClient } from '../api/client'

export interface Asset {
  readonly id: string
  readonly name?: string
  readonly telemetryTypeFormula?: string
}

export interface Tank extends Asset {
  readonly capacity: number
  readonly height: number
  readonly gravity: number
  readonly productionCode: ProductionCode
  readonly isInactive: boolean
}

export interface PlungerLift extends Asset {
  readonly type: PlungerLiftType
}

export interface Well extends Asset {
  readonly hasTubing: boolean
  readonly hasCasing: boolean
  readonly hasCrank: boolean
  readonly hasPump: boolean
  readonly iwcDeviceId: string
  readonly tubingPressure: Asset
  readonly casingPressure: Asset
  readonly crankRevolutions: Asset
  readonly pumpPressure: Asset
}

export interface GasFlow extends Asset {
  // readonly orificeSize: number
  // readonly tubeSize: number
  // readonly gravity: number
  readonly totalFlowType: string
}

export interface Heater extends Asset {}

export interface Separator extends Asset {}

export interface Compressor extends Asset {}

export interface LiquidFlow extends Asset {}

export interface StaticPressure extends Asset {}

export interface StrokesPerMinute extends Asset {}

export interface Vibration extends Asset {}

export interface KnockoutPressure extends Asset {}

export interface GenericSensor extends Asset {}

export interface FlareStatus extends Asset {}

export interface GatewayMetricsAsSensor extends Asset {}

export interface PumpControl extends Asset {}

export interface BalancedTank extends Tank {
  readonly balancedHeight: number
  readonly totalCapacity: number
  readonly isInactive: boolean
  readonly isReciver: boolean
  readonly numberOfTanks: number
  readonly tanks: Tank[]
}

export interface Site {
  readonly id: string
  readonly name?: string
  readonly latitude: number
  readonly longitude: number
  readonly tanks: Tank[]
  readonly state?: string
  readonly county?: string
  readonly township?: string
  readonly plungerLift?: PlungerLift
  readonly wells: Well[]
  readonly gasFlows: GasFlow[]
  readonly heaters: Heater[]
  readonly separators: Separator[]
  readonly compressors: Compressor[]
  readonly liquidFlows: LiquidFlow[]
  readonly genericSensors: GenericSensor[]
  readonly staticPressure: StaticPressure[]
  readonly strokesPerMinute: StrokesPerMinute[]
  readonly vibrations: Vibration[]
  readonly knockoutPressures: KnockoutPressure[]
  readonly BalancedTanks: BalancedTank[]
  readonly flaresStatus: FlareStatus[]
  readonly pumpControls: PumpControl[]
  readonly GatewayMetricsAsSensor: GatewayMetricsAsSensor[]
  readonly startDate?: string
  readonly region?: string
  readonly field?: string
}

export interface SitesState extends ModelState {
  readonly sites: Site[]
}

export const defaultSitesState: SitesState = {
  sites: [],
  loading: true,
}

const requestSitesType = 'REQUEST_SITES'
const receiveSitesType = 'RECEIVE_SITES'
const receiveSitesErrorType = 'RECEIVE_SITES_ERROR'

export const actionCreators = {
  requestSites:
    (accessToken: string, tenantId: string) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ type: requestSitesType })

      try {
        const api = getSiteClient(accessToken, tenantId)
        const { result } = await api.getAll()

        dispatch({
          sites: result,
          type: receiveSitesType,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: receiveSitesErrorType,
        })
      }
    },
}

export const mapSite = (vm: SiteApiModel): Site => {
  return {
    id: vm.id,
    name: vm.name,
    latitude: vm.lat,
    longitude: vm.lon,
    tanks: (vm.tanks ?? []).map((t) => t.toJSON() as Tank),
    state: vm.location?.state ?? '',
    county: vm.location?.county ?? '',
    region: vm.location?.region ?? '',
    field: vm.location?.field ?? '',
    // township: vm.location?.township ?? '',
    plungerLift: vm.plungerLift?.toJSON() as PlungerLift,
    wells: (vm.wells ?? []).map((w) => w.toJSON() as Well),
    gasFlows: (vm.gasFlows ?? []).map((m) => m.toJSON() as GasFlow),
    heaters: (vm.heaters ?? []).map((h) => h.toJSON() as Heater),
    separators: (vm.separators ?? []).map((s) => s.toJSON() as Separator),
    compressors: (vm.compressors ?? []).map((c) => c.toJSON() as Compressor),
    liquidFlows: (vm.liquidFlows ?? []).map((c) => c.toJSON() as LiquidFlow),
    staticPressure: (vm.staticPressure ?? []).map(
      (c) => c.toJSON() as StaticPressure
    ),
    strokesPerMinute: (vm.strokesPerMinute ?? []).map(
      (c) => c.toJSON() as StrokesPerMinute
    ),
    vibrations: (vm.vibrations ?? []).map((c) => c.toJSON() as Vibration),
    knockoutPressures: (vm.knockoutPressures ?? []).map(
      (c) => c.toJSON() as Vibration
    ),
    genericSensors: (vm.genericSensors ?? []).map(
      (c) => c.toJSON() as GenericSensor
    ),
    BalancedTanks: (vm.balancedTanks ?? []).map(
      (c) => c.toJSON() as BalancedTank
    ),
    flaresStatus: (vm.flareStatus ?? []).map((c) => c.toJSON() as FlareStatus),
    pumpControls: (vm.pumpControls ?? []).map((c) => c.toJSON() as PumpControl),
    startDate: vm.startDate?.toString(),
  } as Site
}

export const reducer: Reducer<SitesState> = (
  state = defaultSitesState,
  action: AnyAction
) => {
  switch (action.type) {
    case requestSitesType:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case receiveSitesType:
      const descs: SiteApiModel[] = action.sites
      const sites = descs.map(mapSite) || []

      return {
        ...state,
        sites,
        loading: false,
        error: undefined,
      }

    case receiveSitesErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    default:
      return state
  }
}
