import { Dispatch, Reducer, AnyAction } from 'redux'
import { ISubscription } from '@microsoft/signalr'

import { ModelState } from './ModelState'
import { AppState } from './AppState'
import { getAlertsClient, getPumpAlertsClient } from '../api/client'
import { Alert, AlertPump, SwaggerException } from '../api/alertservice'
import { parseError, delay } from './utils'
import {
  startStreaming,
  ConnectionError,
  ReceiveError,
  StreamingError,
  StreamingSubscription,
} from './utils/streaming'

export interface Alerts {
  [id: string]: Alert
}

export interface AlertsState extends ModelState {
  readonly alertsCount: number
  readonly subscription?: StreamingSubscription<Alert>
}

export interface AlertsPumpState extends ModelState {
  readonly alertsCount: number
  readonly subscription?: StreamingSubscription<AlertPump>
}

export const defaultState: AlertsState = {
  alertsCount: 0,
  loading: true,
}

export const defaultPumpState: AlertsPumpState = {
  alertsCount: 0,
  loading: true,
}

export const defaultPageSize = 30

const requestAllActiveAlertsCountType = 'REQUEST_ALL_ACTIVE_ALERTS_COUNT'
const receiveAllActiveAlertsCountType = 'RECEIVE_ALL_ACTIVE_ALERTS_COUNT'
const receiveAllActiveAlertsCountErrorType =
  'RECEIVE_ALL_ACTIVE_ALERTS_COUNT_ERROR'

const requestAlertsStreamType = 'REQUEST_ALERTS_STREAM'
const receiveAlertsStreamType = 'RECEIVE_ALERTS_STREAM'
const receiveAlertsStreamErrorType = 'RECEIVE_ERROR_ALERTS_STREAM'
const stopAlertsStreamType = 'STOP_ALERTS_STREAM'

function processAlertData(obj) {
  if (obj === undefined || obj === null || typeof obj !== 'object') {
    return obj
  }

  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = `${key.slice(0, 1).toLowerCase()}${key.slice(1)}`

    return {
      ...newObj,
      [newKey]: processAlertData(obj[key]),
    }
  }, {})
}

const requestAllActiveAlertsCount =
  (
    accessToken: string,
    userId: string,
    tenantId: number,
    throttleDelay = 3000
  ) =>
  async (dispatch: Dispatch, state: () => AppState) => {
    dispatch({
      type: requestAllActiveAlertsCountType,
    })

    try {
      const api = getAlertsClient(accessToken, tenantId.toString())
      const { result: alertsCount } = await api.getAllActiveAlertsCount(
        userId,
        tenantId
      )

      dispatch({
        alertsCount,
        type: receiveAllActiveAlertsCountType,
      })
    } catch (error) {
      if (SwaggerException.isSwaggerException(error) && error.status === 429) {
        // too many requests
        console.warn(error)

        await delay(throttleDelay)
        await requestAllActiveAlertsCount(
          accessToken,
          userId,
          tenantId,
          2 * throttleDelay
        )(dispatch, state)
      } else {
        console.error(error)
        dispatch({
          error,
          type: receiveAllActiveAlertsCountErrorType,
        })
      }
    }
  }

export const actionCreators = {
  requestAllActiveAlertsCount,

  requestAlertStream:
    (accessToken: string, tenantId: string, groupIds: string[]) =>
    async (dispatch: Dispatch, __: () => AppState) => {
      dispatch({ type: requestAlertsStreamType })

      try {
        const result = await startStreaming(
          accessToken,
          tenantId,
          'alert',
          () =>
            dispatch({
              type: requestAlertsStreamType,
            }),
          groupIds
        ).catch((error) => new ConnectionError(error))

        if (result instanceof Error) {
          console.error(result)
          dispatch({
            error: result,
            type: receiveAlertsStreamErrorType,
          })
          return
        }

        let streamingSubscription: StreamingSubscription<Alert> | null = null

        const subscription: ISubscription<Alert> = result.subscribe({
          next: (data) => {
            try {
              const json = JSON.parse(data)
              const jsonData = processAlertData(json)
              const alert = Alert.fromJS(jsonData)

              if (streamingSubscription) {
                streamingSubscription.callback(alert)
              }

              dispatch({
                alert,
                type: receiveAlertsStreamType,
              })
            } catch (e) {
              console.error(e)
              dispatch({
                error: new ReceiveError(e),
                type: receiveAlertsStreamErrorType,
              })
            }
          },
          error: (error) =>
            dispatch({
              error: new ReceiveError(error),
              type: receiveAlertsStreamErrorType,
            }),
          complete: () =>
            dispatch({
              type: requestAlertsStreamType,
            }),
        })

        streamingSubscription = new StreamingSubscription<Alert>(subscription)

        dispatch({
          subscription: streamingSubscription,
          type: receiveAlertsStreamType,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          error: new StreamingError(error),
          type: receiveAlertsStreamErrorType,
        })
      }
    },

  stopAlertsStream: () => (dispatch: Dispatch, _: () => AppState) => {
    dispatch({ type: stopAlertsStreamType })
  },

  requestAcknowledge:
    (
      accessToken: string,
      id: string,
      tenantId: string,
      userId?: string,
      note?: string,
      onReceive?: (alert: Alert) => void
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      try {
        const api = getAlertsClient(accessToken, tenantId)
        const { result: alert } = await api.acknowledge(
          id,
          note,
          Number.parseInt(tenantId),
          userId
        )

        if (onReceive) {
          onReceive(alert)
        }
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: receiveAllActiveAlertsCountErrorType,
        })
      }
    },

  requestPumpAcknowledge:
    (
      accessToken: string,
      id: string,
      tenantId: string,
      userId?: string,
      note?: string,
      onReceive?: (alert: AlertPump) => void
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      try {
        const api = getPumpAlertsClient(accessToken, tenantId)
        const { result: alert } = await api.acknowledgePump(
          id,
          note,
          Number.parseInt(tenantId),
          userId
        )

        if (onReceive) {
          onReceive(alert)
        }

        window.location.reload()
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: receiveAllActiveAlertsCountErrorType,
        })
      }
    },
}

export const reducer: Reducer<AlertsState> = (
  state = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case requestAllActiveAlertsCountType:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case receiveAllActiveAlertsCountType:
      const { alertsCount } = action

      return {
        ...state,
        alertsCount,
        loading: false,
        error: undefined,
      }

    case receiveAllActiveAlertsCountErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    case receiveAlertsStreamErrorType:
      return {
        ...state,
        error: parseError(action.error),
      }

    case requestAlertsStreamType:
      return {
        ...state,
        error: undefined,
        subscription: undefined,
      }

    case receiveAlertsStreamType:
      const { subscription, alert } = action
      const newState: AlertsState = subscription
        ? { ...state, subscription }
        : state

      if (alert) {
        return {
          ...newState,
          alertsCount:
            newState.alertsCount + (alert.acknowledgedByUserId ? -1 : 1),
        }
      }

      return newState

    case stopAlertsStreamType:
      state.subscription?.dispose()

      return {
        ...state,
        subscription: undefined,
      }

    default:
      return state
  }
}
