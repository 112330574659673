import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Typography,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  LinearProgress,
} from '@material-ui/core'
import { AppState } from '../../store/AppState'
import { ModelState } from 'store/ModelState'
import { SiteMatch } from '../SiteDetails'
import { defaultSiteState, requestSite, SiteState } from 'store/SiteDetails'
import { SitesState } from '../../store/Site'
import CommonSettings from 'components/details/plungers/CommonSettings'
import ModeSettings from 'components/details/plungers/ModeSettings'
import Arrivals from 'components/details/plungers/Arrivals'
import CurrentState from 'components/details/plungers/CurrentState'
import { UiContextData, withUiContext } from 'components/contexts/UiContext'
import { BreadcrumbsWithSiteLink } from 'components/misc/BreadcrumbsWithSiteLink'
import Snack from 'components/misc/Snack'
import {
  PlungerCommonWellSettingsApiModel,
  PlungerTimeModeSettingsApiModel,
  PlungerPressureModeSettingsApiModel,
  PlungerAutoAdjustModeSettingsApiModel,
  PlungerOperatingMode,
} from 'api/apiservice'
import { getPlungerClient } from 'api/client'
import {
  PlungerState,
  requestArrivalTimes,
  requestPlungerJobs,
  requestPlungerSettings,
  updateAction,
  updateOperatingMode,
} from 'store/Plunger'
import Jobs from 'components/details/plungers/Jobs'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    rightCol: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100vw',
        width: '100%',
      },
      maxWidth: '25vw',
    },
    leftCol: {
      width: '100%',
    },
  })

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly sites: SitesState
}

interface State extends ModelState {
  readonly site: SiteState
  readonly plunger: PlungerState
}

type AllProps = PropsFromState &
  RouteComponentProps<SiteMatch> &
  UiContextData &
  WithStyles<typeof styles>

class PlungerLiftsTab extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)
    const {
      sites: { sites },
    } = this.props
    this.state = {
      site: {
        site: sites.find((w) => w.id === props.match.params.id),
      } as SiteState,
      plunger: {},
    }

    this.updateWellSettings = this.updateWellSettings.bind(this)
    this.updateAutoAdjustModeSettings =
      this.updateAutoAdjustModeSettings.bind(this)
    this.updateTimeModeSettings = this.updateTimeModeSettings.bind(this)
    this.updatePressureModeSettings = this.updatePressureModeSettings.bind(this)
    this.updateOperatingMode = this.updateOperatingMode.bind(this)
  }

  public componentDidMount() {
    const {
      site: { loading: siteLoading, site },
    } = this.state

    if (!site) {
      this.loadSite()
    }

    if (site && !siteLoading) {
      this.loadArrivals()
      this.loadPlungerSettings()
      this.loadPlungerJobs()
    }

    this.loadBreadcrumbs()
  }

  public componentDidUpdate(prevProps: AllProps, prevState: State) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadSite()
    }

    if (prevState.site !== this.state.site) {
      this.loadArrivals()
      this.loadPlungerSettings()
      this.loadPlungerJobs()
      this.loadBreadcrumbs()
    }
  }

  public render() {
    const {
      site: { site, loading: siteLoading },
      plunger: { loading: plungerLoading },
    } = this.state

    if (siteLoading || plungerLoading) {
      return <LinearProgress />
    }

    if (siteLoading !== undefined && site === undefined) {
      return (
        <Typography color="error" variant="h5">
          Well with ID {this.props.match.params.id} not found.
        </Typography>
      )
    }

    const { classes } = this.props
    const { infoMessage, error } = this.state.plunger

    return (
      <div className={classes.root}>
        {this.getColumns()}
        {infoMessage && (
          <Snack
            message={infoMessage}
            onClose={() => this.setPlungerInfoMessage(undefined)}
          />
        )}
        {error && (
          <Snack
            message={error}
            severity="error"
            onClose={() => this.setError(undefined)}
          />
        )}
      </div>
    )
  }

  private getColumns() {
    const { classes } = this.props
    const { plungerSettings, plungerArrivalTimes, plungerJobs, error } =
      this.state.plunger

    if (!error && (!plungerSettings || !plungerArrivalTimes || !plungerJobs)) {
      return <LinearProgress />
    }

    return (
      <>
        <div className={classes.leftCol}>
          <CommonSettings
            commonWellSettings={plungerSettings?.commonWellSettings}
            updateSettings={this.updateWellSettings}
          />
          <ModeSettings
            plungerSettings={plungerSettings}
            updateAutoAdjustModeSettings={this.updateAutoAdjustModeSettings}
            updatePressureModeSettings={this.updatePressureModeSettings}
            updateTimeModeSettings={this.updateTimeModeSettings}
          />
          <Arrivals plungerArrivalTimes={plungerArrivalTimes} />
          <Jobs plungerJobs={plungerJobs} />
        </div>
        <div className={classes.rightCol}>
          <CurrentState
            latestState={plungerSettings?.latestState}
            updateOperatingMode={this.updateOperatingMode}
          />
        </div>
      </>
    )
  }

  private loadArrivals() {
    const { accessToken } = this.props

    if (!accessToken) {
      return
    }

    this.setState({ plunger: {} as PlungerState }, async () => {
      if (!this.state.site.site?.plungerLift) {
        return
      }

      await requestArrivalTimes(
        accessToken,
        this.props.tenantId.toString(),
        this.plungerId,
        () => this.state.plunger,
        (plunger) => this.setState({ plunger })
      )
    })
  }

  private loadPlungerSettings() {
    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    this.setState({ plunger: {} as PlungerState }, async () => {
      if (!this.state.site.site?.plungerLift) {
        return
      }

      await requestPlungerSettings(
        accessToken,
        tenantId.toString(),
        this.plungerId,
        () => this.state.plunger,
        (plunger) => this.setState({ plunger })
      )
    })
  }

  private loadPlungerJobs() {
    const { accessToken } = this.props

    if (!accessToken) {
      return
    }

    this.setState({ plunger: {} as PlungerState }, async () => {
      if (!this.state.site.site?.plungerLift) {
        return
      }

      await requestPlungerJobs(
        accessToken,
        this.props.tenantId.toString(),
        this.plungerId,
        () => this.state.plunger,
        (plunger) => this.setState({ plunger })
      )
    })
  }

  private loadSite() {
    if (this.state.site.loading) {
      return
    }

    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    this.setState({ site: defaultSiteState }, async () => {
      const {
        match: {
          params: { id },
        },
      } = this.props

      await requestSite(
        tenantId.toString(),
        accessToken,
        id,
        () => this.state.site,
        (site) => !site.loading && this.setState({ site: site })
      )
    })
  }

  private loadBreadcrumbs() {
    const {
      site: { site },
    } = this.state

    if (site) {
      const title = (
        <BreadcrumbsWithSiteLink
          siteId={site.id}
          siteName={site.name}
          secondLabel={site.plungerLift?.id}
        />
      )

      this.props.setPageTitle!(title)
    }
  }

  private setPlungerInfoMessage(value?: string) {
    this.setState({
      plunger: {
        ...this.state.plunger,
        infoMessage: value,
      },
    })
  }

  private setError(value?: string) {
    this.setState({
      plunger: {
        ...this.state.plunger,
        error: value,
      },
    })
  }

  private async updateWellSettings(
    settings: PlungerCommonWellSettingsApiModel
  ) {
    if (this.areAccessTokenAndPlungerLiftDefined) {
      const apiCall = () =>
        this.api.updateCommonWellSettings(this.plungerId, settings)

      await updateAction(
        () => this.state.plunger,
        (plunger) => this.setState({ plunger }),
        apiCall,
        'commonWellSettings',
        settings
      )
    }
  }

  private async updateAutoAdjustModeSettings(
    settings: PlungerAutoAdjustModeSettingsApiModel
  ) {
    if (this.areAccessTokenAndPlungerLiftDefined) {
      const apiCall = () =>
        this.api.updateAutoAdjustModeSettings(this.plungerId, settings)

      await updateAction(
        () => this.state.plunger,
        (plunger) => this.setState({ plunger }),
        apiCall,
        'autoAdjustModeSettings',
        settings
      )
    }
  }

  private async updatePressureModeSettings(
    settings: PlungerPressureModeSettingsApiModel
  ) {
    if (this.areAccessTokenAndPlungerLiftDefined) {
      const apiCall = () =>
        this.api.updatePressureModeSettings(this.plungerId, settings)

      await updateAction(
        () => this.state.plunger,
        (plunger) => this.setState({ plunger }),
        apiCall,
        'pressureModeSettings',
        settings
      )
    }
  }

  private async updateTimeModeSettings(
    settings: PlungerTimeModeSettingsApiModel
  ) {
    if (this.areAccessTokenAndPlungerLiftDefined) {
      const apiCall = () =>
        this.api.updateTimeModeSettings(this.plungerId, settings)

      await updateAction(
        () => this.state.plunger,
        (plunger) => this.setState({ plunger }),
        apiCall,
        'timeModeSettings',
        settings
      )
    }
  }

  private async updateOperatingMode(mode?: PlungerOperatingMode) {
    if (this.areAccessTokenAndPlungerLiftDefined && mode) {
      await updateOperatingMode(
        this.props.accessToken!,
        this.props.tenantId.toString(),
        this.plungerId,
        mode,
        () => this.state.plunger,
        (plunger) => this.setState({ plunger })
      )
    }
  }

  private get areAccessTokenAndPlungerLiftDefined() {
    return Boolean(this.props.accessToken && this.state.site.site?.plungerLift)
  }

  private get plungerId() {
    return this.state.site.site?.plungerLift?.id!
  }

  private get api() {
    return getPlungerClient(
      this.props.accessToken!,
      this.props.tenantId.toString()
    )
  }
}

const mapPropsFromState = ({ sites, oidc, multitenantUser }: AppState) => {
  return {
    sites: sites,
    tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    accessToken: multitenantUser.accessToken,
  }
}

export default withRouter(
  connect(mapPropsFromState)(withUiContext(withStyles(styles)(PlungerLiftsTab)))
)
