import React from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { Link } from '@material-ui/core'
import { idRoute } from '../../App'
const SiteItem = (props) => {
  const route = props.route + idRoute
  const path = `/${generatePath(route, { id: props.id })}?status=0
  `
  return (
    <li className="site-item">
      <Link
        component={RouterLink}
        to={path}
        style={{
          fontWeight: props.selectedSite?.id == props.id ? 'bold' : 'normal',
        }}
      >
        {props.name || props.id}
      </Link>
    </li>
  )
}

class SiteList extends React.Component {
  filter(sites) {
    if (!this.props.filter) {
      return sites
    }
    return sites.filter(
      (site) =>
        site.name.toLowerCase().indexOf(this.props.filter.toLowerCase()) >= 0
    )
  }

  render() {
    const route = this.props.siteRoute
    const selectedSite = this.props.selection ? this.props.selection : undefined
    return (
      <ul className="site-list">
        {this.filter(this.props.allSites).map((site) => (
          <SiteItem
            name={site.name}
            route={route}
            id={site.id}
            selectedSite={selectedSite}
          ></SiteItem>
        ))}
      </ul>
    )
  }
}

export default SiteList
