import React from 'react'
import { connect } from 'react-redux'
import { duration, Duration, Moment } from 'moment'
import MomentUtils from '@date-io/moment'
import clsx from 'clsx'

import {
  Grid,
  Theme,
  createStyles,
  WithStyles,
  MenuItem,
  Popover,
  Paper,
  withStyles,
  Select,
  Typography,
  Fade,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {
  WatchLaterTwoTone as TimeRangeIcon,
  ExpandMoreTwoTone as MoreIcon,
  ExpandLessTwoTone as LessIcon,
  ThumbUpAltTwoTone as ApplyIcon,
  SaveAlt as SaveAsCsvIcon,
  RotateLeftOutlined,
} from '@material-ui/icons'
import PopupState, {
  bindPopover,
  InjectedProps as PopupStateType,
  bindTrigger,
} from 'material-ui-popup-state'
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DateTimePickerProps,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import theme from '../../theme'

import { AppState } from '../../store/AppState'
import {
  RelativeTimeRange,
  TimeRange,
  AbsoluteTimeRange,
  isAbsoluteTimeRange,
} from './TimeRange'
import CssStyle from './CssStyle'

const styles = (_: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'flex-start',
    },
    filtersGrid: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: '1fr 1fr',
      gridGap: theme.spacing(0.5),
      alignItems: 'center',
      justifyItems: 'start',
    },
    currentValue: {
      gridColumn: '1 / -1',
      gridRow: '1 / -1',
      justifySelf: 'end',
      alignSelf: 'start',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    disabled: {
      filter: 'grayscale(1) opacity(0.5)',
      cursor: 'auto',
    },
  })

export interface Props extends CssStyle {
  readonly accessToken?: string
  readonly tenantId: number
  readonly relativeTimeRanges: [RelativeTimeRange, React.ReactNode?][]
  readonly initialTimeRange?: TimeRange
  readonly onTimeRangeChanged?: (timeRange?: TimeRange) => void
  readonly onSaveAsCsv?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    timeRange?: TimeRange
  ) => void
  readonly readonly?: boolean
}

type AllProps = Props & WithStyles<typeof styles>

interface State {
  readonly timeRange?: TimeRange
  readonly absoluteTimeRange?: AbsoluteTimeRange
}

class TimeRangePicker extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    const currentTimeRange = this.getInitialTimeRange()
    const isAbsolute = currentTimeRange && isAbsoluteTimeRange(currentTimeRange)

    this.state = {
      timeRange: currentTimeRange,
      absoluteTimeRange: isAbsolute
        ? (currentTimeRange as AbsoluteTimeRange)
        : undefined,
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.initialTimeRange !== this.props.initialTimeRange) {
      this.setState({
        timeRange: this.getInitialTimeRange(),
      })
    }
  }

  public render() {
    const currentTr = this.state.timeRange
    const { style, classes, readonly } = this.props
    const { paper, filtersGrid } = classes
    const currValue = this.getCurrentValueToDisplay(currentTr)
    const isCurrentTrAbsolute = currentTr && isAbsoluteTimeRange(currentTr)
    const rtr = !isCurrentTrAbsolute
      ? (currentTr as RelativeTimeRange)
      : undefined
    const atr = isCurrentTrAbsolute
      ? (currentTr as AbsoluteTimeRange)
      : undefined

    return (
      <Grid
        container={true}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={style}
      >
        <PopupState variant="popover" popupId="popover">
          {(popupState: PopupStateType) => (
            <React.Fragment>
              <div
                className={paper}
                style={{ display: 'grid' }}
                {...(readonly ? {} : bindTrigger(popupState))}
              >
                {this.renderCurrentValue(currValue, popupState)}
              </div>
              <Tooltip title={'Reset filter'}>
                <IconButton
                  onClick={this.onReset(popupState)}
                  disabled={!!readonly}
                >
                  <RotateLeftOutlined />
                </IconButton>
              </Tooltip>
              {this.props.onSaveAsCsv && (
                <Tooltip title="Save as CSV">
                  <div>
                    <IconButton
                      disabled={!currentTr || readonly}
                      onClick={this.saveAsCsv(popupState)}
                    >
                      <SaveAsCsvIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                TransitionComponent={Fade}
              >
                <Paper
                  className={paper}
                  elevation={2}
                  style={{ display: 'grid' }}
                >
                  <div
                    className={filtersGrid}
                    style={{
                      gridColumn: 1,
                      gridRow: 1,
                    }}
                  >
                    <Typography className={paper} variant="button">
                      Relative
                    </Typography>
                    {this.renderRelativeFilters(popupState, rtr)}
                    <Typography className={paper} variant="button">
                      Absolute
                    </Typography>
                    {this.renderAbsoluteFilters(
                      popupState,
                      this.state.absoluteTimeRange || atr
                    )}
                  </div>
                  {this.renderCurrentValue(currValue, popupState)}
                </Paper>
              </Popover>
            </React.Fragment>
          )}
        </PopupState>
      </Grid>
    )
  }

  private getCurrentValueToDisplay(currentTr?: TimeRange) {
    return currentTr
      ? isAbsoluteTimeRange(currentTr)
        ? currentTr.from?.valueOf() !== currentTr.to?.valueOf()
          ? `${
              currentTr.from
                ? currentTr.from.format('lll')
                : 'From the beginning'
            } — ${currentTr.to ? currentTr.to.format('lll') : 'Now'}`
          : 'all'
        : currentTr.asMilliseconds() > 0
        ? `last ${currentTr.humanize(true)}`
        : 'last in a day'
      : undefined
  }

  private renderCurrentValue(currValue, popupState) {
    const {
      readonly,
      classes: { currentValue, disabled },
    } = this.props
    const iconClassName = !readonly ? '' : disabled

    return (
      <div
        className={!readonly ? currentValue : clsx(currentValue, disabled)}
        onClick={() => popupState.close()}
      >
        <TimeRangeIcon className={iconClassName} />
        &nbsp;
        <Typography>{currValue}</Typography>
        &nbsp;
        {popupState.isOpen ? (
          <LessIcon className={iconClassName} />
        ) : (
          <MoreIcon className={iconClassName} />
        )}
      </div>
    )
  }

  private renderRelativeFilters(
    popupState: PopupStateType,
    rtr?: RelativeTimeRange
  ) {
    return (
      <Select
        value={rtr ? rtr.toISOString() : ''}
        onChange={this.setRelativeTimeRange(popupState)}
      >
        {this.props.relativeTimeRanges.map(
          (timeRange: [Duration, React.ReactNode?]) => {
            const tp = timeRange[0]
            const tpString = tp.toISOString()
            const displayValue = timeRange[1]
            return (
              <MenuItem key={tpString} value={tpString} selected={rtr === tp}>
                {displayValue || `last ${tp.humanize(true)}`}
              </MenuItem>
            )
          }
        )}
      </Select>
    )
  }

  private readonly renderAbsoluteFilters = (
    popupState: PopupStateType,
    atr?: AbsoluteTimeRange
  ) => {
    const picker =
      (
        label,
        onChange: (date: MaterialUiPickersDate) => void,
        minDate: Moment | undefined,
        maxDate: Moment | undefined
      ) =>
      (props: Pick<DateTimePickerProps, 'value'>) =>
        (
          <DateTimePicker
            label={label}
            clearable={true}
            inputVariant="outlined"
            variant="dialog"
            showTodayButton={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={(_) => {}}
            onAccept={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={true}
            strictCompareDates={true}
            {...props}
          />
        )

    const setState = (from: MaterialUiPickersDate, to: MaterialUiPickersDate) =>
      this.setState({
        absoluteTimeRange: { from, to },
      })
    const FromPicker = picker(
      'From',
      (from) => setState(from, atr?.to || null),
      undefined,
      atr?.to || undefined
    )
    const ToPicker = picker(
      'To',
      (to) => setState(atr?.from || null, to),
      atr?.from || undefined,
      undefined
    )
    const { timeRange, absoluteTimeRange } = this.state
    const { readonly } = this.props

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container={true}>
          <FromPicker value={atr?.from ?? null} />
          &nbsp;
          <ToPicker value={atr?.to ?? null} />
          {}
          &nbsp;
          <Tooltip title="Apply">
            <div>
              <IconButton
                disabled={
                  JSON.stringify(timeRange) ===
                    JSON.stringify(absoluteTimeRange) ||
                  (!atr?.from && !atr?.to) ||
                  readonly
                }
                onClick={this.setAbsoluteTimeRange(popupState)}
              >
                <ApplyIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }

  private readonly setTimeRange = (
    popupState: PopupStateType,
    timeRange?: TimeRange
  ) => {
    popupState.close()

    this.setState({
      ...this.state,
      timeRange: timeRange ?? this.getInitialTimeRange(),
    })

    if (this.props.onTimeRangeChanged) {
      this.props.onTimeRangeChanged(timeRange)
    }
  }

  private readonly setRelativeTimeRange =
    (popupState: PopupStateType) => (event: React.ChangeEvent<{ value }>) => {
      const relativeTimeRange = duration(event.target.value)

      this.setTimeRange(popupState, relativeTimeRange)
    }

  private readonly setAbsoluteTimeRange = (popupState: PopupStateType) => () =>
    this.state.absoluteTimeRange &&
    this.setTimeRange(popupState, this.state.absoluteTimeRange)

  private readonly saveAsCsv =
    (popupState: PopupStateType) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      popupState.close()
      if (this.props.onSaveAsCsv) {
        this.props.onSaveAsCsv(event, this.state.timeRange)
      }
    }

  private readonly getInitialTimeRange = () => {
    return (
      this.props.initialTimeRange ||
      (this.props.relativeTimeRanges.length
        ? this.props.relativeTimeRanges[0][0]
        : undefined)
    )
  }

  private readonly onReset =
    (popupState: PopupStateType) => (event: React.MouseEvent<any>) => {
      event.preventDefault()
      this.setTimeRange(popupState)
    }
}

const mapStateToProps = (state: AppState) => ({
  accessToken: state.multitenantUser.accessToken,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

export default withStyles(styles)(connect(mapStateToProps)(TimeRangePicker))
