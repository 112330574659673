import React from 'react'
import { withContext } from './Context'

export interface MySitesContextState {
  readonly selectedGroupId?: string
  readonly showMap?: boolean
  readonly hideMapToggle?: boolean
}

export interface MySitesContextData extends MySitesContextState {
  readonly setSelectedGroupId?: (groupId?: string) => void
  readonly setMapSettings?: (showMap: boolean, hideMapToggle?: boolean) => void
}

const mySitesContext = React.createContext<MySitesContextData>({})

export default mySitesContext

export const withMySitesContext = withContext(mySitesContext)
