import React from 'react'

import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { ExpandMoreTwoTone } from '@material-ui/icons'

import EmailNotification from './components/EmailNotifications'
import SmsNotification from './components/SmsNotifications'
import CallNotification from './components/CallNotifications'
import { SmsDataAlert } from './models/SmsDataAlert'
import { CallDataAlert } from './models/CallDataAlert'
import { EmailDataAlert } from './models/EmailDataAlert'

interface PropsFromState {
  phoneNumber?: string
  email?: string
  isUserAdmin?: boolean
  isSuperVisor?: boolean
  smsData?: SmsDataAlert
  callData?: CallDataAlert
  emailData?: EmailDataAlert
  featureFlag?: any
  handleDataChangeSms: any
  handleDataChangeEmail: any
  handleDataChangeCall: any
}

type AllProps = PropsFromState

export function AlertSetUp(props: AllProps) {
  return (
    <div style={{ width: '70%' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" variant="h6">
            SMS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SmsNotification
            phoneNumber={props.phoneNumber}
            isUserAdmin={props.isUserAdmin}
            isSuperVisor={props.isSuperVisor}
            onData={props.handleDataChangeSms}
            smsData={props.smsData}
            featureFlags={props.featureFlag}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography color="primary" variant="h6">
            Emails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EmailNotification
            email={props.email}
            isUserAdmin={props.isUserAdmin}
            isSuperVisor={props.isSuperVisor}
            onData={props.handleDataChangeEmail}
            emailData={props.emailData}
            featureFlags={props.featureFlag}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography color="primary" variant="h6">
            Calls
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CallNotification
            phoneNumber={props.phoneNumber}
            isUserAdmin={props.isUserAdmin}
            isSuperVisor={props.isSuperVisor}
            onData={props.handleDataChangeCall}
            callData={props.callData}
            featureFlags={props.featureFlag}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
