export function parseError(error: Response | Error | string): string {
  if (error instanceof Response) {
    return error.statusText
  }

  const isProduction = process.env.NODE_ENV === 'production'

  if (error instanceof Error) {
    return isProduction ? error.message : `\n${error.stack}`
  }

  return isProduction ? 'An unknown error occurred.' : error
}

export function delay(delayMilliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delayMilliseconds))
}
