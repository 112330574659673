import React from 'react'
import { connect } from 'react-redux'

import { createStyles, Theme, alpha } from '@material-ui/core/styles'
import {
  WithStyles,
  MenuItem,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab'
import { SearchTwoTone as SearchIcon } from '@material-ui/icons'
import { debounce } from 'throttle-debounce'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
    inputRoot: {
      color: 'inherit',
      backgroundColor: alpha(theme.palette.secondary.main, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.25),
      },
      width: 250,
    },
    searchIcon: {
      paddingLeft: theme.spacing(1),
      height: '100%',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    autocompleteFilledInput: {
      '&.MuiFilledInput-underline:before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
    autocompletePopupIndicator: {
      color: theme.palette.primary.light,
    },
    autocompleteOption: {
      '& > .MenuItem.Mui-selected': {
        color: theme.palette.secondary.main,
      },
    },
  })

interface Props<Option> {
  readonly accessToken?: string
  readonly options: Option[]
  readonly onOptionSelected: (data: Option) => void
  readonly getOptionLabel: (option: Option) => string
  readonly placeholder?: string
  readonly disabled?: boolean
  readonly renderOption?: (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  readonly loading?: boolean
  readonly search: (query: string) => void
  readonly noOptionsText?: React.ReactNode
  readonly onQueryChanged?: (query: string, isValidQuery: boolean) => void
}

interface State {
  readonly value: any
}

type AllProps<Option> = Props<Option> & WithStyles<typeof styles>

class Search<Option> extends React.Component<AllProps<Option>, State> {
  constructor(props) {
    super(props)

    this.state = { value: null }
  }

  public render() {
    const { classes, placeholder, loading, disabled, noOptionsText } =
      this.props

    return (
      <div className={classes.root}>
        <Autocomplete<Option>
          classes={{
            inputRoot: classes.autocompleteFilledInput,
            popupIndicator: classes.autocompletePopupIndicator,
            option: classes.autocompleteOption,
          }}
          autoComplete={true}
          autoHighlight={true}
          disableListWrap={true}
          options={this.props.options}
          getOptionLabel={this.props.getOptionLabel}
          renderInput={(renderProps) => (
            <TextField
              {...renderProps}
              placeholder={placeholder}
              fullWidth={true}
              variant="filled"
              InputProps={{
                ...renderProps.InputProps,
                classes: {
                  root: classes.inputRoot,
                },
                startAdornment: (
                  <div className={classes.searchIcon}>
                    {loading ? (
                      <CircularProgress size="1em" color="inherit" />
                    ) : (
                      <SearchIcon />
                    )}
                  </div>
                ),
                style: { padding: 0 },
              }}
            />
          )}
          renderOption={this.props.renderOption || this.renderOption}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          value={this.state.value}
          disabled={disabled}
          loading={loading}
          loadingText={<CircularProgress />}
          noOptionsText={loading ? <CircularProgress /> : noOptionsText}
        />
        <div className={classes.divider} />
      </div>
    )
  }

  private renderOption(option: Option, state: AutocompleteRenderOptionState) {
    return (
      <MenuItem selected={state.selected}>
        {this.props.getOptionLabel(option)}
      </MenuItem>
    )
  }

  private readonly onChange = (
    _: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      this.props.onOptionSelected(option)

      this.setState({ value: null })
    }
  }

  private readonly onInputChange = (event: React.ChangeEvent<{}>) => {
    const query = (event as React.ChangeEvent<HTMLInputElement>).target.value
    const isValidQuery = !!query && query.length >= 2

    if (isValidQuery) {
      this.autocompleteSearchDebounced(query)
    }

    this.props.onQueryChanged && this.props.onQueryChanged(query, isValidQuery)
  }

  private readonly autocompleteSearchDebounced = debounce(2000, (query) =>
    this.props.search(query)
  )
}

export default withStyles(styles)(connect()(Search)) as <
  Option extends unknown
>(
  props: Props<Option>
) => any
