import * as React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core'
import { SaveTwoTone } from '@material-ui/icons'
import { PlungerOperatingMode } from 'api/apiservice'
import { getPlungerOperatingModeFriendlyName } from './formatter'

interface Props {
  readonly operatingMode?: PlungerOperatingMode
  onCloseDialog(): void
  onSave(newMode?: PlungerOperatingMode): void
}

interface State {
  readonly isEditingPopUpVisible: boolean
  readonly changedOperatingMode?: PlungerOperatingMode
}

type AllProps = Props

class ChangeOperatingModeDialog extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      isEditingPopUpVisible: false,
      changedOperatingMode: this.props.operatingMode,
    }
  }

  public render() {
    return (
      <Dialog disableEscapeKeyDown={true} open={true}>
        <DialogTitle>Edit Operating Mode</DialogTitle>
        <DialogContent dividers={true}>
          {this.renderDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={this.isSaveDisabled()}
            style={{ width: '120px' }}
            variant="contained"
            color="primary"
            startIcon={<SaveTwoTone />}
            onClick={this.onSaveClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private readonly onSaveClicked = () => {
    this.props.onSave(this.state.changedOperatingMode)
  }

  private readonly isSaveDisabled = () => {
    return (
      this.state.changedOperatingMode === undefined ||
      this.state.changedOperatingMode === this.props.operatingMode
    )
  }

  private readonly renderDialogContent = () => {
    if (this.state.changedOperatingMode === undefined) {
      return <div>No Operating Mode Defined</div>
    }

    return (
      <Select
        value={this.state.changedOperatingMode}
        onChange={this.setNewOperatingMode}
      >
        {Object.keys(PlungerOperatingMode)
          .filter((key) => !isNaN(Number(PlungerOperatingMode[key])))
          .map((m) => {
            const value = PlungerOperatingMode[m] as PlungerOperatingMode
            return (
              <MenuItem key={m} value={value}>
                {getPlungerOperatingModeFriendlyName(value)}
              </MenuItem>
            )
          })}
      </Select>
    )
  }

  private readonly setNewOperatingMode = (
    event: React.ChangeEvent<{ value }>
  ) => {
    this.setState({
      changedOperatingMode: Number(event.target.value),
    })
  }
}

export default ChangeOperatingModeDialog
