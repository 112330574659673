import * as React from 'react'
import { Typography } from '@material-ui/core'
import {
  DoubleRowBasicWithEmptyText,
  GreyTextTypography,
} from 'components/misc/Components'

export interface Props {
  readonly title?: string
  readonly titleExtension?: React.ReactNode
  readonly subtitle?: string
}

export function TitleAndSubtitle(props: Props) {
  const { title, titleExtension, subtitle } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DoubleRowBasicWithEmptyText
        title={title}
        titleExtension={titleExtension}
        titleVariant="h6"
        TitleComponent={Typography}
        titleStyle={{ display: 'flex' }}
        caption={subtitle}
        CaptionComponent={GreyTextTypography}
        captionStyle={{ marginTop: '-5px' }}
      />
    </div>
  )
}
