import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'

import { ThresholdsTextValidatorProps } from './ThresholdsTextValidator'
import ThresholdsTextValidatorBase from './ThresholdsTextValidatorBase'
import * as Constants from './constants'

type AllProps = Omit<ThresholdsTextValidatorProps, 'telemetryType'>

const ruleNames = [
  Constants.RequiredRuleName,
  Constants.FromLessThanToRuleName,
  Constants.ToGreaterThanFromRuleName,
  Constants.RangeLimitRuleName,
  Constants.IntersectingRangeRuleName,
]

class DefaultThresholdsTextValidation extends React.Component<AllProps> {
  public componentDidMount() {
    ValidatorForm.addValidationRule(
      Constants.FromLessThanToRuleName,
      (rawValue) => {
        return rawValue < this.props.editedThreshold!.maxThreshold
      }
    )

    ValidatorForm.addValidationRule(
      Constants.ToGreaterThanFromRuleName,
      (rawValue) => {
        return rawValue > this.props.editedThreshold!.minThreshold
      }
    )

    ValidatorForm.addValidationRule(
      Constants.RangeLimitRuleName,
      (rawValue) => {
        return (
          rawValue >= Constants.MinRangeValue &&
          rawValue <= Constants.MaxRangeValue
        )
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}From`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { maxThreshold } = editedThreshold!

        return this.props.checkIntersectingRule(rawValue, maxThreshold)
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}To`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { minThreshold } = editedThreshold!

        return this.props.checkIntersectingRule(minThreshold, rawValue)
      }
    )

    ValidatorForm.addValidationRule(
      Constants.RequiredRuleName,
      (rawValue) => rawValue !== undefined
    )
  }

  public componentWillUnmount() {
    ruleNames.forEach(ValidatorForm.removeValidationRule)
  }

  public render() {
    const {
      name,
      value,
      ruleName,
      ruleMessage,
      index,
      property,
      handleThresholdChange,
    } = this.props

    const validators = [
      Constants.RequiredRuleName,
      ruleName,
      Constants.RangeLimitRuleName,
      `${Constants.IntersectingRangeRuleName}${name}`,
    ]
    const errorMessages = [
      Constants.RequiredRuleName,
      ruleMessage,
      `Must be from ${Constants.MinRangeValue} to ${Constants.MaxRangeValue}`,
      'Intersecting ranges',
    ]

    return (
      <ThresholdsTextValidatorBase
        name={name}
        value={value}
        errorMessages={errorMessages}
        validators={validators}
        index={index}
        property={property}
        handleThresholdChange={handleThresholdChange}
        textType="number"
        placeholder="——"
      />
    )
  }
}

export default DefaultThresholdsTextValidation
