import { StatusRange } from 'api/alertservice'
import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import theme from '../../../theme'

export type StatusThresholdMinMaxProperties = Pick<
  StatusRange,
  'minThreshold' | 'maxThreshold' | 'isHighPriority' | 'allowPumpControl'
>

export interface Props {
  name: string
  value: any
  errorMessages?: any[] | string
  validators?: any[]
  index: number
  property: keyof Pick<
    StatusRange,
    'minThreshold' | 'maxThreshold' | 'isHighPriority' | 'allowPumpControl'
  >
  handleThresholdChange?: (
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ) => void
  handleThresholdBlur?: (
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ) => void
  textType?: string
  placeholder?: string
  inputProps?: object
}

type AllProps = Props

class ThresholdsTextValidatorBase extends React.Component<AllProps> {
  public render() {
    const {
      handleThresholdChange,
      handleThresholdBlur,
      textType,
      inputProps,
      index,
      property,
      value,
      ...otherProps
    } = this.props

    return (
      <TextValidator
        {...otherProps}
        value={this.roundValue(value)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
          handleThresholdBlur &&
          handleThresholdBlur(event.target.value, index, property)
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleThresholdChange &&
          handleThresholdChange(event.target.value, index, property)
        }
        type={textType}
        size="small"
        InputLabelProps={{ shrink: true }}
        InputProps={inputProps}
        style={{
          width: '90px',
          margin: theme.spacing(0.5, 0.5, 0, 1),
        }}
        FormHelperTextProps={{
          style: {
            margin: '4px 0',
            fontSize: '0.7rem',
          },
        }}
      />
    )
  }

  private roundValue(value) {
    if (!value) return value

    const valStr = value.toString()

    if (valStr.match(/^-?(0|0?[1-9]\d*)\.\d{3}$/g)) {
      return valStr.replace(/.$/, '')
    }
    return valStr
  }
}

export default ThresholdsTextValidatorBase
