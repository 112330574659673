import React, { useEffect } from 'react'

import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import LockIcon from '@material-ui/icons/LockTwoTone'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import logo from 'img/Scada_Lite_logo-01.png'
import { loginRequest } from 'auth/authConfig'
import { createBrowserHistory } from 'history'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useMsal } from '@azure/msal-react'
const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
type AllProps = React.PropsWithChildren<any> & WithStyles<typeof styles>

function SignIn(props: AllProps) {
  const [ShowFailedSignIn, setShowFailedSignIn] = React.useState(false)
  const [error, setError] = React.useState(undefined)
  const msalContext = useMsal()

  useEffect(() => {
    validateUnauthorized()
  }, [])

  const validateUnauthorized = () => {
    const history = createBrowserHistory()
    const path = history.location.pathname
    if (path.includes('failed-signin')) {
      console.info('Unauthorized User')
      setShowFailedSignIn(true)
    }
  }
  const classes = props.classes

  const onSignInButtonClick = async (event) => {
    event.preventDefault()

    try {
      setError(undefined)
      await msalContext.instance.loginRedirect(loginRequest)
    } catch (e) {
      setError(error)
    }
  }
  const closeFailedSignIn = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setShowFailedSignIn(false)
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <img alt="SCADA Lite" src={logo} width="50%" />
        <form className={classes.form}>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSignInButtonClick}
          >
            Sign In
          </Button>
        </form>
        {error && <Typography color="error">{error}</Typography>}
      </div>

      <Snackbar
        open={ShowFailedSignIn}
        autoHideDuration={6000}
        onClose={closeFailedSignIn}
      >
        <Alert severity="error" onClose={closeFailedSignIn}>
          Your account does not have access to Wellmanager
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default withStyles(styles)(SignIn)
