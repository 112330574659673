/* import external modules */
import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { useDispatch, useSelector } from 'react-redux'

/* import internal modules */
import { useStyles } from './styles'
import { setHandleAlert } from '../../../store/actions/common'

/**
 *  @description This  component is the alert for show status messages requests
 *  @version 1.0.0
 *  @since 22/09/2021
 *  @author Jaime Andrés Gómez Gutiérrez <jaime.gomez@teaminternational.com>
 *  @component
 *  @name Alert
 *  @returns {Component} Returns a generic alert
 *
 **/

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AlertComponent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleAlert = useSelector((state) => state.common.handleAlert)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(
      setHandleAlert({
        message: '',
        status: false,
        severity: handleAlert.severity,
      })
    )
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={handleAlert.status}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={handleAlert.severity}>
          {handleAlert.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertComponent
