import * as React from 'react'

import Search from 'components/misc/Search'
import { SitesState, Site, mapSite } from 'store/Site'
import { getSiteClient } from 'api/client'

interface SiteSearchProps {
  readonly accessToken: string
  readonly tenantId: string
  readonly className?: string
  readonly onOptionSelected: (data: Site) => void
}

export function SiteSearch(props: SiteSearchProps) {
  const [sites, setSites] = React.useState<SitesState>({ sites: [] })
  const [isValidSitesQuery, setIsValidSitesQuery] = React.useState(false)

  function renderSearchOption(site: Site) {
    return (
      <div>
        {site.name}
        <div style={{ fontSize: '0.7em' }}>ID:&nbsp;{site.id}</div>
      </div>
    )
  }

  async function searchSites(query: string) {
    setSites({ ...sites, loading: true })

    try {
      const api = getSiteClient(props.accessToken, props.tenantId)
      const { result: foundSites } = await api.getByQuery(query)

      setSites({
        ...sites,
        sites: foundSites.map(mapSite),
        loading: false,
      })
    } catch (error) {
      console.error(error)
      setSites({ ...sites, loading: false })
    }
  }

  return (
    <div className={props.className}>
      <Search<Site>
        loading={sites.loading}
        options={sites.sites}
        onOptionSelected={props.onOptionSelected}
        getOptionLabel={(site: Site) => site.name ?? site.id}
        placeholder="Search a site"
        renderOption={renderSearchOption}
        search={searchSites}
        noOptionsText={
          isValidSitesQuery
            ? undefined
            : 'At least 2 chars required to start searching'
        }
        onQueryChanged={(_, isValidQuery) => {
          setIsValidSitesQuery(isValidQuery)
          setSites({
            ...sites,
            loading: isValidQuery,
          })
        }}
      />
    </div>
  )
}
