export enum ConfigType {
  Thresholds = 1,
}

export function getConfigTitle(config: ConfigType) {
  switch (config) {
    case ConfigType.Thresholds:
      return 'Thresholds'

    default:
      return '?'
  }
}
