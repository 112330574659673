import React from 'react'
import { Alert, AlertTitle, Color } from '@material-ui/lab'

export function renderAlert(
  message: string,
  title?: string,
  severity?: Color,
  onClose?: (event: React.SyntheticEvent) => void
) {
  return (
    <Alert severity={severity} onClose={onClose} variant="filled">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  )
}

export const renderErrorAlert = (
  message: string,
  title?: string,
  onClose?: (event: React.SyntheticEvent) => void
) => renderAlert(message, title, 'error', onClose)
