import { TelemetryType } from 'api/apiservice'
import {
  feetAndInchesToInches,
  getReadableTelemetryType,
  getTelemetryTypeUnit,
  inchToFeetAndInches,
} from './converter'

export function formatActualTankLevelFeet(gaugedTankLevelInch) {
  const [tankLevelFt, tankLevelIn] = inchToFeetAndInches(gaugedTankLevelInch)
  return `${tankLevelFt.toFixed()}' ${tankLevelIn.toFixed()}"`
}

export function formatTelemetryTypeAndUnit(type: TelemetryType) {
  const readableType = getReadableTelemetryType(type)
  const readableUnit = getTelemetryTypeUnit(type)

  return readableUnit !== ''
    ? `${readableType} (${readableUnit})`
    : readableType
}

export function getInchesNumFromString(input: string) {
  const [feetHeight, inchesHeight] = input.split("'")
  const feetNum = parseInt(feetHeight?.trim(), 10) || 0
  const inchesNum = parseInt(inchesHeight?.trim(), 10) || 0

  return feetAndInchesToInches(feetNum, inchesNum)
}
