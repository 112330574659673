import { HANDLE_UTIL_ALERT, HANDLE_GENERIC_DIALOG } from '../types/types'

export const setHandleAlert = (handleAlert) => ({
  type: HANDLE_UTIL_ALERT,
  payload: { handleAlert },
})

export const setHandleDialog = (handleDialog) => ({
  type: HANDLE_GENERIC_DIALOG,
  payload: { handleDialog },
})
