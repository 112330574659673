import { getFetch, multitenantAlertsApiBasePath } from './fetch'
import {
  TelemetryType,
  GlobalThresholdsClient,
  AlertThresholdsClient,
  SwaggerException,
  AlertThreshold,
  UpdateGlobalThresholdApiModel,
  StatusRange,
  TelemetryThresholdApiModel,
  UpdateAlertThresholdApiModel,
  CreateAlertThresholdApiModel,
} from './alertservice'

export type TelemetryTypeToStatusRanges = Map<TelemetryType, StatusRange[]>

export interface Threshold {
  readonly Id?: string
  readonly AssetId?: string
  readonly thresholds: TelemetryTypeToStatusRanges
}

export const newTelemetryTypeToStatusRanges = (
  other?: TelemetryTypeToStatusRanges | [TelemetryType, StatusRange[]][]
) => new Map<TelemetryType, StatusRange[]>(other ?? [])

export const getEmptyTelemetryTypeToStatusRanges = () =>
  newTelemetryTypeToStatusRanges()

function getGlobalThresholdsClient(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new GlobalThresholdsClient(
    multitenantAlertsApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export function getAlertThresholdsClient(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new AlertThresholdsClient(
    multitenantAlertsApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export async function loadGlobalThresholds(
  accessToken: string,
  tenantId: string
): Promise<Threshold> {
  const thresholdsMap = getEmptyTelemetryTypeToStatusRanges()

  try {
    const api = getGlobalThresholdsClient(accessToken, tenantId)
    const response = await api.getAllThresholds()

    return {
      Id: response.result.id,
      thresholds: mapAlertThresholds(response.result),
    }
  } catch (error) {
    if (error instanceof SwaggerException && error.status === 404) {
      return {
        Id: undefined,
        thresholds: thresholdsMap,
      }
    }

    throw error
  }
}

export async function loadAlertThresholds(
  siteId: string,
  accessToken: string,
  tenantId: string
): Promise<Threshold[]> {
  try {
    const api = getAlertThresholdsClient(accessToken, tenantId)
    const response = await api.getBySiteId(siteId)

    return mapAlertThresholdsToThresholds(response.result)
  } catch (error) {
    if (error instanceof SwaggerException) {
      if (error.status === 404) {
        return []
      }
    }
    throw error
  }
}

export async function loadSitesThresholds(
  accessToken: string,
  tenantId: string,
  siteIds: string[]
): Promise<[string, Threshold[]][]> {
  try {
    const api = getAlertThresholdsClient(accessToken, tenantId)
    const response = await api.getBySiteIds(siteIds)
    const allSitesAlertThresholds = response.result.reduce(
      (siteIdToThresholds, alertThreshold) => {
        const siteId = alertThreshold.siteId!

        if (!siteIdToThresholds.has(siteId)) {
          siteIdToThresholds.set(siteId, [])
        }

        siteIdToThresholds.get(siteId)!.push(alertThreshold)

        return siteIdToThresholds
      },
      new Map<string, AlertThreshold[]>()
    )

    const allSitesThresholds: [string, Threshold[]][] = [
      ...allSitesAlertThresholds.entries(),
    ].map(([siteId, alertThresholds]) => [
      siteId,
      mapAlertThresholdsToThresholds(alertThresholds),
    ])

    return allSitesThresholds
  } catch (error) {
    if (error instanceof SwaggerException) {
      if (error.status === 404) {
        return []
      }
    }
    throw error
  }
}

function mapAlertThresholdsToThresholds(alertThreshold: AlertThreshold[]) {
  const thresholdsArray: Threshold[] = alertThreshold.map((allThresholds) => {
    const thresholdsMap = getEmptyTelemetryTypeToStatusRanges()

    allThresholds.thresholds?.forEach((threshold) => {
      const thresholdsForConcreteTelemetryType = thresholdsMap.get(
        threshold.telemetryType
      )

      thresholdsMap.set(
        threshold.telemetryType,
        (thresholdsForConcreteTelemetryType ?? []).concat(
          threshold.statusRanges ?? []
        )
      )
    })

    return {
      Id: allThresholds.id,
      AssetId: allThresholds.assetId,
      thresholds: thresholdsMap,
    }
  })

  return thresholdsArray
}

function mapAlertThresholds(alertThreshold: AlertThreshold) {
  const thresholdsMap = getEmptyTelemetryTypeToStatusRanges()
  alertThreshold.thresholds?.forEach((threshold) => {
    const thresholdsForConcreteTelemetryType = thresholdsMap.get(
      threshold.telemetryType
    )

    thresholdsMap.set(
      threshold.telemetryType,
      (thresholdsForConcreteTelemetryType ?? []).concat(
        threshold.statusRanges ?? []
      )
    )
  })

  return thresholdsMap
}

export async function deleteThresholds(
  accessToken: string,
  tenantId: string,
  id: string,
  userId?: string
) {
  const api = getAlertThresholdsClient(accessToken, tenantId, userId)
  await api.deleteById(id)
}

export async function updateTelemetryThresholds(
  accessToken: string,
  tenantId: string,
  telemetryType: TelemetryType,
  statusRanges: StatusRange[],
  userId?: string
) {
  try {
    const request = UpdateGlobalThresholdApiModel.fromJS({
      telemetryType,
      statusRanges,
    })
    const api = getGlobalThresholdsClient(accessToken, tenantId, userId)

    await api.updateThresholds(request)
  } catch (e) {
    throw new Error(e)
  }
}

export async function updateAlertThresholds(
  accessToken: string,
  tenantId: string,
  siteId: string,
  siteName: string,
  assetId: string,
  assetName: string,
  updatedThresholds: Threshold,
  userId?: string
) {
  const entries = (
    Array.from(updatedThresholds.thresholds.entries()) ?? []
  ).filter(([_, sr]) => sr.length)

  if (!entries.length) {
    await deleteThresholds(accessToken, tenantId, updatedThresholds.Id!, userId)
    return
  }

  const api = getAlertThresholdsClient(accessToken, tenantId, userId)
  const telemetryThresholdsDto = entries.map(([key, value]) =>
    TelemetryThresholdApiModel.fromJS({
      telemetryType: key,
      statusRanges: value,
    })
  )

  if (updatedThresholds.Id === undefined) {
    const request = CreateAlertThresholdApiModel.fromJS({
      siteId,
      siteName,
      assetId,
      assetName,
      thresholds: telemetryThresholdsDto,
    })

    const response = await api.createAlertThreshold(request)
    return response.result
  } else {
    const request = UpdateAlertThresholdApiModel.fromJS({
      siteId,
      assetId,
      id: updatedThresholds.Id,
      thresholds: telemetryThresholdsDto,
    })

    const response = await api.updateAlertThresholds(request)
    return response.result
  }
}
