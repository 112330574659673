import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'

import ThresholdsTextValidatorBase, {
  StatusThresholdMinMaxProperties,
} from './ThresholdsTextValidatorBase'
import {
  formatActualTankLevelFeet,
  getInchesNumFromString,
} from 'components/utils/formatter'
import { ThresholdsTextValidatorProps } from './ThresholdsTextValidator'
import FeetInchesInputMask from './FeetInchesInputMask'
import * as Constants from './constants'

type AllProps = Omit<ThresholdsTextValidatorProps, 'telemetryType'>

const ruleNames = [
  Constants.RequiredRuleName,
  Constants.FromLessThanToRuleName,
  Constants.ToGreaterThanFromRuleName,
  Constants.IntersectingRangeRuleName,
]

class TankLevelThresholdsTextValidator extends React.Component<AllProps> {
  public componentDidMount() {
    ValidatorForm.addValidationRule(
      Constants.FromLessThanToRuleName,
      (rawValue) => {
        const value = getInchesNumFromString(rawValue)
        return value < this.props.editedThreshold!.maxThreshold
      }
    )

    ValidatorForm.addValidationRule(
      Constants.ToGreaterThanFromRuleName,
      (rawValue) => {
        const value = getInchesNumFromString(rawValue)
        return value > this.props.editedThreshold!.minThreshold
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}From`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { maxThreshold } = editedThreshold!
        const value = getInchesNumFromString(rawValue)

        return this.props.checkIntersectingRule(value, maxThreshold)
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}To`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { minThreshold } = editedThreshold!
        const value = getInchesNumFromString(rawValue)

        return this.props.checkIntersectingRule(minThreshold, value)
      }
    )

    ValidatorForm.addValidationRule(
      Constants.RequiredRuleName,
      (rawValue) => rawValue !== undefined
    )
  }

  public componentWillUnmount() {
    ruleNames.forEach(ValidatorForm.removeValidationRule)
  }

  public render() {
    const { name, value, ruleName, ruleMessage, index, property } = this.props

    const validators = [
      Constants.RequiredRuleName,
      ruleName,
      `${Constants.IntersectingRangeRuleName}${name}`,
    ]
    const errorMessages = [
      Constants.RequiredRuleName,
      ruleMessage,
      'Intersecting ranges',
    ]

    return (
      <ThresholdsTextValidatorBase
        name={name}
        value={this.getFeetAndInchHeight(value)}
        errorMessages={errorMessages}
        validators={validators}
        index={index}
        property={property}
        handleThresholdBlur={this.handleThresholdsBlur}
        inputProps={{
          inputComponent: FeetInchesInputMask as any,
        }}
      />
    )
  }

  private readonly handleThresholdsBlur = (
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ) => {
    const value = getInchesNumFromString(stringValue)
    this.props.handleThresholdChange(value.toString(), index, property)
  }

  private readonly getFeetAndInchHeight = (
    heightInch: number | undefined
  ): string => {
    if (heightInch === undefined) {
      return ''
    }

    return formatActualTankLevelFeet(heightInch)
  }
}

export default TankLevelThresholdsTextValidator
