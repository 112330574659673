import React from 'react'

import { ModelState } from '../../../store/ModelState'
import {
  loadGlobalThresholds,
  TelemetryTypeToStatusRanges,
  newTelemetryTypeToStatusRanges,
} from '../../../api/thresholdClient'
import TelemetryThresholds from './TelemetryThresholds'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export interface Props {
  readonly accessToken?: string
  readonly tenantId: number
}

type AllProps = Props

interface State extends ModelState {
  readonly allThresholds: TelemetryTypeToStatusRanges
}

class GlobalThresholdConfiguration extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      allThresholds: newTelemetryTypeToStatusRanges(),
    }
  }

  public componentDidMount() {
    if (!this.state.allThresholds.size) {
      this.loadThresholds()
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.tenantId && prevProps.tenantId !== this.props.tenantId) {
      this.loadThresholds()
    }
  }

  public render() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {this.state.loading ? (
          <CircularProgress
            style={{
              width: '70px',
              height: '70px',
            }}
          />
        ) : (
          <div
            style={{
              marginTop: '15px',
              display: 'grid',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {this.renderThresholds()}
          </div>
        )}
      </div>
    )
  }

  private renderThresholds() {
    const { allThresholds } = this.state

    if (this.state.error) {
      return this.renderError()
    }

    if (allThresholds.size === 0) {
      return (
        <Grid
          container={true}
          style={{
            marginTop: '15px',
            justifyContent: 'center',
          }}
        >
          <Grid item={true} xs={12} md={10} lg={10}>
            <Typography style={{ textAlign: 'center' }}>
              Global thresholds are not configured
            </Typography>
          </Grid>
        </Grid>
      )
    }

    return Array.from(allThresholds)
      .sort((a, b) => a[0] - b[0])
      .map(([key, value]) => (
        <TelemetryThresholds
          key={key.toString()}
          type={key}
          statusRanges={value}
        />
      ))
  }

  private readonly loadThresholds = async () => {
    const { accessToken, tenantId } = this.props

    if (accessToken && tenantId) {
      try {
        this.setState({ loading: true })
        const threshold = await loadGlobalThresholds(
          accessToken,
          tenantId.toString()
        )
        this.setState({
          allThresholds: threshold.thresholds,
          loading: false,
          error: undefined,
        })
      } catch (error) {
        console.error(error)

        this.setState({
          error: 'Failed to load thresholds',
          loading: false,
        })
      }
    }
  }

  private renderError() {
    return (
      <Grid
        container={true}
        style={{
          marginTop: '15px',
          justifyContent: 'center',
        }}
      >
        <Grid item={true} xs={12} md={10} lg={10}>
          <Alert
            variant="filled"
            style={{ padding: '10px 40px' }}
            severity="error"
          >
            {this.state.error}
          </Alert>
        </Grid>
      </Grid>
    )
  }
}

export default GlobalThresholdConfiguration
