/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

const SmsNotification = (props) => {
  const getStartTimeDefault = (value) => {
    if (value === '00:01') {
      return moment().set({ hour: 0, minute: 1 })
    } else {
      return moment(value, 'HH:mm')
    }
  }

  const getEndTimeDefault = (value) => {
    if (value === '23:59') {
      return moment().set({ hour: 23, minute: 59 })
    } else {
      return moment(value, 'HH:mm')
    }
  }

  const dataLoaded = props.smsData

  const [isLoadedDataThreshold, setIsLoadedDataThreshold] = useState(true)
  const [isLoadedDataGateways, setIsLoadedDataGateways] = useState(true)
  const [isLoadedDataPumps, setIsLoadedDataPumps] = useState(true)

  const [thresholdAlerts, setThresholdAlerts] = useState(
    dataLoaded.allowSendThreshold
  )

  const [yellowThresholdAlerts, setYellowThresholdAlerts] = useState<boolean>(
    dataLoaded.allowSendThresholdYellow
  )
  const [applyHoursForYellowAlerts, setApplyHoursForYellowAlerts] =
    useState<boolean>(dataLoaded.allowSendThresholdYellow)
  const [startTimeFromPickerYellow, setStartTimeFromPickerYellow] = useState(
    getStartTimeDefault(dataLoaded.startTimeThresholdYellowHours)
  )
  const [endTimeFromPickerYellow, setEndTimeFromPickerYellow] = useState(
    getEndTimeDefault(dataLoaded.endTimeThresholdYellowHours)
  )

  const [redThresholdAlerts, setRedThresholdAlerts] = useState<boolean>(
    dataLoaded.allowSendThresholdRed
  )
  const [applyHoursForRedAlerts, setApplyHoursForRedAlerts] = useState<boolean>(
    dataLoaded.allowSendThresholdRed
  )
  const [startTimeFromPickerRed, setStartTimeFromPickerRed] = useState(
    getStartTimeDefault(dataLoaded.startTimeThresholdRedHours)
  )
  const [endTimeFromPickerRed, setEndTimeFromPickerRed] = useState(
    getEndTimeDefault(dataLoaded.endTimeThresholdRedHours)
  )

  const [gatewayAlerts, setGatewayAlerts] = useState<boolean>(
    dataLoaded.allowSendGateway
  )

  const [pumpAlerts, setPumpAlerts] = useState<boolean>(
    dataLoaded.allowPumpAlerts
  )

  const [pumpOnOffAlerts, setPumpOnOffAlerts] = useState<boolean>(
    dataLoaded.allowPumpOnOffAlerts
  )

  const [pumpStatusAlerts, setPumpStatusAlerts] = useState<boolean>(
    dataLoaded.allowPumpStatusAlerts
  )

  const [redThresholdAlertsGateway, setRedThresholdAlertsGateway] =
    useState<boolean>(dataLoaded.allowSendGatewayRed)
  const [applyHoursForRedAlertsGateway, setApplyHoursForRedAlertsGateway] =
    useState<boolean>(dataLoaded.allowSendGatewayRedHours)
  const [startTimeFromPickerRedGateway, setStartTimeFromPickerRedGateway] =
    useState(getStartTimeDefault(dataLoaded.startTimeGatewayRedHours))
  const [endTimeFromPickerRedGateway, setEndTimeFromPickerRedGateway] =
    useState(getEndTimeDefault(dataLoaded.endTimeGatewayRedHours))

  const setToggle = (setter) => (event) => {
    setter(event.target.checked)
  }

  const setDate = (setter) => (event) => {
    setter(event)
  }

  useEffect(() => {
    if (!thresholdAlerts) {
      setYellowThresholdAlerts(false)
      setApplyHoursForYellowAlerts(false)
      setRedThresholdAlerts(false)
      setApplyHoursForRedAlerts(false)
      setIsLoadedDataThreshold(false)
      setApplyHoursForYellowAlerts(
        dataLoaded.startTimeThresholdYellowHours !== '00:01' &&
          dataLoaded.endTimeThresholdYellowHours !== '23:59'
      )
      setApplyHoursForRedAlerts(
        dataLoaded.startTimeThresholdRedHours !== '00:01' &&
          dataLoaded.endTimeThresholdRedHours !== '23:59'
      )
    } else {
      if (!isLoadedDataThreshold) {
        setYellowThresholdAlerts(true)
        setRedThresholdAlerts(true)
      } else {
        setIsLoadedDataThreshold(false)
        setYellowThresholdAlerts(dataLoaded.allowSendThresholdYellow)
        setApplyHoursForYellowAlerts(
          dataLoaded.startTimeThresholdYellowHours !== '00:01' &&
            dataLoaded.endTimeThresholdYellowHours !== '23:59'
        )
        setRedThresholdAlerts(dataLoaded.allowSendThresholdRed)
        setApplyHoursForRedAlerts(
          dataLoaded.startTimeThresholdRedHours !== '00:01' &&
            dataLoaded.endTimeThresholdRedHours !== '23:59'
        )
      }
    }
  }, [thresholdAlerts])

  useEffect(() => {
    if (!yellowThresholdAlerts) {
      setApplyHoursForYellowAlerts(false)
      setStartTimeFromPickerYellow(
        getStartTimeDefault(getStartTimeDefault('00:01'))
      )
      setEndTimeFromPickerYellow(getEndTimeDefault(getEndTimeDefault('23:59')))
    }
  }, [yellowThresholdAlerts])

  useEffect(() => {
    if (!redThresholdAlerts) {
      setApplyHoursForRedAlerts(false)
      setStartTimeFromPickerRed(
        getStartTimeDefault(getStartTimeDefault('00:01'))
      )
      setEndTimeFromPickerRed(getEndTimeDefault(getEndTimeDefault('23:59')))
    }
  }, [redThresholdAlerts])

  useEffect(() => {
    if (!gatewayAlerts) {
      setIsLoadedDataGateways(false)
      setRedThresholdAlertsGateway(false)
      setApplyHoursForRedAlertsGateway(false)
      setStartTimeFromPickerRedGateway(getStartTimeDefault('00:01'))
      setEndTimeFromPickerRedGateway(getEndTimeDefault('23:59'))
    } else {
      if (!isLoadedDataGateways) {
        setRedThresholdAlertsGateway(true)
      } else {
        setIsLoadedDataGateways(false)
        setRedThresholdAlertsGateway(dataLoaded.allowSendGatewayRed)
        setApplyHoursForRedAlertsGateway(
          dataLoaded.startTimeGatewayRedHours !== '00:01' &&
            dataLoaded.endTimeGatewayRedHours !== '23:59'
        )
        setStartTimeFromPickerRedGateway(
          getStartTimeDefault(dataLoaded.startTimeGatewayRedHours)
        )
        setEndTimeFromPickerRedGateway(
          getEndTimeDefault(dataLoaded.endTimeGatewayRedHours)
        )
      }
    }
  }, [gatewayAlerts])

  useEffect(() => {
    if (!yellowThresholdAlerts && !redThresholdAlerts) {
      setThresholdAlerts(false)
    }
  }, [yellowThresholdAlerts, redThresholdAlerts])

  useEffect(() => {
    if (!redThresholdAlertsGateway) {
      setApplyHoursForRedAlertsGateway(false)
      setStartTimeFromPickerRedGateway(getStartTimeDefault('00:01'))
      setEndTimeFromPickerRedGateway(getEndTimeDefault('23:59'))
      setGatewayAlerts(false)
    }
  }, [redThresholdAlertsGateway])

  useEffect(() => {
    if (!applyHoursForYellowAlerts) {
      setStartTimeFromPickerYellow(getStartTimeDefault('00:01'))
      setEndTimeFromPickerYellow(getEndTimeDefault('23:59'))
    }
  }, [applyHoursForYellowAlerts])

  useEffect(() => {
    if (!applyHoursForRedAlerts) {
      setStartTimeFromPickerRed(getStartTimeDefault('00:01'))
      setEndTimeFromPickerRed(getEndTimeDefault('23:59'))
    }
  }, [applyHoursForRedAlerts])

  useEffect(() => {
    if (!applyHoursForRedAlertsGateway) {
      setStartTimeFromPickerRedGateway(getStartTimeDefault('00:01'))
      setEndTimeFromPickerRedGateway(getEndTimeDefault('23:59'))
    }
  }, [applyHoursForRedAlertsGateway])

  useEffect(() => {
    if (!pumpAlerts) {
      setIsLoadedDataPumps(false)
      setPumpOnOffAlerts(false)
      setPumpStatusAlerts(false)
    } else {
      if (!isLoadedDataPumps) {
        setPumpOnOffAlerts(true)
        setPumpStatusAlerts(true)
      } else {
        setIsLoadedDataPumps(false)
        setPumpOnOffAlerts(dataLoaded.allowPumpOnOffAlerts)
        setPumpStatusAlerts(dataLoaded.allowPumpStatusAlerts)
      }
    }
  }, [pumpAlerts])

  useEffect(() => {
    if (!pumpOnOffAlerts && !pumpStatusAlerts) {
      setPumpAlerts(false)
    }
  }, [pumpOnOffAlerts, pumpStatusAlerts])

  useEffect(() => {
    sendData()
  }, [
    thresholdAlerts,
    yellowThresholdAlerts,
    startTimeFromPickerYellow,
    endTimeFromPickerYellow,
    redThresholdAlerts,
    startTimeFromPickerRed,
    endTimeFromPickerRed,
    gatewayAlerts,
    redThresholdAlertsGateway,
    startTimeFromPickerRedGateway,
    endTimeFromPickerRedGateway,
    pumpAlerts,
    pumpOnOffAlerts,
    pumpStatusAlerts,
  ])

  const sendData = () => {
    const data = {
      allowSendThreshold: thresholdAlerts,

      allowSendThresholdYellow: yellowThresholdAlerts,
      allowSendThresholdYellowHours: yellowThresholdAlerts,
      startTimeThresholdYellowHours: moment(startTimeFromPickerYellow).format(
        'HH:mm'
      ),
      endTimeThresholdYellowHours: moment(endTimeFromPickerYellow).format(
        'HH:mm'
      ),

      allowSendThresholdRed: redThresholdAlerts,
      allowSendThresholdRedHours: redThresholdAlerts,
      startTimeThresholdRedHours: moment(startTimeFromPickerRed).format(
        'HH:mm'
      ),
      endTimeThresholdRedHours: moment(endTimeFromPickerRed).format('HH:mm'),

      allowSendGateway: gatewayAlerts,
      allowSendGatewayRedHours: redThresholdAlertsGateway,
      allowSendGatewayRed: redThresholdAlertsGateway,
      startTimeGatewayRedHours: moment(startTimeFromPickerRedGateway).format(
        'HH:mm'
      ),
      endTimeGatewayRedHours: moment(endTimeFromPickerRedGateway).format(
        'HH:mm'
      ),
      allowPumpAlerts: pumpAlerts,
      allowPumpOnOffAlerts: pumpOnOffAlerts,
      allowPumpStatusAlerts: pumpStatusAlerts,
    }

    props.onData(data)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Sms Notifications</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginLeft: '15px' }}>
          {props.phoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ marginLeft: '15px' }}>
          Change the phone number in the upper field
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '30px' }}>
        <Typography>Notify me via SMS about these activities</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup style={{ marginLeft: '15px' }}>
          <div style={{ maxHeight: '250px' }}>
            <FormControlLabel
              label="Thresholds Alerts"
              control={
                <Checkbox
                  // defaultChecked
                  checked={thresholdAlerts}
                  color="primary"
                  onChange={setToggle(setThresholdAlerts)}
                  size="small"
                />
              }
            />

            {thresholdAlerts && (
              <>
                <hr />
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      label={
                        <Typography>
                          Notify me of <b>yellow</b> alerts
                        </Typography>
                      }
                      control={
                        <Checkbox
                          // defaultChecked
                          checked={yellowThresholdAlerts}
                          color="primary"
                          onChange={setToggle(setYellowThresholdAlerts)}
                          size="small"
                        />
                      }
                    />
                    {yellowThresholdAlerts && (
                      <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                          <FormControlLabel
                            label="Apply Hours for Alerts"
                            control={
                              <Checkbox
                                // defaultChecked
                                checked={applyHoursForYellowAlerts}
                                color="primary"
                                onChange={setToggle(
                                  setApplyHoursForYellowAlerts
                                )}
                                size="small"
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {applyHoursForYellowAlerts && (
                    <>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            Start Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              value={startTimeFromPickerYellow}
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              onChange={setDate(setStartTimeFromPickerYellow)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            End Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              value={endTimeFromPickerYellow}
                              onChange={setDate(setEndTimeFromPickerYellow)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
            {thresholdAlerts && (
              <>
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      label={
                        <Typography>
                          Notify me of <b>red</b> alerts
                        </Typography>
                      }
                      control={
                        <Checkbox
                          // defaultChecked
                          checked={redThresholdAlerts}
                          color="primary"
                          onChange={setToggle(setRedThresholdAlerts)}
                          size="small"
                        />
                      }
                    />
                    {redThresholdAlerts && (
                      <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                          <FormControlLabel
                            label="Apply Hours for Alerts"
                            control={
                              <Checkbox
                                // defaultChecked
                                checked={applyHoursForRedAlerts}
                                color="primary"
                                onChange={setToggle(setApplyHoursForRedAlerts)}
                                size="small"
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {applyHoursForRedAlerts && (
                    <>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            Start Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              value={startTimeFromPickerRed}
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              onChange={setDate(setStartTimeFromPickerRed)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            End Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              value={endTimeFromPickerRed}
                              onChange={setDate(setEndTimeFromPickerRed)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
          <div
            style={{
              maxHeight: '150px',
              // display: props.isSuperVisor ? 'block' : 'none',
            }}
            // style={{
            //
            // }}
          >
            <FormControlLabel
              label="Gateway Battery Alerts"
              control={
                <Checkbox
                  checked={gatewayAlerts}
                  color="primary"
                  onChange={setToggle(setGatewayAlerts)}
                  size="small"
                />
              }
            />
            {gatewayAlerts && (
              <>
                <hr />
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      label={
                        <Typography>
                          Notify me of <b>red</b> alerts
                        </Typography>
                      }
                      control={
                        <Checkbox
                          // defaultChecked
                          checked={redThresholdAlertsGateway}
                          color="primary"
                          onChange={setToggle(setRedThresholdAlertsGateway)}
                          size="small"
                        />
                      }
                    />
                    {redThresholdAlertsGateway && (
                      <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                          <FormControlLabel
                            label="Apply Hours for Alerts"
                            control={
                              <Checkbox
                                // defaultChecked
                                checked={applyHoursForRedAlertsGateway}
                                color="primary"
                                onChange={setToggle(
                                  setApplyHoursForRedAlertsGateway
                                )}
                                size="small"
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {applyHoursForRedAlertsGateway && (
                    <>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            Start Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              value={startTimeFromPickerRedGateway}
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              onChange={setDate(
                                setStartTimeFromPickerRedGateway
                              )}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <Typography color="primary" variant="h6">
                            End Time
                          </Typography>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              // disabled={props.isSameBusinessHoursForSmsAndCalls}
                              value={endTimeFromPickerRedGateway}
                              onChange={setDate(setEndTimeFromPickerRedGateway)}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
          {props.featureFlags?.ShowPump ? (
            <div
            // style={{
            //   display: props.isUserAdmin ? 'block' : 'none',
            // }}
            >
              <FormControlLabel
                label="Pump Alerts"
                control={
                  <Checkbox
                    checked={pumpAlerts}
                    color="primary"
                    onChange={setToggle(setPumpAlerts)}
                    size="small"
                  />
                }
              />
              {pumpAlerts && (
                <>
                  <hr />
                  <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                      <FormControlLabel
                        label={
                          <Typography>
                            Notify me of <b>Turn ON/OFF</b> alerts
                          </Typography>
                        }
                        control={
                          <Checkbox
                            // defaultChecked
                            checked={pumpOnOffAlerts}
                            color="primary"
                            onChange={setToggle(setPumpOnOffAlerts)}
                            size="small"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                      <FormControlLabel
                        label={
                          <Typography>
                            Notify me of <b>connection</b> alerts
                          </Typography>
                        }
                        control={
                          <Checkbox
                            // defaultChecked
                            checked={pumpStatusAlerts}
                            color="primary"
                            onChange={setToggle(setPumpStatusAlerts)}
                            size="small"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          ) : null}
        </FormGroup>
      </Grid>
    </Grid>
  )
}

export default SmsNotification
