export const commandsApiBasePath =
  process.env.NODE_ENV === 'production'
    ? '/commandsapi'
    : 'https://dev-multitenant.scada-lite.com/commandsapi'
export const multitenantAuthApiBasePath =
  process.env.NODE_ENV === 'production'
    ? '/auth'
    : 'https://dev-multitenant.scada-lite.com/auth'
export const multitenantApiBasePath =
  process.env.NODE_ENV === 'production'
    ? '/api'
    : 'https://dev-multitenant.scada-lite.com/api'
export const multitenantAlertsApiBasePath =
  process.env.NODE_ENV === 'production'
    ? '/alertsapi'
    : 'https://dev-multitenant.scada-lite.com/alertsapi'

export function getFetch(accessToken: string, tenantId, userId?: string) {
  return {
    fetch: async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const authHeaders = {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        tenantId: tenantId,
      }

      if (userId) {
        // eslint-disable-next-line
        authHeaders['UserId'] = userId
      }
      const headers = init ? { ...init.headers, ...authHeaders } : authHeaders
      const reqInit: RequestInit = init ? { ...init, headers } : { headers }
      return fetch(url, reqInit)
    },
  }
}
