import React from 'react'

import { withContext } from './Context'
import { Site } from 'store/Site'
import { TelemetryState, defaultTelemetryState } from 'store/SiteDetails'

export interface SiteContextData {
  readonly selectedSiteId?: string
  readonly selectedSite?: Site
  readonly lastSeenTime?: Date
  readonly telemetry: TelemetryState
  readonly setSelectedSiteId?: (
    toggleHighlight: boolean,
    siteId?: string
  ) => void
  readonly selectSite?: (selectedSite?: Site) => void
  readonly setTelemetry?: (telemetry: TelemetryState) => void
  readonly setLastSeenTime?: (lastSeenTime?: Date) => void
}

const siteContext = React.createContext<SiteContextData>({
  telemetry: defaultTelemetryState,
})

export default siteContext

export const withSiteContext = withContext(siteContext)
