/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

export class UserClientMultitenant {
  private http: {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>
  }
  private baseUrl: string
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.http = http ? http : <any>window
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : ''
  }

  getUsersSettings(
    userId: string | null
  ): Promise<SwaggerResponse<UserSettingsResponseDtoMultitenant[]>> {
    let url_ = this.baseUrl + '/user/{userId}/settings'
    if (userId === undefined || userId === null)
      throw new Error("The parameter 'userId' must be defined.")
    url_ = url_.replace('{userId}', encodeURIComponent('' + userId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetUsersSettings(_response)
    })
  }

  protected processGetUsersSettings(
    response: Response
  ): Promise<SwaggerResponse<UserSettingsResponseDtoMultitenant[]>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(UserSettingsResponseDtoMultitenant.fromJS(item))
        } else {
          result200 = <any>null
        }
        return new SwaggerResponse(status, _headers, result200)
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<
      SwaggerResponse<UserSettingsResponseDtoMultitenant[]>
    >(new SwaggerResponse(status, _headers, <any>null))
  }

  createUserSettings(
    userId: string | null,
    createSettings: UserSettingsResponseDtoMultitenant[]
  ): Promise<SwaggerResponse<void>> {
    let url_ = this.baseUrl + '/user/{userId}/settings'
    if (userId === undefined || userId === null)
      throw new Error("The parameter 'userId' must be defined.")
    url_ = url_.replace('{userId}', encodeURIComponent('' + userId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(createSettings)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateUserSettings(_response)
    })
  }

  protected processCreateUserSettings(
    response: Response
  ): Promise<SwaggerResponse<void>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        return new SwaggerResponse(status, _headers, <any>null)
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<SwaggerResponse<void>>(
      new SwaggerResponse(status, _headers, <any>null)
    )
  }

  createUserSetting(
    userId: string | null,
    createSetting: UserSettingsResponseDtoMultitenant
  ): Promise<SwaggerResponse<void>> {
    let url_ = this.baseUrl + '/user/{userId}/setting'
    if (userId === undefined || userId === null)
      throw new Error("The parameter 'userId' must be defined.")
    url_ = url_.replace('{userId}', encodeURIComponent('' + userId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(createSetting)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateUserSetting(_response)
    })
  }

  protected processCreateUserSetting(
    response: Response
  ): Promise<SwaggerResponse<void>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        return new SwaggerResponse(status, _headers, <any>null)
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<SwaggerResponse<void>>(
      new SwaggerResponse(status, _headers, <any>null)
    )
  }

  updateUserSettingPUT(
    userId: string | null,
    updateSetting: UserSettingsResponseDtoMultitenant
  ): Promise<SwaggerResponse<void>> {
    let url_ = this.baseUrl + '/user/{userId}/setting'
    if (userId === undefined || userId === null)
      throw new Error("The parameter 'userId' must be defined.")
    url_ = url_.replace('{userId}', encodeURIComponent('' + userId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(updateSetting)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateUserSettingPUT(_response)
    })
  }

  protected processUpdateUserSettingPUT(
    response: Response
  ): Promise<SwaggerResponse<void>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        return new SwaggerResponse(status, _headers, <any>null)
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<SwaggerResponse<void>>(
      new SwaggerResponse(status, _headers, <any>null)
    )
  }

  updateUserSettingPATCH(
    userId: string | null,
    updateSetting: UserSettingsResponseDtoMultitenant
  ): Promise<SwaggerResponse<void>> {
    let url_ = this.baseUrl + '/user/{userId}/setting'
    if (userId === undefined || userId === null)
      throw new Error("The parameter 'userId' must be defined.")
    url_ = url_.replace('{userId}', encodeURIComponent('' + userId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(updateSetting)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateUserSettingPATCH(_response)
    })
  }

  protected processUpdateUserSettingPATCH(
    response: Response
  ): Promise<SwaggerResponse<void>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        return new SwaggerResponse(status, _headers, <any>null)
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<SwaggerResponse<void>>(
      new SwaggerResponse(status, _headers, <any>null)
    )
  }

  getUsersByRole(
    role: string | null
  ): Promise<SwaggerResponse<UserResponseDtoMultitenant[]>> {
    let url_ = this.baseUrl + '/user/role/{role}'
    if (role === undefined || role === null)
      throw new Error("The parameter 'role' must be defined.")
    url_ = url_.replace('{role}', encodeURIComponent('' + role))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetUsersByRole(_response)
    })
  }

  protected processGetUsersByRole(
    response: Response
  ): Promise<SwaggerResponse<UserResponseDtoMultitenant[]>> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(UserResponseDtoMultitenant.fromJS(item))
        } else {
          result200 = <any>null
        }
        return new SwaggerResponse(status, _headers, result200)
      })
    } else if (status === 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetailsMultitenant.fromJS(resultData400)
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException(
          'A server side error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<SwaggerResponse<UserResponseDtoMultitenant[]>>(
      new SwaggerResponse(status, _headers, <any>null)
    )
  }
}

export class ProblemDetailsMultitenant {
  type?: string | undefined
  title?: string | undefined
  status?: number | undefined
  detail?: string | undefined
  instance?: string | undefined
  extensions!: { [key: string]: any }

  init(_data?: any) {
    if (_data) {
      this.type = _data['type']
      this.title = _data['title']
      this.status = _data['status']
      this.detail = _data['detail']
      this.instance = _data['instance']
      if (_data['extensions']) {
        this.extensions = {} as any
        for (let key in _data['extensions']) {
          if (_data['extensions'].hasOwnProperty(key))
            (<any>this.extensions)![key] = _data['extensions'][key]
        }
      }
    }
  }

  static fromJS(data: any): ProblemDetailsMultitenant {
    data = typeof data === 'object' ? data : {}
    let result = new ProblemDetailsMultitenant()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['type'] = this.type
    data['title'] = this.title
    data['status'] = this.status
    data['detail'] = this.detail
    data['instance'] = this.instance
    if (this.extensions) {
      data['extensions'] = {}
      for (let key in this.extensions) {
        if (this.extensions.hasOwnProperty(key))
          (<any>data['extensions'])[key] = this.extensions[key]
      }
    }
    return data
  }
}

export class UserResponseDtoMultitenant {
  id?: string | undefined
  fullName?: string | undefined
  roles?: string[] | undefined
  settings?: UserSettingsResponseDtoMultitenant[] | undefined

  init(_data?: any) {
    if (_data) {
      this.id = _data['id']
      this.fullName = _data['fullName']
      if (Array.isArray(_data['roles'])) {
        this.roles = [] as any
        for (let item of _data['roles']) this.roles!.push(item)
      }
      if (Array.isArray(_data['settings'])) {
        this.settings = [] as any
        for (let item of _data['settings'])
          this.settings!.push(UserSettingsResponseDtoMultitenant.fromJS(item))
      }
    }
  }

  static fromJS(data: any): UserResponseDtoMultitenant {
    data = typeof data === 'object' ? data : {}
    let result = new UserResponseDtoMultitenant()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['fullName'] = this.fullName
    if (Array.isArray(this.roles)) {
      data['roles'] = []
      for (let item of this.roles) data['roles'].push(item)
    }
    if (Array.isArray(this.settings)) {
      data['settings'] = []
      for (let item of this.settings) data['settings'].push(item.toJSON())
    }
    return data
  }
}

export class UserSettingsResponseDtoMultitenant {
  key!: string
  value?: Array<string> | undefined // o string[]

  init(_data?: any) {
    if (_data) {
      // Asigna la clave y el valor de los datos
      this.key = _data['key']
      this.value = _data['value']
    }
  }

  static fromJS(data: any): UserSettingsResponseDtoMultitenant {
    data = typeof data === 'object' ? data : {}
    let result = new UserSettingsResponseDtoMultitenant()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['key'] = this.key
    data['value'] = this.value
    return data
  }
}

export class SwaggerResponse<TResult> {
  status: number
  headers: { [key: string]: any }
  result: TResult

  constructor(
    status: number,
    headers: { [key: string]: any },
    result: TResult
  ) {
    this.status = status
    this.headers = headers
    this.result = result
  }
}

export class SwaggerException extends Error {
  message: string
  status: number
  response: string
  headers: { [key: string]: any }
  result: any

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super()

    this.message = message
    this.status = status
    this.response = response
    this.headers = headers
    this.result = result
  }

  protected isSwaggerException = true

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  throw new SwaggerException(message, status, response, headers, result)
}
