import {
  AccountInfo,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  AuthenticationResult,
} from '@azure/msal-browser'
import { Dispatch } from 'redux'
import { loginRequest } from '../../auth/authConfig'
import { getMultitenantUser } from '../../api/multitenantAuth'
import {
  HANDLE_SET_MULTITENANT_USER,
  HANDLE_SET_ACTIVE_TENANT,
} from '../types/types'

export const actionCreators = {
  getMultitenantUser:
    (msalInstance: IPublicClientApplication, accounts: AccountInfo[]) =>
    async (dispatch: Dispatch) => {
      const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: accounts[0],
        authority: loginRequest.authority,
      }
      await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (response) => {
          await setToken(dispatch, response)
        })
        .catch(async (error) => {
          // Acquire token silent failure, send an interactive request.
          console.error(error)
          if (
            InteractionRequiredAuthError.isInteractionRequiredError(
              error.errorCode
            )
          ) {
            console.info('Interaction Required Error')
            await msalInstance
              .acquireTokenRedirect(accessTokenRequest)
              .catch(function (error) {
                console.error(error)
              })
          } else if (error.errorCode === 'multiple_matching_tokens') {
            await msalInstance.loginRedirect(loginRequest)
          }
        })
    },
  setActiveTenant: (tenantId: number) => (dispatch: Dispatch) => {
    dispatch({ type: HANDLE_SET_ACTIVE_TENANT, payload: { tenantId } })
    // document.location.href = '/'
  },
}

async function setToken(dispatch: Dispatch, response: AuthenticationResult) {
  await getMultitenantUser(response.accessToken)
    .then((result) => {
      const { id, fullName, email, tenants } = result.data
      dispatch({
        type: HANDLE_SET_MULTITENANT_USER,
        payload: {
          accessToken: response.accessToken,
          id,
          email,
          fullName,
          tenants,
        },
      })
    })
    .catch((error) => {
      console.error(error)
      localStorage.clear()
      document.location.href = '/failed-signin'
    })
}
