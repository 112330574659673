import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'

import { ThresholdsTextValidatorProps } from './ThresholdsTextValidator'
import ThresholdsTextValidatorBase, {
  StatusThresholdMinMaxProperties,
} from './ThresholdsTextValidatorBase'
import * as Constants from './constants'
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
} from 'components/utils/converter'

type AllProps = Omit<ThresholdsTextValidatorProps, 'telemetryType'>

const ruleNames = [
  Constants.RequiredRuleName,
  Constants.FromLessThanToRuleName,
  Constants.ToGreaterThanFromRuleName,
  Constants.RangeLimitRuleName,
  Constants.IntersectingRangeRuleName,
]

class HeaterTempThresholdsValidation extends React.Component<AllProps> {
  public componentDidMount() {
    ValidatorForm.addValidationRule(
      Constants.FromLessThanToRuleName,
      (rawValue) => {
        const value = fahrenheitToCelsius(rawValue)
        return value < this.props.editedThreshold!.maxThreshold
      }
    )

    ValidatorForm.addValidationRule(
      Constants.ToGreaterThanFromRuleName,
      (rawValue) => {
        const value = fahrenheitToCelsius(rawValue)
        return value > this.props.editedThreshold!.minThreshold
      }
    )

    ValidatorForm.addValidationRule(
      Constants.RangeLimitRuleName,
      (rawValue) => {
        const value = fahrenheitToCelsius(rawValue)

        return (
          value >= Constants.MinRangeValue && value <= Constants.MaxRangeValue
        )
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}From`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { maxThreshold } = editedThreshold!
        const value = fahrenheitToCelsius(rawValue)

        return this.props.checkIntersectingRule(value, maxThreshold)
      }
    )

    ValidatorForm.addValidationRule(
      `${Constants.IntersectingRangeRuleName}To`,
      (rawValue) => {
        const { editedThreshold } = this.props
        const { minThreshold } = editedThreshold!
        const value = fahrenheitToCelsius(rawValue)

        return this.props.checkIntersectingRule(minThreshold, value)
      }
    )

    ValidatorForm.addValidationRule(
      Constants.RequiredRuleName,
      (rawValue) => rawValue !== undefined
    )
  }

  public componentWillUnmount() {
    ruleNames.forEach(ValidatorForm.removeValidationRule)
  }

  public render() {
    const { name, value, ruleName, ruleMessage, index, property } = this.props

    const validators = [
      Constants.RequiredRuleName,
      ruleName,
      Constants.RangeLimitRuleName,
      `${Constants.IntersectingRangeRuleName}${name}`,
    ]
    const errorMessages = [
      Constants.RequiredRuleName,
      ruleMessage,
      `Must be from ${Constants.MinRangeValue} to ${Constants.MaxRangeValue}`,
      'Intersecting ranges',
    ]

    return (
      <ThresholdsTextValidatorBase
        name={name}
        value={celsiusToFahrenheit(value)}
        errorMessages={errorMessages}
        validators={validators}
        index={index}
        property={property}
        handleThresholdChange={this.handleThresholdChange}
        textType="number"
        placeholder="——"
      />
    )
  }

  private readonly handleThresholdChange = (
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ) => {
    const value = fahrenheitToCelsius(parseInt(stringValue, 10))
    this.props.handleThresholdChange(value.toString(), index, property)
  }
}

export default HeaterTempThresholdsValidation
