import { TelemetryStatus } from 'api/alertservice'

const statusWeights: {
  [key in TelemetryStatus]: number
} = {
  [TelemetryStatus.Red]: 1000 * 1000 * 1000,
  [TelemetryStatus.Yellow]: 1000 * 1000,
  [TelemetryStatus.Green]: 1000,
  [TelemetryStatus.Undefined]: 0,
}

export function getStatusesSeverity(statuses?: TelemetryStatus[]): number {
  if (!statuses?.length) {
    return 0
  }

  return statuses.map((s) => statusWeights[s]).reduce((w, sum) => sum + w)
}

export function getMostSevereStatus(
  statuses?: TelemetryStatus[]
): TelemetryStatus {
  if (statuses?.length) {
    const [first, ...rest] = statuses
    return rest.reduce(
      (status, mostSevereStatus) => Math.max(status, mostSevereStatus),
      first
    )
  }

  return TelemetryStatus.Undefined
}

export enum GasFlowType {
  VolumeFlowRate = 1,
  TodayFlowFlowed = 2,
  YesterdayVolumeFlowed = 3,
  StaticPressure = 4,
  AccumulatedVolume = 5,
  BatteryVolts = 6,
  DiffPressure = 7,
  OrificeDiameter = 8,
  PipeDiameter = 9,
  Temperature = 10,
}
