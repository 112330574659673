import React from 'react'

export function withContext<ContextData>(context: React.Context<ContextData>) {
  return function <Props>(component: React.ComponentType<Props & ContextData>) {
    return (props: Props) => {
      const Context = context
      const Component = component

      return (
        <Context.Consumer>
          {(ctx) => {
            const allProps = { ...props, ...ctx }

            return <Component {...allProps} />
          }}
        </Context.Consumer>
      )
    }
  }
}
