import * as React from 'react'
import { Snackbar, SnackbarOrigin } from '@material-ui/core'
import { renderAlert } from './utils'
import { Color } from '@material-ui/lab'

interface Props {
  readonly closed?: boolean
  readonly autoHideDuration?: number
  readonly anchorOrigin?: SnackbarOrigin
  readonly message: string
  readonly title?: string
  readonly severity?: Color
  readonly onClose?: () => void
}

const defaultAutoHideDuration = 4000
const defaultAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

export default function Snack({
  closed,
  autoHideDuration,
  anchorOrigin,
  message,
  title,
  severity,
  onClose,
}: Props): React.ReactElement {
  const [open, setOpen] = React.useState(!closed)
  const onCloseSnackBar = (_, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false)

      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration ?? defaultAutoHideDuration}
      onClose={onCloseSnackBar}
      anchorOrigin={anchorOrigin ?? defaultAnchorOrigin}
    >
      {renderAlert(message, title, severity, () => onCloseSnackBar(null, null))}
    </Snackbar>
  )
}
