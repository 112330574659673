import React, { useRef } from 'react'
import moment from 'moment'
import { IntermittentAlertDto } from 'api/apiservice'
import MaterialTable, { Column } from '@material-table/core'
import { nameOf } from 'components/utils'
import { TimeRange } from 'components/misc/TimeRange'

const nameof = (property: keyof IntermittentAlertDto) =>
  nameOf<IntermittentAlertDto>(property)

interface Props {
  readonly timeRange: TimeRange
  alerts: IntermittentAlertDto[]
}

type AllProps = Props

export function AlertsList(props: AllProps) {
  const table = useRef<MaterialTable<IntermittentAlertDto>>()
  const alertColumns: Column<IntermittentAlertDto>[] = [
    {
      title: 'WELL',
      field: nameof('wellId'),
    },
    {
      title: 'STATUS CHANGE',
      field: nameof('status'),

      render: (rowData) => {
        return (
          <p
            style={{
              color: rowData.status === 'On' ? '#00BB2D' : '#C00',
              fontWeight: 'bold',
            }}
          >
            {rowData.status}
          </p>
        )
      },
    },
    {
      title: 'TIME',
      field: nameof('statusUpdatedAt'),
      type: 'date',
      render: (rowData) =>
        moment(rowData.statusUpdatedAt).format('MM/DD/YYYY HH:mm'),
    },
    {
      title: 'SITE',
      field: nameof('siteName'),
    },
  ]

  return (
    <div style={{ overflowY: 'hidden' }}>
      <MaterialTable<IntermittentAlertDto>
        tableRef={table}
        options={{
          toolbar: false,
          paging: true,
          search: false,
          overflowY: 'hidden',
          actionsColumnIndex: -1,
          pageSize: 20,
          pageSizeOptions: [],
          headerStyle: {
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#GGG',
          },
        }}
        columns={alertColumns}
        data={props.alerts || []}
        style={{
          overflow: 'auto',
          height: '100%',
          textAlign: 'center',
        }}
      />
    </div>
  )
}
