import React, { Component } from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { duration } from 'moment'
import flat from 'array.prototype.flat'

import {
  Theme,
  createStyles,
  WithStyles,
  Modal,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import {
  NotificationsActiveTwoTone,
  NotificationsNoneTwoTone,
} from '@material-ui/icons'

import { withUiContext, UiContextData } from './contexts/UiContext'
import { actionCreators, AlertsState } from '../store/Alert'
import { AppState } from '../store/AppState'
import { Site } from '../store/Site'
import { saveAlertHistoryDataAsCsv } from '../api/client'
import { Tabs, TabProps } from './misc/Tabs'
import FilteredAlerts from './alerts/FilteredAlerts'
import { TimeRange, RelativeTimeRange, getTimeRange } from './misc/TimeRange'
import TimeRangePicker from './misc/TimeRangePicker'

export enum AlertTypes {
  Active = 1,
  Acknowledged = 2,
  All = 3,
}

function getRequestType(type: AlertTypes) {
  switch (type) {
    case AlertTypes.Acknowledged:
      return true
    case AlertTypes.Active:
      return false
    case AlertTypes.All:
      return undefined
  }
}

export const relativeTimeRanges: [RelativeTimeRange][] = flat([
  [1, 6, 12].map((d) => [duration(d, 'hour')]),
  [1, 3, 7, 14].map((d) => [duration(d, 'day')]),
  [1].map((d) => [duration(d, 'month')]),
])

const initialTimeRange = duration(0)

const styles = (_: Theme) =>
  createStyles({
    container: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    timeRangePicker: {
      position: 'absolute',
      zIndex: 10,
      alignSelf: 'flex-end',
    },
  })

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly userId?: string
  readonly alerts: AlertsState
  readonly sites: Site[]
}

type PropsFromDispatch = typeof actionCreators

type AllProps = PropsFromState &
  PropsFromDispatch &
  WithStyles<typeof styles> &
  UiContextData

interface State {
  readonly acknowledging: string[]
  readonly alertIdToConfirm?: string
  readonly timeRange?: TimeRange
  readonly savingCsv?: boolean
  readonly currentTab: AlertTypes
}

class MyAlerts extends Component<AllProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      acknowledging: [],
      timeRange: initialTimeRange,
      currentTab: AlertTypes.Active,
    }
  }

  public componentDidMount() {
    this.props.setPageTitle!('My Alerts')
  }

  public render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        {this.props.accessToken && this.props.tenantId && this.renderTabs()}
        {this.state.savingCsv && (
          <Modal open={true}>
            <div
              style={{
                display: 'grid',
                height: '100%',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <CircularProgress size="10em" />
            </div>
          </Modal>
        )}
      </div>
    )
  }

  private renderTabs() {
    const tabs: TabProps<AlertTypes>[] = [
      {
        value: AlertTypes.Active,
        icon: <NotificationsActiveTwoTone />,
        label: 'Active',
      },
      {
        value: AlertTypes.Acknowledged,
        icon: <NotificationsNoneTwoTone />,
        label: 'Acknowledged',
      },
    ]
    const getTabPanel = (tab: AlertTypes) => (
      <div style={{ width: '100%', height: '100%' }}>
        <FilteredAlerts
          key={tab}
          isAcknowledged={getRequestType(tab)}
          timeRange={this.state.timeRange}
        />
      </div>
    )

    return (
      <Tabs<AlertTypes>
        initialTab={this.state.currentTab}
        tabs={tabs}
        getTabPanel={getTabPanel}
        onTabChanged={this.onTabChanged}
        tabsBarContent={(_) => this.renderTimePicker()}
        style={{ width: '100%', height: '100%' }}
      />
    )
  }

  private renderTimePicker() {
    return (
      <TimeRangePicker
        relativeTimeRanges={relativeTimeRanges}
        initialTimeRange={this.state.timeRange}
        onTimeRangeChanged={this.onTimeRangeChanged}
        onSaveAsCsv={this.onSaveAsCsv}
      />
    )
  }

  private readonly onTimeRangeChanged = (timeRange?: TimeRange) =>
    this.setState({
      timeRange: timeRange ?? initialTimeRange,
    })

  private readonly onTabChanged = (currentTab: AlertTypes) =>
    this.setState({ currentTab })

  private readonly onSaveAsCsv = (_, timeRange?: TimeRange) => {
    const { accessToken, userId, tenantId } = this.props
    const [from, to] = getTimeRange(timeRange)

    this.setState({ savingCsv: true }, async () => {
      await saveAlertHistoryDataAsCsv(
        accessToken!,
        userId!,
        tenantId.toString(),
        undefined,
        getRequestType(this.state.currentTab),
        from,
        to
      )
      this.setState({ savingCsv: false })
    })
  }
}

const mapStateToProps = (state: AppState): PropsFromState => ({
  alerts: state.alerts,
  sites: state.sites.sites,
  accessToken: state.multitenantUser.accessToken,
  userId: state.multitenantUser.id,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withUiContext(MyAlerts))
)
