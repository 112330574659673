import { Reducer, AnyAction } from 'redux'
import { getTelemetryHistoricalData, HistoricalDataEntry } from '../api/client'
import { TelemetryType } from '../api/apiservice'
import { TimeRange, getTimeRange } from '../components/misc/TimeRange'
import { ModelState } from './ModelState'
import { parseError } from './utils'
import { TelemetryMessage } from './SiteDetails'

type HistoricalDataByTelemetryTypeDictionary = {
  [key in TelemetryType]: HistoricalDataEntry[]
}

export type HistoricalDataByTelemetryType =
  Partial<HistoricalDataByTelemetryTypeDictionary>

type HistoricalDataByTelemetryTypeAndAssetIdDictionary = {
  [key in TelemetryType]: Map<string, HistoricalDataEntry[]>
}

export type HistoricalDataByTelemetryTypeAndAssetId =
  Partial<HistoricalDataByTelemetryTypeAndAssetIdDictionary>

export interface HistoricalDataState extends ModelState {
  readonly data: HistoricalDataByTelemetryTypeAndAssetId
}

export const defaultState: HistoricalDataState = {
  data: {},
}

const requestHistoricalDataType = 'REQUEST_HISTORICAL_DATA'
const receiveHistoricalDataType = 'RECEIVE_HISTORICAL_DATA'
const receiveHistoricalDataErrorType = 'RECEIVE_HISTORICAL_DATA_ERROR'

export const actionCreators = {
  requestHistoricalData: async (
    accessToken: string,
    tenantId: number,
    siteId: string,
    telemetryType: TelemetryType,
    assetId: string | null,
    sensorId: string | null,
    timeRange: TimeRange,
    getState: () => HistoricalDataState,
    setState: (state: HistoricalDataState) => void
  ) => {
    const dispatch = (action: AnyAction) =>
      setState(reducer(getState(), action))

    const [from, to] = getTimeRange(timeRange)
    dispatch({ type: requestHistoricalDataType })
    try {
      const result = await getTelemetryHistoricalData(
        accessToken,
        tenantId,
        siteId,
        telemetryType,
        assetId,
        sensorId,
        from,
        to
      )
      dispatch({
        result,
        telemetryType,
        type: receiveHistoricalDataType,
      })

      if (result instanceof Error) {
        console.error(result)
        dispatch({
          result,
          type: receiveHistoricalDataErrorType,
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        error,
        type: receiveHistoricalDataErrorType,
      })
    }
  },
}

export const reducer: Reducer<HistoricalDataState> = (
  state = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case requestHistoricalDataType:
      return {
        ...state,
        loading: true,
        error: undefined,
      }

    case receiveHistoricalDataType:
      const { telemetryType, result } = action

      const messages = result as TelemetryMessage[]
      const initialMap: Map<string, HistoricalDataEntry[]> =
        state.data[telemetryType] ?? new Map<string, HistoricalDataEntry[]>()
      const historicalData = messages.reduce((grouped, m) => {
        const {
          CreationTimeUtc,
          Payload: { value, rawValue, unitOfMeasure, metaData },
          AssetId,
          GatewayId,
          Id,
          TelemetryType,
          SiteId,
          invalidData,
          isSimulated,
        } = m
        const currHistoricalEntry = {
          metaData,
          value,
          rawValue,
          timestamp: CreationTimeUtc.valueOf(),
          id: Id,
          telemetryType: TelemetryType,
          assetId: AssetId,
          gatewayId: GatewayId,
          siteId: SiteId,
          isSimulated,
          invalidData,
          unitOfMeasure,
        } as HistoricalDataEntry

        const assetRecord = grouped?.get(AssetId) ?? []
        assetRecord.push(currHistoricalEntry)
        grouped.set(AssetId, assetRecord)

        return grouped
      }, initialMap)

      return {
        ...state,
        data: {
          ...state.data,
          [telemetryType]: historicalData,
        },
        error: undefined,
      }

    case receiveHistoricalDataErrorType:
      return {
        ...state,
        error: parseError(action.error),
      }

    default:
      return state
  }
}
