import { Dispatch, AnyAction, Reducer } from 'redux'
import { ModelState } from './ModelState'
import { AppState } from './AppState'
import { parseError } from './utils'
import { getGroupClient } from '../api/client'
import {
  Group as GroupVM,
  CreateUserGroupRequest,
  ReplaceUserGroupRequest,
  SwaggerException,
} from '../api/identityprovider'

export interface Group {
  readonly id: string
  readonly name: string
  readonly siteIds: string[]
  readonly userEmails: string[]
}

export interface GroupsState extends ModelState {
  readonly groups: Group[]
}

export const defaultState: GroupsState = {
  groups: [],
  loading: true,
}

const groupsActionType = '_GROUPS'

const requestGroupsType = `REQUEST${groupsActionType}`
const receivedGroupsType = `RECEIVED${groupsActionType}`
const errorReceivingGroupsType = `ERROR_RECEIVING${groupsActionType}`

const deleteGroupType = `DELETE${groupsActionType}`
const deletedGroupType = `DELETED${groupsActionType}`
const errorDeletingGroupType = `ERROR_DELETING${groupsActionType}`

const updateGroupType = `UPDATE${groupsActionType}`
const updatedGroupType = `UPDATED${groupsActionType}`
const errorUpdatingGroupType = `ERROR_UPDATING${groupsActionType}`

const createGroupType = `CREATE${groupsActionType}`
const createdGroupType = `CREATED${groupsActionType}`
const errorCreatingGroupType = `ERROR_CREATING${groupsActionType}`

const clearErrors = `CLEAR_ERRORS${groupsActionType}`

export const actionCreators = {
  requestGroups:
    (accessToken: string, tenantId: string) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ type: requestGroupsType })

      try {
        const api = getGroupClient(accessToken, tenantId)
        const { result } = await api.get()

        dispatch({
          groups: result,
          type: receivedGroupsType,
        })
      } catch (error) {
        dispatch({
          error,
          type: errorReceivingGroupsType,
        })
      }
    },
  requestAvailableGroups:
    (accessToken: string, tenantId: string) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
      dispatch({ type: requestGroupsType })

      try {
        const user = getState().multitenantUser

        if (!user) {
          throw new Error('User is not loaded')
        }

        const userId = user.id
        if (!userId) {
          throw new Error('Cannot retrieve sub claim')
        }

        const api = getGroupClient(user.accessToken, tenantId)
        const { result } = await api.getByUserId(userId)

        dispatch({
          groups: result,
          type: receivedGroupsType,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          error,
          type: errorReceivingGroupsType,
        })
      }
    },
  deleteGroup:
    (
      accessToken: string,
      tenantId: string,
      groupId: string,
      onSuccess?: () => void
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ groupId, type: deleteGroupType })

      try {
        const api = getGroupClient(accessToken, tenantId)

        await api.deleteGroup(groupId)

        dispatch({
          groupId,
          type: deletedGroupType,
        })
        onSuccess && onSuccess()
      } catch (error) {
        console.error(error)
        dispatch({
          groupId,
          error,
          type: errorDeletingGroupType,
        })
      }
    },
  updateGroup:
    (
      accessToken: string,
      group: Group,
      tenantId: string,
      successCallback: () => void
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ group, type: updateGroupType })

      try {
        const api = getGroupClient(accessToken, tenantId)
        const request = ReplaceUserGroupRequest.fromJS(group)

        await api.replaceGroup(request)

        dispatch({
          group,
          type: updatedGroupType,
        })

        successCallback()
      } catch (error) {
        console.error(error)
        if (error instanceof SwaggerException && error.status === 404) {
          dispatch({
            error: error.response,
            type: errorUpdatingGroupType,
          })
        } else {
          dispatch({
            error: error,
            type: errorUpdatingGroupType,
          })
        }
      }
    },
  createGroup:
    (
      accessToken: string,
      group: Group,
      tenantId: string,
      onSuccess: (groupId: string) => void,
      onError: (error: Error) => void
    ) =>
    async (dispatch: Dispatch, _: () => AppState) => {
      dispatch({ group, type: createGroupType })

      try {
        const api = getGroupClient(accessToken, tenantId)
        const request = CreateUserGroupRequest.fromJS(group)

        const { result: groupId } = await api.createGroup(request)

        dispatch({
          groupId,
          group,
          type: createdGroupType,
        })

        onSuccess(groupId)
      } catch (error) {
        console.error(error)
        dispatch({
          group,
          error,
          type: errorCreatingGroupType,
        })
        onError(error)
      }
    },
  clearErrors: () => (dispatch: Dispatch, _: () => AppState) => {
    dispatch({ type: clearErrors })
  },
}

export const mapSites = (vm: GroupVM): Group => {
  return {
    id: vm.id!,
    name: vm.name || vm.id!,
    siteIds: vm.siteIds || [],
    userEmails: vm.userEmails || [],
  }
}

export const reducer: Reducer<GroupsState> = (
  state = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case receivedGroupsType: {
      const descs: GroupVM[] = action.groups
      const groups = (descs || []).map(mapSites)

      return {
        ...state,
        groups,
        error: undefined,
        loading: false,
      }
    }

    case errorReceivingGroupsType:
    case errorUpdatingGroupType:
    case errorDeletingGroupType:
    case errorCreatingGroupType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    case requestGroupsType:
    case updateGroupType:
    case deleteGroupType:
    case createGroupType:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case updatedGroupType: {
      const group: Group = action.group
      const groups = state.groups
        .filter((g) => g.id !== group.id)
        .concat([group])

      return {
        ...state,
        groups,
        error: undefined,
        loading: false,
      }
    }

    case deletedGroupType: {
      const { groupId } = action
      const groups = state.groups.filter((g) => g.id !== groupId)

      return {
        ...state,
        groups,
        error: undefined,
        loading: false,
      }
    }

    case createdGroupType: {
      const id = action.groupId
      const group: Group = { ...action.group, id }
      const groups = state.groups.filter((g) => g.id !== id).concat([group])

      return {
        ...state,
        groups,
        error: undefined,
        loading: false,
      }
    }

    case clearErrors:
      return { ...state, error: undefined }

    default:
      return state
  }
}
