import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'

import { setHandleDialog } from '../../../store/actions/common'

const CancelButton = () => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setHandleDialog(false))
  }

  return (
    <Button color="primary" onClick={handleClose} id="cancelButtonAcknowledge">
      Cancel
    </Button>
  )
}

export default CancelButton
