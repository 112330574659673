import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DropResult } from 'react-beautiful-dnd'
import { Button, Typography } from '@material-ui/core'

import { DraggableList } from './draggableList'
import { reorder } from './utils'
import { IntermittentIgnitionDto, IntermittentOrderDto } from 'api/apiservice'
import Snack from '../../misc/Snack'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginBottom: 20,
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    margin: 16,
    marginLeft: 0,
    minWidth: 350,
  },
  saveButton: {
    alignSelf: 'left',
    width: 30,
    marginTop: 15,
    marginBottom: 15,
  },
})

interface Props {
  isValidRole: boolean
  ignitionOrder: IntermittentIgnitionDto
  saveIgnitionOrder: any
}

export const CompressorOrder = (props: Props) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const [onOrderItems, setOnOrderItems] = useState<IntermittentOrderDto[]>([])
  const [offOrderItems, setOffOrderItems] = useState<IntermittentOrderDto[]>([])
  const [error, setError] = useState<string | undefined>()
  const [saveSuccess, setSaveSuccess] = useState<string | undefined>()

  const onOnItemDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return
    const delays = onOrderItems.map((item) => item.delay)
    const newItems = reorder(onOrderItems, source.index, destination.index)
    delays.forEach((d, i) => (newItems[i].delay = d))
    setOnOrderItems(newItems)
  }

  const onOffItemDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return
    const delays = offOrderItems.map((item) => item.delay)
    const newItems = reorder(offOrderItems, source.index, destination.index)
    delays.forEach((d, i) => (newItems[i].delay = d))
    setOffOrderItems(newItems)
  }

  const adjustPostOrder = (wellsOrder: IntermittentOrderDto[]) => {
    wellsOrder.forEach((well, index) => {
      well.order = index
    })
    return wellsOrder
  }

  const onSaveClick = async () => {
    try {
      setSaving(true)
      const onOrderToPost = adjustPostOrder(onOrderItems)
      const offOrderToPost = adjustPostOrder(offOrderItems)

      const ignitionDtoToPost = IntermittentIgnitionDto.fromJS({
        id: props.ignitionOrder.id,
        compressorDeviceId: props.ignitionOrder.compressorDeviceId,
        offOrder: offOrderToPost,
        onOrder: onOrderToPost,
      })

      await props.saveIgnitionOrder(
        props.ignitionOrder.compressorDeviceId,
        ignitionDtoToPost
      )

      setSaving(false)
      setSaveSuccess('Successfully saved')
    } catch (e) {
      setSaving(false)
      setError('An error while saving ignition order occurred')
    }
  }

  useEffect(() => {
    if (props.ignitionOrder.onOrder) {
      setOnOrderItems(props.ignitionOrder.onOrder)
    }

    if (props.ignitionOrder.offOrder) {
      setOffOrderItems(props.ignitionOrder.offOrder)
    }
  }, [props.ignitionOrder])

  return props.ignitionOrder.onOrder || props.ignitionOrder.offOrder ? (
    <>
      <div className={classes.root}>
        {props.isValidRole && (
          <>
            <Typography>
              Please organize the well to Open/Shut automatically
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={onSaveClick}
              disabled={saving}
            >
              Save
            </Button>
          </>
        )}
        <div className={classes.columnsContainer}>
          <div className={classes.column}>
            <Typography color="primary">
              <strong>ON</strong>
            </Typography>
            <DraggableList
              isValidRole={props.isValidRole}
              items={onOrderItems}
              onDragEnd={onOnItemDragEnd}
              setDelay={(delayInMinutes, itemIndex) => {
                setOnOrderItems([
                  ...onOrderItems.map((item, index) => {
                    if (index === itemIndex) {
                      item.delay = delayInMinutes * 60
                    }
                    return item
                  }),
                ])
              }}
            />
          </div>
          <div className={classes.column}>
            <Typography color="primary">
              <strong>OFF</strong>
            </Typography>
            <DraggableList
              isValidRole={props.isValidRole}
              items={offOrderItems}
              onDragEnd={onOffItemDragEnd}
              setDelay={(delayInMinutes, itemIndex) => {
                setOffOrderItems([
                  ...offOrderItems.map((item, index) => {
                    if (index === itemIndex) {
                      item.delay = delayInMinutes * 60
                    }
                    return item
                  }),
                ])
              }}
            />
          </div>
        </div>
      </div>
      {error && (
        <Snack
          message={error}
          severity="error"
          onClose={() => setError(undefined)}
        />
      )}
      {saveSuccess && (
        <Snack
          message={saveSuccess}
          severity="success"
          onClose={() => setSaveSuccess(undefined)}
        />
      )}
    </>
  ) : (
    <Typography align="center">
      Please select a compressor to display the wells associated
    </Typography>
  )
}
