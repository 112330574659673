import React from 'react'

class SiteFilter extends React.Component {
  handleChange(event) {
    this.props.updateSearch(event.target.value)
  }

  render() {
    return (
      <input
        type="text"
        placeholder="Find a site"
        className="input-search"
        onChange={this.handleChange.bind(this)}
        value={this.props.searchText}
      />
    )
  }
}

export default SiteFilter
