import {
  HubConnectionBuilder,
  LogLevel,
  ISubscription,
} from '@microsoft/signalr'
import { ErrorBase } from '../ErrorBase'

export type StreamHandler<T> = (T) => void

export interface StreamingSubscription<T> extends ISubscription<T> {
  subscribe(handler: StreamHandler<T>): void
  unsubscribe(handler: StreamHandler<T>): void
}

export class StreamingSubscription<T> implements StreamingSubscription<T> {
  private readonly sub: ISubscription<T>
  private readonly handlers = new Set<StreamHandler<T>>()

  constructor(sub: ISubscription<T>) {
    this.sub = sub
  }

  public subscribe(handler: StreamHandler<T>): void {
    this.handlers.add(handler)
  }

  public unsubscribe(handler: StreamHandler<T>): void {
    this.handlers.delete(handler)
  }

  public dispose(): void {
    this.sub.dispose()
  }

  public callback(value: T) {
    this.handlers.forEach((handler) => {
      try {
        handler(value)
      } catch (error) {
        console.error(error)
      }
    })
  }
}

export function startStreaming(
  accessToken: string,
  tenantId: string,
  method: string,
  onConnection: () => void,
  ...params
) {
  const url = `${
    process.env.NODE_ENV === 'production'
      ? ''
      : 'https://dev-multitenant.scada-lite.com'
  }/streaming/hub`

  const connection = new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => accessToken,
      headers: {
        tenantId,
      },
    })
    .configureLogging(LogLevel.Information)
    .build()

  return connection.start().then(() => {
    onConnection()
    return connection.stream(method, ...params)
  })
}

export class ConnectionError extends ErrorBase {
  constructor(error: string) {
    super('Connecting to stream failed', error)
  }
}

export class ReceiveError extends ErrorBase {
  constructor(error: string) {
    super('Receiving stream failed', error)
  }
}

export class StreamingError extends ErrorBase {
  constructor(error: string) {
    super('Streaming failed', error)
  }
}
