import {
  CircularProgress,
  createStyles,
  LinearProgress,
  Link,
  Modal,
  Paper,
  Portal,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import flat from 'array.prototype.flat'
import AlertThresholdsDialog from 'components/details/thresholds/ThresholdsDialog'
import { ConfirmDialog } from 'components/misc/ConfirmDialog'
import Snack from 'components/misc/Snack'
import { duration } from 'moment'
import React from 'react'
import { Media } from 'react-breakpoints'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { bindActionCreators } from 'redux'
import { actionCreators } from 'store/AllSites'
import { Asset, Site } from 'store/Site'
import theme from 'theme'
import { generatePath, Link as RouterLink, withRouter } from 'react-router-dom'
import {
  getLatestTelemetry,
  getSiteClient,
  saveAlertHistoryDataAsCsv,
  saveTelemetryHistoryDataAsCsv,
} from '../../api/client'
import { CsvExportType } from '../../api/apiservice'
import {
  getEmptyTelemetryTypeToStatusRanges,
  loadGlobalThresholds,
  loadAlertThresholds,
  Threshold,
  updateAlertThresholds,
} from '../../api/thresholdClient'
import { AlertsState } from '../../store/Alert'
import { AppState } from '../../store/AppState'
import {
  actionCreators as historicalDataActionCreators,
  HistoricalDataState,
} from '../../store/HistoricalData'
import {
  hasSupervisorUserAccessLevel,
  isAdmin,
  isSystemAdmin,
} from '../../store/oidc/userManager'
import {
  defaultTelemetryState,
  defaultSiteState,
  requestSite,
  requestSiteTelemetry,
  TelemetryMessage,
  TelemetryState,
  SiteState,
} from '../../store/SiteDetails'
import FilteredAlerts from '../alerts/FilteredAlerts'
import {
  MySitesContextData,
  withMySitesContext,
} from '../contexts/MySitesContext'
import { UiContextData, withUiContext } from '../contexts/UiContext'
import SiteContext, {
  SiteContextData,
  withSiteContext,
} from '../contexts/SiteContext'
import GatewayMetrics from '../details/GatewayMetrics'
import Sensors from '../details/Sensors'
import AssetInfoDialog from '../details/Asset/AssetInfo'
import { TelemetryThresholdsComponentState } from '../details/thresholds/TelemetryThresholds'
import ThresholdConfiguration from '../details/thresholds/ThresholdConfiguration'
import { getTimeRange, RelativeTimeRange, TimeRange } from '../misc/TimeRange'
import TimeRangePicker from '../misc/TimeRangePicker'
import { relativeTimeRanges as alertRelativeTimeRanges } from '../MyAlerts'
import { SiteMatch } from '../SiteDetails'
import SitesMap from '../sites/SitesMap'
import { StatusRange, TelemetryStatus, TelemetryType } from 'api/alertservice'
import { configurationRoute } from 'App'
import { MultitenantUserState } from 'store/reducers/multitenant'
import { Color } from '@material-ui/lab'
import { deepCopyItem } from 'components/utils'
import {
  setFilteringTimeRange,
  getFilteringTimeRangeObj,
} from 'utils/localStorage'
import {
  getFilteringDurationByTimeRangeType,
  getTimeRangeTypeByDurationInHours,
} from '../utils/converter'

export enum Details {
  Sensors = 0,
  Alerts = 1,
  Thresholds = 2,
}

export function getTimeRanges(currentTab: Details) {
  switch (currentTab) {
    case Details.Sensors:
    case Details.Thresholds:
      return relativeTimeRanges

    case Details.Alerts:
      return alertRelativeTimeRanges
  }
}

export const relativeTimeRanges: [RelativeTimeRange][] = flat([
  [6, 12, 24].map((d) => [duration(d, 'hour')]),
  [3, 7, 14].map((d) => [duration(d, 'day')]),
  [1].map((d) => [duration(d, 'month')]),
])

const initialTimeRange = duration(1, 'day')

const styles = (theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    sensors: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100vw',
      },
      flex: 1,
      maxWidth: '65vw',
    },
    details: {
      width: '100%',
    },
    dialogPaperWidthSm: {
      maxWidth: 'unset',
    },
  })

interface PropsFromState {
  readonly alerts: AlertsState
  readonly accessToken?: string
  readonly userId?: string
  readonly tenantId: number
  readonly user: MultitenantUserState
}

type PropsFromDispatch = typeof actionCreators
type HistoricalPropsFromDispatch = typeof historicalDataActionCreators

interface Props {
  readonly oneColumn?: boolean
  readonly hideGaugeBreakpoint?: keyof BreakpointValues
  readonly tabBarContainer: React.RefObject<HTMLDivElement>
  readonly setIsPlungerTabVisible: (value: boolean) => void
  readonly setIsPumpControlTabVisible: (value: boolean) => void
  readonly status?: TelemetryStatus
}

export type AllProps = Props &
  PropsFromState &
  PropsFromDispatch &
  HistoricalPropsFromDispatch &
  WithStyles<typeof styles> &
  UiContextData &
  SiteContextData &
  MySitesContextData &
  RouteComponentProps<SiteMatch>

interface State
  extends SiteContextData,
    Partial<TelemetryThresholdsComponentState> {
  readonly site: SiteState
  readonly AssetDialogSiteInfo: Site | undefined
  readonly asset: Asset | undefined
  readonly telemetry: TelemetryState
  readonly timeRanges: (TimeRange | undefined)[]
  readonly savingCsv?: boolean
  readonly lastSeenTime?: Date
  readonly effectiveThresholds: Threshold[]
  readonly localThresholds: Threshold[]
  readonly globalThresholds: Threshold[]
  readonly isGlobalThresholds: boolean
  readonly showThresholds?: TelemetryType
  readonly showAssetInfoTelemetryType?: TelemetryType
  readonly assetInfoUpdateMessage?: Color
  readonly assetId?: string
  readonly assetName?: string
  readonly oneColumn?: boolean
  readonly showThresholdsUpdateSuccess?: boolean
  readonly showConfirmation?: boolean
  readonly historicalData: HistoricalDataState
  readonly latestTelemetry: TelemetryMessage[]
  readonly isEditThresholdsViewEnabled: boolean
  readonly gatewayMetrics: TelemetryMessage[]
}

class MetricsDashboardTab extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      site: {},
      asset: undefined,
      AssetDialogSiteInfo: undefined,
      timeRanges: Object.values(Details)
        .filter((d) => typeof d === 'number')
        .map((d) => this.getInitialTimeRange(d as Details)),
      effectiveThresholds: [
        {
          thresholds: getEmptyTelemetryTypeToStatusRanges(),
        },
      ],
      localThresholds: [
        {
          thresholds: getEmptyTelemetryTypeToStatusRanges(),
        },
      ],
      globalThresholds: [
        {
          thresholds: getEmptyTelemetryTypeToStatusRanges(),
        },
      ],
      isGlobalThresholds: true,
      historicalData: { data: {} },
      telemetry: { telemetry: {} },
      gatewayMetrics: [],
      latestTelemetry: [],
      isEditThresholdsViewEnabled: false,
      assetInfoUpdateMessage: undefined,
    }
    this.load = this.load.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
  }

  isSystemAdmin = isSystemAdmin(this.props.user)
  siteAPI = getSiteClient(
    this.props.accessToken!,
    this.props.tenantId.toString(),
    this.props.userId
  )

  private readonly thresholdConfiguration =
    React.createRef<ThresholdConfiguration>()

  public componentDidMount() {
    this.load()
    // setInterval(
    //  async () => await this.loadTelemetry(this.state.timeRanges),
    //  60000
    // )
  }

  public componentDidUpdate({ match, accessToken }: AllProps) {
    if (
      accessToken !== this.props.accessToken ||
      match.params.id !== this.props.match.params.id
    ) {
      this.load()
    }
  }

  public componentWillUnmount() {
    this.props.selectSite()
  }

  private getInitialTimeRange(detail: Details) {
    switch (detail) {
      case Details.Sensors:
      case Details.Alerts:
        return this.getLocalStorageMetricsFilteringDuration()

      default:
        return undefined
    }
  }

  private getLocalStorageMetricsFilteringDuration() {
    const durationObj = getFilteringTimeRangeObj()

    if (!durationObj) {
      return initialTimeRange
    }

    return getFilteringDurationByTimeRangeType(durationObj.value)
  }

  public render() {
    if (this.state.site.loading) {
      return <LinearProgress />
    }

    const { root } = this.props.classes
    const style: React.CSSProperties | undefined = this.props.oneColumn
      ? {
          flexWrap: 'wrap',
          overflow: 'visible',
        }
      : undefined

    return (
      <div className={root} style={style}>
        {this.renderSensors()}
        {this.renderSiteDetails()}
        {this.state.savingCsv && (
          <Modal open={true}>
            <div
              style={{
                display: 'grid',
                height: '100%',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <CircularProgress size="10em" />
            </div>
          </Modal>
        )}
        {this.state.showThresholds && this.renderThresholdsEditor()}
        {this.state.showAssetInfoTelemetryType && this.renderAssetInfoDialog()}
        {this.state.showThresholdsUpdateSuccess && (
          <Snack
            message="Thresholds updated"
            onClose={() =>
              this.setState({
                showThresholdsUpdateSuccess: false,
              })
            }
          />
        )}
        {this.state.assetInfoUpdateMessage && (
          <Snack
            message={
              this.state.assetInfoUpdateMessage === 'success'
                ? 'Assset Updated, Reloading.'
                : 'Something went wrong updating the asset'
            }
            severity={this.state.assetInfoUpdateMessage}
            onClose={() =>
              this.setState({
                assetInfoUpdateMessage: undefined,
              })
            }
          />
        )}
        {this.state.showConfirmation &&
          this.renderThresholdsUpdateConfirmation()}
        <Portal container={this.props.tabBarContainer.current}>
          {this.renderTimePicker(Details.Sensors)}
        </Portal>
      </div>
    )
  }

  private async handleStartDateChange(startDatetoUpdate: string) {
    try {
      const siteToUpdate = deepCopyItem(this.state.site.site!)
      siteToUpdate.startDate = startDatetoUpdate
      await this.siteAPI.updateSite(siteToUpdate)
      await this.siteAPI.updateCache()
      this.load()
    } catch (error) {
      console.error(error)
    }
  }

  private renderGatewayMetrics() {
    const { gatewayMetrics, lastSeenTime } = this.state

    return (
      <>
        {gatewayMetrics.map((element) => (
          <GatewayMetrics
            key={element.Id}
            lastSeenTime={lastSeenTime}
            startDate={this.state.site.site?.startDate}
            metrics={element}
            handleStartDateChange={this.handleStartDateChange}
            isSystemAdmin={this.isSystemAdmin}
            style={{ maxHeight: '35vh' }}
          />
        ))}
      </>
    )
  }

  private renderSiteDetails() {
    const { showMap, classes } = this.props

    return (
      <div className={classes.details}>
        {showMap && this.renderMap()}
        {this.renderGatewayMetrics()}
        {this.renderAlerts()}
      </div>
    )
  }

  private renderMap() {
    const {
      site: { site },
    } = this.state

    return site ? (
      <SiteContext.Provider
        value={{
          selectedSiteId: this.state.site.site?.id,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setSelectedSiteId: (_) => {},
          telemetry: this.state.telemetry,
        }}
      >
        <SitesMap
          mySites={[site]}
          statuses={[{ siteId: site.id, status: Number(this.props.status) }]}
          paperProps={{
            elevation: 6,
            square: true,
            style: {
              margin: theme.spacing(1),
              maxHeight: '30vh',
            },
          }}
        />
      </SiteContext.Provider>
    ) : null
  }

  private renderAlerts() {
    const {
      site: { site },
      timeRanges,
    } = this.state

    if (!site) {
      return null
    }

    const id = site?.id

    return (
      <Paper
        className={this.props.classes.margin}
        elevation={6}
        square={true}
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          paddingBottom: theme.spacing(1),
          minHeight: '34vh',
        }}
      >
        {this.renderTimePicker(Details.Alerts)}
        <FilteredAlerts siteId={id} timeRange={timeRanges[Details.Alerts]} />
      </Paper>
    )
  }

  private load() {
    if (this.state.site.loading) {
      return
    }

    this.state.telemetry.subscription?.dispose()

    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }
    this.setState(
      {
        site: defaultSiteState,
        telemetry: defaultTelemetryState,
      },
      async () => {
        const {
          match: {
            params: { id },
          },
        } = this.props

        await requestSite(
          accessToken,
          tenantId.toString(),
          id,
          () => this.state.site,
          async (site) => {
            if (site.loading) {
              return
            }
            this.setState({ site: site })
            const isPumpControlTabVisible =
              site.site?.pumpControls &&
              site.site.pumpControls.length > 0 &&
              isAdmin(this.props.user.selectedTenant?.roles)

            if (site.site?.plungerLift) {
              this.props.setIsPlungerTabVisible(true)
            } else {
              this.props.setIsPlungerTabVisible(false)
            }

            this.props.setIsPumpControlTabVisible(isPumpControlTabVisible)

            let lastSeenTime: Date

            try {
              const result = await getLatestTelemetry(
                accessToken,
                tenantId.toString(),
                [id]
              )

              if (!(result instanceof Error)) {
                lastSeenTime = this.getMaxValue(
                  result.map((s) => s.CreationTimeUtc)
                )!

                const gateways = result.filter(
                  (x) => x.TelemetryType === TelemetryType.GatewayMetrics
                )

                this.setState({
                  lastSeenTime,
                  gatewayMetrics: gateways,
                  latestTelemetry: result,
                })
              }
            } catch (error) {
              console.warn(
                `Error loading site's ${id} latest telemetry.`,
                error
              )
            }

            this.setTitle(site?.site)

            this.props.selectSite(site.site)
            this.props.setLastSeenTime!(lastSeenTime!)
            this.loadThresholds()
          }
        )

        await this.loadTelemetry(this.state.timeRanges)

        await requestSiteTelemetry(
          accessToken,
          tenantId.toString(),
          [id],
          () => this.state.telemetry,
          (telemetry) => {
            const dates = [
              ...(this.state.lastSeenTime !== undefined
                ? [this.state.lastSeenTime]
                : []),
            ] as Date[]
            Object.keys(telemetry.telemetry).forEach((k) =>
              telemetry.telemetry[k].forEach(
                (value) =>
                  value.realtimeDataMaxTimeStamp &&
                  dates.push(value.realtimeDataMaxTimeStamp)
              )
            )

            const lastSeenTime = this.getMaxValue(dates)
            this.props.setTelemetry!(telemetry)
            this.props.setLastSeenTime!(lastSeenTime)

            this.setState({
              telemetry,
              lastSeenTime,
            })
          }
        )
      }
    )
  }

  private readonly renderTimePicker = (
    details: Details,
    readonly?: boolean
  ) => {
    const { timeRanges } = this.state

    return (
      <TimeRangePicker
        relativeTimeRanges={getTimeRanges(details)}
        onTimeRangeChanged={(timeRange) =>
          this.onTimeRangeChanged(details, timeRange)
        }
        onSaveAsCsv={(event, timeRange) =>
          this.onSaveAsCsv(details, timeRange, event.altKey)
        }
        initialTimeRange={timeRanges[details]}
        readonly={readonly}
      />
    )
  }

  private readonly onSaveAsCsv = (
    details: Details,
    timeRange?: TimeRange,
    altKey?: boolean
  ) => {
    const { accessToken, userId, tenantId } = this.props
    const [from, to] = getTimeRange(timeRange)

    this.setState({ savingCsv: true }, async () => {
      switch (details) {
        case Details.Sensors:
          await saveTelemetryHistoryDataAsCsv(
            accessToken!,
            tenantId,
            this.state.site.site!.id,
            from,
            to,
            altKey ? CsvExportType.Cygnet : CsvExportType.Standard
          )
          break

        case Details.Alerts:
          await saveAlertHistoryDataAsCsv(
            accessToken!,
            userId!,
            tenantId.toString(),
            undefined,
            undefined,
            from,
            to
          )
          break

        default:
          break
      }

      this.setState({ savingCsv: false })
    })
  }

  private readonly onTimeRangeChanged = (
    details: Details,
    timeRange?: TimeRange
  ) => {
    const { timeRanges } = this.state
    const newTimeRanges = {
      ...timeRanges,
      [details]:
        timeRange !== undefined ? timeRange : this.getInitialTimeRange(details),
    }

    const telemetry = {
      telemetry: {},
    }

    this.setLocalStorageMetricsTimeRange(timeRange)

    this.setState({
      timeRanges: newTimeRanges,
      telemetry,
    })
    this.loadTelemetry(newTimeRanges)
  }

  private setLocalStorageMetricsTimeRange(timeRange?: TimeRange) {
    const getTimeRangeType = getTimeRangeTypeByDurationInHours(
      duration(timeRange).asHours()
    )
    setFilteringTimeRange(getTimeRangeType)
  }

  public readonly loadTelemetry = (timeRanges: (TimeRange | undefined)[]) => {
    const { accessToken, tenantId } = this.props

    if (!accessToken || !timeRanges || !tenantId) {
      return
    }

    try {
      this.setState({
        historicalData: {
          ...this.state.historicalData,
          loading: true,
        },
      })

      const {
        match: {
          params: { id },
        },
      } = this.props
      const telTypes = [...this.getTelemetryTypeAndAssetIds()]

      telTypes.map(([t, assetId]) => {
        historicalDataActionCreators.requestHistoricalData(
          accessToken,
          tenantId,
          id,
          t,
          assetId,
          null,
          timeRanges[Details.Sensors]!,
          () => this.state.historicalData,
          (historicalData) => this.setState({ historicalData })
        )
      })
    } catch (error) {
      console.error('Failed to retrieve telemetry', error)
    } finally {
      this.setState({
        historicalData: {
          ...this.state.historicalData,
          loading: false,
        },
      })
    }
  }

  private readonly loadThresholds = async () => {
    const { accessToken, tenantId } = this.props
    const {
      site: { site, loading },
    } = this.state

    if (accessToken && !loading && site) {
      try {
        const alertThresholds: Threshold[] = await loadAlertThresholds(
          site.id,
          accessToken,
          tenantId.toString()
        )
        const globalThreshold: Threshold = await loadGlobalThresholds(
          accessToken,
          tenantId.toString()
        )
        const isGlobalThresholds = !alertThresholds.length
        const effectiveThresholds: Threshold[] = isGlobalThresholds
          ? [globalThreshold]
          : alertThresholds
        this.setState({
          effectiveThresholds,
          localThresholds: alertThresholds,
          globalThresholds: [globalThreshold],
          isGlobalThresholds,
          isEditThresholdsViewEnabled: !isGlobalThresholds,
        })
      } catch (error) {
        console.error('Failed to load thresholds', error)
      }
    }
  }

  private readonly renderSensors = () => {
    const {
      site,
      telemetry,
      timeRanges,
      localThresholds,
      globalThresholds,
      historicalData,
      latestTelemetry,
    } = this.state
    const { accessToken, classes, hideGaugeBreakpoint, tenantId } = this.props

    return (
      <div className={classes.sensors}>
        <Media>
          {({ breakpoints, currentBreakpoint }) => (
            <Sensors
              site={site}
              telemetry={telemetry}
              accessToken={accessToken!}
              tenantId={tenantId}
              timeRange={timeRanges[Details.Sensors]!}
              localThresholds={localThresholds}
              globalThresholds={globalThresholds}
              onOpenThresholds={(
                showThresholds: TelemetryType,
                assetId: string,
                assetName: string
              ) =>
                this.setState({
                  showThresholds,
                  assetId,
                  assetName,
                })
              }
              onOpenAssetInfo={(
                showAssetInfoTelemetryType: TelemetryType,
                asset: Asset,
                AssetDialogSiteInfo: Site
              ) =>
                this.setState({
                  showAssetInfoTelemetryType,
                  asset,
                  AssetDialogSiteInfo,
                })
              }
              fullWidthChart={
                this.props.oneColumn ||
                breakpoints[currentBreakpoint] <= breakpoints.md
              }
              hideGaugeBreakpoint={hideGaugeBreakpoint}
              allHistoricalData={historicalData}
              latestTelemetry={latestTelemetry}
            />
          )}
        </Media>
      </div>
    )
  }

  private readonly setTitle = (site?: Site) => {
    const { setPageTitle, match } = this.props

    setPageTitle!(site?.name ?? site?.id ?? match.params.id)
  }

  private *getTelemetryTypeAndAssetIds(): Generator<
    [TelemetryType, string | null]
  > {
    const {
      site: { site },
    } = this.state

    if (site) {
      for (const { id } of site.pumpControls) {
        yield [TelemetryType.PumpControl, id]
      }

      for (const { id } of site.vibrations) {
        yield [TelemetryType.Vibration, id]
      }
      for (const well of site.wells) {
        const { id, hasCasing, hasTubing, hasCrank, hasPump } = well

        if (hasCasing) {
          yield [TelemetryType.CasingPressure, id]
        }

        if (hasTubing) {
          yield [TelemetryType.TubingPressure, id]
        }

        if (hasCrank) {
          yield [TelemetryType.CrankRevolutions, id]
        }

        if (hasPump) {
          yield [TelemetryType.PumpPressure, id]
        }
      }

      for (const { id } of site.tanks) {
        yield [TelemetryType.TankLevel, id]
      }

      for (const { id } of site.BalancedTanks) {
        yield [TelemetryType.BalancedTank, id]
      }

      for (const { id } of site.gasFlows) {
        yield [TelemetryType.GasFlow, id]
        yield [TelemetryType.DiffPressure, id]
        yield [TelemetryType.StaticPressure, id]
        yield [TelemetryType.StaticTemp, id]
        // yield [TelemetryType.Flow, id]
      }

      for (const { id } of site.heaters) {
        yield [TelemetryType.HeaterTemp, id]
      }

      for (const { id } of site.separators) {
        yield [TelemetryType.SeparatorPressure, id]
      }

      for (const { id } of site.compressors) {
        yield [TelemetryType.CompressorPressure, id]
      }

      for (const { id } of site.knockoutPressures) {
        yield [TelemetryType.KnockoutPressure, id]
      }

      for (const { id } of site.liquidFlows) {
        yield [TelemetryType.LiquidFlow, id]
      }

      for (const { id } of site.genericSensors) {
        yield [TelemetryType.GenericSensor, id]
      }

      for (const { id } of site.strokesPerMinute) {
        yield [TelemetryType.StrokesPerMinute, id]
      }

      for (const { id } of site.staticPressure) {
        yield [TelemetryType.StaticPressure, id]
        yield [TelemetryType.StaticTemp, id]
      }

      for (const { id } of site.flaresStatus) {
        yield [TelemetryType.FlareStatus, id]
      }

      yield [TelemetryType.SensorBatteryLevel, null]
    }
  }

  private getMaxValue(dates: (Date | undefined)[]): Date | undefined {
    const values = dates.map((d) => d?.valueOf()).filter((v) => v) as number[]
    const sorted = values.sort((a, b) => b - a)

    return sorted.length ? new Date(sorted[0]) : undefined
  }

  private readonly renderAssetInfoDialog = (): React.ReactNode => {
    return (
      <AssetInfoDialog
        showAssetInfoTelemetryType={this.state.showAssetInfoTelemetryType}
        loadSite={this.load}
        asset={this.state.asset!}
        site={this.state.site.site}
        siteThresholds={this.state.effectiveThresholds}
        latestTelemetry={this.state.latestTelemetry}
        closeAssetInfoDialog={this.closeAssetInfoDialog}
        showSiteUpdateNotification={this.showSiteUpdateNotification}
      />
    )
  }

  private readonly renderThresholdsEditor = (): React.ReactNode => {
    if (
      this.state.isGlobalThresholds &&
      !this.state.isEditThresholdsViewEnabled
    ) {
      return this.getThresholdsConfigConfirm()
    } else {
      const thresholds =
        this.state.effectiveThresholds.find(
          (el) => el.AssetId === this.state.assetId
        )?.thresholds ?? getEmptyTelemetryTypeToStatusRanges()

      return (
        <AlertThresholdsDialog
          showThresholds={this.state.showThresholds}
          assetId={this.state.assetId!}
          isSaveAllowed={
            !this.state.allowSave ||
            !hasSupervisorUserAccessLevel(this.props.user.selectedTenant?.roles)
          }
          onSaveClicked={this.onSaveClicked}
          closeThresholdsDialog={this.closeThresholdsDialog}
          thresholds={thresholds}
          historicalData={this.state.historicalData}
          site={this.state.site.site}
          thresholdConfigurationRef={this.thresholdConfiguration}
          timeRanges={this.state.timeRanges}
          telemetry={this.state.telemetry.telemetry}
          thresholdsStateUpdated={this.thresholdsStateUpdated}
        />
      )
    }
  }

  private readonly getThresholdsConfigConfirm = () => {
    return (
      <ConfirmDialog
        title="Thresholds configuration"
        titleOkButton="Define"
        onAction={(ok) => {
          if (!ok) {
            this.closeThresholdsDialog()
          }

          if (ok) {
            this.setState({
              isEditThresholdsViewEnabled: true,
            })
          }
        }}
      >
        Thresholds configuration is inherited from{' '}
        <Link component={RouterLink} to={generatePath(configurationRoute)}>
          Global Thresholds
        </Link>
        . Defining configuration specific for this site will override Global
        configuration.
        <br />
        <br />
        Do you want to define Site Thresholds?
      </ConfirmDialog>
    )
  }

  private readonly updateThresholds = async (thresholds: Threshold) => {
    this.closeThresholdsDialog()

    const siteId = this.state.site.site?.id
    const assetId = this.state.assetId

    const { accessToken, tenantId, userId } = this.props

    if (!accessToken || !siteId || !assetId || !tenantId) {
      return
    }

    const assetName = this.state.assetName || assetId
    const siteName = this.state.site.site?.name || siteId

    await updateAlertThresholds(
      accessToken,
      tenantId.toString(),
      siteId,
      siteName,
      assetId,
      assetName,
      thresholds,
      userId
    )
  }

  private readonly closeAssetInfoDialog = () =>
    this.setState({
      showAssetInfoTelemetryType: undefined,
      AssetDialogSiteInfo: undefined,
      asset: undefined,
    })

  private readonly showSiteUpdateNotification = (updateResult?: Color) =>
    this.setState({
      assetInfoUpdateMessage: updateResult,
    })

  private readonly closeThresholdsDialog = () =>
    this.setState({
      showThresholds: undefined,
      allowSave: undefined,
      statusRanges: [],
      isEditThresholdsViewEnabled: false,
    })

  private readonly onSaveClicked = () => {
    this.thresholdConfiguration.current?.save((savedState) => {
      if (savedState.allowSave) {
        this.setState({
          ...savedState,
          showConfirmation: true,
        })
      }
    })
  }

  private renderThresholdsUpdateConfirmation() {
    const { type, assetId, statusRanges } = this.state
    const allStatusRanges = this.state.effectiveThresholds.reduce(
      (res, curr) => {
        curr.thresholds.forEach((value, key) => {
          // skip thresholds for currently updated (TelemetryType, AssetId)
          if (key === type && curr.AssetId === assetId) {
            return
          }

          res.push(...value)
        })

        return res
      },
      [] as StatusRange[]
    )

    const hasThresholds =
      allStatusRanges.length + this.state.statusRanges!.length > 0

    const isResetThresholds =
      allStatusRanges.length > 0 && this.state.statusRanges!.length === 0

    const title =
      (hasThresholds &&
        (isResetThresholds ? 'Reset thresholds' : 'Thresholds modified')) ||
      'All thresholds removed'

    const promptMessage = isResetThresholds
      ? 'All local thresholds for this metric will be removed. Apply changes?'
      : 'Are you sure to apply latest changes?'

    return (
      <ConfirmDialog
        title={title}
        onAction={async (ok) => {
          this.setState({
            showConfirmation: false,
          })

          if (ok) {
            const effectiveThresholds =
              this.getUpdatedThresholdsForCurrTypeAndAsset(type!, statusRanges!)

            await this.saveThresholds(effectiveThresholds, hasThresholds)
          }
        }}
      >
        {hasThresholds
          ? promptMessage
          : [
              'The global thresholds will be used for the site.',
              <br key={0} />,
              promptMessage,
            ]}
      </ConfirmDialog>
    )
  }

  private getUpdatedThresholdsForCurrTypeAndAsset(
    key: TelemetryType,
    statusRanges: StatusRange[]
  ): Threshold[] {
    const { assetId } = this.state
    const newEffectiveThresholds = this.state.effectiveThresholds
    const newEffectiveThresholdsForTypeAndAssetIndex =
      newEffectiveThresholds.findIndex((el) => el.AssetId === assetId)

    if (~newEffectiveThresholdsForTypeAndAssetIndex) {
      newEffectiveThresholds[
        newEffectiveThresholdsForTypeAndAssetIndex
      ].thresholds.set(key, statusRanges)
    } else {
      newEffectiveThresholds.push({
        AssetId: assetId,
        thresholds: new Map<TelemetryType, StatusRange[]>([
          [key, statusRanges],
        ]),
      })
    }

    return newEffectiveThresholds
  }

  private async saveThresholds(
    effectiveThresholds: Threshold[],
    hasThresholds
  ) {
    try {
      const savingThresholds = effectiveThresholds.find(
        (el) => el.AssetId === this.state.assetId
      )!
      const isNewlyCreated = savingThresholds.Id === undefined

      const updatedThreshold: any = await this.updateThresholds(
        savingThresholds
      )

      if (isNewlyCreated) {
        const updatedThresholdIndex = effectiveThresholds.findIndex(
          (el) => el.AssetId === this.state.assetId
        )!
        const updatedThresholdWithId: Threshold = {
          Id: updatedThreshold?.id,
          AssetId: savingThresholds.AssetId,
          thresholds: savingThresholds.thresholds,
        }

        effectiveThresholds[updatedThresholdIndex] = updatedThresholdWithId
      }

      this.setState(
        {
          effectiveThresholds,
          showThresholdsUpdateSuccess: true,
        },
        () => this.loadThresholds()
      )
    } catch (exception) {
      console.error(exception.response)
    }
  }

  private readonly thresholdsStateUpdated = (
    allowSave,
    onStateUpdated?: () => void
  ) => this.setState({ allowSave }, onStateUpdated)
}

const mapPropsFromState = (
  { alerts, oidc: { user }, multitenantUser }: AppState,
  {
    match: {
      params: { id },
    },
  }: AllProps
): PropsFromState => ({
  alerts,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  accessToken: multitenantUser.accessToken,
  userId: multitenantUser.id,
  user: multitenantUser,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default connect(
  mapPropsFromState,
  mapDispatchToProps
)(
  withRouter(
    withStyles(styles)(
      withUiContext(withSiteContext(withMySitesContext(MetricsDashboardTab)))
    )
  )
)
