import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  TelemetryThresholds,
  Props as TelemetryThresholdsProps,
  TelemetryThresholdsComponentState,
} from './TelemetryThresholds'
import SensorChart, {
  Props as SensorChartProps,
} from 'components/sensors/SensorChart'
import { AppState } from '../../../store/AppState'
import { HistoricalDataEntry } from 'api/client'
import { celsiusToFahrenheit, inchToFeet } from 'components/utils/converter'
import { StatusRange, TelemetryType } from 'api/alertservice'
import { isAdmin } from 'store/oidc/userManager'
import { MultitenantUserState } from 'store/reducers/multitenant'
import { Site } from '../../../store/Site'
import { Grid } from '@material-ui/core'

interface PropsFromState {
  readonly user: MultitenantUserState
  readonly accessToken?: string
  readonly tenantId?: number
  readonly launchDarklyClientsideId?: string
}

interface Props extends TelemetryThresholdsProps {
  readonly sensorChartProps?: SensorChartProps
  readonly site: Site
  readonly telemetryType?: TelemetryType
}

interface State {
  readonly statusRanges: StatusRange[]
  readonly data: HistoricalDataEntry[]
}

type AllProps = PropsFromState & Props

class TelemetryThresholdsWithChartPreview extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    const thresholds = props.sensorChartProps?.statusRanges ?? []
    const data = this.processData(props.sensorChartProps?.data)

    this.state = {
      statusRanges: thresholds,
      data,
    }
  }

  isAdmin = isAdmin(this.props.user.selectedTenant?.roles)

  public render() {
    const { statusRanges, data } = this.state
    const { site, telemetryType } = this.props
    const statusRangesWithAdjustedUnits = this.processStatusRanges(statusRanges)

    let historicalDataGasFlow: HistoricalDataEntry[] = []

    if (data.length > 0 && isNaN(data[0].value)) {
      if (!(Object.keys(data[0]).indexOf('values') > -1)) {
        historicalDataGasFlow = this.transformObjectData(
          data,
          historicalDataGasFlow
        )
      }
    } else {
      historicalDataGasFlow = data
    }

    return (
      <div
        style={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <TelemetryThresholds
          {...(this.props as TelemetryThresholdsProps)}
          stateUpdated={this.telemetryThresholdsStateUpdated}
          isAdmin={this.isAdmin}
          tenantId={this.props.tenantId || 0}
          site={this.props.site}
          launchDarklyClientsideId={this.props.launchDarklyClientsideId || ''}
        />
        {this.props.sensorChartProps && (
          <SensorChart
            {...this.props.sensorChartProps}
            data={historicalDataGasFlow.filter((d) => d.invalidData !== true)}
            statusRanges={statusRangesWithAdjustedUnits}
            style={{ height: 'unset' }}
          />
        )}
      </div>
    )
  }

  private transformObjectData(
    data: HistoricalDataEntry[],
    historicalDataGasFlow: HistoricalDataEntry[]
  ) {
    data.forEach((element) => {
      const historicalData = {
        timestamp: element.timestamp,
        value: element.value?.VolumeFlowRate || 0,
        todayVolumeFlowed: element.value?.TodayVolumeFlowed || 0,
        yesterdayVolumeFlowed: element.value?.YesterdayVolumeFlowed || 0,
        staticPressure: element.value?.StaticPressure || 0,
        values: element.values,
      }
      historicalDataGasFlow.push(historicalData)
    })

    data = historicalDataGasFlow.sort((a, b) => a.timestamp - b.timestamp)
    return data
  }

  private processData(data?: HistoricalDataEntry[]): HistoricalDataEntry[] {
    if (data === undefined || !data.length) {
      return []
    }

    const processFunc = this.getProcessFunc(this.props.type)
    return data.map((t) => {
      return {
        ...t,
        value:
          processFunc === undefined ? t.value : t.value && processFunc(t.value),
      }
    })
  }

  private readonly telemetryThresholdsStateUpdated = (
    state: TelemetryThresholdsComponentState
  ) => {
    const { statusRanges: thresholds } = state

    this.setState({ statusRanges: thresholds }, () =>
      this.props.stateUpdated(state)
    )
  }

  private processStatusRanges(statusRanges: StatusRange[]): StatusRange[] {
    const { type } = this.props
    if (
      type === TelemetryType.TankLevel ||
      type === TelemetryType.BalancedTank ||
      type === TelemetryType.HeaterTemp
    ) {
      const processFunc = this.getProcessFunc(type)

      return statusRanges.map((item) =>
        StatusRange.fromJS({
          status: item.status,
          minThreshold: processFunc!(item.minThreshold),
          maxThreshold: processFunc!(item.maxThreshold),
        })
      )
    } else {
      return statusRanges
    }
  }

  private getProcessFunc(telemetryType: TelemetryType) {
    switch (telemetryType) {
      case TelemetryType.TankLevel:
      case TelemetryType.BalancedTank:
        return inchToFeet

      case TelemetryType.HeaterTemp:
        return celsiusToFahrenheit

      default:
        return undefined
    }
  }
}

const mapStateToProps = (state: AppState): PropsFromState => ({
  accessToken: state.multitenantUser.accessToken,
  user: state.multitenantUser,
  tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id,
  launchDarklyClientsideId: state.appConfig.launchDarklyClientsideId,
})

export default connect(mapStateToProps)(TelemetryThresholdsWithChartPreview)
