import { Reducer, AnyAction, Dispatch } from 'redux'
import { Site } from './Site'

export interface Selection {
  readonly state?: string
  readonly county?: string
  readonly site?: string
  readonly pumper?: string
}

export interface AllSitesState {
  readonly selection: Selection
  readonly selectedSite?: Site
  readonly selectedClusterType?: number
}

export const defaultState: AllSitesState = {
  selection: {},
}

const changeSelectionType = 'ALL_SITES_CHANGE_SELECTION'
const selectSiteType = 'ALL_SITES_CHANGE_SELECT_SITE'
const selectClusterType = 'ALL_SITES_CHANGE_CLUSTER_TYPE'

export const reducer: Reducer<AllSitesState> = (
  state = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case changeSelectionType:
      const { selection } = action
      return { ...state, selection }

    case selectSiteType:
      const { selectedSite } = action
      return { ...state, selectedSite }

    case selectClusterType:
      const { selectedClusterType } = action
      return { ...state, selectedClusterType }

    default:
      return state
  }
}

export const actionCreators = {
  changeSelection: (selection: Selection) => (dispatch: Dispatch) =>
    dispatch({
      selection,
      type: changeSelectionType,
    }),
  selectSite: (selectedSite?: Site) => (dispatch: Dispatch) =>
    dispatch({
      selectedSite,
      type: selectSiteType,
    }),
  selectClusterType: (selectedClusterType: number) => (dispatch: Dispatch) =>
    dispatch({
      selectedClusterType,
      type: selectClusterType,
    }),
}
