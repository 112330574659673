import * as React from 'react'
import { renderErrorAlert } from '../misc/utils'
import { ModelState } from '../../store/ModelState'

type Props = Pick<ModelState, 'error'>

type State = ModelState

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = { ...props }
  }

  public static getDerivedStateFromError() {
    return { error: 'Something went wrong.' }
  }

  public render() {
    const { error } = this.state

    return error ? renderErrorAlert(error) : this.props.children
  }
}

export default ErrorBoundary
