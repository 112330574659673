import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { loadUser } from 'redux-oidc'

import { AppState } from './AppState'
import { userManager } from './oidc/userManager'
import rootReducer from './rootReducer'

export default function configureStore(initialState?: AppState) {
  const middleware = [thunk]

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers: Function[] = []
  const isDevelopment = process.env.NODE_ENV === 'development'

  // eslint-disable-next-line dot-notation
  if (
    isDevelopment &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ) {
    // eslint-disable-next-line dot-notation
    enhancers.push(window['__REDUX_DEVTOOLS_EXTENSION__']())
  }

  function loadStore() {
    return new Promise((resolve) => {
      loadUser(store, userManager)

      return resolve(initialState)
    })
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  )

  loadStore()

  return store
}
