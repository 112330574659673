import React from 'react'
import SiteList from './SiteList'
import SiteFilter from './SiteFilter'
import './collapsible.css'

class ListController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allSites: [],
      filter: null,
      siteRoute: null,
    }
    this.querySites = this.querySites.bind(this)
  }

  componentDidMount() {
    this.querySites()
  }

  querySites() {
    const { siteList } = this.props

     const allSites = siteList
      .map((site) => ({ id: site.id, name: site.name }))
      .sort((a, b) => a.name.localeCompare(b.name))

    this.setState({
      allSites: allSites,
      siteRoute: this.props.siteRoute,
    })
  }

  updateSearch(inputValue) {
    const filter = this.state.filter

    this.setState({
      filter: inputValue,
    })
  }

  render() {
    return (
      <div className="app">
        <SiteFilter
          updateSearch={this.updateSearch.bind(this)}
          searchText={this.state.filter}
        />
        <SiteList
          filter={this.state.filter}
          siteRoute={this.state.siteRoute}
          allSites={this.state.allSites}
          selection={this.props.selection}
        />
      </div>
    )
  }
}

export default ListController
