import jwt_decode from 'jwt-decode'
import { isJwtExpired } from 'jwt-check-expiration'

export const extractParamManuallyFromSearchLocation = (
  searchLocation,
  paramToExtract
) => {
  let splitParam = searchLocation?.split(paramToExtract)
  let paramWithEqual = splitParam[1]
  let param = paramWithEqual?.substring(1)

  return param
}

export const getDecodedToken = (encodeToken) => {
  let decodedToken = jwt_decode(encodeToken)

  return decodedToken
}

export const checkExpirationToken = (encodedToken) => {
  let isExpired = isJwtExpired(encodedToken)

  return isExpired
}
