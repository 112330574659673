import React, { useState, useEffect, ChangeEvent } from 'react'
import {
  WellApiModel,
  IntermittentSetPointDto,
  IntermittentSetpointRangeDto,
  SetPointType,
  CompressorDeviceApiModel,
} from '../../../api/apiservice'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Typography,
  Grid,
  Button,
  Box,
  TextField,
} from '@material-ui/core'
import {
  getWellStatusRequest,
  turnOnWellRequest,
  turnOffWellRequest,
} from 'api/commands'
import { useSelector } from 'react-redux'

import { AppState } from '../../../store/AppState'

interface State {
  casingOffValue: string
  casingOnValues: string[]
  tubingOffValue: string
  tubingOnValues: string[]
  linePSIOffValue: string
  linePSIOnValues: string[]
  differentialPSIOffValue: string
  differentialPSIOnValues: string[]
  hasTubing: boolean
  hasCasing: boolean
  hasLinePSI: boolean
  hasDifferentialPSI: boolean
}

interface Props {
  isValidRole: boolean
  siteId: string
  wells: (WellApiModel | undefined)[] | undefined
  setPoints: IntermittentSetPointDto | undefined
  WellSelectedForSetPoints: any
  SaveSetPoints: any
  compressorDevice: CompressorDeviceApiModel | undefined
}

interface WellState {
  wellStatus: string
  deviceId: string
  message: string
  siteId: string
  wellId: string
}

type AllProps = Props

export function WellFilter(props: AllProps) {
  const accessToken = useSelector(
    (state: AppState) => state.multitenantUser.accessToken
  )
  const tenantId = useSelector(
    (state: AppState) =>
      state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0
  )
  const clientName = useSelector(
    (state: AppState) => state.appConfig.clientName
  )
  const [currentWellStatus, setCurrentWellStatus] = useState<WellState>({
    wellStatus: '',
    deviceId: '',
    message: '',
    siteId: '',
    wellId: '',
  })
  const [valueWellFilter, setValueWellFilter] = useState<string>('')
  const [formValues, setFormValues] = React.useState<State>({
    casingOffValue: '',
    casingOnValues: ['', '', '', ''],
    tubingOffValue: '',
    tubingOnValues: ['', '', '', ''],
    linePSIOffValue: '',
    linePSIOnValues: ['', '', '', ''],
    differentialPSIOffValue: '',
    differentialPSIOnValues: ['', '', '', ''],
    hasTubing: false,
    hasCasing: false,
    hasLinePSI: false,
    hasDifferentialPSI: false,
  })
  const [isFormValid, setIsformVvalid] = useState(true)

  const handleChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const typedValue = event.target.value
      const re = /(-|)[0-9.]+/g

      if (prop.split('-').length === 2) {
        const value =
          Number(typedValue.match(re)?.find((_, i) => i === 0) || '0') || ''

        setFormValues({
          ...formValues,
          [prop.split('-')[1]]: value,
        })

        setIsformVvalid(
          validateSetPoints(formValues[prop.split('-')[0]], Number(value || 0))
        )
      } else {
        const index = Number(prop.split('-')[0])
        const formField = prop.split('-')[1]
        const formFieldValues: any[] = formValues[formField]
        formFieldValues[index] =
          Number(typedValue.match(re)?.find((_, i) => i === 0) || '0') || ''

        setFormValues({ ...formValues, [formField]: formFieldValues })
        setIsformVvalid(
          validateSetPoints(formFieldValues, formValues[prop.split('-')[2]])
        )
      }
    }

  const handleChangeWellFilter = (event) => {
    setValueWellFilter(event.target.value)
    props.WellSelectedForSetPoints(event.target.value)
  }

  const handleTurnOnOffWellButtonClick = async () => {
    if (valueWellFilter && currentWellStatus) {
      const currentWell = props.wells?.find(
        (well) => valueWellFilter === well?.id
      )
      const requestBody = {
        clientName,
        siteId: props.siteId,
        wellId: valueWellFilter,
        deviceId: currentWell?.iwcDeviceId,
        provider: 0,
      }

      try {
        switch (currentWellStatus.wellStatus?.toLowerCase()) {
          case 'on':
            await turnOffWellRequest(accessToken, tenantId, requestBody)
            break
          case 'off':
            await turnOnWellRequest(accessToken, tenantId, requestBody)
            break
          default:
            break
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const getWellStatus = async () => {
    try {
      const currentWell = props.wells?.find(
        (well) => valueWellFilter === well?.id
      )
      const requestBody = {
        wells: [
          {
            wellId: valueWellFilter,
            position: 0,
            deviceId: currentWell?.iwcDeviceId,
          },
        ],
        clientName,
        siteId: props.siteId,
      }
      const wellStatus = await getWellStatusRequest(
        accessToken,
        tenantId,
        requestBody
      )
      setCurrentWellStatus(wellStatus.data[0])
    } catch (error) {
      console.error(error)
    }
  }

  const renderTurnOnOffButtonLabel = () => {
    if (!valueWellFilter) {
      return 'Select a Well'
    }

    switch (currentWellStatus.wellStatus?.toLowerCase()) {
      case 'on':
        return 'Shut Well'
      case 'off':
        return 'Open Well'
      case 'unknown':
        return 'Unknown Device'
      case 'notavailable':
        return 'Well not Available'
      default:
        return 'loading'
    }
  }

  useEffect(() => {
    if (valueWellFilter) {
      getWellStatus()
    } else {
      setCurrentWellStatus({
        wellStatus: '',
        deviceId: '',
        message: '',
        siteId: '',
        wellId: '',
      })
    }
  }, [valueWellFilter])

  useEffect(() => {
    setValueWellFilter('')
    setFormValues({
      tubingOffValue: '',
      tubingOnValues: ['', '', '', ''],
      casingOffValue: '',
      casingOnValues: ['', '', '', ''],
      linePSIOffValue: '',
      linePSIOnValues: ['', '', '', ''],
      differentialPSIOffValue: '',
      differentialPSIOnValues: ['', '', '', ''],
      hasTubing: false,
      hasCasing: false,
      hasLinePSI: false,
      hasDifferentialPSI: false,
    })
  }, [props.siteId])

  useEffect(() => {
    displaySetPoints()
  }, [props.setPoints])

  const section = {
    height: '100%',
    with: '100%',
    paddingTop: 13,
  }

  const paddingTop30 = {
    height: '100%',
    with: '100%',
    paddingTop: 30,
  }

  const setPointPercentages = ['', '25', '50', '75']

  const validateSetPoints = (onWellValues: string[], shutWellValue: number) => {
    let valid = true

    const filteredValues = onWellValues
      .filter((v) => Number(v) > 0)
      .map((v) => Number(v))

    filteredValues.forEach((value, i, a) => {
      if (!valid) return

      if (i < a.length - 1) {
        const nextValue = Number(a[i + 1])
        if (value >= nextValue) {
          valid = false
        }
      }
    })

    if (valid) {
      valid = filteredValues.every((v) => v < shutWellValue)
    }

    return valid
  }

  const handleSave = () => {
    const pushSetPoints = (
      onValues: string[],
      shutWell: string,
      type: SetPointType
    ) => {
      const openWellValues = {}

      onValues.forEach(
        (value, i) =>
          (openWellValues[
            `openWell${setPointPercentages[i]}${
              setPointPercentages[i] && 'Percent'
            }`
          ] = value)
      )

      setPointsToSave.push(
        IntermittentSetpointRangeDto.fromJS({
          type,
          ...openWellValues,
          shutWell,
        })
      )

      return openWellValues
    }

    let validSetPoints = true
    const setPointsToSave: IntermittentSetpointRangeDto[] = []
    if (
      validSetPoints &&
      formValues.hasTubing &&
      formValues.tubingOnValues.find((_, i) => i === 0) &&
      formValues.tubingOffValue
    ) {
      if (
        !validateSetPoints(
          formValues.tubingOnValues,
          Number(formValues.tubingOffValue)
        )
      ) {
        validSetPoints = false
        return
      }
      pushSetPoints(
        formValues.tubingOnValues,
        formValues.tubingOffValue,
        SetPointType.Tubing
      )
    }
    if (
      validSetPoints &&
      formValues.hasCasing &&
      formValues.casingOnValues.find((_, i) => i === 0) &&
      formValues.casingOffValue
    ) {
      if (
        !validateSetPoints(
          formValues.casingOnValues,
          Number(formValues.casingOffValue)
        )
      ) {
        validSetPoints = false
        return
      }
      pushSetPoints(
        formValues.casingOnValues,
        formValues.casingOffValue,
        SetPointType.Casing
      )
    }
    if (
      validSetPoints &&
      formValues.hasLinePSI &&
      formValues.linePSIOnValues.find((_, i) => i === 0) &&
      formValues.linePSIOffValue
    ) {
      if (
        !validateSetPoints(
          formValues.linePSIOnValues,
          Number(formValues.linePSIOffValue)
        )
      ) {
        validSetPoints = false
        return
      }
      pushSetPoints(
        formValues.linePSIOnValues,
        formValues.linePSIOffValue,
        SetPointType.LinePSI
      )
    }
    if (
      validSetPoints &&
      formValues.hasDifferentialPSI &&
      formValues.differentialPSIOnValues.find((_, i) => i === 0) &&
      formValues.differentialPSIOffValue
    ) {
      if (
        !validateSetPoints(
          formValues.differentialPSIOnValues,
          Number(formValues.differentialPSIOffValue)
        )
      ) {
        validSetPoints = false
        return
      }
      pushSetPoints(
        formValues.differentialPSIOnValues,
        formValues.differentialPSIOffValue,
        SetPointType.DifferentialPSI
      )
    }

    if (validSetPoints && setPointsToSave.length !== 0) {
      props.SaveSetPoints(setPointsToSave)
    }
  }

  const displaySetPoints = () => {
    const getOpenSetPoints = (
      setPoints: IntermittentSetpointRangeDto[],
      type: SetPointType
    ) => {
      const setPoint = setPoints.find((sp) => sp.type === type)

      if (!setPoint) return setPointPercentages.map((_) => '')

      const openWellValues = Object.entries(setPoint)
        .filter((entry) => entry[0].search('openWell') !== -1)
        .map((entry) => entry[1])
      return openWellValues
    }

    const getShutSetPoint = (
      setPoints: IntermittentSetpointRangeDto[],
      type: SetPointType
    ) => setPoints.find((sp) => sp.type === type)?.shutWell.toString() ?? ''

    setFormValues({
      tubingOffValue: getShutSetPoint(
        props.setPoints?.setPoints || [],
        SetPointType.Tubing
      ),
      tubingOnValues: getOpenSetPoints(
        props.setPoints?.setPoints || [],
        SetPointType.Tubing
      ),
      casingOffValue: getShutSetPoint(
        props.setPoints?.setPoints || [],
        SetPointType.Casing
      ),
      casingOnValues: getOpenSetPoints(
        props.setPoints?.setPoints || [],
        SetPointType.Casing
      ),
      linePSIOffValue: getShutSetPoint(
        props.setPoints?.setPoints || [],
        SetPointType.LinePSI
      ),
      linePSIOnValues: getOpenSetPoints(
        props.setPoints?.setPoints || [],
        SetPointType.LinePSI
      ),
      differentialPSIOffValue: getShutSetPoint(
        props.setPoints?.setPoints || [],
        SetPointType.DifferentialPSI
      ),
      differentialPSIOnValues: getOpenSetPoints(
        props.setPoints?.setPoints || [],
        SetPointType.DifferentialPSI
      ),
      hasTubing: !!props.setPoints?.telemetryType?.hasTubing,
      hasCasing: !!props.setPoints?.telemetryType?.hasCasing,
      hasLinePSI: !!props.setPoints?.telemetryType?.hasLinePSI,
      hasDifferentialPSI: !!props.setPoints?.telemetryType?.hasDifferentialPSI,
    })
  }

  return props.siteId ? (
    <Grid container>
      <Grid item xs={8}>
        <FormControl variant="standard" style={{ margin: 10, minWidth: 150 }}>
          <InputLabel>Select Well</InputLabel>
          <Select
            style={{
              borderRadius: 2,
              padding: 2,
              minWidth: 300,
            }}
            value={valueWellFilter}
            onChange={handleChangeWellFilter}
          >
            {props.wells?.map((well) => {
              return (
                <MenuItem key={well?.id} value={well?.id}>
                  {well?.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        {props.isValidRole && (
          <div style={section}>
            <Typography>To Open/Shut the Well press the button</Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !valueWellFilter ||
                !currentWellStatus ||
                currentWellStatus.wellStatus === 'unknown' ||
                currentWellStatus.wellStatus === 'notavailable' ||
                renderTurnOnOffButtonLabel() === 'loading'
              }
              onClick={handleTurnOnOffWellButtonClick}
            >
              {renderTurnOnOffButtonLabel()}
            </Button>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <div style={paddingTop30}>
          <Grid container>
            <Grid item xs={2}>
              <Typography>TELEMETRY TYPE</Typography>
            </Grid>
            {setPointPercentages.map((percentage) => (
              <Grid item xs={2} key={`grid-percentage-${percentage}`}>
                <Typography
                  style={{ marginLeft: 30 }}
                  key={`typography-percentage-${percentage}`}
                >
                  OPEN WELL {percentage && `(${percentage}%)`}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={2}>
              <Typography style={{ marginLeft: 30 }}>SHUT WELL</Typography>
            </Grid>
          </Grid>
          {formValues.hasTubing && (
            <Grid container>
              <Grid item xs={2}>
                <div style={section}>
                  <Typography>Tubing</Typography>
                </div>
              </Grid>
              <>
                {setPointPercentages.map((_, i) => (
                  <Grid item xs={2} key={`${i}-tubingOnValues-grid`}>
                    <TextField
                      inputProps={{
                        readOnly: !props.isValidRole,
                        min: 0,
                        style: { textAlign: 'center' },
                      }}
                      key={`${i}-tubingOnValues`}
                      name={`${i}-tubingOnValues`}
                      id={`${i}-tubingOnValues`}
                      value={formValues.tubingOnValues[i]}
                      onChange={handleChange(
                        `${i}-tubingOnValues-tubingOffValue`
                      )}
                      variant="standard"
                    />
                  </Grid>
                ))}
              </>
              <Grid item xs={2}>
                <TextField
                  inputProps={{
                    readOnly: !props.isValidRole,
                    min: 0,
                    style: { textAlign: 'center' },
                  }}
                  value={formValues.tubingOffValue}
                  onChange={handleChange('tubingOnValues-tubingOffValue')}
                  variant="standard"
                />
              </Grid>
            </Grid>
          )}
          {formValues.hasCasing && (
            <Grid container>
              <Grid item xs={2}>
                <div style={section}>
                  <Typography>Casing</Typography>
                </div>
              </Grid>
              <>
                {setPointPercentages.map((_, i) => (
                  <Grid item xs={2} key={`${i}-casingOnValues-grid`}>
                    <TextField
                      inputProps={{
                        readOnly: !props.isValidRole,
                        min: 0,
                        style: { textAlign: 'center' },
                      }}
                      key={`${i}-casingOnValues`}
                      name={`${i}-casingOnValues`}
                      id={`${i}-casingOnValues`}
                      value={formValues.casingOnValues[i]}
                      onChange={handleChange(
                        `${i}-casingOnValues-casingOffValue`
                      )}
                      variant="standard"
                    />
                  </Grid>
                ))}
              </>
              <Grid item xs={2}>
                <TextField
                  inputProps={{
                    readOnly: !props.isValidRole,
                    min: 0,
                    style: { textAlign: 'center' },
                  }}
                  value={formValues.casingOffValue}
                  onChange={handleChange('casingOnValues-casingOffValue')}
                  variant="standard"
                />
              </Grid>
            </Grid>
          )}
          {formValues.hasLinePSI && (
            <Grid container>
              <Grid item xs={2}>
                <div style={section}>
                  <Typography>Line PSI</Typography>
                </div>
              </Grid>
              <>
                {setPointPercentages.map((_, i) => (
                  <Grid
                    item
                    xs={2}
                    key={`${i}-linePSIOnValues-grid'
                    }`}
                  >
                    <TextField
                      inputProps={{
                        readOnly: !props.isValidRole,
                        min: 0,
                        style: { textAlign: 'center' },
                      }}
                      key={`${i}-linePSIOnValues`}
                      name={`${i}-linePSIOnValues`}
                      id={`${i}-linePSIOnValues`}
                      value={formValues.linePSIOnValues[i]}
                      onChange={handleChange(
                        `${i}-linePSIOnValues-linePSIOffValue`
                      )}
                      variant="standard"
                    />
                  </Grid>
                ))}
              </>
              <Grid item xs={2}>
                <TextField
                  inputProps={{
                    readOnly: !props.isValidRole,
                    min: 0,
                    style: { textAlign: 'center' },
                  }}
                  value={formValues.linePSIOffValue}
                  onChange={handleChange('linePSIOnValues-linePSIOffValue')}
                  variant="standard"
                />
              </Grid>
            </Grid>
          )}
          {formValues.hasDifferentialPSI && (
            <Grid container>
              <Grid item xs={2}>
                <div style={section}>
                  <Typography>Differential PSI</Typography>
                </div>
              </Grid>
              <>
                {setPointPercentages.map((_, i) => (
                  <Grid item xs={2} key={`${i}-differentialPSIOnValues-grid`}>
                    <TextField
                      inputProps={{
                        readOnly: !props.isValidRole,
                        min: 0,
                        style: { textAlign: 'center' },
                      }}
                      key={`${i}-differentialPSIOnValues`}
                      name={`${i}-differentialPSIOnValues`}
                      id={`${i}-differentialPSIOnValues`}
                      value={formValues.differentialPSIOnValues[i]}
                      onChange={handleChange(
                        `${i}-differentialPSIOnValues-differentialPSIOffValue`
                      )}
                      variant="standard"
                    />
                  </Grid>
                ))}
              </>
              <Grid item xs={2}>
                <TextField
                  inputProps={{
                    readOnly: !props.isValidRole,
                    min: 0,
                    style: { textAlign: 'center' },
                  }}
                  value={formValues.differentialPSIOffValue}
                  onChange={handleChange(
                    'differentialPSIOnValues-differentialPSIOffValue'
                  )}
                  variant="standard"
                />
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
      {props.isValidRole && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-end" marginTop={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave()}
                  disabled={!isFormValid}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography align="center">
      Please select a site to display the set points
    </Typography>
  )
}
