import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { setHandleDialog } from '../../../store/actions/common'

const GenericDialog = ({
  children,
  dialogTitle,
  actionButton,
  cancelButton,
  openDialogButton,
  dialogContentText,
}) => {
  const dispatch = useDispatch()
  const handleDialog = useSelector((state) => state.common.handleDialog)

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(setHandleDialog(false))
    }
  }

  return (
    <div>
      {openDialogButton}
      <Dialog
        open={handleDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {cancelButton}
          {actionButton}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GenericDialog
