import React, { useReducer } from 'react'

import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Button,
  Paper,
  Chip,
  Checkbox,
  TextField,
} from '@material-ui/core'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'

import { Autocomplete } from '@material-ui/lab'
import { Site } from '../../../store/Site'

const styles = (theme: Theme) =>
  createStyles({
    form: {
      width: 'fit-content',
      padding: theme.spacing(2),
    },
  })

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_OPTIONS':
      return { selectedOptions: action.payload.options }
    case 'REMOVE_OPTION':
      return {
        selectedOptions: state.selectedOptions.filter(
          (option) => option.id !== action.payload.id
        ),
      }
    default:
      throw new Error()
  }
}

export interface Props {
  readonly site: Site | undefined
  readonly indexCheck: number
  readonly values: string[]
  readonly onResult: (indexCheck: number, selectedItems?: string[]) => void
}

type AllProps = Props & WithStyles<typeof styles>

function AddPumpAssets(props: AllProps) {
  const pumpControls = props.site?.pumpControls ?? []

  const initialState = {
    selectedOptions:
      props.values?.map((value) =>
        pumpControls.find((control) => control.id === value)
      ) || [],
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const handleChange = (event, values) => {
    dispatch({ type: 'SET_SELECTED_OPTIONS', payload: { options: values } })
    const selectedItems = values.map((option) => option.id)
    props.onResult(props.indexCheck, selectedItems)
  }

  const removeOption = (id) => {
    dispatch({ type: 'REMOVE_OPTION', payload: { id: id } })
    const updatedItems = state.selectedOptions
      .filter((option) => option.id !== id)
      .map((option) => option.id)
    props.onResult(props.indexCheck, updatedItems)
  }

  const { classes } = props

  return (
    <Paper elevation={1} className={classes.form}>
      <Autocomplete
        multiple
        options={pumpControls}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        value={state.selectedOptions}
        renderTags={(values) =>
          values.map((value) => (
            <Chip
              key={value.id}
              label={value.name}
              onDelete={() => {
                removeOption(value.id)
              }}
            />
          ))
        }
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </React.Fragment>
        )}
        style={{ width: 500 }}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pumps"
            placeholder="Type a pump name and select"
          />
        )}
      />
    </Paper>
  )
}

export default withStyles(styles)(AddPumpAssets)
