import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'

import { setHandleDialog } from '../../../store/actions/common'

const ExpiredActionButton = () => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setHandleDialog(false))
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handleClose}
      id="expiredActionButtonAcknowledge"
    >
      Ok
    </Button>
  )
}

export default ExpiredActionButton
