import React from 'react'
import { ModelState } from '../../store/ModelState'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Paper,
} from '@material-ui/core'
import { Media } from 'react-breakpoints'
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import { TitleAndSubtitle } from 'components/misc/TitleAndSubtitle'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: '20rem',
      margin: theme.spacing(1.5),
      padding: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
      },
    },
  })

interface Props extends ModelState {
  readonly title: string
  readonly subTitle?: string
  readonly value: React.ReactNode
  readonly timestamp?: Date
  readonly content?: React.ReactNode
  readonly titleExtension?: React.ReactNode
  readonly titleBarContent?: React.ReactNode
  readonly hideGaugeBreakpoint?: keyof BreakpointValues
}

type AllProps = Props & WithStyles<typeof styles>

class Sensor extends React.Component<AllProps> {
  public render() {
    const { classes, content, hideGaugeBreakpoint } = this.props

    return (
      <Paper className={classes.container} elevation={6} square={true}>
        {this.renderTitle()}
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            return breakpoints[currentBreakpoint] >
              breakpoints[hideGaugeBreakpoint ?? 'xs'] ? (
              <React.Fragment>
                <div
                  style={{
                    gridRow: 2,
                    gridColumn: 1,
                  }}
                >
                  {content}
                </div>
                <div
                  style={{
                    gridRow: '1 / span 2',
                    gridColumn: 2,
                    alignSelf: 'center',
                  }}
                >
                  {this.props.children}
                </div>
              </React.Fragment>
            ) : (
              <div
                style={{
                  gridRow: 2,
                  gridColumn: '1 / span 2',
                  alignSelf: 'center',
                }}
              >
                {this.props.children}
              </div>
            )
          }}
        </Media>
      </Paper>
    )
  }

  private renderTitle() {
    const { title, subTitle, titleExtension, titleBarContent } = this.props

    return (
      <div
        style={{
          gridRow: 1,
          gridColumn: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'start',
          flexWrap: 'nowrap',
        }}
      >
        <TitleAndSubtitle
          title={title}
          titleExtension={titleExtension}
          subtitle={`Asset: ${subTitle}`}
        />
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {titleBarContent}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Sensor)
