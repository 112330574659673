import { TelemetryType } from '../../api/apiservice'
import BaseSensor from './BaseSensor'

export default class LinePsi extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.StaticPressure, 'Line Pressure')
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
