/* import external modules */
import { combineReducers } from 'redux'
import { reducer } from 'redux-oidc'

/* import internal modules */
import { AppState } from './AppState'
import * as AppConfig from './AppConfig'
import * as Sites from './Site'
import * as Groups from './Group'
import * as Alert from './Alert'
import * as AllSites from './AllSites'
import * as UserSettings from './UserSettings'
import * as ControlPanel from './reducers/controlPanel'
import * as Common from './reducers/common'
import * as UserAppSettings from './UserAppSettings'
import * as Multitenant from './reducers/multitenant'

const rootReducer = combineReducers<AppState>({
  appConfig: AppConfig.reducer,
  sites: Sites.reducer,
  oidc: reducer,
  groups: Groups.reducer,
  alerts: Alert.reducer,
  allSites: AllSites.reducer,
  userSettings: UserSettings.reducer,
  controlPanel: ControlPanel.reducer,
  common: Common.reducer,
  userAppSettings: UserAppSettings.reducer,
  multitenantUser: Multitenant.multitenantUserReducer,
})

export default rootReducer
