import moment from 'moment'

import {
  multitenantApiBasePath,
  multitenantAuthApiBasePath,
  getFetch,
  multitenantAlertsApiBasePath,
} from './fetch'
import { TelemetryMessage } from '../store/SiteDetails'
import { ConfigurationClient, ConfigurationViewModel } from './webportal'
import {
  TelemetryClient,
  TelemetryType,
  SiteClient,
  CsvExportType,
  SensorsClient,
  PlungersClient,
  CompressorClient,
  GatewaysClient,
  TankLevelBalanceResponse,
  StatisticsClient,
  TelemetryPeriodResponse,
  PumpClient,
} from './apiservice'
import { GroupsClient, UserClient } from './identityprovider'
import { saveAs } from 'file-saver'
import { AlertsClient, AlertsPumpsClient, StringEnum } from './alertservice'
import { UserClientMultitenant } from './identityProviderMultitenant'

export async function getAppConfiguration(): Promise<
  ConfigurationViewModel | Error
> {
  try {
    const api = new ConfigurationClient()
    const { result } = await api.get()

    return result
  } catch (e) {
    return e
  }
}

export async function updateTelemetryDatapoint(
  accessToken: string,
  tenantId: number,
  telemetryType: TelemetryType | undefined,
  isHidden: boolean | undefined,
  email: string,
  id: string
): Promise<boolean> {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId)
    )
    if (telemetryType === undefined) {
      return false
    }

    if (isHidden) {
      await api.unhideTelemetry(telemetryType, id, tenantId)
    } else {
      await api.getLatestTelemetryValuesPUT(telemetryType, id, email, tenantId)
    }

    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function getTelemetryHistoricalData(
  accessToken: string,
  tenantId: number,
  siteId: string,
  telemetryType: TelemetryType,
  assetId: string | null,
  sensorId: string | null,
  from?: Date,
  to?: Date
): Promise<TelemetryMessage[] | Error> {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId.toString())
    )
    const { result } = await api.getTelemetry(
      telemetryType,
      siteId,
      assetId,
      sensorId,
      from,
      tenantId,
      to || moment().toDate()
    )

    const historicalData = result.map(
      (m) =>
        ({
          Id: m.id,
          SiteId: m.siteId,
          AssetId: m.assetId,
          TelemetryType: m.telemetryType,
          CreationTimeUtc: new Date(m.creationTimeUtc),
          GatewayId: m.gatewayId,
          invalidData: m.invalidData,
          isSimulated: m.isSimulated,
          Payload: m.payload || {},
        } as TelemetryMessage)
    )
    return historicalData
  } catch (e) {
    console.error(e)
    return e
  }
}

export async function getLatestTelemetry(
  accessToken: string,
  tenantId: string,
  siteIds: string[]
): Promise<TelemetryMessage[] | Error> {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId)
    )

    const { result } = await api.getLatestTelemetryBySites(siteIds)

    const latestTelemetry = (result ?? []).map(
      (m) =>
        ({
          Id: m.id,
          SiteId: m.siteId,
          AssetId: m.assetId,
          GatewayId: m.gatewayId,
          TelemetryType: m.telemetryType,
          CreationTimeUtc: new Date(m.creationTimeUtc),
          Payload: m.payload || {},
          sensorBatteryLevel: m.sensorBatteryLevel ?? '0',
        } as TelemetryMessage)
    )

    return latestTelemetry
  } catch (e) {
    console.error(e)
    return e
  }
}

export interface HistoricalDataEntry {
  readonly timestamp: number
  readonly value: any
  readonly values?: any
  readonly rawValue?: any
  readonly id: any
  readonly assetId?: string
  readonly siteId?: string
  readonly telemetryType?: TelemetryType
  invalidData?: boolean
  readonly isSimulated?: boolean
  readonly unitOfMeasure: string
  readonly metaData?: any
}

export async function loadMostRecentValue(
  accessToken: string,
  tenantId: string,
  siteId: string,
  assetId: string | null,
  telemetryType: TelemetryType,
  sensorId?: string
) {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId)
    )

    const { result } = await api.getLatestTelemetryValuesGET(
      telemetryType,
      siteId,
      sensorId,
      assetId
    )
    const mostRecentValue: HistoricalDataEntry | undefined =
      result && result.creationTimeUtc
        ? {
            timestamp: result.creationTimeUtc.valueOf(),
            id: result.id,
            assetId: result.assetId,
            telemetryType: result.telemetryType,
            invalidData: result.invalidData,
            isSimulated: result.isSimulated,
            siteId: result.siteId,
            value:
              telemetryType !== TelemetryType.GatewayMetrics
                ? result.payload?.value
                : result.payload,
          }
        : undefined

    return mostRecentValue
  } catch (error) {
    console.error(
      `Failed to load most recent value for telemetry ${TelemetryType[telemetryType]}`,
      error
    )
  }

  return undefined
}

export async function getTankLevelBalance(
  accessToken: string,
  tenantId: string,
  siteId: string,
  assetId: string | null
): Promise<TankLevelBalanceResponse> {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId)
    )

    const { result } = await api.getTankLevelBalance(siteId, assetId)

    return result
  } catch (error) {
    console.error(
      `Failed to load Balance for tank with assetId ${assetId}`,
      error
    )
  }

  return new TankLevelBalanceResponse()
}

export async function saveTelemetryHistoryDataAsCsv(
  accessToken: string,
  tenantId: number,
  siteId: string,
  from?: Date,
  to?: Date,
  exportType?: CsvExportType
): Promise<any | undefined> {
  try {
    const api = new TelemetryClient(
      multitenantApiBasePath,
      getFetch(accessToken, tenantId.toString())
    )
    const response = await api.exportCsv(
      siteId,
      from,
      tenantId,
      to ?? moment().toDate(),
      exportType
    )

    saveAs(response.result.data, response.result.fileName)
  } catch (e) {
    console.error(e)
    return e
  }

  return undefined
}

export async function saveAlertHistoryDataAsCsv(
  accessToken: string,
  userId: string,
  tenantId: string,
  siteId?: string,
  isAcknowledged?: boolean,
  from?: Date,
  to?: Date
): Promise<any | undefined> {
  try {
    const api = getAlertsClient(accessToken, tenantId)

    let response
    if (siteId !== undefined) {
      response = await api.getPerSiteAsCsv(siteId, isAcknowledged, from, to)
    } else {
      response = await api.getAllAsCsv(userId, isAcknowledged, from, to)
    }

    saveAs(response.result.data, response.result.fileName)
  } catch (e) {
    console.error(e)
    return e
  }

  return undefined
}

export async function getProcessPeriodByTenant(
  accessToken: string,
  tenantId: number
): Promise<TelemetryPeriodResponse | undefined> {
  try {
    const api = getStatisticsClient(accessToken, tenantId.toString())
    const { result } = await api.getProcessPeriodByTenant(tenantId)
    return result
  } catch (e) {
    console.error(e)
    return e
  }
}

export function getSiteClient(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new SiteClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export function getGroupClient(accessToken: string, tenantId: string) {
  return new GroupsClient(
    multitenantAuthApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getAlertsClient(accessToken: string, tenantId: string) {
  return new AlertsClient(
    multitenantAlertsApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getPumpAlertsClient(accessToken: string, tenantId: string) {
  return new AlertsPumpsClient(
    multitenantAlertsApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getSensorClient(accessToken: string, tenantId: string) {
  return new SensorsClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getGatewaysClient(accessToken: string, tenantId: string) {
  return new GatewaysClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getPlungerClient(accessToken: string, tenantId: string) {
  return new PlungersClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getStatisticsClient(accessToken: string, tenantId: string) {
  return new StatisticsClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId)
  )
}

export function getPumpClient(accessToken: string, tenantId: string) {
  return new PumpClient(multitenantApiBasePath, getFetch(accessToken, tenantId))
}

export function getUserClient(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new UserClient(
    multitenantAuthApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export function getUserClientMultitenant(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new UserClientMultitenant(
    multitenantAuthApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export function getCompressorClient(
  accessToken: string,
  tenantId: string,
  userId?: string
) {
  return new CompressorClient(
    multitenantApiBasePath,
    getFetch(accessToken, tenantId, userId)
  )
}

export interface GatewayMetricsPayload {
  readonly primaryBatteryLevel?: number // Unit is %
  readonly capacitorLevel?: number // Unit is %
  readonly pressure?: number // Unit is mBar
  readonly temperature?: number // Unit in Celsius
  readonly signalStrength?: number // CSQ provided by radio
  readonly commType?: string
  readonly gpsLatitude?: number
  readonly gpsLongitude?: number
}
