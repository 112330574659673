import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store/AppState'
import {
  withMySitesContext,
  MySitesContextData,
} from '../contexts/MySitesContext'
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
  Zoom,
} from '@material-ui/core'
import { Group } from 'store/Group'

interface State {
  readonly selectedGroupId?: string
}

interface PropsFromState {
  readonly groups: Group[]
}

type AllProps = PropsFromState & MySitesContextData & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    fieldset: {
      padding: theme.spacing(1),
      display: 'grid',
      height: 'min-content',
    },
    radioButton: {
      marginLeft: 0,
      marginRight: 0,
    },
  })

class MySitesSideMenu extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      selectedGroupId: props.selectedGroupId,
    }
  }

  public render() {
    const { selectedGroupId } = this.state
    const { classes, groups } = this.props

    return (
      <FormControl component="fieldset" className={classes.fieldset}>
        <FormLabel
          component="legend"
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          Filter Sites By Group
        </FormLabel>
        <RadioGroup
          onChange={(event) => this.onSelectedGroupChange(event.target.value)}
        >
          <Tooltip
            title="Select sites from all groups to which user belongs"
            placement="top"
            TransitionComponent={Zoom}
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label="All groups"
              className={classes.radioButton}
              onClick={() => this.onSelectedGroupChange()}
              checked={!selectedGroupId}
            />
          </Tooltip>
          {groups
            .map((group) => (
              <FormControlLabel
                className={classes.radioButton}
                key={group.id}
                value={group.id}
                control={<Radio color="primary" />}
                label={
                  <Typography
                    style={{
                      whiteSpace: 'initial',
                    }}
                  >
                    {group.name}
                  </Typography>
                }
                onClick={() => this.onSelectedGroupChange(group.id)}
                checked={selectedGroupId === group.id}
              />
            ))
            .sort((a, b) =>
              a.props.label.props.children.localeCompare(
                b.props.label.props.children
              )
            )}
        </RadioGroup>
      </FormControl>
    )
  }

  private readonly onSelectedGroupChange = (value?: string) => {
    this.setState({ selectedGroupId: value })
    this.props.setSelectedGroupId!(value)
  }
}

const mapStateToProps = ({
  groups,
  userSettings,
}: AppState): PropsFromState => ({
  groups: groups.groups,
})

export default connect(mapStateToProps)(
  withMySitesContext(withStyles(styles)(MySitesSideMenu))
)
