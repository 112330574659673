import axios from 'axios'
import { multitenantAlertsApiBasePath } from './fetch'

export const sendAcknowledgeEmail = (data) => {
  return axios.put(`${multitenantAlertsApiBasePath}/AcknowledgeByEmail`, data)
}

export const sendPumpAcknowledgeEmail = (data) => {
  return axios.put(
    `${multitenantAlertsApiBasePath}/alertspump/AcknowledgeByEmail`,
    data
  )
}
