import React from 'react'
import moment from 'moment'
import { AxisDomain } from 'recharts'
import { Box, Typography, Tooltip } from '@material-ui/core'

import SensorWithChart, {
  SensorWithChartProps,
  Reading,
} from './SensorWithChart'
import { TelemetryAverageResponse, TelemetryType } from '../../api/apiservice'
import { getStatusLensColor } from 'components/utils'
import { StatusRange, TelemetryStatus } from 'api/alertservice'
import { HistoricalDataByTelemetryType } from 'store/HistoricalData'

export const defaultGaugeWidth = 300

export interface MajorTicksWithHighlights {
  readonly majorTicks: string[]
  readonly highlights: Highlight[]
}

export interface Highlight {
  readonly from: number
  readonly to: number
  readonly color: string
}

export default abstract class GaugeSensor extends SensorWithChart {
  constructor(
    props: SensorWithChartProps,
    telemetryType: TelemetryType,
    title: string,
    yAxisDomain: [AxisDomain, AxisDomain] = ['auto', 'auto']
  ) {
    super(props, telemetryType, title, yAxisDomain)
  }

  protected timestamp: Date = new Date()

  protected renderContent = (reading: Reading) => {
    const time = reading.timestamp ? moment(reading.timestamp) : undefined

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr auto',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {this.TelemetryType !== TelemetryType.FlareStatus
          ? this.renderGauge(reading)
          : this.renderFlareStatus(reading)}

        {this.renderUpdateTime(time)}
      </div>
    )
  }

  protected renderUpdateTime(time: moment.Moment | undefined) {
    return (
      <Tooltip title={time ? time.toLocaleString() : 'N/A'}>
        <Box>
          <Typography variant="body2" align="center">
            Updated at:&nbsp;
            {time ? time.format('MM/DD/YYYY HH:mm:ss') : 'N/A'}
          </Typography>
        </Box>
      </Tooltip>
    )
  }

  protected getIsAveraging(): boolean {
    if (
      Object.keys(this.props.timeRange).indexOf('from') > -1 ||
      Object.keys(this.props.timeRange).indexOf('to') > -1
    )
      return true
    else return false
  }

  // this function return the average using the historicalData from storage
  protected getAveragingValue(
    historicalData: HistoricalDataByTelemetryType
  ): number {
    let average = 0
    for (const key in historicalData) {
      const value = historicalData[key]
      if (value.length) {
        for (const key2 in value) {
          const value2 = value[key2].value
          // For the totalFlow values, check if the property is an Object to use the volumeFlowRate
          const sum = value.reduce(
            (sum, current) =>
              parseFloat(sum) +
              parseFloat(
                isNaN(value2) && value2 !== undefined
                  ? current.value.volumeFlowRate
                  : current.value
              ),
            0
          )
          average = sum / value.length // Average from data by filterDate
        }
      }
    }
    return average
  }

  protected getBPDRangeAverage(bpdList: TelemetryAverageResponse[]): number {
    if (bpdList.length <= 0 || !bpdList) {
      return 0
    }

    const rangeDays = bpdList.length
    let sum = 0
    bpdList.forEach((bpd) => {
      sum += bpd.value
    })
    return sum / rangeDays
  }

  protected getLastBPDAverage(bpdList: TelemetryAverageResponse[]): number {
    const bpd = bpdList[bpdList.length - 1]
    return bpd.value
  }

  protected getMinMaxAverage(
    historicalData: HistoricalDataByTelemetryType
  ): [number, number] {
    // The Min/Max is setup by default 0 to 100, if the historical is empty data
    let minValue = 0
    let maxValue = 100
    // Using the historicalData from the storage, Identify the Max and Min Value, to apply in the gauge
    if (historicalData) {
      for (const key in historicalData) {
        const value = historicalData[key]
        if (value.length) {
          for (const key2 in value) {
            const value2 = value[key2].value
            // For the totalFlow values, check if the property is an Object to use the volumeFlowRate
            maxValue = Math.max(
              ...value.map(function (o) {
                return isNaN(value2) && value2 !== undefined
                  ? o.value.volumeFlowRate
                  : o.value
              })
            ) // Max value data by filterDate
            minValue = Math.min(
              ...value.map(function (o) {
                return isNaN(value2) && value2 !== undefined
                  ? o.value.volumeFlowRate
                  : o.value
              })
            )
          }
        }
      }
    }
    return [minValue, maxValue]
  }

  protected abstract renderGauge(reading: Reading): React.ReactNode
  protected abstract renderFlareStatus(reading: Reading): React.ReactNode
  // protected abstract renderPumpControl(reading: Reading): React.ReactNode

  protected abstract getMinMaxValue(
    statusRanges: StatusRange[],
    readingValue?: number,
    isAveraging?: boolean
  ): [number, number, Highlight[]]

  protected abstract getMajorTicksWithUpdatedHighlights(
    minValue: number,
    maxValue: number,
    hasThresholds: boolean,
    highlights: Highlight[]
  ): MajorTicksWithHighlights

  protected *getMajorTicks(
    minValue: number,
    maxValue: number,
    majorTick: number,
    precision?: number
  ) {
    for (let tick = minValue; tick <= maxValue; tick += majorTick) {
      yield tick.toFixed(precision)
    }
  }

  protected getBPDGaugeParams(
    bpdList: TelemetryAverageResponse[]
  ): MajorTicksWithHighlights {
    if (!bpdList || bpdList.length === 0)
      return {
        majorTicks: ['0', '2500', '5000', '7500', '10000'],
        highlights: [{ from: 0, to: 10000, color: '#BDBDBD' }],
      }

    const orderedBpdList = bpdList.sort((a, b) => a.value - b.value)
    const maxValue =
      orderedBpdList[orderedBpdList.length - 1].value >= 0 &&
      orderedBpdList[orderedBpdList.length - 1].value <= 2
        ? 5
        : orderedBpdList[orderedBpdList.length - 1].value // this validation put 5 tics if the max value is for the majorTicks are between 1 or 2
    const { majorTicks, highlights } = this.getMajorTicksWithUpdatedHighlights(
      0,
      maxValue,
      false,
      []
    )

    return { majorTicks, highlights }
  }

  protected getGaugeParams(
    statusRanges: StatusRange[],
    readingValue?: number,
    isAveraging?: boolean
  ): MajorTicksWithHighlights {
    const ths = statusRanges ?? []
    const hasThresholds = !!ths.length

    const [minValue, maxValue, highlights] = this.getMinMaxValue(
      ths,
      readingValue,
      isAveraging
    )
    if (maxValue === undefined || minValue === undefined) {
      return { majorTicks: [], highlights: [] }
    }

    const { majorTicks, highlights: updHighlights } =
      this.getMajorTicksWithUpdatedHighlights(
        minValue,
        maxValue,
        hasThresholds,
        highlights
      )

    if (!hasThresholds) {
      const from = Number.parseInt(majorTicks[0])
      const to = Number.parseInt(majorTicks[majorTicks.length - 1])
      const color = getStatusLensColor(TelemetryStatus.Undefined)

      updHighlights.push({ from, to, color })
    }

    if (!updHighlights.length) {
      updHighlights.push({
        from: minValue,
        to: maxValue,
        color: getStatusLensColor(TelemetryStatus.Undefined),
      })
    }

    return {
      majorTicks: majorTicks,
      highlights: updHighlights,
    }
  }

  protected getMinMaxRangeValue(highlights: Highlight[]): [number, number] {
    const minRangeValue = Math.min(...highlights.map((h) => h.from))
    const maxRangeValue = Math.max(...highlights.map((h) => h.to))

    return [minRangeValue, maxRangeValue]
  }

  protected renderValue(label: React.ReactNode, value: React.ReactNode) {
    return (
      <Typography variant="subtitle1" noWrap={true}>
        {label}:&nbsp;<b>{value}</b>
      </Typography>
    )
  }
}
