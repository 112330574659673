import * as React from 'react'
import theme from 'theme'
import {
  createStyles,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'

import { BooleanSelectTextField } from 'components/misc/BooleanSelectTextField'
import SettingsTileWrapper from './SettingsTileWrapper'
import { PlungerCommonWellSettingsApiModel } from 'api/apiservice'

const styles = (theme: Theme) =>
  createStyles({
    gridRow: {
      marginLeft: theme.spacing(1),
      '& .MuiInput-root': {
        marginTop: theme.spacing(2.5),
      },
    },
  })

interface Props {
  readonly commonWellSettings?: PlungerCommonWellSettingsApiModel
  readonly updateSettings: (
    newSettings: PlungerCommonWellSettingsApiModel
  ) => void
}

interface State {
  readonly commonWellSettings?: PlungerCommonWellSettingsApiModel
}

type AllProps = Props & WithStyles<typeof styles>

class CommonSettings extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      commonWellSettings: this.props.commonWellSettings,
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.commonWellSettings !== this.props.commonWellSettings) {
      this.setState({
        commonWellSettings: this.props.commonWellSettings,
      })
    }
  }

  public render() {
    return (
      <Paper
        elevation={8}
        square={true}
        style={{
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        {this.renderCommonSettings()}
      </Paper>
    )
  }

  private readonly renderCommonSettings = () => {
    const { classes } = this.props
    const { commonWellSettings } = this.state

    if (commonWellSettings === undefined) {
      return (
        <Typography color="error">
          No Common Well Settings Has Been Found
        </Typography>
      )
    }

    return (
      <SettingsTileWrapper
        tileTitle="Common Well Settings"
        isSubmitVisible={true}
        onClick={this.handleSubmit}
      >
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={classes.gridRow}
          key={this.props.commonWellSettings!.criticalFlowRate.toString()}
        >
          <Grid item xs={4}>
            <TextField
              id="wellDepth"
              name="wellDepth"
              label="Well Depth"
              type="number"
              value={commonWellSettings.wellDepth}
              onChange={this.handleInput}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="detectPlungerFalls"
              label="Detect Plunger Falls"
              value={commonWellSettings.detectPlungerFalls.toString()}
              onChange={this.handleInput}
              isEditable={true}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="hiLoFunction"
              label="Hi-Lo Function"
              value={commonWellSettings.hiLoFunction.toString()}
              onChange={this.handleInput}
              isEditable={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={classes.gridRow}
          key={commonWellSettings.bValveFunction.toString()}
        >
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="bValveFunction"
              label="B Valve Function"
              value={commonWellSettings.bValveFunction.toString()}
              onChange={this.handleInput}
              isEditable={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="maxPlungerFails"
              name="maxPlungerFails"
              label="Max Plunger Fails"
              type="number"
              value={commonWellSettings.maxPlungerFails}
              onChange={this.handleInput}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="criticalFlowRate"
              name="criticalFlowRate"
              label="Critical Flow Rate"
              type="number"
              value={commonWellSettings.criticalFlowRate}
              onChange={this.handleInput}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid item xs={4}>
            <BooleanSelectTextField
              idPrefix="afterflowOnFlowrateFunc"
              label="Afterflow On Flowrate Func"
              value={commonWellSettings.afterflowOnFlowrateFunc.toString()}
              onChange={this.handleInput}
              isEditable={true}
            />
          </Grid>
        </Grid>
      </SettingsTileWrapper>
    )
  }

  private readonly handleInput = (event) => {
    const newSettings = {
      ...this.state.commonWellSettings,
      [event.target.name]: event.target.value,
    }

    this.setState({
      commonWellSettings: PlungerCommonWellSettingsApiModel.fromJS(newSettings),
    })
  }

  private readonly handleSubmit = (event) => {
    event.preventDefault()

    if (!this.state.commonWellSettings) {
      return
    }

    this.props.updateSettings(this.state.commonWellSettings)
  }
}

export default withStyles(styles)(CommonSettings)
