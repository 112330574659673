import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
  Snackbar,
} from '@material-ui/core'
import { AppState } from '../../store/AppState'
import { getPumpClient } from 'api/client'
import { ModelState } from 'store/ModelState'
import {
  DoubleRowTableColumnHeader,
  StyledTableCell as TableCell,
} from 'components/misc/Components'
import { SiteMatch } from '../SiteDetails'
import { SitesState } from 'store/Site'
import { requestSite, SiteState } from 'store/SiteDetails'
import { UiContextData, withUiContext } from 'components/contexts/UiContext'
import { SaveTwoTone } from '@material-ui/icons'
import { TitleAndSubtitle } from 'components/misc/TitleAndSubtitle'

import { PumpLogs } from '../logs/pumpLogs'
// import FilteredPumpLogs from '../logs/pump/FilteredPumpLogs'
import { getMultitenantUser } from '../../api/multitenantAuth'
import PumpControlButton from '../pumpController'
import { setFrequencyRequest } from '../../api/commands'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { QueryClient, QueryClientProvider } from 'react-query'
import { isAdmin } from '../../store/oidc/userManager'

interface PropsFromState {
  readonly accessToken?: string
  readonly tenantId: number
  readonly sites: SitesState
}

interface State extends ModelState {
  readonly pumps: any[]
  readonly loadingBatteryLevel?: boolean
  readonly site: SiteState
  readonly open: boolean
  readonly loading: boolean
  readonly modalData: any
  readonly email: any
  readonly pumpResponse: any
  readonly error: any
  readonly frequency: any
  readonly showSnackbar: boolean
  readonly snackbarMessage: string
  readonly snackbarSeverity: string
  readonly siteUpdating: boolean
}

interface Props {
  readonly setIsPlungerTabVisible: (value: boolean) => void
  readonly setIsPumpControlTabVisible: (value: boolean) => void
}

type AllProps = Props &
  PropsFromState &
  RouteComponentProps<SiteMatch> &
  UiContextData

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const queryClient = new QueryClient()

class PumpControllerTab extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)
    const {
      sites: { sites },
    } = this.props
    this.state = {
      pumps: [],
      email: '',
      open: false,
      loading: false,
      modalData: undefined,
      error: undefined,
      frequency: undefined,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarSeverity: 'success',
      siteUpdating: false,
      pumpResponse: {
        pumpState: 'Pending',
        frequency: [],
        metaData: [],
        outputFrequency: undefined,
      },
      site: {
        site: sites.find((w) => w.id === props.match.params.id),
      } as SiteState,
    }
  }

  public componentDidMount() {
    this.load()
  }

  public componentDidUpdate({ match, accessToken }: AllProps) {
    if (
      accessToken !== this.props.accessToken ||
      match.params.id !== this.props.match.params.id
    ) {
      this.load()
    }
  }

  handleOpen = (data) => {
    this.setState({ modalData: data, open: true })
  }

  handleClose = () => {
    this.setState({
      open: false,
      loading: false,
      modalData: undefined,
      error: undefined,
      frequency: undefined,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarSeverity: 'success',
      siteUpdating: false,
      pumpResponse: {
        pumpState: 'Pending',
        enableControls: false,
        metaData: [],
        frequency: [],
        outputFrequency: undefined,
      },
    })
  }

  handleFrequencyChange = (event) => {
    const newValue = event.target.value
    this.setState((prevState) => ({
      pumpResponse: {
        ...prevState.pumpResponse,
        outputFrequency: newValue,
      },
    }))
  }

  handlePumpStateChange = (newState) => {
    this.setState((prevState) => {
      const newPumpResponse = {
        pumpState: newState.pumpState,
        enableControls: newState.enableControls,
        metaData: newState.metaData,
        outputFrequency: this.getFrequencyValue(
          newState.metaData,
          'Output Frequency'
        ),
      }

      if (
        JSON.stringify(prevState.pumpResponse) !==
        JSON.stringify(newPumpResponse)
      ) {
        return { pumpResponse: newPumpResponse }
      }
      return null
    })
  }

  handleSaveOption = () => {
    const { accessToken, tenantId } = this.props
    const { id } = this.props.match.params
    if (!accessToken || !tenantId) {
      return
    }

    this.setState({ siteUpdating: true })

    setFrequencyRequest(accessToken, tenantId, {
      email: this.state.email,
      frequency: Number(this.state.pumpResponse.outputFrequency),
      pumpDeviceId: this.state.modalData.pumpDeviceId,
      siteId: id,
    }).then((r) => {
      const { success, error } = r

      if (success) {
        this.setState({
          showSnackbar: true,
          snackbarMessage:
            'Successfully sent the command to update frequency on pump',
          snackbarSeverity: 'success',
          siteUpdating: false,
        })
      } else {
        if (error.Message === undefined) {
          this.setState({
            showSnackbar: true,
            snackbarMessage: this.extractErrorMessages(error),
            snackbarSeverity: 'error',
            siteUpdating: false,
          })
        } else {
          this.setState({
            showSnackbar: true,
            snackbarMessage: error.Message,
            snackbarSeverity: 'error',
            siteUpdating: false,
          })
        }
      }
    })
  }

  extractErrorMessages(errorResponse) {
    const { errors } = errorResponse
    let errorMessages = []

    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        errorMessages = errorMessages.concat(errors[key])
      }
    }

    return errorMessages.join(' ')
  }

  private async load() {
    const { accessToken, tenantId } = this.props

    if (!accessToken || !tenantId) {
      return
    }

    const { id } = this.props.match.params

    try {
      this.setState({ loading: true })
      this.loadSite(accessToken, tenantId.toString() || '', id)

      const api = getPumpClient(accessToken, tenantId.toString())
      const { data } = await getMultitenantUser(this.props.accessToken)

      if (!api || !api.getPumpsBySite) {
        throw new Error('API client or method getPumpsBySite is not defined')
      }

      api.getPumpsBySite(id, tenantId).then((r) => {
        const { result } = r

        this.setState({
          pumps: result,
          email: data.email,
          error: undefined,
        })
      })
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }

  private async loadSite(
    accessToken: string,
    tenantId: string,
    siteId: string
  ) {
    await requestSite(
      accessToken,
      tenantId,
      siteId,
      () => this.state.site,
      (site) =>
        !site.loading &&
        this.setState({ site: site }, () => {
          this.props.setPageTitle!(site.site?.name ?? site.site?.id)
          const isPumpControlTabVisible =
            this.state.site.site?.pumpControls &&
            this.state.site.site.pumpControls.length > 0 &&
            isAdmin(this.props.user.selectedTenant?.roles)

          if (this.state.site.site?.plungerLift) {
            this.props.setIsPlungerTabVisible(true)
          }

          this.props.setIsPumpControlTabVisible(isPumpControlTabVisible)
        })
    )
  }

  public render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Grid
          container={true}
          style={{ maxHeight: '500px', overflow: 'auto', marginTop: '30px' }}
        >
          <Grid item={true} xs={12}>
            {this.renderTable()}
          </Grid>
        </Grid>
        {this.renderModal()}
        {this.state.showSnackbar && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ zIndex: 2147483647, top: 100 }}
            onClose={() => {
              this.setState({
                showSnackbar: false,
                snackbarMessage: '',
                snackbarSeverity: 'success',
              })
            }}
          >
            <Alert severity={this.state.snackbarSeverity}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        )}
      </QueryClientProvider>
    )
  }

  public renderTable() {
    return (
      <TableContainer
        component={Paper}
        style={{ width: '70%', margin: '0 auto' }}
      >
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>
                <DoubleRowTableColumnHeader title="Pump Device Id" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Pump Name" />
              </TableCell>
              <TableCell>
                <DoubleRowTableColumnHeader title="Assets Controlled" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(this.state.pumps) &&
              this.state.pumps.map((s, i) => (
                <TableRow key={i}>
                  <TableCell align="left">
                    <p
                      style={{
                        margin: 0,
                        padding: '0.5em 0 0.5em 0',
                        fontWeight: 'bold',
                      }}
                    >
                      <Link
                        component="button"
                        onClick={() =>
                          this.handleOpen({
                            siteId: s.siteId,
                            id: s.id,
                            pumpDeviceId: s.pumpDeviceId,
                            enableNotifications: s.enableNotifications,
                            hasFrequency: s.frequency.hasFrequency,
                            minValue: s.frequency.min || 0,
                            maxValue: s.frequency.max || 0,
                            email: this.state.email,
                            assetName: s.assetsId[0],
                            assetId: s.assetsId[0],
                          })
                        }
                      >
                        {s.pumpDeviceId}
                      </Link>
                    </p>
                  </TableCell>
                  <TableCell align="center">{s.assetName}</TableCell>
                  <TableCell align="center">
                    {s.assetsName.join(', ')}
                  </TableCell>
                  {/* <TableCell align="center">{s.enableNotifications ? 'ON' : 'OFF'}</TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  public renderModal = () => {
    const { loading, open, modalData, pumpResponse } = this.state

    if (modalData === undefined) return <></>

    const disabled = !(
      modalData.hasFrequency &&
      pumpResponse.enableControls &&
      !this.state.siteUpdating &&
      this.state.email !== '' &&
      this.state.pumpResponse.outputFrequency !== undefined &&
      modalData.minValue !== 0 &&
      modalData.maxValue !== 0
    )
    if (open) {
      return (
        <Dialog
          disableEscapeKeyDown={true}
          open={open}
          fullWidth={true}
          maxWidth={'xl'}
        >
          <DialogContent dividers={true}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12,
                }}
              >
                <TitleAndSubtitle
                  title={'Edit Pump Controller'}
                  subtitle={`${modalData.pumpDeviceId}`}
                />
              </div>
              {this.renderModalFields()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button
              style={{ width: '120px' }}
              variant="contained"
              color="primary"
              startIcon={<SaveTwoTone />}
              form="siteInfoForm"
              onClick={this.handleSaveOption}
              disabled={disabled}
            >
              {this.state.siteUpdating ? 'Saving' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  renderModalFields = () => {
    const { pumpResponse } = this.state
    const { accessToken, tenantId } = this.props

    const validation = this.state.modalData.hasFrequency || false
    return (
      <>
        <Grid container>
          <Grid item md={5} xs={5}>
            {!validation ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LinearProgress style={{ width: '100%' }} />
              </div>
            ) : (
              <>
                <h3>Frequency (Hz)</h3>
                <TextField
                  fullWidth={true}
                  type="number"
                  value={pumpResponse.outputFrequency}
                  disabled={!validation}
                  onChange={this.handleFrequencyChange}
                />
                <h3>
                  {validation &&
                    `Accept values between ${this.state.modalData.minValue} and ${this.state.modalData.maxValue}.`}
                </h3>
              </>
            )}
          </Grid>
          <Grid item md={1} xs={12}></Grid>
          <Grid item md={5} xs={12}>
            <h3>Current Status</h3>
            <PumpControlButton
              accessToken={accessToken}
              tenantId={tenantId}
              data={this.state.modalData}
              onStateChange={this.handlePumpStateChange}
            />
          </Grid>
          <Grid item md={1} xs={12}></Grid>
        </Grid>
        <Grid container>
          <Grid item={true} xs={12}>
            <Typography color="primary" variant="h6">
              Pump Logs
            </Typography>
          </Grid>
          <Grid
            item={true}
            xs={12}
            style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}
          >
            {this.modalPumpLogs()}
          </Grid>
        </Grid>
      </>
    )
  }

  modalPumpLogs() {
    const { modalData } = this.state
    return (
      <PumpLogs
        accessToken={this.props.accessToken}
        hasFrequency={modalData.hasFrequency}
        pumpDeviceId={modalData.pumpDeviceId}
        tenantId={this.props.tenantId}
        userId={this.props.userId}
      />
    )
  }

  getFrequencyValue = (metaData, key) => {
    if (!Array.isArray(metaData)) {
      return null
    }
    const item = metaData.find((entry) => entry.key === key)
    return item ? item.value : null
  }
}

const mapPropsFromState = ({ oidc, sites, multitenantUser }: AppState) => ({
  user: multitenantUser, // state.oidc.user,
  email: multitenantUser.email,
  sites: sites,
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
})

export default withRouter(
  withUiContext(connect(mapPropsFromState)(PumpControllerTab))
)
