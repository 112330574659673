/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UserState } from 'redux-oidc'

import {
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core'
import { actionCreators as userAppSettingsActionCreators } from 'store/UserAppSettings'
import { NotificationsActive } from '@material-ui/icons'
import { actionCreators as commonActionCreators } from 'store/reducers/common'

import { getUserClientMultitenant, getUserClient } from 'api/client'

import 'react-phone-number-input/style.css'
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input'

import en from 'react-phone-number-input/locale/en.json'
import { withUiContext, UiContextData } from './../contexts/UiContext'
import { AlertSetUp } from './AlertSetUp'
import {
  hasSupervisorUserAccessLevel,
  isAdmin,
} from '../../store/oidc/userManager'

import { SmsDataAlert } from './models/SmsDataAlert'
import { EmailDataAlert } from './models/EmailDataAlert'
import { CallDataAlert } from './models/CallDataAlert'

import {
  userSettingsModel,
  mapResponseToUserSettings,
  ResponseElement,
  mapUserSettingsToResponse,
} from './models/userSettingsModel'
import { bindActionCreators } from 'redux'

import * as LDClient from 'launchdarkly-js-client-sdk'

interface PropsFromState {
  readonly accessToken?: string
  readonly userId?: string
  readonly tenantId?: any
  readonly selectedTenant?: any
  readonly isoCode?: any
  readonly countryCode?: any
  readonly phoneNumber?: any
  readonly email?: any
  readonly isUserAdmin?: boolean
  readonly isSuperVisor?: boolean
  readonly smsData?: SmsDataAlert
  readonly emailData?: EmailDataAlert
  readonly callData?: CallDataAlert
  readonly userAppSettings?: userSettingsModel
  readonly loading?: boolean
  readonly launchDarklyClientsideId?: string
}
interface BooleanAlerts {
  name: string
  state: boolean
}

type PropsFromDispatch = typeof userAppSettingsActionCreators &
  typeof commonActionCreators

type PropsFromUserState = Pick<UserState, 'user'>

type AllProps = PropsFromState &
  PropsFromDispatch &
  UiContextData &
  PropsFromUserState

class UserSettings extends React.Component<AllProps, PropsFromState> {
  flags: any
  constructor(props: AllProps) {
    super(props)
    this.state = {
      phoneNumber: '',
      countryCode: '',
      isoCode: '',
      isUserAdmin: false,
      isSuperVisor: false,
      loading: true,
      smsData: {},
      emailData: {},
      callData: {},
    }
  }

  public handleDataChangeSms = (data: SmsDataAlert) => {
    this.setState({ smsData: data })
  }

  public handleDataChangeEmail = (data: EmailDataAlert) => {
    this.setState({ emailData: data })
  }

  public handleDataChangeCall = (data: CallDataAlert) => {
    this.setState({ callData: data })
  }

  private featureFlag() {
    const user: LDClient.LDUser = {
      key: this.props.tenantId.toString(),
    }

    const LaunchDarklyClientsideId = this.props.launchDarklyClientsideId!
    const options: LDClient.LDOptions = { bootstrap: 'localStorage' }
    const client = LDClient.initialize(LaunchDarklyClientsideId, user, options)
    client
      .waitForInitialization()
      .then(() => {
        this.flags = client.allFlags()
        if ('ShowSuperIntendent' in this.flags) {
          this.showSuperIntendent = this.flags.ShowSuperIntendent
        } else {
          this.showSuperIntendent = true
        }
      })
      .catch((err) => {
        console.error(
          '🚀 ~ file: Navigation.tsx ~ line 288 ~ client.waitForInitialization ~ err',
          err
        )
      })
  }

  public getUserSettings() {
    const { accessToken, userId, tenantId } = this.props

    if (accessToken && userId && tenantId) {
      const userClientApi = getUserClient(
        this.props.accessToken!,
        this.props.tenantId?.toString(),
        this.props.userId
      )
      userClientApi.getUsersSettings(userId).then((response) => {
        if (response) {
          if (this.state.loading === true) {
            const userResponse = response.result as unknown as ResponseElement[]
            let userSettings: userSettingsModel = {}

            userSettings = mapResponseToUserSettings(userResponse)

            const isUserAdmin = this.props.selectedTenant
              ? isAdmin(this.props.selectedTenant.roles)
              : false

            const isSuperVisor = this.props.selectedTenant
              ? hasSupervisorUserAccessLevel(this.props.selectedTenant.roles)
              : false

            this.setState({
              isUserAdmin,
              isSuperVisor,
              userAppSettings: userSettings,
              phoneNumber: userSettings.PhoneNumber,
              countryCode: this.getIsoCodeAsNumber(userSettings.IsoCode),
              isoCode: userSettings.IsoCode,
              email: userSettings.CorporateEmail,
              smsData: {
                allowPumpAlerts: Boolean(userSettings.AllowSendSmsPumpAlerts),
                allowSendThreshold: Boolean(userSettings.AllowSendSms),
                allowSendThresholdYellow: Boolean(userSettings.AllowYellowSms),
                allowSendThresholdYellowHours: Boolean(
                  userSettings.YellowSmsHoursStart !== '00:01' &&
                    userSettings.YellowSmsHoursEnd !== '23:59'
                ),
                startTimeThresholdYellowHours: userSettings.YellowSmsHoursStart,
                endTimeThresholdYellowHours: userSettings.YellowSmsHoursEnd,

                allowSendThresholdRed: Boolean(userSettings.AllowRedSms),
                allowSendThresholdRedHours: Boolean(
                  userSettings.RedSmsHoursStart !== '00:01' &&
                    userSettings.RedSmsHoursEnd !== '23:59'
                ),
                startTimeThresholdRedHours: userSettings.RedSmsHoursStart,
                endTimeThresholdRedHours: userSettings.RedSmsHoursEnd,

                allowSendGateway: Boolean(userSettings.AllowGatewayRedSms),
                allowSendGatewayRed: Boolean(userSettings.AllowGatewayRedSms),
                allowSendGatewayRedHours: Boolean(
                  userSettings.RedGatewayBatterySmsHoursStart !== '00:01' &&
                    userSettings.RedGatewayBatterySmsHoursEnd !== '23:59'
                ),
                startTimeGatewayRedHours:
                  userSettings.RedGatewayBatterySmsHoursStart,
                endTimeGatewayRedHours:
                  userSettings.RedGatewayBatterySmsHoursEnd,

                allowPumpOnOffAlerts: Boolean(
                  userSettings.AllowSendSmsPumpOnOffAlerts
                ),
                allowPumpStatusAlerts: Boolean(
                  userSettings.AllowSendSmsPumpStatusAlerts
                ),
              },
              emailData: {
                allowPumpAlerts: Boolean(userSettings.AllowSendEmailPumpAlerts),
                allowSendThreshold: Boolean(userSettings.AllowSendEmail),
                allowSendThresholdYellow: Boolean(
                  userSettings.AllowYellowEmail
                ),
                allowSendThresholdYellowHours: Boolean(
                  userSettings.YellowEmailHoursStart !== '00:01' &&
                    userSettings.YellowEmailHoursEnd !== '23:59'
                ),
                startTimeThresholdYellowHours:
                  userSettings.YellowEmailHoursStart,
                endTimeThresholdYellowHours: userSettings.YellowEmailHoursEnd,

                allowSendThresholdRed: Boolean(userSettings.AllowRedEmail),
                allowSendThresholdRedHours: Boolean(
                  userSettings.RedEmailHoursStart !== '00:01' &&
                    userSettings.RedEmailHoursEnd !== '23:59'
                ),
                startTimeThresholdRedHours: userSettings.RedEmailHoursStart,
                endTimeThresholdRedHours: userSettings.RedEmailHoursEnd,

                allowSendGateway: Boolean(userSettings.AllowGatewayRedEmail),
                allowSendGatewayRed: Boolean(userSettings.AllowGatewayRedEmail),
                allowSendGatewayRedHours: Boolean(
                  userSettings.RedGatewayBatteryEmailHoursStart !== '00:01' &&
                    userSettings.RedGatewayBatteryEmailHoursEnd !== '23:59'
                ),
                startTimeGatewayRedHours:
                  userSettings.RedGatewayBatteryEmailHoursStart,
                endTimeGatewayRedHours:
                  userSettings.RedGatewayBatteryEmailHoursEnd,

                allowNotReportingSensor: Boolean(
                  userSettings.AllowSendEmailSensors
                ),

                allowPumpOnOffAlerts: Boolean(
                  userSettings.AllowSendEmailPumpOnOffAlerts
                ),
                allowPumpStatusAlerts: Boolean(
                  userSettings.AllowSendEmailPumpStatusAlerts
                ),
              },
              callData: {
                allowSendThreshold: Boolean(userSettings.AllowCall),
                allowSendThresholdYellow: Boolean(userSettings.AllowYellowCall),
                allowSendThresholdYellowHours: Boolean(
                  userSettings.YellowCallHoursStart !== '00:01' &&
                    userSettings.YellowCallHoursEnd !== '23:59'
                ),
                startTimeThresholdYellowHours:
                  userSettings.YellowCallHoursStart,
                endTimeThresholdYellowHours: userSettings.YellowCallHoursEnd,

                allowSendThresholdRed: Boolean(userSettings.AllowRedCall),
                allowSendThresholdRedHours: Boolean(
                  userSettings.RedCallHoursStart !== '00:01' &&
                    userSettings.RedCallHoursEnd !== '23:59'
                ),
                startTimeThresholdRedHours: userSettings.RedCallHoursStart,
                endTimeThresholdRedHours: userSettings.RedCallHoursEnd,

                allowSendGateway: Boolean(userSettings.AllowGatewayRedCall),
                allowSendGatewayRed: Boolean(userSettings.AllowGatewayRedCall),
                allowSendGatewayRedHours: Boolean(
                  userSettings.RedGatewayBatteryCallHoursStart !== '00:01' &&
                    userSettings.RedGatewayBatteryCallHoursEnd !== '23:59'
                ),
                startTimeGatewayRedHours:
                  userSettings.RedGatewayBatteryCallHoursStart,
                endTimeGatewayRedHours:
                  userSettings.RedGatewayBatteryCallHoursEnd,

                allowSendHighPriority: Boolean(
                  userSettings.AllowHighPriorityAlerts
                ),
                callAttempts: userSettings.CallAttempts,
              },
              loading: false,
            })
          }
        }
      })
    }
  }

  private getIsoCodeAsNumber(isoCode: any): string {
    return '+'.concat(
      getCountryCallingCode(
        getCountries().find((country) => country === isoCode) || 'US'
      )
    )
  }

  private validateEmail(email: string): boolean {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  private verifyAlertSettingsAndSaveForm() {
    const { emailData, smsData, callData, phoneNumber, isoCode, email } =
      this.state
    const userId = this.props.userId ? this.props.userId : ''

    const userSettings: userSettingsModel = {}
    userSettings.CountryCode = this.getIsoCodeAsNumber(isoCode)
    userSettings.IsoCode = isoCode
    userSettings.PhoneNumber = phoneNumber
    userSettings.CorporateEmail = email

    if (smsData) {
      userSettings.AllowSendSms = smsData.allowSendThreshold
      userSettings.AllowYellowSms = smsData.allowSendThresholdYellow
      userSettings.YellowSmsHoursStart = smsData.startTimeThresholdYellowHours
      userSettings.YellowSmsHoursEnd = smsData.endTimeThresholdYellowHours

      userSettings.AllowRedSms = smsData.allowSendThresholdRed
      userSettings.RedSmsHoursStart = smsData.startTimeThresholdRedHours
      userSettings.RedSmsHoursEnd = smsData.endTimeThresholdRedHours

      userSettings.AllowGatewayRedSms = smsData.allowSendGatewayRed
      userSettings.RedGatewayBatterySmsHoursStart =
        smsData.startTimeGatewayRedHours
      userSettings.RedGatewayBatterySmsHoursEnd = smsData.endTimeGatewayRedHours
      userSettings.AllowSendSmsPumpAlerts = smsData.allowPumpAlerts
      userSettings.AllowSendSmsPumpOnOffAlerts = smsData.allowPumpOnOffAlerts
      userSettings.AllowSendSmsPumpStatusAlerts = smsData.allowPumpStatusAlerts
    }

    if (emailData) {
      userSettings.AllowSendEmail = emailData.allowSendThreshold
      userSettings.AllowYellowEmail = emailData.allowSendThresholdYellow
      userSettings.YellowEmailHoursStart =
        emailData.startTimeThresholdYellowHours
      userSettings.YellowEmailHoursEnd = emailData.endTimeThresholdYellowHours

      userSettings.AllowRedEmail = emailData.allowSendThresholdRed
      userSettings.RedEmailHoursStart = emailData.startTimeThresholdRedHours
      userSettings.RedEmailHoursEnd = emailData.endTimeThresholdRedHours

      userSettings.AllowGatewayRedEmail = emailData.allowSendGatewayRed
      userSettings.RedGatewayBatteryEmailHoursStart =
        emailData.startTimeGatewayRedHours
      userSettings.RedGatewayBatteryEmailHoursEnd =
        emailData.endTimeGatewayRedHours

      userSettings.AllowSendEmailSensors = emailData.allowNotReportingSensor
      userSettings.AllowSendEmailPumpAlerts = emailData.allowPumpAlerts
      userSettings.AllowSendEmailPumpOnOffAlerts =
        emailData.allowPumpOnOffAlerts
      userSettings.AllowSendEmailPumpStatusAlerts =
        emailData.allowPumpStatusAlerts
    }

    if (callData) {
      userSettings.AllowCall = callData.allowSendThreshold
      userSettings.AllowYellowCall = callData.allowSendThresholdYellow
      userSettings.YellowCallHoursStart = callData.startTimeThresholdYellowHours
      userSettings.YellowCallHoursEnd = callData.endTimeThresholdYellowHours

      userSettings.AllowRedCall = callData.allowSendThresholdRed
      userSettings.RedCallHoursStart = callData.startTimeThresholdRedHours
      userSettings.RedCallHoursEnd = callData.endTimeThresholdRedHours

      userSettings.AllowGatewayRedCall = callData.allowSendGatewayRed
      userSettings.RedGatewayBatteryCallHoursStart =
        callData.startTimeGatewayRedHours
      userSettings.RedGatewayBatteryCallHoursEnd =
        callData.endTimeGatewayRedHours

      userSettings.AllowHighPriorityAlerts = callData.allowSendHighPriority
      userSettings.CallAttempts = callData.callAttempts
    }

    const userSettingsObject = mapUserSettingsToResponse(userSettings)

    const userClientApi = getUserClient(
      this.props.accessToken!,
      this.props.tenantId?.toString(),
      this.props.userId
    )
    userClientApi
      .createUserSettings(userId, userSettingsObject)
      .then((response) => {
        if (response) {
          this.showAlertMessage(`Saved Information`, true, 'success')
        }
      })
  }

  public componentDidUpdate(prevProps: AllProps) {
    this.featureFlag()
    if (
      this.props.userAppSettings !== undefined &&
      this.state.loading === true
    ) {
      this.getUserSettings()

      // if (this.props.selectedTenant !== prevProps.selectedTenant) {
      this.props.setPageTitle!('User Settings')

      // }
    }
  }

  public componentDidMount() {
    this.flags = {}
    this.featureFlag()
    this.props.setPageTitle!('User Settings')
    this.getUserSettings()
  }

  private readonly showAlertMessage = (message, status, severity) => {
    this.props.setHandleAlert({
      message,
      status,
      severity,
    })
  }

  public render() {
    if (this.props.userAppSettings !== undefined && !this.state.loading) {
      return (
        <div
          style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}
        >
          <Grid
            container={true}
            // style={{ margin: '10px 10px', height: '100%', overflow: 'scroll' }}
          >
            <Grid xs={12}>{this.renderTitle()}</Grid>
            <Grid
              xs={12}
              style={{
                marginTop: '30px',
              }}
            >
              {this.renderSubtitle()}
            </Grid>
            <Grid
              item={true}
              xs={12}
              style={{
                marginTop: '30px',
              }}
            >
              {this.renderForm()}
            </Grid>
          </Grid>
        </div>
      )
    }
    return <LinearProgress />
  }

  private renderTitle() {
    return (
      <div
        style={{
          padding: '5px 25px',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography color="primary" variant="h6">
          <NotificationsActive style={{ marginRight: '10px' }} />
          NOTIFICATIONS
        </Typography>
        <Typography variant="caption">
          Alerts will be sent for all the sites you have set up on “My Sites”
        </Typography>
      </div>
    )
  }

  private renderSubtitle() {
    return (
      <div
        style={{
          padding: '5px 25px',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography color="primary" variant="h6">
          SET UP
        </Typography>
        <Typography variant="caption">
          Keep your email and mobile phone number updated below
        </Typography>
      </div>
    )
  }

  private renderForm() {
    const phone = this.state.phoneNumber
    const phoneLengthInvalid =
      phone == null || phone.length <= 8 || phone.length >= 16
    const phoneNumberWithIsoCodeInBrackets = `(${this.getIsoCodeAsNumber(
      this.state.isoCode
    )}) ${phone ?? ''}`

    const emailUser = this.state.email
    const emailNotValid = !this.validateEmail(this.state.email)

    const helperText = emailNotValid ? 'Invalid Email' : ''
    const isoCodeIsEmpty = this.state.isoCode == null

    const CountrySelect = ({ value, onChange, labels, ...rest }) => (
      <Select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <MenuItem value="">{labels.ZZ}</MenuItem>
        {getCountries()
          .sort((a, b) => {
            if (a === 'US') return -1
            if (b === 'US') return 1
            return a.localeCompare(b)
          })
          .map((country) => (
            <MenuItem key={country} value={country}>
              {labels[country]} +{getCountryCallingCode(country)}
            </MenuItem>
          ))}
      </Select>
    )

    const handlePhoneNumberChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const typedValue = event.target.value
      const re = /[0-9]+/g
      const phoneVerified = typedValue.match(re)
      this.setState({
        phoneNumber: phoneVerified ? phoneVerified[0] || '' : '',
      })
    }

    return (
      <div
        style={{
          padding: '5px 25px',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div>
          <Grid container>
            <Grid item xs={3}>
              <Typography color="primary" variant="h6">
                Select Country
              </Typography>
              <CountrySelect
                labels={en}
                value={this.state.isoCode || ''}
                onChange={(isoCode) => this.setState({ isoCode })}
                name="countrySelect"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color="primary" variant="h6">
                Phone Number
              </Typography>
              <TextField
                required={true}
                value={this.state.phoneNumber || ''}
                error={phoneLengthInvalid}
                onChange={handlePhoneNumberChange}
              />
            </Grid>
          </Grid>
          <Grid
            item={true}
            xs={4}
            style={{
              marginTop: '30px',
            }}
          >
            <Typography color="primary" variant="h6">
              Notification Email
            </Typography>
            <TextField
              required={true}
              type="email"
              fullWidth={true}
              helperText={helperText}
              error={emailNotValid}
              value={this.state.email || ''}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <AlertSetUp
                phoneNumber={phoneNumberWithIsoCodeInBrackets}
                email={emailUser}
                isUserAdmin={this.state.isUserAdmin}
                isSuperVisor={this.state.isSuperVisor}
                smsData={this.state.smsData}
                emailData={this.state.emailData}
                callData={this.state.callData}
                featureFlag={this.flags}
                handleDataChangeSms={this.handleDataChangeSms.bind(this)}
                handleDataChangeEmail={this.handleDataChangeEmail.bind(this)}
                handleDataChangeCall={this.handleDataChangeCall.bind(this)}
              />
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={1}>
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => this.verifyAlertSettingsAndSaveForm()}
                disabled={
                  emailNotValid ||
                  phoneLengthInvalid ||
                  isoCodeIsEmpty ||
                  (this.state.smsData?.allowSendThreshold === false &&
                    this.state.emailData?.allowSendThreshold === false &&
                    this.state.callData?.allowSendThreshold === false)
                }
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
        <div>
          <Dialog
            // open={this.state.isSendEmailRequiredDialogOpen}
            // onClose={this.handleSendEmailRequiredDialogClose}
            aria-labelledby="alert-email-required-dialog-title"
            aria-describedby="alert-email-required-dialog-description"
          >
            <DialogTitle id="alert-email-required-dialog-dialog-title">
              {'Alerts settings'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-email-required-dialog-description">
                Currently, at least email alerts need to be turned ON.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                // onClick={this.handleSendEmailRequiredDialogAggree}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            // open={this.state.isSetConstantAlertsDialogOpen}
            // onClose={this.handleSetConstantAlertsDialogClose}
            aria-labelledby="constant-alerts-dialog-title"
            aria-describedby="constant-alerts-dialog-description"
          >
            <DialogTitle id="constant-alerts-dialog-dialog-title">
              {'Alerts settings'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="constant-alerts-dialog-description">
                Alerts are turned ON and will be sent 24 H. Check Business Hours
                to set up another schedule.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                // onClick={this.handleSetConstantAlertsDialogAggree}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div
          style={{
            marginTop: '30px',
          }}
        ></div>
      </div>
    )
  }
}

const mapPropsFromState = ({
  appConfig,
  oidc: { user },
  userAppSettings,
  multitenantUser,
}) => ({
  accessToken: multitenantUser.accessToken,
  userId: multitenantUser.id,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  userAppSettings: userAppSettings.userAppSettings,
  selectedTenant: multitenantUser.selectedTenant,
  user: user,
  launchDarklyClientsideId: appConfig.launchDarklyClientsideId,
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(commonActionCreators, dispatch),
})

export default withUiContext(
  connect(mapPropsFromState, mapDispatchToProps)(UserSettings)
)
