import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { SaveTwoTone } from '@material-ui/icons'

import { TelemetryType } from 'api/alertservice'
import { TelemetryTypeToStatusRanges } from 'api/thresholdClient'
import { feetToFeetAndInches, feetToBbl } from 'components/utils/converter'
import ThresholdConfiguration from './ThresholdConfiguration'
import { Props as SensorChartProps } from 'components/sensors/SensorChart'
import { Site } from 'store/Site'
import { HistoricalDataState } from 'store/HistoricalData'
import { TimeRange } from 'components/misc/TimeRange'
import { TelemetryByTelemetryTypeAndAssetId } from 'store/SiteDetails'
import {
  getTimeRanges,
  Details as MetricDetails,
} from 'components/site/MetricsDashboardTab'

const styles = () =>
  createStyles({
    dialogPaperWidthSm: {
      maxWidth: 'unset',
    },
  })

interface Props {
  showThresholds?: TelemetryType
  assetId: string
  isSaveAllowed: boolean
  onSaveClicked(): void
  closeThresholdsDialog(): void
  thresholds: TelemetryTypeToStatusRanges
  historicalData: HistoricalDataState
  site?: Site
  thresholdConfigurationRef: React.RefObject<ThresholdConfiguration>
  timeRanges: (TimeRange | undefined)[]
  telemetry: TelemetryByTelemetryTypeAndAssetId
  thresholdsStateUpdated(allowSave, onStateUpdated?: () => void): void
}

type AllProps = Props & WithStyles<typeof styles>

class ThresholdsDialog extends React.Component<AllProps> {
  public render() {
    const telemetryType = this.props.showThresholds!

    return (
      <Dialog
        disableEscapeKeyDown={true}
        open={true}
        classes={{
          paperWidthSm: this.props.classes.dialogPaperWidthSm,
        }}
      >
        <DialogContent dividers={true}>
          {this.renderThresholds(telemetryType)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeThresholdsDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={this.props.isSaveAllowed}
            style={{ width: '120px' }}
            variant="contained"
            color="primary"
            startIcon={<SaveTwoTone />}
            onClick={this.props.onSaveClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private readonly renderThresholds = (telemetryType: TelemetryType) => {
    const { site, thresholds, timeRanges, telemetry, historicalData, assetId } =
      this.props
    const siteId = site?.id

    if (!siteId) {
      return null
    }

    const timeRange =
      timeRanges[MetricDetails.Sensors] ??
      getTimeRanges(MetricDetails.Sensors)[0][0]
    const realtimeData = (
      telemetry[telemetryType]?.get(assetId)?.data ?? []
    ).map((t) => ({
      timestamp: t.CreationTimeUtc.valueOf(),
      value: t.Payload.value,
    }))

    const currHistData = historicalData.data[telemetryType]?.get(assetId) ?? []
    const data = (currHistData ?? []).concat(realtimeData)

    const formatValue = this.getFormatByType(telemetryType)

    const sensorChartProps: SensorChartProps = {
      timeRange,
      data,
      statusRanges: thresholds.get(telemetryType) ?? [],
      formatValue: formatValue,
    }

    return (
      <ThresholdConfiguration
        ref={this.props.thresholdConfigurationRef}
        type={telemetryType}
        siteId={siteId}
        site={site}
        assetId={assetId}
        allThresholds={thresholds}
        stateUpdated={this.props.thresholdsStateUpdated}
        sensorChartProps={sensorChartProps}
      />
    )
  }

  private readonly getFormatByType = (type: TelemetryType) => {
    switch (type) {
      case TelemetryType.TankLevel:
      case TelemetryType.BalancedTank:
        return this.formatTankLevelValueTooltip
      case TelemetryType.CasingPressure:
      case TelemetryType.TubingPressure:
      case TelemetryType.PumpPressure:
      case TelemetryType.HeaterTemp:
      case TelemetryType.SeparatorPressure:
      case TelemetryType.CompressorPressure:
      case TelemetryType.StaticPressure:
      case TelemetryType.LiquidFlow:
      case TelemetryType.GenericSensor:
      case TelemetryType.StrokesPerMinute:
      case TelemetryType.KnockoutPressure:
        return this.formatPressureValueTooltip

      default:
        return undefined
    }
  }

  private readonly formatPressureValueTooltip = (
    value?: any,
    timeStamp?: Date
  ): React.ReactNode => {
    return <b>{typeof value === 'number' ? value.toFixed(1) : 'N/A'}</b>
  }

  private readonly formatTankLevelValueTooltip = (
    value?: any,
    timeStamp?: Date
  ): React.ReactNode => {
    if (typeof value !== 'number') {
      return 'N/A'
    }

    const [ft, ins] = feetToFeetAndInches(value)
    const { site, assetId } = this.props

    if (!site) {
      return null
    }

    const tank =
      this.props.showThresholds! === TelemetryType.BalancedTank
        ? site.BalancedTanks.find((t) => t.id === assetId)
        : site.tanks.find((t) => t.id === assetId)

    const volume = tank
      ? feetToBbl(value, tank.height, tank.capacity).toFixed(1)
      : undefined

    return (
      <React.Fragment>
        {this.renderValue('Height', `${ft}' ${ins.toFixed(1)}"`)}
        {this.renderValue('Volume', volume ? `${volume} bbl` : 'N/A')}
      </React.Fragment>
    )
  }

  private readonly renderValue = (
    label: React.ReactNode,
    value: React.ReactNode
  ) => {
    return (
      <Typography variant="subtitle1" noWrap={true}>
        {label}:&nbsp;<b>{value}</b>
      </Typography>
    )
  }
}

export default withStyles(styles)(ThresholdsDialog)
