import React from 'react'
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core'
import { ModelState } from 'store/ModelState'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PlungerArrivalTimesResponse } from 'api/apiservice'
import { SiteMatch } from 'components/SiteDetails'
import SettingsTileWrapper from './SettingsTileWrapper'
import theme from 'theme'

const styles = (theme: Theme) =>
  createStyles({
    row: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    table: {
      marginLeft: theme.spacing(1),
    },
  })

interface Props {
  readonly plungerArrivalTimes?: PlungerArrivalTimesResponse
}

interface State extends ModelState {
  readonly plungerArrivalTimes?: PlungerArrivalTimesResponse
}

type AllProps = Props &
  RouteComponentProps<SiteMatch> &
  WithStyles<typeof styles>

class Arrivals extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      plungerArrivalTimes: this.props.plungerArrivalTimes,
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.plungerArrivalTimes !== this.props.plungerArrivalTimes) {
      this.setState({
        plungerArrivalTimes: this.props.plungerArrivalTimes,
      })
    }
  }

  public render() {
    const arrivalsData = this.state.plungerArrivalTimes
    return (
      <Paper
        elevation={8}
        square={true}
        style={{
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        {arrivalsData === undefined
          ? this.renderNoDataToDisplayMessage()
          : this.renderArrivalsGrid(arrivalsData)}
      </Paper>
    )
  }

  private readonly renderArrivalsGrid = (
    arrivalsData: PlungerArrivalTimesResponse
  ) => {
    const { classes } = this.props
    const length = arrivalsData?.arrivals?.length
    if (length) {
      const leftColSize = length ? Math.ceil(length / 2) : 0

      return (
        <SettingsTileWrapper tileTitle="Arrivals">
          <Grid container item xs={12} spacing={1} className={classes.table}>
            <Grid item xs={4}>
              {this.renderArrivalTimes(arrivalsData?.arrivals!, 0, leftColSize)}
            </Grid>
            <Grid item xs={4}>
              {this.renderArrivalTimes(
                arrivalsData?.arrivals!,
                leftColSize,
                length
              )}
            </Grid>
            <Grid item xs={4}>
              {this.renderStatistics(arrivalsData)}
            </Grid>
          </Grid>
        </SettingsTileWrapper>
      )
    }

    return this.renderNoDataToDisplayMessage()
  }

  private renderNoDataToDisplayMessage() {
    return <Typography color="error">No Arrival Times to display.</Typography>
  }

  private renderArrivalTimes(arrivals: number[], from: number, to: number) {
    return (
      <React.Fragment>
        {arrivals
          ?.slice(from, to)
          .map((value, index) =>
            this.renderGridRow(
              `Arrival Time ${++index + from}`,
              value === -1 ? 'FAIL' : value
            )
          )}
      </React.Fragment>
    )
  }

  private renderStatistics(arrivalsData: PlungerArrivalTimesResponse) {
    return Object.keys(arrivalsData).map((i) => {
      const result = this.getDisplayLabelAndValue(
        i as keyof Omit<PlungerArrivalTimesResponse, 'arrivals'>,
        arrivalsData[i]
      )
      if (result === undefined) {
        return null
      }
      const [label, value] = result
      return this.renderGridRow(label, value)
    })
  }

  private readonly renderGridRow = (label, value) => {
    return (
      <Grid
        item={true}
        container={true}
        alignItems="baseline"
        key={label}
        className={this.props.classes.row}
        lg={12}
        md={6}
        sm={6}
        xs={6}
      >
        <Typography variant="body1">{label}:</Typography>
        <Typography variant="body2">
          <b>&nbsp;{value}</b>
        </Typography>
      </Grid>
    )
  }

  private getDisplayLabelAndValue(
    key: keyof Omit<PlungerArrivalTimesResponse, 'arrivals'>,
    rawValue
  ): [string, string] | undefined {
    switch (key) {
      case 'failuresTotal':
        return ['Plungers Failure Total', rawValue]
      case 'failuresToday':
        return ['Plungers Failure Today', rawValue]
      case 'failuresAverage':
        return ['Plungers Failures Average', rawValue]
      case 'arrivalsTotal':
        return ['Plungers Arrivals Total', rawValue]
      case 'arrivalsAverage':
        return ['Plungers Arrivals Average', rawValue]
      default:
        return undefined
    }
  }
}

export default withRouter(withStyles(styles)(Arrivals))
