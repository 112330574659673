import React, { forwardRef, PropsWithChildren, TdHTMLAttributes } from 'react'

import { Icons } from 'material-table'
import {
  AddBox,
  Check,
  Clear,
  Edit,
  DeleteOutline,
  ChevronRight,
  ArrowDownwardTwoTone,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  Remove,
  ViewColumn,
} from '@material-ui/icons'
import theme from '../../theme'

export const icons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardTwoTone {...props} ref={ref} />
  )),

  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),

  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export interface OneCellRowProps {
  readonly style?: React.CSSProperties
  readonly colSpan?: TdHTMLAttributes<{}>['colSpan']
  readonly align?: TdHTMLAttributes<{}>['align']
}

export function OneCellRow(props: PropsWithChildren<OneCellRowProps>) {
  return (
    <tr>
      <td
        align={props.align ?? 'center'}
        colSpan={props.colSpan}
        style={{
          padding: theme.spacing(1),
          ...props.style,
        }}
      >
        {props.children}
      </td>
    </tr>
  )
}
