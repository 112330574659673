import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'

import { sendPumpAcknowledgeEmail } from '../../../api/acknowledge'
import { setHandleAlert, setHandleDialog } from '../../../store/actions/common'

const NotExpiredActionButton = ({ token, email, note }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setHandleDialog(false))
  }

  const showAlertMessage = (message, status, severity) => {
    dispatch(
      setHandleAlert({
        message,
        status,
        severity,
      })
    )
  }

  const sendAcknowledgeNotification = () => {
    const data = {
      token,
      email,
      note,
    }

    sendPumpAcknowledgeEmail(data)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          handleClose()
          showAlertMessage('Acknowledge success', true, 'success')
        }
      })
      .catch((error) => {
        console.error(error)
        handleClose()
        showAlertMessage(error?.response?.data, true, 'error')
      })
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={sendAcknowledgeNotification}
      id="notExpiredActionButtonAcknowledge"
    >
      Ok
    </Button>
  )
}

export default NotExpiredActionButton
