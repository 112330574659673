import React from 'react'
import {
  createStyles,
  WithStyles,
  withStyles,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core'
import { ModelState } from 'store/ModelState'
import { PlungerJobInfo } from 'api/apiservice'
import SettingsTileWrapper from './SettingsTileWrapper'
import theme from 'theme'
import moment from 'moment'

const styles = () =>
  createStyles({
    gridRow: {
      marginLeft: 1,
      textAlign: 'center',
      padding: 6,
    },
  })

interface Props {
  readonly plungerJobs?: PlungerJobInfo[]
}

interface State extends ModelState {
  readonly plungerJobs?: PlungerJobInfo[]
}

type AllProps = Props & WithStyles<typeof styles>

class Jobs extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      plungerJobs: props.plungerJobs,
    }
  }

  public render() {
    const { plungerJobs } = this.props

    return (
      <Paper
        elevation={8}
        square={true}
        style={{
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        {plungerJobs === undefined || plungerJobs.length === 0
          ? this.renderNoDataToDisplayMessage()
          : this.renderPlungerJobsGrid(plungerJobs)}
      </Paper>
    )
  }

  private readonly renderPlungerJobsGrid = (plungerJobs: PlungerJobInfo[]) => {
    const { classes } = this.props

    return (
      <SettingsTileWrapper tileTitle="Jobs">
        <Grid
          container
          item
          xs={12}
          spacing={1}
          style={{ backgroundColor: '#E6E6E6' }}
          className={classes.gridRow}
        >
          <Grid item xs={4}>
            Job Id
          </Grid>
          <Grid item xs={4}>
            Status
          </Grid>
          <Grid item xs={4}>
            Created
          </Grid>
        </Grid>
        {plungerJobs.map((job, index) => this.renderGridRow(job, index))}
      </SettingsTileWrapper>
    )
  }

  private renderNoDataToDisplayMessage() {
    return <Typography color="error">No Plunger Jobs to display.</Typography>
  }

  private readonly renderGridRow = (job: PlungerJobInfo, index: number) => {
    const { classes } = this.props
    return (
      <Grid
        key={`job_${index}`}
        container
        item
        xs={12}
        spacing={1}
        className={classes.gridRow}
      >
        <Grid item xs={4}>
          <Typography noWrap>{job.jobId}</Typography>
        </Grid>
        <Grid item xs={4}>
          {job.status}
        </Grid>
        <Grid item xs={4}>
          {moment.unix(job.time).format('lll')}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Jobs)
