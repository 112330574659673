/* eslint-disable @typescript-eslint/class-name-casing */

import { UserSettingsResponseDto } from 'api/identityprovider'

export interface userSettingsModel {
  CorporateEmail?: string
  CountryCode?: string
  IsoCode?: string
  PhoneNumber?: string

  AllowSendSms?: boolean

  AllowYellowSms?: boolean
  YellowSmsHoursStart?: string
  YellowSmsHoursEnd?: string

  AllowRedSms?: boolean
  RedSmsHoursStart?: string
  RedSmsHoursEnd?: string

  AllowGatewayRedSms?: boolean
  RedGatewayBatterySmsHoursStart?: string
  RedGatewayBatterySmsHoursEnd?: string

  AllowSendEmail?: boolean

  AllowYellowEmail?: boolean
  YellowEmailHoursStart?: string
  YellowEmailHoursEnd?: string

  AllowRedEmail?: boolean
  RedEmailHoursStart?: string
  RedEmailHoursEnd?: string

  AllowGatewayRedEmail?: boolean
  RedGatewayBatteryEmailHoursStart?: string
  RedGatewayBatteryEmailHoursEnd?: string

  AllowSendEmailSensors?: boolean

  AllowCall?: boolean

  AllowYellowCall?: boolean
  YellowCallHoursStart?: string
  YellowCallHoursEnd?: string

  AllowRedCall?: boolean
  RedCallHoursStart?: string
  RedCallHoursEnd?: string

  AllowGatewayRedCall?: boolean
  RedGatewayBatteryCallHoursStart?: string
  RedGatewayBatteryCallHoursEnd?: string

  AllowHighPriorityAlerts?: boolean
  CallAttempts?: number

  AllowSendSmsPumpAlerts?: boolean
  AllowSendEmailPumpAlerts?: boolean

  AllowSendSmsPumpOnOffAlerts?: boolean
  AllowSendSmsPumpStatusAlerts?: boolean

  AllowSendEmailPumpOnOffAlerts?: boolean
  AllowSendEmailPumpStatusAlerts?: boolean
}

export interface ResponseElement {
  key: string
  value: string | number | boolean | null
}

export function mapResponseToUserSettings(
  response: ResponseElement[]
): userSettingsModel {
  const userSettings: userSettingsModel = {}

  for (const element of response) {
    switch (element.key) {
      case 'CorporateEmail':
        userSettings.CorporateEmail = element.value as string
        break
      case 'CountryCode':
        userSettings.CountryCode = element.value as string
        break
      case 'IsoCode':
        userSettings.IsoCode = element.value as string
        break
      case 'PhoneNumber':
        userSettings.PhoneNumber = element.value as string
        break

      case 'AllowSendSms':
        userSettings.AllowSendSms = element.value === 'true'
        break

      case 'AllowYellowSms':
        userSettings.AllowYellowSms = element.value === 'true'
        break
      case 'YellowSmsHoursStart':
        userSettings.YellowSmsHoursStart = element.value as string
        break
      case 'YellowSmsHoursEnd':
        userSettings.YellowSmsHoursEnd = element.value as string
        break

      case 'AllowRedSms':
        userSettings.AllowRedSms = element.value === 'true'
        break

      case 'RedSmsHoursStart':
        userSettings.RedSmsHoursStart = element.value as string
        break
      case 'RedSmsHoursEnd':
        userSettings.RedSmsHoursEnd = element.value as string
        break

      case 'AllowGatewayRedSms':
        userSettings.AllowGatewayRedSms = element.value === 'true'
        break
      case 'RedGatewayBatterySmsHoursStart':
        userSettings.RedGatewayBatterySmsHoursStart = element.value as string
        break
      case 'RedGatewayBatterySmsHoursEnd':
        userSettings.RedGatewayBatterySmsHoursEnd = element.value as string
        break

      case 'AllowSendEmail':
        userSettings.AllowSendEmail = element.value === 'true'
        break
      case 'AllowYellowEmail':
        userSettings.AllowYellowEmail = element.value === 'true'
        break
      case 'YellowEmailHoursStart':
        userSettings.YellowEmailHoursStart = element.value as string
        break
      case 'YellowEmailHoursEnd':
        userSettings.YellowEmailHoursEnd = element.value as string
        break

      case 'AllowRedEmail':
        userSettings.AllowRedEmail = element.value === 'true'
        break
      case 'RedEmailHoursStart':
        userSettings.RedEmailHoursStart = element.value as string
        break
      case 'RedEmailHoursEnd':
        userSettings.RedEmailHoursEnd = element.value as string
        break

      case 'AllowGatewayRedEmail':
        userSettings.AllowGatewayRedEmail = element.value === 'true'
        break
      case 'RedGatewayBatteryEmailHoursStart':
        userSettings.RedGatewayBatteryEmailHoursStart = element.value as string
        break
      case 'RedGatewayBatteryEmailHoursEnd':
        userSettings.RedGatewayBatteryEmailHoursEnd = element.value as string
        break

      case 'AllowSendEmailSensors':
        userSettings.AllowSendEmailSensors = element.value === 'true'
        break

      case 'AllowCall':
        userSettings.AllowCall = element.value === 'true'
        break

      case 'AllowYellowCall':
        userSettings.AllowYellowCall = element.value === 'true'
        break

      case 'YellowCallHoursStart':
        userSettings.YellowCallHoursStart = element.value as string
        break
      case 'YellowCallHoursEnd':
        userSettings.YellowCallHoursEnd = element.value as string
        break

      case 'AllowRedCall':
        userSettings.AllowRedCall = element.value === 'true'
        break
      case 'RedCallHoursStart':
        userSettings.RedCallHoursStart = element.value as string
        break
      case 'RedCallHoursEnd':
        userSettings.RedCallHoursEnd = element.value as string
        break

      case 'AllowGatewayRedCall':
        userSettings.AllowGatewayRedCall = element.value === 'true'
        break

      case 'RedGatewayBatteryCallHoursStart':
        userSettings.RedGatewayBatteryCallHoursStart = element.value as string
        break
      case 'RedGatewayBatteryCallHoursEnd':
        userSettings.RedGatewayBatteryCallHoursEnd = element.value as string
        break

      case 'AllowHighPriorityAlerts':
        userSettings.AllowHighPriorityAlerts = element.value === 'true'
        break
      case 'CallAttempts':
        userSettings.CallAttempts = element.value as unknown as number
        break
      case 'AllowSendSmsPumpAlerts':
        userSettings.AllowSendSmsPumpAlerts = element.value === 'true'
        break
      case 'AllowSendSmsPumpOnOffAlerts':
        userSettings.AllowSendSmsPumpOnOffAlerts = element.value === 'true'
        break
      case 'AllowSendSmsPumpStatusAlerts':
        userSettings.AllowSendSmsPumpStatusAlerts = element.value === 'true'
        break
      case 'AllowSendEmailPumpOnOffAlerts':
        userSettings.AllowSendEmailPumpOnOffAlerts = element.value === 'true'
        break
      case 'AllowSendEmailPumpStatusAlerts':
        userSettings.AllowSendEmailPumpStatusAlerts = element.value === 'true'
        break
      case 'AllowSendEmailPumpAlerts':
        userSettings.AllowSendEmailPumpAlerts = element.value === 'true'
        break
      default:
        break
    }
  }
  return userSettings
}

export function mapUserSettingsToResponse(
  userSettings: userSettingsModel
): UserSettingsResponseDto[] {
  const entries = Object.entries(userSettings)
  const response = entries.map(([key, value]) => {
    const element: UserSettingsResponseDto = {
      key: key,
      value: value,
    }
    return element
  })
  return response
}
